import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import LogRocket from 'logrocket';
import { Auth0Provider } from "@auth0/auth0-react";
import TagManager from 'react-gtm-module'


if(navigator.userAgent.indexOf("prerender") === -1) {
    LogRocket.init('51j2wk/hearty');
}

const tagManagerArgs = {
    gtmId: 'GTM-M6KNXD2'
}

TagManager.initialize(tagManagerArgs)



ReactDOM.render(
<Auth0Provider
    domain={process.env.REACT_APP_AUTH_DOMAIN}
    clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
    redirectUri={`${window.location.origin}/auth`}
    audience="https://api-v2.behearty.co"
    scope="read:current_user offline_access"
    useRefreshTokens={true}
    cacheLocation= 'localstorage'
    screen_hint="signup"

>


<App LogRocket={LogRocket} /> </Auth0Provider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
