import React, { useEffect, useState, useRef } from "react";
import "../CSS/Collective/Match.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as Taco } from "@fortawesome/free-regular-svg-icons";
import {
  faCode,
  faFileAlt,
  faStar,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import ReactMarkdown from 'react-markdown';

export default function Match(props, ref) {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();

  const [skills, setSkills] = useState([]);
  const [showIntroduce, setShowIntroduce] = useState(false);
  const [comments, setComments] = useState("");
  const [interviewComplete, setInterviewComplete] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [error, setError] = useState(false);
  const [rejectedInterview, setRejectedInterview] = useState(false);
  const [acceptedInterview, setAcceptedInterview] = useState(false);

  useEffect(() => {
    setMatches();
  }, [props.item]);

  const changeStatus = async (status, validate) => {
    setError(false);

    const newUser = { ...props.item.id };

    newUser.status = status || selectedStatus
    newUser.feedback = comments;

    if(validate) {
      if(!comments || (!status && !selectedStatus)) {
        setError("Please fill out all fields");
        return;
      }
    }

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/company-collective-users/${props.item.companyCollectiveUserId}/status?guid=${props.guid}`,
      {
        method: "PATCH",
        body: JSON.stringify(newUser),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const blob = await response.json();
    setInterviewComplete(false);
    setRejectedInterview(false);
    setAcceptedInterview(false);
    props.finished();
  };

  const changeSubstatus = async (substatus) => {
    setError(false);

    const newUser = { ...props.item.id };

    newUser.substatus = substatus;

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/company-collective-users/${props.item.companyCollectiveUserId}/substatus?guid=${props.guid}`,
      {
        method: "PATCH",
        body: JSON.stringify(newUser),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const blob = await response.json();
    props.finished();
  };

  const reviewMatch = async (interest, validate) => {
    setError(false);

    const body = {
      id: props.item.companyCollectiveUserId,
      companyCollectiveId: -1,
      userCollectiveId: -1,
      companyCollectiveRoleId: -1,
      companyAccepted: interest,
      feedback: comments
  }

    if(validate) {
      if(!comments) {
        setError("Please fill out all fields");
        return;
      }
    }

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/company-collective-users/${props.item.companyCollectiveUserId}/company-interest?guid=${props.guid}`,
      {
        method: "PATCH",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const blob = await response.json();
    setInterviewComplete(false);
    setRejectedInterview(false);
    setAcceptedInterview(false);
    props.finished();
  };

  const download = async (id) => {
    const download = await fetch(
    `${process.env.REACT_APP_URL_JAVA}/company-collective-users/${props.item.companyCollectiveUserId}/resume?guid=${props.guid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // Extract filename from header
    const filename = download.headers.get('content-disposition')
      .split(';')
      .find(n => n.includes('filename='))
      .replace('filename=', '')
      .replaceAll('\"', '')
      .trim()
    ;

    const blob = await download.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
  };

  const setMatches = () => {
    const roles = props.item.roleTechnologies.map((role) => {
      const filter = props.item.userTechnologies.filter((user) => {
        return user.technologyId === role.technologyId;
      });
      if (filter.length > 0) {
        role.included = true;
      }

      return role;
    });

    setSkills(roles);
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });

  const getStatus = (match) => {
    let status = match.status.replaceAll("_", " ");
    if (match.status === "INTRODUCTION_OFFERED" && match.companyAccepted == false) {
      status = 'INTRODUCTION DECLINED';
    } else if (match.status === "INTRODUCTION_OFFERED" && match.companyAccepted == true && match.candidateAccepted != true) {
      status = 'PENDING INTRODUCTION';
    }
    return status;
  };

  return (
    <div className="collectiveMatch">
      <div className="info">
        <div className="imageTop">
          <a href={`/candidate/${props.item.userCustomUrl}`} target="_blank" className="candidateProfileLink">
            <img src={props.item.userImageUrl} alt="company" />
            <h2>
              {props.item.userName} - {props.item.roleName}
            </h2>
          </a>
        </div>
        {props.item.status !== "INTRODUCTION_MADE" && (
          <span className="status">
            {getStatus(props.item)}
          </span>
        )}
        {props.item.status === "INTRODUCTION_MADE" && (
          <span className="substatus">
            <select className="substatus"
              onChange={(e) => {
                changeSubstatus(e.target.value);
              }}
              value={props.item.substatus}
            >
              <option key="INITIAL_SCREEN" value="INITIAL_SCREEN">Initial Screen</option>
              <option key="TECH_EVALUATION" value="TECH_EVALUATION">Tech Evaluation</option>
              <option key="FINAL_INTERVIEW" value="FINAL_INTERVIEW">Final Interview</option>
            </select>
          </span>
        )}
      </div>
      <div className="fit">
        <div className="skills">
          <h3>
            Skills Fit
            <span className="stars">
              {props.item.skillMatch >= 1 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
              {props.item.skillMatch >= 2 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
              {props.item.skillMatch >= 3 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
            </span>
          </h3>

          <div className="skillMap">
            {skills.map((item) => {
              return (
                <div
                  className={`skill-item ${item.included ? "included" : ""}`}
                >
                  <p>{item.technologyName}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="experience">
          <h3>
            Experience Fit
            <span className="stars">
              {props.item.experienceMatch >= 1 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
              {props.item.experienceMatch >= 2 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
              {props.item.experienceMatch >= 3 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
            </span>
          </h3>

          <p>{props.item.userExperience} Years</p>
        </div>
        <div className="compensation">
          <h3>
            Compensation Fit
            <span className="stars">
              {props.item.salaryMatch >= 1 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
              {props.item.salaryMatch >= 2 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
              {props.item.salaryMatch >= 3 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
            </span>
          </h3>
          <p>{formatter.format(props.item.userSalary)}</p>
        </div>
      </div>

      <div className="tldr">
        <h2>TLDR;</h2>
        <p>
          <ReactMarkdown>{props.item.tldr}</ReactMarkdown>
        </p>
      </div>

      <div className="actionBox">
        <div className="links">
          {props.item.resumeKey && (
            <div className='resume-download'
              onClick={() => {
                download(props.item.userId);
              }}
            >
              <FontAwesomeIcon icon={faFileAlt} title='Resume' />
            </div>
          )}

          {props.item.githubUrl && (
            <a href={props.item.githubUrl} target='_blank'>
              <FontAwesomeIcon icon={faGithub} title='Github' />
            </a>
          )}

          {props.item.linkedInUrl && (
            <a href={props.item.linkedInUrl} target='_blank'>
              <FontAwesomeIcon icon={faLinkedin} title='LinkedIn' />
            </a>
          )}

          {props.item.videoScreenUrl && (
            <a href={props.item.videoScreenUrl} target='_blank'>
              <FontAwesomeIcon icon={faVideo} title='Video' />
            </a>
          )}

          {props.item.codeAssessmentUrl && (
            <a href={props.item.codeAssessmentUrl} target='_blank'>
              <FontAwesomeIcon icon={faCode} title='Code Assessment' />
            </a>
          )}
        </div>
        {((props.item.status === "INTRODUCTION_OFFERED" || props.item.status == "SUGGESTED") && props.item.companyAccepted == null) && (
          <div className="buttons">
            <button
              onClick={() => {
                setAcceptedInterview(true);
              }}
              className="accept"
            >
              Accept
            </button>
            <button onClick={()=>{
              setRejectedInterview(true);
            }}>Decline</button>
          </div>
        )}
        {props.item.status === "INTRODUCTION_MADE" && (
          <div className="buttons">
            <button
              onClick={() => {
                setInterviewComplete(true);
              }}
              className="accept"
            >
              Interview Complete
            </button>
          </div>
        )}
      </div>

      {interviewComplete && (
        <div className="introduceModal">
        <div className="modal">
          <h3>Interview Complete</h3>

          <div className="info">
            {error && <p className="error">{error}</p>}
            <div className="profile">
              <img src={props.item.userImageUrl} alt="profile" />
              <div className="info-text">
                <h4>{props.item.userName}</h4>
                <p>{props.item.roleName}</p>
              </div>
            </div>

            <div className="hiringStatus">
              <h4>Choose a hiring status:</h4>
              <select onChange={(event)=>{
                setSelectedStatus(event.target.value);
              }}>
                <option selected disabled value="">Select</option>
                <option value="OFFER_DECLINED">Offer Made - Candidate Declined</option>
                <option value="OFFER_ACCEPTED">Offer Made - Candidate Accepted</option>
                <option value="OFFER_NOT_MADE">Offer Not Made</option>
              </select>
            </div>
   
            <div className="textarea">
            <textarea onChange={(event)=>{
                setComments(event.target.value)
            }} placeholder="Add Comments" name="introduce" id="introduce" cols="30" rows="10" />
            <button onClick={()=>{
                changeStatus(null, true);
            }} type="btn btn-large">Submit</button>
            </div>
          </div>
        </div>
        <div onClick={()=>{
          setInterviewComplete(false)
        }} className="skrim"></div>
      </div>
      )}

      {rejectedInterview && (
        <div className="introduceModal">
        <div className="modal">
          <h3>Reject Interview</h3>

          <div className="info">
            {error && <p className="error">{error}</p>}
            <div className="profile">
              <img src={props.item.userImageUrl} alt="profile" />
              <div className="info-text">
                <h4>{props.item.userName}</h4>
                <p>{props.item.roleName}</p>
              </div>
            </div>
   
            <div className="textarea">
              <p style={{
                marginBottom:'20px'
              }}>Sorry we missed the mark on this candidate! Please let us know what you didn't like about them so we can improve our recommendations. <span className="bold">The candidate will NOT see your message.</span></p>
            <textarea onChange={(event)=>{
                setComments(event.target.value)
            }} placeholder="Add Comments" name="introduce" id="introduce" cols="30" rows="10" />
            <button onClick={()=>{
                reviewMatch(false, true);
            }} type="btn btn-large">Submit</button>
            </div>
          </div>
        </div>
        <div onClick={()=>{
          setRejectedInterview(false)
        }} className="skrim"></div>
      </div>
      )}

      {acceptedInterview && (
        <div className="introduceModal">
        <div className="modal">
          <h3>Accept Introduction</h3>

          <div className="info">
            {error && <p className="error">{error}</p>}
            <div className="profile">
              <img src={props.item.userImageUrl} alt="profile" />
              <div className="info-text">
                <h4>{props.item.userName}</h4>
                <p>{props.item.roleName}</p>
              </div>
            </div>
   
            <div className="textarea">
              <p style={{
                marginBottom:'20px'
              }}>We will send an email introducing you to the candidate. Please provide any language we should include to help expedite scheduling your discussion. <span className="bold">The candidate will see your message.</span></p>
            <textarea onChange={(event)=>{
                setComments(event.target.value)
            }} placeholder="Add Comments" name="introduce" id="introduce" cols="30" rows="10" />
            <button onClick={()=>{
                reviewMatch(true, true);
            }} type="btn btn-large">Submit</button>
            </div>
          </div>
        </div>
        <div onClick={()=>{
          setAcceptedInterview(false)
        }} className="skrim"></div>
      </div>
      )}
    </div>
  );
}
