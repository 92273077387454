import React, { useEffect, useState } from "react";
import "./App.scss";
import Header from "./images/Rectangle.png";
import Logo from "./images/blackLogo.png";
import NFT from "./images/NFT.png";
import AnimatedGif from "./images/animatedNFT.gif";

import marketingFooter from "./images/marketing_footer.png";
import Faq from "./components/FAQ";
import FrogLanding from "./FrogLanding";
import Century21Landing from "./Century21Landing"

import { useAuth0 } from "@auth0/auth0-react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";

export default function Create(props, ref) {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();

  const [firstNameInvalid, setFirstNameInvalid] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastNameInvalid, setLastNameInvalid] = useState(false);
  const [lastName, setLastName] = useState("");
  const [usernameInvalid, setUsernameInvalid] = useState(false);
  const [username, setUsername] = useState("");
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [passwordConfirmInvalid, setPasswordConfirmInvalid] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState(false);
  const [matchCriteria, setMatchCriteria] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [showRedirectMessage, setShowRedirectMessage] = useState(false);
  let { code } = useParams();
  const [trophy, setTrophy] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const header = document.querySelector("nav");
    header.style.display = "none";
    window.scrollTo(0, 0);

    checkLoggedIn();
    getTrophy();

    return () => {
      const header = document.querySelector("nav");
      header.style.display = "block";
    };
  }, []);

  const checkLoggedIn = async () => {
    localStorage.setItem("NFTCode", code);

    try {
      await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
      return history.push(`/claim-nft/${code}`);
    } catch (error) {}
  };

  const getTrophy = async () => {
    let response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophies/invite/${code}`,
      {
        method: "GET",
        headers: {},
      }
    );

    const trophy = await response.json();

    setTrophy(trophy);
  };

  const login = () => {
    setShowRedirectMessage(true);

    setTimeout(() => {
      props.auth0.loginWithRedirect();
    }, 2000);
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const externalRegister = async (object) => {

    if (
      !object.passwordMatch ||
      !object.matchCriteria ||
      !object.username ||
      !object.password ||
      !object.firstName ||
      !object.lastName ||
      !validateEmail(object.username)
    ) {
      if (object.passwordMatch) {
        setPasswordConfirmInvalid(true);
        setIsRegistering(false);
      }

      if (!object.username || !validateEmail(object.username)) {
        setUsernameInvalid(true);
        setIsRegistering(false);
      }

      if (!object.password) {
        setPasswordInvalid(true);
        setIsRegistering(false);
      }

      if (object.matchCriteria === false) {
        setError({
          msg: "Please make sure your password contains at least 8 characters, an uppercase and a lowercase letter.",
        });
        setInvalid(true);
        setIsRegistering(false);

        return;
      }

      if (!object.firstName) {
        setFirstNameInvalid(true);
        setIsRegistering(false);
      }

      if (!object.lastName) {
        setLastNameInvalid(true);
        setIsRegistering(false);
      }



      if (!validateEmail(object.username)) {
        setError({
          msg: "Please enter a valid email.",
        });
        setInvalid(true);
        setIsRegistering(false);

        return;
      }

      setError({
        msg: "Please fill out all fields.",
      });
      setInvalid(true);
      setIsRegistering(false);
      return;
    }

    if (!object.checked) {
      setError({
        msg: "Please accept our terms.",
      });
      setIsRegistering(false);

      return;
    }

    const body = {
      email: object.username,
      password: object.password,
      firstName: object.firstName,
      lastName: object.lastName,
    };

    const call = fetch(`${process.env.REACT_APP_URL_JAVA}/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const data = await call;

    if (data.errors && data.errors && data.errors[0]) {
      if (data.message === "User with this email already exists") {
        setError({
          msg: `This email is already registered. <a href="/login">You can login here.</a>`,
        });
        setIsRegistering(false);

        return;
      }

      setError({
        msg: data.message,
      });
      setIsRegistering(false);

      return;
    }


    if (data.errors) {
      setError({
        msg: "We had an issue. Please contact support at admins@behearty.co",
      });
      setIsRegistering(false);

      return;
    }

    login(object.username, object.password);
  };

  const register = async () => {
    if (
      !passwordMatch ||
      !matchCriteria ||
      !username ||
      !password ||
      !firstName ||
      !lastName ||
      !validateEmail(username)
    ) {
      if (passwordMatch) {
        setPasswordConfirmInvalid(true);
        setIsRegistering(false);
      }

      if (!username || !validateEmail(username)) {
        setUsernameInvalid(true);
        setIsRegistering(false);
      }

      if (!password) {
        setPasswordInvalid(true);
        setIsRegistering(false);
      }

      if (matchCriteria === false) {
        setError({
          msg: "Please make sure your password contains at least 8 characters, an uppercase and a lowercase letter.",
        });
        setInvalid(true);
        setIsRegistering(false);

        return;
      }

      if (!firstName) {
        setFirstNameInvalid(true);
        setIsRegistering(false);
      }

      if (!lastName) {
        setLastNameInvalid(true);
        setIsRegistering(false);
      }



      if (!validateEmail(username)) {
        setError({
          msg: "Please enter a valid email.",
        });
        setInvalid(true);
        setIsRegistering(false);

        return;
      }

      setError({
        msg: "Please fill out all fields.",
      });
      setInvalid(true);
      setIsRegistering(false);
      return;
    }

    if (!checked) {
      setError({
        msg: "Please accept our terms.",
      });
      setIsRegistering(false);

      return;
    }



    const body = {
      email: username,
      password: password,
      firstName: firstName,
      lastName: lastName,
    };

    const call = fetch(`${process.env.REACT_APP_URL_JAVA}/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const data = await call;

    if (data.errors && data.errors && data.errors[0]) {
      if (data.message === "User with this email already exists") {
        setError({
          msg: `This email is already registered. <a href="/login">You can login here.</a>`,
        });
        setIsRegistering(false);

        return;
      }

      setError({
        msg: data.message,
      });
      setIsRegistering(false);

      return;
    }


    if (data.errors) {
      setError({
        msg: "We had an issue. Please contact support at admins@behearty.co",
      });
      setIsRegistering(false);

      return;
    }

    login(username, password);
  };

  if(!trophy) {
    return <p></p>
  }

  let name = trophy.trophyCollectionName.toLowerCase();

  if(name.includes('ribbit')) {
    return (
      <FrogLanding showRedirectMessage={showRedirectMessage} error={error} isRegistering={isRegistering} trophy={trophy} register={externalRegister} />
      )
  } else if (name.includes('century 21')) {
    return (
      <Century21Landing showRedirectMessage={showRedirectMessage} error={error} isRegistering={isRegistering} trophy={trophy} register={externalRegister} />
      )
  }

  return (
    <div
      style={{
        minHeight: "100%",
        paddingBottom: "50px",
      }}
      className="login register individual nftRegister"
    >
      <header
        style={{
          backgroundImage: `url(${Header})`,
        }}
      >
        <div className="logo">
          <img src={Logo} />
        </div>
        <div className="right">
          <h2>Congratulations! You've been awarded a Hearty NFT!</h2>
          <h3>
            Claim your NFT, add to your collection, and share with your
            friends and colleagues
          </h3>
        </div>
      </header>
      <div className="widths">
        <div className="left">
          <h2>Register To Claim</h2>
          <div class="registerContainer">
            {error && (
              <p
                dangerouslySetInnerHTML={{
                  __html: error.msg,
                }}
                className="error"
              ></p>
            )}

            {showRedirectMessage && (
              <p className="alert success">
                Thank you for registering! You are being redirected to login now
                and claim your NFT!
              </p>
            )}

            <div className="field">
              <label>First Name *</label>
              <input
                className={`
            ${firstNameInvalid ? "invalid" : ""}
          `}
                type="text"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </div>

            <div className="field">
              <label>Last Name *</label>
              <input
                className={`
            ${lastNameInvalid ? "invalid" : ""}
          `}
                type="text"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </div>

            <div style={{
              width:'100%'
            }} className="field">
              <label>Email *</label>
              <input
                className={`
            ${usernameInvalid ? "invalid" : ""}
          `}
                type="text"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
            </div>

            <div className="field">
              <label>Password *</label>
              <input
                className={`
            ${passwordInvalid ? "invalid" : ""}
          `}
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);

                  const regex = new RegExp(
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])\S{8,}$/g
                  );

                  const matchCriteria = regex.test(e.target.value);

                  setMatchCriteria(matchCriteria);
                }}
              />
              <p className={`passwordMatch ${matchCriteria ? "good" : "bad"}`}>
                Password must be 8 characters long and include an uppercase and
                lowercase character.
              </p>
            </div>

            <div className="field">
              <label>Confirm Password *</label>
              <input
                className={`
            ${passwordConfirmInvalid ? "invalid" : ""}
          `}
                type="password"
                value={passwordConfirm}
                onChange={(e) => {
                  setPasswordConfirm(e.target.value);
                  if (e.target.value !== password) {
                    setPasswordMatch(false);
                  } else {
                    setPasswordMatch(true);
                  }
                }}
              />
              {!passwordMatch && (
                <p className="passwordError">Passwords must match</p>
              )}
            </div>
          </div>

          <div className="row policies">
            <input
              onClick={(e) => {
                setChecked(e.target.checked);
              }}
              type="checkbox"
            ></input>
            I agree to the{" "}
            <a href="/terms" target="_blank">
              Terms and Services
            </a>{" "}
            and{" "}
            <a href="/privacy" target="_blank">
              Privacy Policy
            </a>
          </div>

          <button
            type="submit"
            disabled={isRegistering}
            onClick={() => {
              register();
            }}
          >
            Register
          </button>

          <a className="login" href="/login">
            Already a member? Login
          </a>
        </div>
        <div className="right">
          <img
            style={{
              maxWidth: "400px",
              float: "right",
              marginRight: "60px",
            }}
            src={trophy.animatedThumbnailUrl}
          />
        </div>
        <button className="down">
          Learn More <FontAwesomeIcon icon={faArrowDown} />
        </button>
      </div>

      <div className="additionalInformation">
        <div className="one">
          <div className="left">
            <h2>
              Trophies by Hearty &trade; <br />
              Turn your professional achievements into NFT keepsakes
            </h2>
            <p>
              Nike, Macys, Budweiser and more are minting NFTs for collectors --
              now we've created the first platform to recognize and share your
              sucess across the metaverse...
            </p>
          </div>
          <div className="right">
            <img src={NFT} />
          </div>
        </div>
        <div className="two">
          <div className="left">
            <img src={AnimatedGif} />
          </div>
          <div className="right">
            <h2>Trophies are verified, portable, proof of accomplishment</h2>
            <p>
              All trophies are verified by official partners or the Hearty
              community and are minted on the blockchain.
            </p>
          </div>
        </div>
        <div className="three">
          <div className="left">
            <h2>
              Your trophy is a ticket to join our exclusive communities of
              like-minded leaders
            </h2>
            <p>Come for the NFT, stay for the upgraded professional network.</p>
          </div>
        </div>
      </div>

      <footer>
        <div
          style={{
            backgroundImage: `url(${marketingFooter})`,
          }}
          className="bg"
        ></div>
        <div className="content">
          <h3>Just the FAQs</h3>
          <div className="faqs">
            <Faq
              question="Why do I have to register with Hearty to get my trophy?"
              answer="We need you to create and account so that we can verify your identity and create an NFT wallet for your NFTs."
            />

            <Faq
              question="Can I share this with other winners, such as my teammates who are part of this award?"
              answer="Yes! They must register or log in at Hearty and select the appropriate trophy in our store. They will be asked to submit proof of their eligibility in the checkout process."
            />

            <Faq
              question="May I move this to another wallet?"
              answer="You earned it, you own it, and you can move it to another wallet if you wish. Simply request a transfer within our app and we'll help you through the process."
            />

            <Faq
              question="Can I sell my NFT?"
              answer="Yes. However the Hearty wallet is not built for purchase or exchange of NFTs among members, so you will have to move it to another wallet such as (Open Sea?)"
            />

            <Faq
              question="Where can I request new NFTs?"
              answer="You'll find a Request form on the Hearty NFT Store. Note that we have a high demand and are prioritizing award requests from organizations and companies."
            />

            <Faq
              question="Who's behind this project?"
              answer={`<a target="_blank" href="https://www.bizjournals.com/cincinnati/inno/stories/news/2021/04/20/hearty-is-second-act-for-ahalogy-execs.html">Hearty was founded in 2020</a> by a team that has built successful companies together in the Web1 and Web2 waves. We’re building Hearty for Web3 and we’re on a mission to scale what we love doing most: Helping great people discover each other and accomplish great things together.`}
            />

            <div className="moreInfo">
            <h3>Other Questions?</h3>
            <p>Hit the chat button, or <a href="mailto:bob@hearty.co">shoot us an email</a>, or grab time on <a target="_blank" href="https://calendly.com/bob-gilbreath/hearty-office-hours">the calendar of Bob</a>, our co-founder and CEO.</p>
            </div>
          </div>
        </div>
        <div className="bottom">
          <p>&copy; 2021 ReferIn Inc. All rights reserved</p>

          <div className="icons">
            <a target="_blank" href="https://www.linkedin.com/company/be-hearty/">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a target="_blank" href="https://twitter.com/beheartyapp">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a target="_blank" href="mailto:admins@behearty.co">
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}
