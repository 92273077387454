import { faPencilAlt, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {useState, useEffect} from "react";
import '../CSS/Leaderboards/create.scss';
import { useAuth0 } from "@auth0/auth0-react";

export default function LeaderboardSingle(props) {
  const images = ['https://s3.us-east-2.amazonaws.com/public-assets.behearty.co/leaderboard_stocks/1.jpg', 'https://s3.us-east-2.amazonaws.com/public-assets.behearty.co/leaderboard_stocks/2.jpg', 'https://s3.us-east-2.amazonaws.com/public-assets.behearty.co/leaderboard_stocks/3.jpg','https://s3.us-east-2.amazonaws.com/public-assets.behearty.co/leaderboard_stocks/4.jpg','https://s3.us-east-2.amazonaws.com/public-assets.behearty.co/leaderboard_stocks/5.jpg','https://s3.us-east-2.amazonaws.com/public-assets.behearty.co/leaderboard_stocks/6.jpg','https://s3.us-east-2.amazonaws.com/public-assets.behearty.co/leaderboard_stocks/7.jpg','https://s3.us-east-2.amazonaws.com/public-assets.behearty.co/leaderboard_stocks/8.jpg','https://s3.us-east-2.amazonaws.com/public-assets.behearty.co/leaderboard_stocks/9.jpg','https://s3.us-east-2.amazonaws.com/public-assets.behearty.co/leaderboard_stocks/10.jpg','https://s3.us-east-2.amazonaws.com/public-assets.behearty.co/leaderboard_stocks/11.jpg','https://s3.us-east-2.amazonaws.com/public-assets.behearty.co/leaderboard_stocks/12.jpg','https://s3.us-east-2.amazonaws.com/public-assets.behearty.co/leaderboard_stocks/13.jpg','https://s3.us-east-2.amazonaws.com/public-assets.behearty.co/leaderboard_stocks/14.jpg','https://s3.us-east-2.amazonaws.com/public-assets.behearty.co/leaderboard_stocks/15.jpg','https://s3.us-east-2.amazonaws.com/public-assets.behearty.co/leaderboard_stocks/16.jpg','https://s3.us-east-2.amazonaws.com/public-assets.behearty.co/leaderboard_stocks/17.jpg','https://s3.us-east-2.amazonaws.com/public-assets.behearty.co/leaderboard_stocks/18.jpg','https://s3.us-east-2.amazonaws.com/public-assets.behearty.co/leaderboard_stocks/19.jpg','https://s3.us-east-2.amazonaws.com/public-assets.behearty.co/leaderboard_stocks/20.jpg'];
  const rando = Math.floor(Math.random() * 19);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState({
      error: false,
      message: ''
  });
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const [backgroundImageStyle, setBackgroundImageStyle] = useState({
      backgroundImage: `url(${images[rando]})`,
      url: images[rando]
  })

  const onFileChange = async (file) => {
    const selectedImage = file[0]
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", selectedImage, selectedImage.name);

    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access"
    });

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/files/uploadFile?directory=leaderboardHeader`,
      {
        method: "POST",
        enctype: "multipart/form-data",
        contentType: false,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    )
      .then((response) => {
        console.log(response);
        if (response.ok) {
          return response.text();
        }

        throw new Error("Error uploading image");
      })
      .then((data) => {
        setBackgroundImageStyle({
            backgroundImage: `url(${data})`,
            url: data
        })
      })
      .catch((error) => {});
  };


  const saveLeaderboard = async ()=>{
    if(name === '' || description === '') {
        setError({
            error: true,
            message: "Please enter a name and a description."
        })

        return;
    }

    setError({
        error: false,
        message: ""
    })

    const user = JSON.parse(localStorage.getItem('user'))
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access"
    });
    const data = {
        userId: user.id,
        title: name,
        description,
        image: backgroundImageStyle.url
    }

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/leaderboard`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error(response);
      })
      .then((data) => {
        props.history.push({
            pathname: `/leaderboards/${data.customUrl}`,
            state: { new: 'Succesfully Created Leaderboard' }
        })
      })
      .catch((error) => {
          console.log(data);
      });
  }



  useEffect(() => {

  }, [
  ])



  return (
    <div className="LeaderboardCreate app">
        <section className="left">
            <section style={backgroundImageStyle} className="titleCard">
                {error.error &&
                    <p className="error alert">{error.message}</p>
                }
                <form onSubmit={(e)=>{
                    e.preventDefault();
                    saveLeaderboard();
                }}>
                <input maxLength={80} onChange={(e)=>{
                    setName(e.target.value)
                }} type="text" className='name' placeholder="Name your leaderboard"></input>
                <textarea maxLength={100} onChange={(e)=>{
                    setDescription(e.target.value)
                }} placeholder="Add a description" />

                <div className="imageUpload">
                    <input onChange={e=> onFileChange(e.target.files)} type="file" accept="image/*" />
                    <button className="uploadHeaderPhoto"><FontAwesomeIcon icon={faPencilAlt} /></button>
                </div>
                <div className="break"></div>
                <button type="submit" className="save btn large">Create</button>
                </form>
            </section>
        </section>
        <section className="right">
            <h3>You may know</h3>
            <div className="content">

            </div>
        </section>
    </div>
  );
}
