import React, {useEffect, useState} from 'react';

import { Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { setAuthenticatedUserCollectives } from "./LocalStorageUtil.js";

export default function Auth(props) {

  const [redirect, setRedirect] = useState(false);
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if(isAuthenticated && !isLoading && getAccessTokenSilently) {
      getData().catch((error) => {
        console.log("Failed getting data: " + error);
      });
    }

    async function register() {
      const body = {
        email: user.email,
        firstName: user.given_name,
        lastName: user.family_name,
        auth0Id: user.sub,
      };
  
      const userData = await fetch(`${process.env.REACT_APP_URL_JAVA}/users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })

      const data = await userData.json();

      return user;
    }

    async function getData() {
      let token = false;
      try {
        token = await getAccessTokenSilently({
          audience: `https://api-v2.behearty.co`,
          scope: "read:current_user offline_access",
        });
      } catch (error) {
        console.log(error);
        return;
      }


        const userDetailsByIdUrl = `${process.env.REACT_APP_URL_JAVA}/users/me`;


        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
              Authorization: `Bearer ${token}`,
          },
        });
        
        const data = await metadataResponse.json();


        // They have an Auth0 id but not a db entry.
        if(data.errors && data.errors.length > 0) {
          await register();
          getData();
          return;
        }

        if(data) {
          const userObject = data;

          userObject.external_profile = data.external_profile;
          userObject.External_Profile = data.external_profile;
          let roles = [];

          roles = userObject.role_users.map((ru)=>{
            return ru.role.name;
          })

          const email = await getEmail();

          props.LogRocket.identify(data.id, {
            name: data.first_name + " "+ data.last_name,
            email: email,
          });

          localStorage.setItem("roles", JSON.stringify(roles));
          localStorage.setItem("user", JSON.stringify(data));
          await getCollectives();
        }
        props.changeHeader(true);

        const orgInvite = localStorage.getItem('orgInviteCode');

        // Are they coming from an organizational invite
        if(orgInvite) {
          await createOrgMember(orgInvite, data);
          localStorage.removeItem('orgInviteCode');
        }

        // They don't have an external profile
        if(!data.External_Profile) {
            setRedirect('/install')
        }

        await props.getOnboardingStep();

        setRedirect('/home');
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently]);

  const redirectValue = ()=>{
    if (redirect) {
      return <Redirect to={redirect} />
    }
  }

  const getEmail = async()=> {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access"
      });
    } catch (error) {
      return false;
    }
    const user = localStorage.getItem("user");
    let userObject = JSON.parse(user);

    if (!token || !userObject) return false;

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/users/email`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )

    const email = await response.text();
    
    return email;

  }

  const getOrg = async(customUrl)=> {
    let token = false;

    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
        ignoreCache: true
      });
    } catch (error) {
      console.log("ERROR GETTING TOKEN ON GET ORG")
      return;
    }


    const response = await fetch(`${process.env.REACT_APP_URL_JAVA}/community/custom-url?url=${customUrl}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "application/json",
      }
    })

    const community = await response.json();

    return community.id;
}

  const createOrgMember = async(org, user, redirect = false)=> {
    let token = false;
      try {
        token = await getAccessTokenSilently({
          audience: `https://api-v2.behearty.co`,
          scope: "read:current_user offline_access",
          ignoreCache: true
        });
      } catch (error) {
        return false;
      }

    const organization = await getOrg(org);

    let data = {
      communityId: organization
    }

    const response = await fetch(`${process.env.REACT_APP_URL_JAVA}/community-member`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    })

    const orgMember = await response.json();

    return orgMember;
  }

  const getCollectives = async()=> {
    let token = false;

    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
        ignoreCache: true
      });
    } catch (error) {
      return;
    }


    const response = await fetch(`${process.env.REACT_APP_URL_JAVA}/user-collectives/roles`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "application/json",
      }
    })

    const collectives = await response.json();

    setAuthenticatedUserCollectives(collectives);
}

  return (
    <div className="app">
        {redirectValue()}
    </div>
  );
}
