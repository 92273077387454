import React from 'react';
import './../App.scss';
import {
  Redirect,
  Link
} from "react-router-dom";



class SettingPopover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotificationModal: false
    };
    this.listRef = React.createRef();
  }

  render() {

    if (this.state.redirect) return <Redirect to={this.state.redirect} />
    const rolesJSON = localStorage.getItem('roles');

    const roles = JSON.parse(rolesJSON);
    let admin = false;

    if(roles && roles.includes('Admin')) {
      admin = true;
    }

    return (
      <div className="settingPopover">
        <ul>
            {this.props.user.has_seen_welcome_modal && (
              <>
              {/* <li><Link to="/profile" onClick={()=>{this.props.toggleModal()}}>My Profile</Link></li> */}
              <li><Link to="/settings" onClick={()=>{this.props.toggleModal()}}>My Account</Link></li>
              </>
            )}
            {admin &&
              <li><Link to="/admin" onClick={()=>{this.props.toggleModal()}}>Admin</Link></li>
            }
            <li><a target="_blank" href="https://heartycollective.notion.site/heartycollective/5657cfe48f1241cb94f157b0eeec22e0">Help</a></li>
            <li><a onClick={this.props.logout.bind(this)}>Logout</a></li>
        </ul>
        <div onClick={()=>{this.props.toggleModal()}} className="skrim"></div>
      </div>
    );
  }
}

export default SettingPopover;
