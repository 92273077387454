import React, { useEffect, useState } from "react";
import "./CSS/Century21Landing.scss";
import Logo from "./images/century-21.png";

import { useAuth0 } from "@auth0/auth0-react";
import { useParams, useLocation, useHistory } from "react-router-dom";


export default function Create(props, ref) {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();

  const [firstNameInvalid, setFirstNameInvalid] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastNameInvalid, setLastNameInvalid] = useState(false);
  const [lastName, setLastName] = useState("");
  const [usernameInvalid, setUsernameInvalid] = useState(false);
  const [username, setUsername] = useState("");
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [passwordConfirmInvalid, setPasswordConfirmInvalid] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [matchCriteria, setMatchCriteria] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [checked, setChecked] = useState(false);
  const [invalid, setInvalid] = useState(false);
  let { code } = useParams();
  const [trophy, setTrophy] = useState(false);
  const history = useHistory();
  

  const data = {
    passwordMatch,
    matchCriteria,
    username,
    password,
    firstName,
    lastName,
    checked,
  }



  return (
    <div
      style={{
        minHeight: "100%",
        paddingBottom: "50px",
      }}
      className="login register individual nftRegister Century21Landing"
    >
      <header>
        <div className="logo">
          <img src={Logo} />
        </div>
      </header>
      <div className="widths">
        <div className="left">
          <h2>Register To Claim Your Century 21 NFT</h2>
          <div class="registerContainer">
            {props.error && (
              <p
                dangerouslySetInnerHTML={{
                  __html: props.error.msg,
                }}
                className="error"
              ></p>
            )}

            {props.showRedirectMessage && (
              <p className="alert success">
                Thank you for registering! You are being redirected to login now
                and claim your NFT!
              </p>
            )}

            <div className="field">
              <label>First Name *</label>
              <input
                className={`
            ${firstNameInvalid ? "invalid" : ""}
          `}
                type="text"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </div>

            <div className="field">
              <label>Last Name *</label>
              <input
                className={`
            ${lastNameInvalid ? "invalid" : ""}
          `}
                type="text"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </div>

            <div style={{
              width:'100%'
            }} className="field">
              <label>Email *</label>
              <input
                className={`
            ${usernameInvalid ? "invalid" : ""}
          `}
                type="text"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
            </div>

            <div className="field">
              <label>Password *</label>
              <input
                className={`
            ${passwordInvalid ? "invalid" : ""}
          `}
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);

                  const regex = new RegExp(
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])\S{8,}$/g
                  );

                  const matchCriteria = regex.test(e.target.value);

                  setMatchCriteria(matchCriteria);
                }}
              />
              <p className={`passwordMatch ${matchCriteria ? "good" : "bad"}`}>
                Password must be 8 characters long and include an uppercase and
                lowercase character.
              </p>
            </div>

            <div className="field">
              <label>Confirm Password *</label>
              <input
                className={`
            ${passwordConfirmInvalid ? "invalid" : ""}
          `}
                type="password"
                value={passwordConfirm}
                onChange={(e) => {
                  setPasswordConfirm(e.target.value);
                  if (e.target.value !== password) {
                    setPasswordMatch(false);
                  } else {
                    setPasswordMatch(true);
                  }
                }}
              />
              {!passwordMatch && (
                <p className="passwordError">Passwords must match</p>
              )}
            </div>
          </div>

          <div className="row policies">
            <input
              onClick={(e) => {
                setChecked(e.target.checked);
              }}
              type="checkbox"
            ></input>
            I agree to the{" "}
            <a href="/terms" target="_blank">
              Terms and Services
            </a>{" "}
            and{" "}
            <a href="/privacy" target="_blank">
              Privacy Policy
            </a>
          </div>

          <button
            type="submit"
            disabled={props.isRegistering}
            onClick={() => {
              props.register(data);
            }}
          >
            Register
          </button>

          <a className="login" href="/login">
            Already a member? Login
          </a>
        </div>
        <div className="right">
          <img
            style={{
              maxWidth: "400px",
              float: "right",
              marginRight: "60px",
            }}
            src={props.trophy.animatedThumbnailUrl}
          />
        </div>
      </div>
    </div>
  );
}
