import React, { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import "../CSS/Collective/admin.scss";
import { Link } from "react-router-dom";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { formatCandidateStatus } from "../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faFileAlt, faVideo } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';

const CollectiveCandidateAdmin = (props) => {
  const {
    loginWithRedirect,
    user,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    logout,
  } = useAuth0();
  const userObject = JSON.parse(localStorage.getItem("user"));
  const [users, setUsers] = useState([]);
  const [nearMatches, setNearMatches] = useState([]);
  const [activeCandidates, setActiveCandidates] = useState([]);
  const [accepted, setAccepted] = useState([]);
  const [view, setView] = useState("active");
  const [searchText, setSearchText] = useState("");
  const [skillsDict, setSkillsDict] = useState({});
  const [counts, setCounts] = useState({});

  const [isAdmin, setIsAdmin] = useState(() => {
    const rolesJSON = localStorage.getItem("roles");
    const roles = JSON.parse(rolesJSON);
    return roles && roles.includes("Admin");
  });

  const handleSearch = async (query) => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-collectives/list?size=1000&name=${query}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setView('all');
    const searched = await response.json();
    setUsers(searched.content);
  };

  const searchAPIDebounced = AwesomeDebouncePromise(handleSearch, 400);

  const getUsers = async (status) => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      loginWithRedirect();
    }

    let statusText = '';

    if(status) {
      statusText = `&status=${status}`;
    }

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-collectives/list?size=1000${statusText}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();

    setUsers(data.content);
  };

  const getActiveCandidates = async () => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      loginWithRedirect();
    }

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-collectives/active`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();

    setActiveCandidates(data);
  };

  const getSkills = async(userCollectiveId) => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      loginWithRedirect();
    }

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-collective-technologies?userCollectiveId=${userCollectiveId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return await response.json();
  };

  const getCounts = async() => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      loginWithRedirect();
    }

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-collectives/counts`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    let countsResponse = await response.json();
    setCounts(countsResponse);
  };

  const getUsersBySubstatus = async (substatus) => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      loginWithRedirect();
    }

    if (substatus == 'active_matches') {
      substatus += ',introduction_made,introduction_offered';
    }

    let statusText = '';

    if(substatus) {
      substatus.split(',').forEach((sub) => {
        statusText = statusText + `&substatus=${sub}`;
      });
    }

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-collectives/list?size=1000${statusText}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();

    setUsers(data.content);

    if (substatus.includes("pending")) {
      await Promise.all(data.content.map(async (u) => {
        const skills = await getSkills(u.userCollectiveId);
        skillsDict[u.userCollectiveId] = skills;
      }));
    }
  };

  const getNearMatches = async () => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      loginWithRedirect();
    }

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/company-collective-users/near-matches`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();

    setNearMatches(data);
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0
  });

  const downloadResume = async (externalProfileId) => {
    const token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const download = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-collectives/resume?externalProfileId=${externalProfileId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    // Extract filename from header
    const filename = download.headers.get('content-disposition')
      .split(';')
      .find(n => n.includes('filename='))
      .replace('filename=', '')
      .replaceAll('\"', '')
      .trim()
    ;

    const blob = await download.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
  };

  const updateUserStatus = async(userCollectiveId, status) => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      loginWithRedirect();
    }

    const body = {
      status: status,
    };

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-collectives/${userCollectiveId}/status`,
      {
        method: "PATCH",
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    let res = await response.json();

    if (response.status === 400) {
      window.alert(res.message);
    }

    getUsersBySubstatus(view);
    getCounts();
  };

  const dismissMatch = async (companyCollectiveUserId) => {
    if (window.confirm("Are you sure you want to dismiss this match?")) {
      let token = false;
      try {
        token = await getAccessTokenSilently({
          audience: `https://api-v2.behearty.co`,
          scope: "read:current_user offline_access",
        });
      } catch (error) {
        console.log(error);
      }
      let headers = {
        "Content-Type": "application/json",
      };

      if (token) {
        headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
      }

      let response = await fetch(
        `${process.env.REACT_APP_URL_JAVA}/company-collective-users/${companyCollectiveUserId}`,
        {
          method: "DELETE",
          headers,
        }
      );
    }
  };

  const introduce = async (match) => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      console.log(error);
    }
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }

    const body = {
        id: match.companyCollectiveUserId,
        companyCollectiveId: match.companyCollectiveId,
        userCollectiveId: match.userCollectiveId,
        companyCollectiveRoleId: match.companyCollectiveRoleId,
        status: "INTRODUCTION_MADE"
    }

    let response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/company-collective-users/${match.companyCollectiveUserId}/status`,
      {
        method: "PATCH",
        body: JSON.stringify(body),
        headers,
      }
    );
  };

  const updateLastContacted = async (userCollectiveId, date) => {
    const token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const body = {
      lastContactDate: date,
    };

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-collectives/${userCollectiveId}/last-contact`,
      {
        method: "PATCH",
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();
    getActiveCandidates();
  };

  useEffect(() => {
    if (isAdmin) {
      getActiveCandidates();
      getCounts();
    }
  }, []);

  if (!userObject) {
    return <div>Loading...</div>;
  }

  return (
    <div className="collectiveAdmin">
      <div className="sidebar">
        <a
          className={`${view === "all" ? "active" : ""}`}
          onClick={async() => {
            await getUsers();
            setView("all");
          }}
        >
          All
        </a>
        <a
          className={`${view === "active" ? "active" : ""}`}
          onClick={async() => {
            await getActiveCandidates();
            setView("active");
          }}
        >
          Actively Interviewing ({counts.activelyInterviewing})
        </a>
        <a
          className={`${view === "pending" ? "active" : ""}`}
          onClick={async() => {
            await getUsers("pending");
            setView("pending");
          }}
        >
          Pending Review {counts.pendingReview > 0 ? `(${counts.pendingReview})` : null}
        </a>
        <a
          className={`${view === "initial_approval" ? "active" : ""}`}
          onClick={async() => {
            await getUsers("initial_approval");
            setView("initial_approval");
          }}
        >
          Initial Approval {counts.initialApproval > 0 ? `(${counts.initialApproval})` : null}
        </a>
        <a
          className={`${view === "pending_introduction" ? "active" : ""}`}
          onClick={async() => {
            await getUsers("pending_introduction");
            setView("pending_introduction");
          }}
        >
          Pending Introduction {counts.pendingIntroduction > 0 ? `(${counts.pendingIntroduction})` : null}
        </a>
        <a
          className={`${view === "near_matches" ? "active" : ""}`}
          onClick={async() => {
            await getNearMatches();
            setView("near_matches");
          }}
        >
          Near Matches {counts.nearMatches > 0 ? `(${counts.nearMatches})` : null}
        </a>
      </div>
      <div className="talentContainer">
        <div className="talent">
          {view !== 'active' && (
            <div className="filters">
              <input
                className="search"
                onChange={(e) => {
                  setSearchText(e.target.value);
                  searchAPIDebounced(e.target.value);
                }}
                type="text"
                value={searchText}
                placeholder="Search"
              />
              <select className="statusFilter"
                onChange={(e) => {
                  setView('all');
                  getUsersBySubstatus(e.target.value);
                }}
                defaultValue="Filter by Status"
              >
                <option disabled={true} value="Filter by Status">Filter by Status</option>
                <option key="ALL" value="">All</option>
                <option key="ACTIVE_MATCHES" value="ACTIVE_MATCHES">Matches</option>
                <option key="ACTIVE_NO_MATCHES" value="ACTIVE_NO_MATCHES">No Matches</option>
                <option key="INTRODUCTION_OFFERED" value="INTRODUCTION_OFFERED">Introduction Offered</option>
                <option key="INTRODUCTION_MADE" value="INTRODUCTION_MADE">Actively Interviewing</option>
                <option key="INACTIVE" value="INACTIVE">Inactive</option>
                <option key="REJECTED" value="REJECTED">Rejected</option>
                <option key="PENDING" value="PENDING">Pending</option>
              </select>
            </div>
          )}
          {view !== 'near_matches' && view !== 'active' && users.map((user) => {
            return (
              <div className="allCandidate" key={user.userCollectiveId}>
                <Link to={`/collective/user/${user.userCollectiveId}`} target="_blank">
                  <div className="name-image">
                    <div className="avatar">
                      <img src={user?.imageUrl} alt="avatar" />
                      <h3>{user.userUsername}</h3>
                    </div>
                  </div>
                </Link>
                <div className="matchCount">
                  {user.matchCount} matches
                </div>
                {(view === 'pending' || view === 'pending_introduction' || view === 'initial_approval') && (
                  <>
                  <div>
                    {formatter.format(user.salary)}
                  </div>
                  <div>
                    {user.experience} years
                  </div>
                  <div className="icons">
                    {(user.resumeKey || view === 'pending_introduction') && (
                      <div className={`resume-download ${user.resumeKey ? '' : 'greyed-out'}`}
                        onClick={() => {
                          if (user.resumeKey !== null) {
                            downloadResume(user.externalProfileId);
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faFileAlt} title="Resume" />
                      </div>
                    )}

                    {(view === 'pending_introduction') && (
                      <>
                      <a href={user.videoUrl} target='_blank'>
                        <FontAwesomeIcon icon={faVideo} title="Video" className={`${user.videoUrl ? '' : 'greyed-out'}`} />
                      </a>
                      <div className={`tldr ${user.tldr ? '' : 'greyed-out'}`}>
                        ;TLDR
                      </div>
                      </>
                    )}

                    {(view === 'pending' || view === 'initial_approval') && user.linkedinUrl && (
                      <a href={user.linkedinUrl} target='_blank'>
                        <FontAwesomeIcon icon={faLinkedin} title="LinkedIn" />
                      </a>
                    )}
                  </div>

                  {view === 'pending' && (
                    <div className="skills">
                      {
                        skillsDict[user.userCollectiveId]?.map((s) => { return s.technologyName + " (" + s.score + ")" }).join(", ")
                      }
                    </div>
                  )}
                  <div className="buttons">
                    {(view === 'pending' &&
                      <>
                      <button className="introduce"
                        onClick={async()=> {
                          await updateUserStatus(user.userCollectiveId, 'INITIAL_APPROVAL');
                        }}>
                        Initial Approval
                      </button>
                      <button className="decline"
                        onClick={async()=> {
                          await updateUserStatus(user.userCollectiveId, 'REJECTED');
                        }}>
                        Reject
                      </button>
                      </>
                    )}
                    {(view === 'initial_approval' &&
                      <>
                      <button className="introduce"
                        onClick={async()=> {
                          await updateUserStatus(user.userCollectiveId, 'PENDING_INTRODUCTION');
                        }}>
                        Pending Introduction
                      </button>
                      <button className="decline"
                        onClick={async()=> {
                          await updateUserStatus(user.userCollectiveId, 'REJECTED');
                        }}>
                        Reject
                      </button>
                      </>
                    )}
                    {(view === 'pending_introduction' &&
                      <>
                      <button className="introduce"
                        onClick={async()=> {
                          await updateUserStatus(user.userCollectiveId, 'ACTIVE');
                        }}>
                        Active
                      </button>
                      <button className="decline"
                        onClick={async()=> {
                          await updateUserStatus(user.userCollectiveId, 'INACTIVE');
                        }}>
                        Inactive
                      </button>
                      </>
                    )}

                  </div>
                </>
                )}
                {view !== 'pending' && view !== 'pending_introduction' && view !== 'initial_approval' && (
                  <div className="status">
                    <div><p>{formatCandidateStatus(user.substatus) || "Inactive"}</p></div>
                  </div>
                )}
              </div>
            );
          })}
          {view === 'near_matches' && nearMatches.map((match) => {
            return (
              <div className="allCandidate">
                <Link to={`/collective/user/${match.userCollectiveId}`}>
                  <div className="name-image">
                    <div className="avatar">
                      <img src={match?.userImageUrl} alt="avatar" />
                      <h3>{match.userName}</h3>
                    </div>
                  </div>
                </Link>
                <div>
                  {match.candidateAccepted == null ? 'No Response' : 'Declined'}
                </div>
                <div>
                  {`${match.roleName} @ ${match.companyName}`}
                </div>
                <div className="buttons">
                  <button className="introduce"
                    onClick={async()=> {
                      await introduce(match);
                      getNearMatches();
                    }}>
                    Introduce
                  </button>
                  <button className="decline"
                    onClick={async()=> {
                      await dismissMatch(match.companyCollectiveUserId);
                      getNearMatches();
                    }}>
                    Decline
                  </button>
                </div>
              </div>
            )
          })}
          {view === 'active' && (
            <table className="active">
              <thead className="sticky">
                <tr>
                  <th>Candidate</th>
                  <th>Introductions Offered</th>
                  <th>Initial Screen</th>
                  <th>Tech Evaluation</th>
                  <th>Final Interview</th>
                  <th>Offer Not Made</th>
                </tr>
              </thead>
              <tbody>
                {activeCandidates.map((candidate) => {
                  return (
                    <tr key={candidate.userCollectiveId}>
                      <td className="name-image">
                        <span className="avatar">
                          <Link to={`/collective/user/${candidate.userCollectiveId}`} target="_blank">
                            <img src={candidate.imageUrl} alt="avatar" />
                          </Link>
                        </span>
                        <span className="name-date">
                          <Link to={`/collective/user/${candidate.userCollectiveId}`} target="_blank">
                            <h3 className="name">{candidate.name}</h3>
                          </Link>
                          <span className="last-contacted">
                            <div className="label">Last Contacted:</div> 
                            <DatePicker
                              selected={candidate.lastContactDate ? new Date(candidate.lastContactDate + " 00:00:00") : null}
                              onChange={(date) => updateLastContacted(candidate.userCollectiveId, date)}
                              placeholderText="No contact"
                              maxDate={new Date()}
                              dateFormat="M/d/yy"
                            />
                          </span>
                        </span>
                      </td>
                      <td><span className="tooltip">{candidate.introductionsOffered}<span className="tooltiptext">{candidate.introductionsOfferedCompanies?.replaceAll("|", "\n")}</span></span></td>
                      <td><span className="tooltip">{candidate.initialScreen}<span className="tooltiptext">{candidate.initialScreenCompanies?.replaceAll("|", "\n")}</span></span></td>
                      <td><span className="tooltip">{candidate.techEvaluation}<span className="tooltiptext">{candidate.techEvaluationCompanies?.replaceAll("|", "\n")}</span></span></td>
                      <td><span className="tooltip">{candidate.finalInterview}<span className="tooltiptext">{candidate.finalInterviewCompanies?.replaceAll("|", "\n")}</span></span></td>
                      <td><span className="tooltip">{candidate.offerNotMade}<span className="tooltiptext">{candidate.offerNotMadeCompanies?.replaceAll("|", "\n")}</span></span></td>
                    </tr>
                  )
                })}
              </tbody>
              <tfoot className="sticky">
                <tr>
                  <td>TOTAL</td>
                  {activeCandidates && activeCandidates.length > 0 && (
                    <>
                    <td>{activeCandidates.map((c) => c.introductionsOffered).reduce((sum, candidate) => sum + candidate)}</td>
                    <td>{activeCandidates.map((c) => c.initialScreen).reduce((sum, candidate) => sum + candidate)}</td>
                    <td>{activeCandidates.map((c) => c.techEvaluation).reduce((sum, candidate) => sum + candidate)}</td>
                    <td>{activeCandidates.map((c) => c.finalInterview).reduce((sum, candidate) => sum + candidate)}</td>
                    <td>{activeCandidates.map((c) => c.offerNotMade).reduce((sum, candidate) => sum + candidate)}</td>
                    </>
                  )}
                </tr>
              </tfoot>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default CollectiveCandidateAdmin;
