import React, { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight, faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import Nft from "./Nft";
import "../../CSS/Trophies/UserProperty.scss";

const UserProperty = (props) => {
  const {
    getAccessTokenSilently,
  } = useAuth0();
  let {code} = useParams();
  const [nfts, setNfts] = useState([]);
  const [values, setValues] = useState(props.property.values);
  const [enteredValue, setEnteredValue] = useState("");


  useEffect(() => {
  }, []);

  return (
    <div className="userProperty">
    <p>{props.property.name}</p>
    <FontAwesomeIcon icon={faTimes} onClick={()=>{
      props.remove(props.property);
    }} />

    <div className="dropdownValues">
    <h3>
        Add Values
    </h3>

    <input value={enteredValue} type='text' placeholder='Add Value' onChange={(e)=>{
        setEnteredValue(e.target.value);
    }}/>

    <span className="btn medium" onClick={()=>{
        setValues([...values, enteredValue]);
        setEnteredValue("");
        const newProperty  = {...props.property};
        newProperty.values = [...values, enteredValue];
        props.update(newProperty);
    }}>Add</span>

    {values && values.map((value, index) => {
        return (
            <div key={index}>
                {value}
                <FontAwesomeIcon icon={faTimes} onClick={()=>{
                    const newValues = values.filter((v)=>{
                        return v !== value;
                    });
                    setValues(newValues);
                    const newProperty  = {...props.property};
                    newProperty.values = newValues;
                    props.update(newProperty);
                }} />
            </div>
        );
    })}
  </div>
  </div>
  )
};

export default UserProperty;
