import Captain from "../images/captain-emoji.png";
import { Link } from "react-router-dom";
import { capitalizeLocation } from "../helpers";
import "../CSS/ProfileHomeSidebar.scss"

export default function ProfileHomeSidebar(props) {
    return (
        <div className="profile-home-sidebar">
            <div className="logo">
                {props.headerImage && <img src={props.headerImage} alt="logo" />}
            </div>
            <div className="profile-img-edit">
                <div className="profile-img">
                    <Link to={`/user/${props.customUrl}`}>
                    <img src={props.image ? props.image : Captain} alt="profile" />
                    </Link>
                </div>
            </div>
            <h2 className="name">
            <Link to={`/user/${props.customUrl}`}>
                <span>{props.name}</span>
            </Link>
            <div className="badges">
                {props.jobFunctions && props.jobFunctions.length > 0 &&  (
                    props.jobFunctions.map((jobFunction) => {
                        return (
                        <span className="job-function" key={jobFunction}>{jobFunction}</span>
                        );
                    })
                )}
                {props.badges && props.badges.length > 0 && props.badges.map((badge) => {
                    return (
                        <span key={badge} className="allyBadge">{badge}</span>
                    )
                })}
            </div>
            </h2>
            <div className="headline">
                <span>{props.headline}</span>
            </div>
            <div className="location">
                <span>{capitalizeLocation(props.location)}</span>
            </div>
            {props.area_of_expertise && (
            <div className="expertise">
                <label>Area of Expertise:</label>
                <div>{props.area_of_expertise}</div>
            </div>
            )}
            <div className="edit-profile">
                <Link to="/profile/edit"><button>Edit Profile</button></Link>
            </div>
        </div>
    )
} 