import React, { useEffect, useState, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as Taco } from "@fortawesome/free-regular-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { formatCandidateStatus } from "../helpers";
import {
  faCode,
  faFileAlt,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

export default function CandidateMatch(props) {
  const { getAccessTokenSilently } = useAuth0();
  const [userSalaryMin, setUserSalaryMin] = useState(0);
  const [userSalaryMax, setUserSalaryMax] = useState(0);
  const [userTechnologies, setUserTechnologies] = useState([]);

  useEffect(() => {
    const roleTechnologies = props.roleTechnologies.map((tech) => { return tech.technologyName });
    const userTech = props.match.userTechnologies;

    userTech.forEach((skill) => {
      if (roleTechnologies.includes(skill.technologyName)) {
        skill.included = true;
      }
    });
    setUserTechnologies(userTech);
  });

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });

  const download = async (id) => {
    const token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const download = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-collectives/resume?externalProfileId=${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    // Extract filename from header
    const filename = download.headers.get('content-disposition')
      .split(';')
      .find(n => n.includes('filename='))
      .replace('filename=', '')
      .replaceAll('\"', '')
      .trim()
    ;

    const blob = await download.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
  };

  const dismiss = async (companyCollectiveUserId) => {
    if (window.confirm("Are you sure you want to dismiss this match?")) {
      let token = false;
      try {
        token = await getAccessTokenSilently({
          audience: `https://api-v2.behearty.co`,
          scope: "read:current_user offline_access",
        });
      } catch (error) {
        console.log(error);
      }
      let headers = {
        "Content-Type": "application/json",
      };

      if (token) {
        headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
      }

      let response = await fetch(
        `${process.env.REACT_APP_URL_JAVA}/company-collective-users/${companyCollectiveUserId}`,
        {
          method: "DELETE",
          headers,
        }
      );

      props.dismiss(companyCollectiveUserId);
    }
  };

  const offerIntroduction = async (companyCollectiveUserId) => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      console.log(error);
    }
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }

    const body = {
        id: companyCollectiveUserId,
        companyCollectiveId: props.match.companyCollectiveId,
        userCollectiveId: props.match.userCollectiveId,
        companyCollectiveRoleId: props.match.companyCollectiveRoleId,
        status: "INTRODUCTION_OFFERED"
    }

    let response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/company-collective-users/${companyCollectiveUserId}/status`,
      {
        method: "PATCH",
        body: JSON.stringify(body),
        headers,
      }
    );

    if (response.status === 200) {
        props.offerIntroduction(companyCollectiveUserId);
    }
  };

  return (
    <div className="collectiveMatch">
      <div className="header">
        <div className="userInfo">
          <a className="userLink" target="_blank" href={`/collective/user/${props.match.userCollectiveId}`}>
            <span>{props.match.userName}</span>
          </a>
        </div>
        <div className="links">
          {props.match.userResumeKey && (
            <div className='resume-downloads'
              onClick={() => {
                download(props.match.externalProfileId);
              }}
            >
              <FontAwesomeIcon icon={faFileAlt} title="Resume" />
            </div>
          )}

          {props.match.userGithub && (
            <div>
            <a href={props.match.userGithub} target='_blank'>
              <FontAwesomeIcon icon={faGithub} title="Github" />
            </a>
            </div>
          )}

          {props.match.userLinkedinUrl && (
            <div>
            <a href={props.match.userLinkedinUrl} target='_blank'>
              <FontAwesomeIcon icon={faLinkedin} title="LinkedIn" />
            </a>
            </div>
          )}

          {props.match.userVideoUrl && (
            <div>
            <a href={props.match.userVideoUrl} target='_blank'>
              <FontAwesomeIcon icon={faVideo} title="Video" />
            </a>
            </div>
          )}

          {props.match.userCodeAssessmentUrl && (
            <div>
            <a href={props.match.userCodeAssessmentUrl} target='_blank'>
              <FontAwesomeIcon icon={faCode} title="Code Assessment" />
            </a>
            </div>
          )}
        </div>
        {props.match.status === 'SUGGESTED' && (
          <div>
            {props.match.userStatus === 'ACTIVE' && (
              <button className="offerIntro" onClick={() => {offerIntroduction(props.match.companyCollectiveUserId)}}>Offer Introduction</button>
            )}
            {props.match.userStatus !== 'ACTIVE' && (
              <label>Candidate {props.match.userStatus}</label>
            )}
            <button className="dismiss" onClick={() => {dismiss(props.match.companyCollectiveUserId)}}>X</button>
          </div>
        )}
        {props.match.status !== 'SUGGESTED' && (
          <div className="status">
            {formatCandidateStatus(props.match.status)}
          </div>
        )}
      </div>
      <div className="fit">
        <div className="skills">
          <h3>
            Skills Fit
            <span>
              {props.match.skillMatch >= 1 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
              {props.match.skillMatch >= 2 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
              {props.match.skillMatch >= 3 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
            </span>
          </h3>

          <div className="skillMap">
            {userTechnologies.map((skill) => {
              return (
                <div key={skill.id}
                  className={`skill-item ${skill.included ? "included" : ""}`}
                  title={skill.score}
                >
                  <p>{skill.required ? "*" : ""}{skill.technologyName}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="experience">
          <h3>
            Experience Fit
            <span>
              {props.match.experienceMatch >= 1 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
              {props.match.experienceMatch >= 2 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
              {props.match.experienceMatch >= 3 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
            </span>
          </h3>

          <p>{props.match.userExperience} Years</p>
        </div>
        <div className="compensation">
          <h3>
            Compensation Fit
            <span>
              {props.match.salaryMatch >= 1 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
              {props.match.salaryMatch >= 2 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
              {props.match.salaryMatch >= 3 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
            </span>
          </h3>
          <p>{formatter.format(props.match.userSalary)}</p>
        </div>
      </div>
    </div>
  );
}
