import React, { useEffect } from 'react'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import './styles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBold, faList, faListOl, faListUl, faParagraph } from '@fortawesome/free-solid-svg-icons'

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null
  }

  return (
    <>
      <button
        onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().toggleBold().run()}
        }
        className={editor.isActive('bold') ? 'is-active' : ''}
      >
        <FontAwesomeIcon icon={faBold} />
      </button>
      <button
        onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().toggleBulletList().run()}
        }
        className={editor.isActive('bulletList') ? 'is-active' : ''}
      >
         <FontAwesomeIcon icon={faListUl} />
      </button>
      <button
        onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().toggleOrderedList().run()}
        }
        className={editor.isActive('orderedList') ? 'is-active' : ''}
      >
         <FontAwesomeIcon icon={faListOl} />
      </button>
    </>
  )
}

export default (props) => {


  if(props.editing === true && !props.data) return null;

  const editor = useEditor({
    extensions: [
      StarterKit,
    ],
    onUpdate() {
        const json = this.getHTML()
        props.update(json);
      },
    content: props.data
  })



  return (
    <div className="editor">
      <EditorContent editor={editor} />
      <MenuBar editor={editor} />

    </div>
  )
}