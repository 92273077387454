import React, { useEffect, useState } from "react";
import "../CSS/Collective/Companies.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { getAuthenticatedUserCollectives } from "../LocalStorageUtil";

export default function Companies(props, ref) {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [companies, setCompanies] = useState([]);
  const [admin, setAdmin] = useState(() => {
    const rolesJSON = localStorage.getItem("roles");
    const roles = JSON.parse(rolesJSON);
    return roles && roles.includes("Admin");
  });

  useEffect(() => {
    getCompanies();
  }, []);

  const getCompanies = async () => {
    const collectives = getAuthenticatedUserCollectives();

    let token = false;
    let headers = {};
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });

      headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
    } catch (error) {}

    let url = `${process.env.REACT_APP_URL_JAVA}/company-collectives?`
    if (collectives) {
      for (let i = 0; i < collectives.length; i++) {
        url += `collectiveId=${collectives[i].collectiveId}&`;
      }
    }

    const response = await fetch(
      url,
      {
        headers,
      }
    );
    const data = await response.json();
    setCompanies(data.content);
  };



  return (
    <div className="companies">
      <h2>Hearty Collective Companies</h2>
      {admin && (
        <Link to="/company/new">Create new company</Link>
      )}
      <div className="content">
      {companies.map((company) => {
        return (
          <div className="company" key={company.customUrl}>
            <Link to={`/company/${company.customUrl}`}>
              <div className="left">
                <img src={company.image} alt="company logo" />
              </div>
              <div className="middle">
                <h3>{company.name}</h3>
                <h4>{company.collectiveDescription}</h4>
              </div>
            </Link>
          </div>
        )
      })}
      </div>
    </div>
  );
}
