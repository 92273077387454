import React, { useState, useEffect } from "react";
import "../../CSS/Trophies/components/Nft.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExchangeAlt,
  faTimes,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

const NftSingle = (props) => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  const [nft, setNft] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [properties, setProperties] = useState(JSON.parse(props.nft.metadata));
  const [owners, setOwners] = useState([]);
  const [showClaim, setShowClaim] = useState(false);
  const [etherWallet, setEtherWallet] = useState(false);
  const [match, setMatch] = useState(false);
  const [claimed, setClaimed] = useState(false);
  const [selector, setSelector] = useState("details");
  const [requested, setRequested] = useState(false);
  const [recipientCount, setRecipientCount] = useState(props.nft.recipientCount);
  const [me, setMe] = useState({
    external_profile: {},
  });
  const [isAdmin, setIsAdmin] = useState(() => {
    const rolesJSON = localStorage.getItem("roles");
    const roles = JSON.parse(rolesJSON);
    return roles && roles.includes("Admin");
  });
  const history = useHistory();
  const [awaitingClaim, setAwaitingClaim] = useState(false);



  useEffect(() => {
    const rolesJSON = localStorage.getItem("roles");
    const roles = JSON.parse(rolesJSON);
    const me = localStorage.getItem("user");
    setMe(JSON.parse(me));


  }, [props.nft]);



  const getOwners = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_JAVA}/trophies/${props.nft.trophyId}/awardees?page=0&size=1000`,
        {
          headers: {},
        }
      );
      const owners = await response.json();
      setOwners(owners.content);
    } catch (error) {}
  };

  const claim = async () => {
    const token = await getAccessTokenSilently();
    const body = {
      request: 'SINGLE NFT CLAIMED',
    };
    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophies/${props.nft.trophyId}/request-trophy`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const owners = await response.json();
    setRecipientCount(1);
    setRequested(true);
  };

  const sendClaim = async () => {
    const token = await getAccessTokenSilently();
    setAwaitingClaim(true);
    const body = {
      walletAddress: etherWallet,
    };
    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophies/${props.nft.trophyId}/request-nft`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const owners = await response.json();
    setClaimed(true);
    setAwaitingClaim(false);
  };

  return (
    <div key={props.nft.id} className={`nft ${(recipientCount > 0 && props.singleDisplay !== true) ? 'claimed' : ''}`}>
      <div
        onClick={() => {
          setShowPopup(!showPopup);
          getOwners();
        }}
      >
        <img className="displayImage" src={props.nft.animatedThumbnailUrl} alt={props.nft.name} />
        {props.nft.requestingUserHasTrophy && props.showClaimed && (<div className="pending">CLAIMED</div>)}

        {props.collectionView !== true ? (
          <h3>{props.nft.trophyCollectionName}</h3>
        ) : (
          <h3>{props.nft.trophyName}</h3>
        )}
        <div className="info">
          {props.collectionView !== true ? (
            <h3>{props.nft.trophyName}</h3>
          ) : (
            <h3></h3>
          )}
          {props.collectionView !== true ? (
            <span>
            <FontAwesomeIcon icon={faUser} /> {recipientCount}
          </span>
          ) : (
            <span style={{
              marginTop:'-30px'
            }}>
            <FontAwesomeIcon icon={faUser} /> {recipientCount}
          </span>
          )}
          
        </div>
        {props.singleDisplay !== true && (
          <div>
        {recipientCount > 0 && <h3 className="claimed">Claimed</h3>}
        {recipientCount === 0 && (
          <h3 className="available">Available</h3>
        )}
        </div>
        )}
      </div>
      {showPopup && (
        <div className="popupModal">
          <div className="modal">
            <h3>
              NFT Details
              {!props.nft.requestingUserHasTrophy > 0 && (
                <div className="buttons">
                  <FontAwesomeIcon
                    onClick={() => {
                      setShowPopup(false);
                    }}
                    icon={faTimes}
                  />
                </div>
              )}
              {props.nft.requestingUserHasTrophy > 0 && (
                <div className="buttons">
                  <div className="claim">
                    <h3
                      onClick={() => {
                        setShowClaim(!showClaim);
                      }}
                    >
                      <FontAwesomeIcon icon={faExchangeAlt} />
                    </h3>
                    <div className="hover">
                      <p>Transfer to Wallet</p>
                    </div>
                    {showClaim &&
                      !awaitingClaim &&
                      !claimed &&
                      !props.nft.nftRequested && (
                        <div className="formBox">
                          <p>
                            Enter the address of the wallet you want to transfer
                            the NFT to.
                          </p>
                          <input
                            onChange={(e) => {
                              setEtherWallet(e.target.value);
                              const regex = /^0x[a-fA-F0-9]{40}$/;
                              const match = e.target.value.match(regex);
                              const ens = /.*\.eth/;
                              const matchENS = e.target.value.match(ens);

                              if (match || matchENS) {
                                setMatch(true);
                              } else {
                                setMatch(false);
                              }
                            }}
                            type="text"
                            placeholder="Enter wallet address"
                          />
                          {!match && etherWallet.length > 0 && (
                            <p class="alert">Invalid Ether Address.</p>
                          )}
                          {!match ? (
                            <button disabled className="btn large">
                              Claim
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                sendClaim();
                              }}
                              className="btn large"
                            >
                              Claim
                            </button>
                          )}

                          {claimed && (
                            <p class="alert success">
                              Success! Please give the Hearty team up to 5
                              business days to transfer your NFT.
                            </p>
                          )}
                        </div>
                      )}

                    {showClaim &&
                      awaitingClaim &&
                      !claimed &&
                      !props.nft.nftRequested && (
                        <div className="formBox">
                          <p>Please Wait...</p>
                        </div>
                      )}

                    {showClaim && claimed && !props.nft.nftRequested && (
                      <div className="formBox">
                        <p>
                          Congratulations your NFT has been sent to your
                          wallet.
                        </p>
                      </div>
                    )}

                    {showClaim && props.nft.nftRequested &&
                      props.nft.userTrophyTokenIdentifier > 0 && (
                        <div className="formBox">
                          <p>
                            Congratulations your NFT has been sent to your
                            wallet.
                          </p>
                        </div>
                      )}
                  </div>

                  <FontAwesomeIcon
                    onClick={() => {
                      setShowPopup(false);
                    }}
                    icon={faTimes}
                  />
                </div>
              )}
            </h3>
            <div className="content">
              <div className="left">
                {props.nft.url !== "" ? (
                  <video autoPlay muted loop controls>
                    <source src={props.nft.url} />
                  </video>
                ) : (
                  <img
                    style={{
                      borderRadius: "0px",
                    }}
                    src={props.nft.animatedThumbnailUrl}
                    alt={props.nft.name}
                  />
                )}
              </div>
              <div className="right">
                {selector !== "request" && (
                  <div className="selector">
                    <h3
                      className={selector === "details" ? "active" : ""}
                      onClick={() => {
                        setSelector("details");
                      }}
                    >
                      NFT details
                    </h3>
                    <h3
                      className={selector === "owner" ? "active" : ""}
                      onClick={() => {
                        setSelector("owner");
                      }}
                    >
                      Owner
                    </h3>
                  </div>
                )}

                {selector === "details" && (
                  <div className="details">
                    <h4 className="nameBox">
                      <b>Name</b> {props.nft.trophyName}
                    </h4>
                    <h4>
                      <b>Collection</b> {props.nft.trophyCollectionName}
                    </h4>
                    <h4 className="nameBox">
                      <b>About</b>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: props.nft.trophyDescription,
                        }}
                      />
                    </h4>
                    <div className="info">
                      <h4>
                        <b>Properties</b>
                      </h4>
                      {Object.keys(properties).map((key, index) => {
                        const split = properties[key].split("<<>>");
                        return (
                          <div className="meta" key={index}>
                            <h5>{key}</h5>
                            <p>{split[0].replace("_", " ")}</p>
                            {split[1] && (
                              <p
                                style={{
                                  marginTop: "10px",
                                }}
                              >
                                Rarity: <b>{Math.round(split[1])}%</b>
                              </p>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                {selector === "owner" && (
                  <div className="owners">
                    <div className="owners">
                      <h4
                        onClick={() => {}}
                      >
                        <b>Owner</b>
                      </h4>
                      <div className="ownersContainer">
                        {owners.map((owner, index) => {
                          return (
                            <div className="person" key={index}>
                              <a href={`/user/${owner.customUrl}`}>
                                <img src={owner.image} alt={owner.name} />
                                {owner.name}
                              </a>
                            </div>
                          );
                        })}

                        {!owners ||
                          (owners.length === 0 && (
                            <p
                              style={{
                                padding: 20,
                              }}
                            >
                              No Owner Yet!
                            </p>
                          ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="bottom">
              {selector !== "request" && (
                <button
                  onClick={() => {
                    setShowPopup(false);
                  }}
                  className="btn medium"
                >
                  Close
                </button>
              )}

              {/* If they arent logged in and the trophy is already claimed */}
              {selector !== "request" && !me && recipientCount > 0 && (
                  <button
                  style={{
                    backgroundColor:'grey',
                    color:"#fff"
                  }}
                    className="btn large"
                  >
                    Claimed
                  </button>
                )}

               {/* If they arent logged in and the trophy is not claimed */}
              {selector !== "request" && !me && recipientCount === 0 && (
                  <button
                    onClick={() => {
                      return history.push({
                        pathname: `/nft/${props.nft.inviteCode}`,
                      });
                    }}
                    className="btn large"
                  >
                    Claim
                  </button>
                )}

              {selector !== "request" &&
                !props.nft.requestingUserHasTrophy > 0 &&
                props.nft.public === true &&
                recipientCount === 0 &&
                props.nft.ownsTrophyCollection === false &&
                me && (
                  <button
                    onClick={() => {
                      claim();
                    }}
                    className="btn large"
                  >
                    Claim
                  </button>
                )}
            </div>
          </div>
          <div
            onClick={() => {
              setShowPopup(false);
            }}
            className="skrim"
          ></div>
        </div>
      )}
    </div>
  );
};

export default NftSingle;
