import React, { useState, useEffect } from "react";
import "../CSS/Modal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import MessagingUserSearch from './MessagingUserSearch';

export default function AddMemberModal(props) {
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if(props.message) {
      setMessage(props.message);
    }
  }, [props.message]);

  return (
    <div className="modalComponent">
      <div className="modal">
        <h2>{props.headline}</h2>
        <FontAwesomeIcon
          icon={faTimes}
          onClick={(e) => {
            props.close();
          }}
        />

        <MessagingUserSearch 
          setUsers={(users)=>{
            console.log(users);
            setUsers(users)
          }}
          conversationUserObjects= {props.conversationUserObjects}
        />

        <textarea onChange={(e)=>{
            setMessage(e.target.value);
        }} value={message} placeholder="Message"></textarea>

        {(props.cancel || props.success) && 
            <div className="actions">
                {props.cancel &&
                    <button onClick={(e)=>{
                        props.cancel();
                    }} className="btn btn-medium">
                        {props.cancelText}
                    </button>
                }

                {props.confirm &&
                    <button onClick={(e)=>{
                        props.confirm(
                          {
                            users,
                            message
                          }
                        );
                    }} className="btn btn-large">
                        {props.confirmButtonText}
                    </button>
                }
            </div>
        }
      </div>
      <div
        onClick={(e) => {
          props.close();
        }}
        className="skrim"
      ></div>
    </div>
  );
}
