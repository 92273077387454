import React, { useEffect, useState } from "react";
import "../App.scss";
import Logo from "../images/collective-white.png";
import Google from "../images/google.png";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { Helmet } from "react-helmet";

export default function Signup(props, ref) {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();

  const [firstNameInvalid, setFirstNameInvalid] = useState(false);
  const [firstName, setFirstName] = useState(props.firstName || "");
  const [lastNameInvalid, setLastNameInvalid] = useState(false);
  const [lastName, setLastName] = useState(props.lastName || "");
  const [usernameInvalid, setUsernameInvalid] = useState(false);
  const [username, setUsername] = useState("");
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [passwordConfirmInvalid, setPasswordConfirmInvalid] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState(false);
  const [matchCriteria, setMatchCriteria] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [showRedirectMessage, setShowRedirectMessage] = useState(false);
  let { code } = useParams();
  const [trophy, setTrophy] = useState(false);
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [linkedinUrl, setLinkedinUrl] = useState(props.linkedinUrl || null);

  useEffect(() => {
    const header = document.querySelector("nav");
    header.style.display = "none";
    window.scrollTo(0, 0);

    checkLoggedIn();

    return () => {
      const header = document.querySelector("nav");
      header.style.display = "block";
    };
  }, []);

  const checkLoggedIn = async () => {
    try {
      await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
      return history.push(`/collective-candidate`);
    } catch (error) {}
  };

  const login = () => {
    setShowRedirectMessage(true);

    setTimeout(() => {
      props.auth0.loginWithRedirect();
    }, 2000);
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const register = async () => {

    setIsRegistering(true);

    if (
      !passwordMatch ||
      !matchCriteria ||
      !username ||
      !password ||
      !firstName ||
      !lastName ||
      !validateEmail(username)
    ) {
      if (passwordMatch) {
        setPasswordConfirmInvalid(true);
        setIsRegistering(false);
      }

      if (!username || !validateEmail(username)) {
        setUsernameInvalid(true);
        setIsRegistering(false);
      }

      if (!password) {
        setPasswordInvalid(true);
        setIsRegistering(false);
      }

      if (matchCriteria === false) {
        setError({
          msg: "Please make sure your password contains at least 8 characters, an uppercase and a lowercase letter.",
        });
        setInvalid(true);
        setIsRegistering(false);

        return;
      }

      if (!firstName) {
        setFirstNameInvalid(true);
        setIsRegistering(false);
      }

      if (!lastName) {
        setLastNameInvalid(true);
        setIsRegistering(false);
      }

      if (!validateEmail(username)) {
        setError({
          msg: "Please enter a valid email.",
        });
        setInvalid(true);
        setIsRegistering(false);

        return;
      }

      setError({
        msg: "Please fill out all fields.",
      });
      setInvalid(true);
      setIsRegistering(false);
      return;
    }

    if (!checked) {
      setError({
        msg: "Please accept our terms.",
      });
      setIsRegistering(false);

      return;
    }

    const body = {
      email: username,
      password: password,
      firstName: firstName,
      lastName: lastName,
      linkedinUrl: linkedinUrl,
    };

    const call = fetch(`${process.env.REACT_APP_URL_JAVA}/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const data = await call;

    if (data.errors && data.errors && data.errors[0]) {
      if (data.message === "User with this email already exists") {
        setError({
          msg: `This email is already registered. <a href="/login">You can login here.</a>`,
        });
        setIsRegistering(false);

        return;
      }

      setError({
        msg: data.message,
      });
      setIsRegistering(false);

      return;
    }

    if (data.errors) {
      setError({
        msg: "We had an issue. Please contact support at admins@behearty.co",
      });
      setIsRegistering(false);

      return;
    }

    login(username, password);
  };

  return (
    <div
      style={{
        minHeight: "100%",
        paddingBottom: "50px",
      }}
      className="login register individual nftRegister collectiveRegister"
    >
      <div>
        <div>
            <div className="gradient"></div>

          <div className="google">
            <button
            style={{
              marginTop:'20px',
              marginBottom:'20px'
            }}
              onClick={() => {
                props.auth0.loginWithRedirect({
                  connection: "google-oauth2",
                });
              }}
            >
              <img src={Google} /> Sign in with Google
            </button>

            <h4>- or register with email -</h4>
          </div>

          <div>
            {error && (
              <p
                dangerouslySetInnerHTML={{
                  __html: error.msg,
                }}
                className="error"
              ></p>
            )}

            {showRedirectMessage && (
              <p className="alert success">
                Thank you for registering! You are being redirected to login now
                and onboard into the collective.
              </p>
            )}

            {step === 0 && (
              <div className="form">
                <div
                  style={{
                    width: "100%",
                  }}
                  className="field"
                >
                  <label>Email *</label>
                  <input
                    className={`${usernameInvalid ? "invalid" : ""}`}
                    type="text"
                    value={username}
                    onChange={(e) => {
                      const email = e.target.value.trim();
                      setUsername(email);
                    }}
                  />
                </div>
                <button
                  className="create-new-account"
                  type="button"
                  onClick={() => {
                    let valid = validateEmail(username);

                    console.log(valid, username);

                    if(!valid) {
                      setError({
                        msg: "Please enter a valid email.",
                      });

                      return;
                    }

                    if (!username) {
                      setUsernameInvalid(true);
                      setError({
                        msg: "Please enter an email.",
                      });
                      return;
                    }
                    setStep(1);
                    setError(false)
                  }}
                >
                  Create New Account
                </button>
              </div>
            )}

            {step === 1 && (
              <div className="first">
                <div className="field">
                  <label>First Name *</label>
                  <input
                    className={`
              ${firstNameInvalid ? "invalid" : ""}
            `}
                    type="text"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </div>

                <div className="field">
                  <label>Last Name *</label>
                  <input
                    className={`
              ${lastNameInvalid ? "invalid" : ""}
            `}
                    type="text"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </div>

                <div className="field">
                  <label>Password *</label>
                  <input
                    className={`
              ${passwordInvalid ? "invalid" : ""}
            `}
                    type="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);

                      const regex = new RegExp(
                        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])\S{8,}$/g
                      );

                      const matchCriteria = regex.test(e.target.value);

                      setMatchCriteria(matchCriteria);
                    }}
                  />
                  <p
                    className={`passwordMatch ${
                      matchCriteria ? "good" : "bad"
                    }`}
                  >
                    Password must be 8 characters long and include an uppercase
                    and lowercase character.
                  </p>
                </div>

                <div className="field">
                  <label>Confirm Password *</label>
                  <input
                    className={`
              ${passwordConfirmInvalid ? "invalid" : ""}
            `}
                    type="password"
                    value={passwordConfirm}
                    onChange={(e) => {
                      setPasswordConfirm(e.target.value);
                      if (e.target.value !== password) {
                        setPasswordMatch(false);
                      } else {
                        setPasswordMatch(true);
                      }
                    }}
                  />
                  {!passwordMatch && (
                    <p className="passwordError">Passwords must match</p>
                  )}
                </div>

                <div className="row policies">
                  <input
                    onClick={(e) => {
                      setChecked(e.target.checked);
                    }}
                    type="checkbox"
                  ></input>
                  I agree to the{" "}
                  <a href="/terms" target="_blank">
                    Terms and Services
                  </a>{" "}
                  and{" "}
                  <a href="/privacy" target="_blank">
                    Privacy Policy
                  </a>
                </div>

                <button
                  type="submit"
                  disabled={isRegistering}
                  onClick={() => {
                    register();
                  }}
                >
                  Register
                </button>
              </div>
            )}
          </div>

          <a className="login" href="/login">
            Already a member? Login
          </a>
        </div>
      </div>
    </div>
  );
}
