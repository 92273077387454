import React, { useState, useEffect } from "react";
import useQueryParam from './useQueryParam.ts';
import { useAuth0 } from "@auth0/auth0-react";
import "./CSS/CommunityConnections.scss";
import { useUserContext } from "./UserContext";
import MeetingSuggestion from "./components/MeetingSuggestion";
import { getJobFunctions } from "./service/ExternalProfileService.js";
import BookMeetingModal from "./components/BookMeetingModal";
import RateMeetingModal from "./components/RateMeetingModal";
import ProfileCardHeader from "./components/ProfileCardHeader";
import ProfileHomeSidebar from "./components/ProfileHomeSidebar";
import CompanySidebarWidget from "./components/CompanySidebarWidget";
import { useIsRecruiter, useIsEmployerAdmin } from "./RoleHelper";

const CommunityConnections = (props) => {
  const { loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const [recommendations, setRecommendations] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [jobFunctions, setJobFunctions] = useState([]);
  const user = useUserContext().user;
  const collectives = useUserContext().collectives;
  const [badges, setBadges] = useState([]);
  const [showBookMeetingModal, setShowBookMeetingModal] = useState(false);
  const [showRateMeetingModal, setShowRateMeetingModal] = useState(false);

  const [company, setCompany] = useState({});
  const isRecruiter = useIsRecruiter();
  const isEmployerAdmin = useIsEmployerAdmin();
  
  const [rateMeetingId, setRateMeetingId] = useQueryParam("rateMeetingId", null);
  const [hasHadMeeting, setHasHadMeeting] = useQueryParam("hasHadMeeting", null);

  const [meetingId, setMeetingId] = useState("");
  const [meetingName, setMeetingName] = useState("");
  const [meetingImage, setMeetingImage] = useState("");
  const [meetingBadges, setMeetingBadges] = useState([]);
  const [meetingHeadline, setMeetingHeadline] = useState("");
  const [meetingLocation, setMeetingLocation] = useState("");
  const [meetingDate, setMeetingDate] = useState("");
  const [meetingUserId, setMeetingUserId] = useState("");

  useEffect(() => {
    getMeetings();
    getRecommendations();
  }, []);

  useEffect(() => {
    if (user && user.external_profile) {
      getJobFunctions(user.external_profile.id).then((response) => {
        setJobFunctions(response);
      });
    }
  }, [user]);

  useEffect(() => {
    if (meetings && meetings.length > 0 && rateMeetingId) {
      const meeting = meetings.find((meeting) => meeting.id === rateMeetingId);
      submitFeedback(
        meeting.id,
        meeting.name,
        meeting.image,
        meeting.badges,
        meeting.headline,
        meeting.location,
        meeting.userId
      );
    }
  }, [meetings, rateMeetingId, hasHadMeeting]);

  const getRecommendations = async () => {
    let token = false;
    try {
        token = await getAccessTokenSilently({
            audience: `https://api-v2.behearty.co`,
            scope: "read:current_user offline_access",
        });
    } catch (error) {
        loginWithRedirect();
    }

    const response = await fetch(
        `${process.env.REACT_APP_URL_JAVA}/meeting-recommendations`,
        {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        }
    );

    const data = await response.json();
    setRecommendations(data);
  };

  const getMeetings = async () => {
    let token = false;
    try {
        token = await getAccessTokenSilently({
            audience: `https://api-v2.behearty.co`,
            scope: "read:current_user offline_access",
        });
    } catch (error) {
        loginWithRedirect();
    }

    const response = await fetch(
        `${process.env.REACT_APP_URL_JAVA}/ally-meeting/list`,
        {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        }
    );

    response.json().then((data) => {
      setMeetings(data);
    });
  };

  const meetingBooked = () => {
    resetMeeting();
    getRecommendations();
    getMeetings();
    setShowBookMeetingModal(false);
  };

  const bookMeeting = (
    name,
    image,
    badges,
    headline,
    location,
    date,
    userId
  ) => {
    setMeetingName(name);
    setMeetingImage(image);
    setMeetingBadges(badges)
    setMeetingHeadline(headline);
    setMeetingLocation(location);
    setMeetingDate(date);
    setMeetingUserId(userId);
    setShowBookMeetingModal(true);
  };

  const feedbackSubmitted = () => {
    resetMeeting();
    setShowRateMeetingModal(false);
  };

  const submitFeedback = (
    id,
    name,
    image,
    badges,
    headline,
    location,
    userId,
  ) => {
    setMeetingId(id);
    setMeetingName(name);
    setMeetingImage(image);
    setMeetingBadges(badges);
    setMeetingHeadline(headline);
    setMeetingLocation(location);
    setMeetingUserId(userId);
    setShowRateMeetingModal(true);
  };

  const resetMeeting = () => {
    setMeetingId("");
    setMeetingName("");
    setMeetingImage("");
    setMeetingBadges([]);
    setMeetingHeadline("");
    setMeetingLocation("");
    setMeetingDate("");
    setMeetingUserId("");
    setRateMeetingId(null);
    setHasHadMeeting(null);
  };

  const getBadgesForRoles = (roles) => {
    let badges = [];
    if (roles.includes("ALLY")) {
      badges.push("ALLY");
    }
    if (roles.includes("EMPLOYEE" || "EMPLOYER_ADMIN")) {
      badges.push("RECRUITER");
    }
    if (roles.includes("ERG")) {
      badges.push("ERG");
    }
    return badges;
  };

  const getBadges = (profile) => {
    const roles = profile.collectives.map((c) => c.roles.map((r) => r.name)).flat();
    return getBadgesForRoles(roles);
  };

  useEffect(() => {
    if (collectives && collectives.length > 0) {
      const roles = collectives.map((c) => c.roles).flat();
      const b = getBadgesForRoles(roles);
      setBadges(b);
    }
  }, [collectives]);

  useEffect(() => {
    if (user && user.company_id) {
        getCompany(user.company_id);
    }
}, [user]);

  const getCompany = async (companyId) => {
    let token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
    });

    let headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
    };

    let url = `${process.env.REACT_APP_URL_JAVA}/companies/${companyId}/profile`

    const response = await fetch(
        url,
        {
            headers,
            method: "GET",
        }
    );

    const data = await response.json();
    setCompany(data);
  };

  return (
    <div className="homeContainer">
      <div className="collectiveHome">
        <div className="sidebar">
          {user && user.external_profile && (
            <ProfileHomeSidebar
              headerImage={user.external_profile.headerImage}
              customUrl={user.external_profile.customUrl}
              image={user.external_profile.image}
              name={user.name}
              jobFunctions={jobFunctions}
              badges={badges}
              headline={user.external_profile.headline}
              location={user.external_profile.location}
              areaOfExpertise={user.area_of_expertise}
            />
          )}
          {user && company.id && (
            <CompanySidebarWidget
              customUrl={company.customUrl}
              image={company.image}
              name={company.name}
              location={company.location}
              url={company.url}
              overview={company.overview}
              recruiterCount={company.recruiterCount}
              allyCount={company.allyCount}
              id={company.id}
              showEmployeeCounts={isRecruiter && company.id === user.company_id}
              showEditButton={isEmployerAdmin && company.id === user.company_id}
            />
          )}
        </div>

        <div className="recommendations">
          <h3 className="recommendations-title">Community Members You Should Meet</h3>
          <div className="recommendations-list">
            {recommendations.map((item, i) => {
              return (
                  <MeetingSuggestion
                    key={item.id}
                    id={item.id}
                    name={item.profile.name}
                    image={item.profile.image}
                    headline={item.profile.headline}
                    location={item.profile.location}
                    headerImage={item.profile.headerImage}
                    customUrl={item.profile.customUrl}
                    areaOfExpertise={item.profile.areaOfExpertise}
                    badges={getBadges(item.profile)}
                    userId={item.profile.userId}
                    bookMeeting={bookMeeting}
                  />
              );
            })}
          </div>
      </div>

        <div className="connections">
          <div className="connections-header">
            <div className="">
              <h3>Connections Made</h3>
            </div>
          </div>
          <div className="connections-list">
            {(meetings && meetings.length > 0) ? (
              meetings.map((meeting) => {
                return (
                  <ProfileCardHeader
                    key={meeting.allyMeetingId}
                    id={meeting.allyMeetingId}
                    name={meeting.allyName}
                    image={meeting.allyImage}
                    headline={meeting.allyHeadline}
                    location={meeting.allyLocation}
                    customUrl={meeting.allyCustomUrl}
                    viewMeetingDetails={true}
                    submitFeedback={submitFeedback}
                  />
                );
              })) : (
                <p>You haven’t made any community connections yet. Browse our recommendations or search the full community to book time to meet fellow members and allies.</p>
              )}
          </div>
        </div>
      </div>
      {showBookMeetingModal && (
        <div className="modal-container">
          <BookMeetingModal
            meetingBooked={meetingBooked}
            name={meetingName}
            image={meetingImage}
            badges={meetingBadges}
            headline={meetingHeadline}
            location={meetingLocation}
            date={meetingDate}
            userId={meetingUserId}
          />
          <div className="skrim"
            onClick={() => {setShowBookMeetingModal(false)}}>
          </div>
        </div>
      )}
      {showRateMeetingModal && (
        <div className="modal-container">
          <RateMeetingModal
            feedbackSubmitted={feedbackSubmitted}
            id={meetingId}
            name={meetingName}
            image={meetingImage}
            badges={meetingBadges}
            headline={meetingHeadline}
            location={meetingLocation}
            userId={meetingUserId}
            hasHadMeeting={hasHadMeeting}
          />
          <div className="skrim"
            onClick={() => {setShowRateMeetingModal(false)}}>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommunityConnections;
