import {
  faCopy,
  faFilter,
  faMapPin,
  faShare,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faFacebook,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import "../CSS/Leaderboards/single.scss";
import Leader from "./components/Leader";
import { Helmet } from "react-helmet";
import { CopyToClipboard } from "react-copy-to-clipboard";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAuth0 } from "@auth0/auth0-react";
import Captain from "../images/captain-emoji.png";
import HeartyName from "../components/HeartyName";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import Select from "react-select";
import { useHistory } from 'react-router-dom'

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

export default function LeaderboardSingle(props) {
  const [showShareMenu, setShowShareMenu] = useState(false);
  const [leaders, setLeaders] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [size, setSize] = useState(100);
  const [copyText, setCopyText] = useState("Copy link");
  const [page, setPage] = useState(0);
  const [leaderLength, setLeaderLength] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [noUser, setNoUser] = useState(false);
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  const [sort, setSort] = useState("recommended");
  const [promoted, setPromoted] = useState(false);
  const [addedSelf, setAddedSelf] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [view, setView] = useState("leaders");
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [sortObject, setSortObject] = useState({
    value: "recommended",
    label: "Recommendations",
  });
  const [loading, setLoading] = useState(false);

  let injector = false;

  const [leaderBoardData, setLeaderBoardData] = useState({
    user: {
      external_profile: {},
    },
  });
  let params = useParams();
  const location = useLocation();
  const history = useHistory();

  const search = useLocation().search;
  let leaderId = new URLSearchParams(search).get("leaderId");

  useEffect(() => {
    const jsonUser = localStorage.getItem("user");

    const rolesJSON = localStorage.getItem("roles");
    const roles = JSON.parse(rolesJSON);

    if (roles && roles.includes("Admin")) {
      setAdmin(true);
    }

    if (jsonUser) {
      const user = JSON.parse(jsonUser);
      setLoggedInUser(user);
    }
    async function fetchData() {
      window.scrollTo(0, 0);
      const leaderboard = props.leaderBoardData;
      const user = JSON.parse(localStorage.getItem("user"));
      if (user && leaderboard.userId === user.id) {
        setCanEdit(true);
      }
      getLocations();
      setLeaderBoardData(leaderboard);
      await getLeaders(leaderboard);
    }
    fetchData();
  }, [props.getOnboardingStep]);

  const updateLeader = (id) => {
    if (id) leaderId = id;
    setLeaders([]);
    getLeaders(leaderBoardData);
  };

  const getLocations = async () => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      return;
    }

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/leaderboard/msa-list?leaderboardId=${props.leaderBoardData.id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();

    const formattedData = data
      .sort((a, b) => {
        return b.memberCount - a.memberCount;
      })
      .map((location) => {
        return {
          value: location.id,
          label: `${location.name.replace("Metropolitan Area", "")} (${
            location.memberCount
          })`,
        };
      });

    setLocations(formattedData);
  };

  const fetchMoreData = async () => {
    if (!leaderBoardData.id) return false;
    if (hasMore === false) return;
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      console.log(error);
    }
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }

    let locationSort = "";

    if (selectedLocations) {
      locationSort = selectedLocations.reduce((prev, curr) => {
        prev += `&msaId=${curr.value}`;

        return prev;
      }, "");
    }

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/leaderboard-recommendation/list?leaderboardId=${leaderBoardData.id}&page=${page}&size=100&sort=${sort}${locationSort}`,
      {
        method: "GET",
        headers,
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error(response);
      })
      .then((data) => {
        const newData = data._embedded.leaderboardRecommendation;

        const items = newData.map((leader) => {
          if ((!leader.isUserAndVoted) && injector === false) {
            leader.injector = true;
            injector = true;
          }
          return leader;
        });

        setLeaders([...leaders, ...items]);
        setLeaderLength(data.page.totalElements);

        // Is this the last call we need?
        if (data.page.size >= data.page.totalElements) {
          return setHasMore(false);
        }

        setPage(page + 1);
      })
      .catch((error) => {
        console.log();
      });
  };

  const getLeaders = async (leaderboard, locations, sort) => {
    if (!leaderboard.id) return false;
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      console.log(error);
    }
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }

    let count = size;

    // If its a direct leader, then set count to 1000 for now.
    if (leaderId) {
      count = 1000;
      setSize(1000);
    }

    let locationSort = "";
    // Convert array of locations into string for query
    if (locations) {
      locationSort = locations.reduce((prev, curr) => {
        prev += `&msaId=${curr.value}`;

        return prev;
      }, "");
    }

    setLoading(true);

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/leaderboard-recommendation/list?leaderboardId=${leaderboard.id}&page=0&size=${count}&sort=${sort}${locationSort}`,
      {
        method: "GET",
        headers,
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error(response);
      })
      .then((data) => {
        setLeaderLength(data.page.totalElements);
        let items = data._embedded.leaderboardRecommendation;
        setLoading(false);
        let firstSet = false;

        if (leaderId > 0) {
          items = items.map((leader) => {
            if (
              Number(leader.recommendedExternalProfileId) === Number(leaderId)
            )
              leader.scroll = true;

            if ((!leader.isUserAndVoted) && injector === false) {
              leader.injector = true;
              injector = true;
            }
            return leader;
          });
        } else {
          items = items.map((leader) => {
            if ((!leader.isUserAndVoted) && injector === false) {
              leader.injector = true;
              injector = true;
            }
            return leader;
          });
        }
        setLeaders(items);

        if (data.page.totalPages == 1) {
          return setHasMore(false);
        }
        setPage(1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const makeWorthy = async () => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      return false;
    }
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }
    fetch(
      `${process.env.REACT_APP_URL_JAVA}/leaderboard/${leaderBoardData.id}/worthy`,
      {
        method: "PATCH",
        headers,
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error(response);
      })
      .then((data) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addYourself = async () => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });
    const user = JSON.parse(localStorage.getItem("user"));
    const data = {
      recommendedExternalProfileId: user.External_Profile.id,
      leaderboardId: leaderBoardData.id,
      userId: user.id,
    };

    fetch(`${process.env.REACT_APP_URL_JAVA}/leaderboard-recommendation`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) return response.text();
        throw new Error(response);
      })
      .then(async (data) => {
        const leaderboardData = { ...leaderBoardData };
        leaderboardData.featured = true;
        setLeaderBoardData(leaderboardData);
        setAddedSelf(true);
        setTimeout(() => {
          setAddedSelf(false);
        }, 7000);
        updateLeader();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const tweetText = `Checkout all the great people on the ${leaderBoardData.title} leaderboard on @beheartyapp!`;
  const url = `https://app.hearty.xyz/leaderboards/${leaderBoardData.customUrl}`;

  return (
    <div className="LeaderboardSingle app">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${leaderBoardData.title} - Hearty Leaderboard`}</title>
        <meta name="description" content={leaderBoardData.description} />
        <meta property="og:title" content={leaderBoardData.title} />
        <meta property="og:description" content={leaderBoardData.description} />
        <meta property="og:image" content={leaderBoardData.image} />
        <meta property="og:url" content={window.location} />

        <meta property="twitter:title" content={leaderBoardData.title} />
        <meta
          property="twitter:description"
          content={leaderBoardData.description}
        />
        <meta property="twitter:image" content={leaderBoardData.image} />
        <meta name="twitter:card" content="summary_large_image"></meta>
      </Helmet>

      <section className="left">
        {location && location.state && location.state.new && !promoted && (
          <p className="alert success">
            {" "}
            Your leaderboard was succesfully created.
          </p>
        )}
        {addedSelf && (
          <p className="alert success">
            {" "}
            You succesfully added yourself to this leaderboard!
          </p>
        )}
        <section
          style={{
            backgroundImage: `url('${leaderBoardData.image}')`,
          }}
          className="titleCard"
        >
          {canEdit && (
            <a
              href={`/leaderboards/edit/${leaderBoardData.customUrl}`}
              className="btn large edit"
            >
              Edit
            </a>
          )}

          {leaderBoardData.worthy === 1 && <div className="worthy">Worthy</div>}

          {admin === true && leaderBoardData.worthy === 0 && (
            <div
              onClick={(e) => {
                makeWorthy();
              }}
              className="setWorthy"
            >
              Set Worthy
            </div>
          )}

          <h2>{leaderBoardData.title}</h2>
          <p>{leaderBoardData.description}</p>
          <div className="creator">
            <img
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = Captain;
              }}
              src={leaderBoardData.creatorImage}
            />
            <p>created by {leaderBoardData.creatorName}</p>
          </div>
        </section>
        <div className="content">
          <div className="actions">
            <div className="numbers">
              <h3>
                {leaders[0] && (
                  <img
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = Captain;
                    }}
                    src={leaders[0].image}
                  />
                )}
                {leaders[1] && (
                  <img
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = Captain;
                    }}
                    src={leaders[1].image}
                  />
                )}
                <span className="bold">{leaderLength}</span> Members
              </h3>

              <div className="tabs">
                <span
                  className={`${view === "leaders" ? "active" : ""}`}
                  onClick={() => {
                    setView("leaders");
                  }}
                >
                  Leaders
                </span>
              </div>

              <div className="break"></div>
            </div>

            <div className="actionButtons">
              <div className="shareButton">
                <button
                  onClick={(e) => {
                    setShowShareMenu(!showShareMenu);
                  }}
                  className="share btn large"
                >
                  {" "}
                  <FontAwesomeIcon icon={faShare} /> Share
                </button>
                <div className="break"></div>

                {showShareMenu && (
                  <div className="shareDropdown">
                    <ul>
                      <li>
                        <TwitterShareButton
                          url={url}
                          title={tweetText}
                          onShareWindowClose={() => {}}
                        >
                          <FontAwesomeIcon icon={faTwitter} />
                          <span>Share on Twitter</span>
                        </TwitterShareButton>
                      </li>
                      <li>
                        <LinkedinShareButton
                          url={url}
                          title={leaderBoardData.title}
                          summary={tweetText}
                          source={"Hearty"}
                          onShareWindowClose={() => {}}
                        >
                          <FontAwesomeIcon icon={faLinkedin} />
                          <span>Share on Linkedin</span>
                        </LinkedinShareButton>
                      </li>
                      <li>
                        <FacebookShareButton
                          url={url}
                          quote={tweetText}
                          onShareWindowClose={() => {}}
                        >
                          <FontAwesomeIcon icon={faFacebook} />
                          <span>Share on Facebook</span>
                        </FacebookShareButton>
                      </li>

                      <li>
                        <CopyToClipboard
                          text={window.location}
                          onCopy={() => {
                            setCopyText("Link Copied!");
                            setTimeout(() => {
                              setCopyText("Copy Link");
                            }, 3000);
                          }}
                        >
                          <span>
                            <FontAwesomeIcon icon={faCopy} /> {copyText}
                          </span>
                        </CopyToClipboard>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className="break"></div>
            </div>
          </div>
          <div className="left">
            <div className="content">
              <div className="newMembers">
                {view === "leaders" &&
                <div className="locationFilter">
                  {locations.length > 0 && (
                    <ReactMultiSelectCheckboxes
                      options={locations}
                      getDropdownButtonLabel={(label, value)=>{
                        if(selectedLocations.length === 0) {
                          return `Filter By Metro Area`
                        }

                        if (selectedLocations.length === 1) {
                          return `Location: ${label.value[0].label}`;
                        }

                        return `Locations: ${selectedLocations.length} selected`;
                      }}
                      onChange={(value) => {
                        setSelectedLocations(value);
                        getLeaders(leaderBoardData, value, sort);
                      }}
                    />
                  )}
                </div>
                }
                {view === "leaders" &&
                <div className="sortFilter">
                <Select
                  placeholder="Sort By"
                  options={[
                    { value: 'recommended', label: 'Recommendations' },
                    { value: 'proximity', label: 'Network Proximity' },
                  ]}
                  isClearable={false}
                  classNamePrefix="select"
                  value={sortObject}
                  onChange={(value) => {
                    setSort(value.value);
                    setSortObject(value);
                    getLeaders(leaderBoardData, selectedLocations, value.value);
                  }}
                />
                </div>
                }
              </div>

              {(view === "leaders" && loading === false) && (
                <InfiniteScroll
                  dataLength={leaders.length}
                  next={() => {
                    return fetchMoreData();
                  }}
                  style={{ display: "flex", width: "100%", flexWrap: "wrap" }} //To put endMessage and loader to the top.
                  inverse={false}
                  hasMore={hasMore}
                >
                  {leaders.map((leader) => {
                    return (
                      <Leader
                        updateLeader={updateLeader}
                        leaderboard={leaderBoardData}
                        getOnboardingStep={props.getOnboardingStep}
                        leader={leader}
                        sort={sort}
                        key={leader.id}
                        inputRef={(el) => (this.inputElement = el)}
                      />
                    );
                  })}
                </InfiniteScroll>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="right">
      </section>
    </div>
  );
}
