import { useEffect, useState } from "react";
import { useUserContext } from "./UserContext";
import { useCompanyService } from "./service/context/CompanyServiceContext";
import CompanySidebarWidget from "./components/CompanySidebarWidget";
import "./CSS/Supporters.scss";

export default function Supporters() {
    const collectives = useUserContext().collectives;
    const [companies, setCompanies] = useState([]);
    const companyService = useCompanyService();

    useEffect (() => {
        if (collectives && companyService) {
            const collectiveIds = collectives.map((collective) => collective.collectiveId);
            companyService.getCompanyProfilesByCollectiveIds(collectiveIds).then((response) => {
                setCompanies(response);
            });
        }
    }
    , [collectives, companyService]);

    return (
        <div className="supporters">
            <h1>Supporters</h1>
            <div className="companies-grid">
                {companies && companies.length > 0 && companies.map((company) => (
                    <CompanySidebarWidget
                        customUrl={company.customUrl}
                        image={company.image}
                        name={company.name}
                        location={company.location}
                        url={company.url}
                        overview={company.overview}
                        recruiterCount={company.recruiterCount}
                        allyCount={company.allyCount}
                        id={company.id}
                        showEmployeeCounts={false}
                        showEditButton={false}
                    />
                ))}
            </div>
        </div>
    );

}