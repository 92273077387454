import Bird from './images/bird.png';
import Pirate from './images/pirate.png';
import Pirate2 from './images/pirate2.png';
import Pirate3 from './images/pirate3.png';
import Pirate4 from './images/pirate4.png';
import Pirate5 from './images/pirate5.png';
import Pirate6 from './images/pirate6.png';
import React from "react";

export const validURL = (string)=> {
    let url;
  
    try {
        url = new URL(string);
    } catch (_) {
        return false;  
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

export const convertSpecility = (string)=> {
    switch (string) {
        case 'Accounting_Finance':
            return 'Accounting and Finance'
            break;
        case 'Sales_Marketing':
            return 'Sales and Marketing'
            break;
        case 'Account_Management':
            return 'Account Management'
            break;
        case 'Customer_Support':
            return 'Customer Support'
            break;
        case 'Product_Engineering':
            return 'Product & Engineering'
            break;
        case 'Human_Resources':
            return 'HR'
            break;

        default:
            return string;
            break;
    }
}

function urlify(text) {
    if (!text) return text;
    var urlRegex = /([\w]+:\/\/[\w-?&;#~=\.\/\@]+[\w\/])/g;
    return text.replace(urlRegex, function (url) {
      return '<a target="_blank" href="' + url + '">' + url + "</a>";
    });
  }

export const cleanMessage = (message) =>{
    if(!message) return '';
    
    const convertBreak = message.split("\n").join("<br/>");
    const regex = /\@\[(.*?)\]\((\d*?)\)/g;
    const broken = convertBreak.replaceAll(regex, (a,b,c)=>{
        try {
            const user = JSON.parse(b);
            if(user.isLeaderboard) return `<a href="/leaderboards/${user.customUrl}">@${user.name}</a>`
            return `<a href="/user/${user.customUrl}">@${user.name}</a>`
        } catch (error) {
            return `<span>@${b}</span>`;
        }
    });


    return broken;
}

export const randomImage = ()=> {
    const random = Math.floor(Math.random() * 7);
    const images = [Bird,Pirate, Pirate2, Pirate3, Pirate4, Pirate5, Pirate6];

    return images[random];
}

export const truncateString = (str, num)=> {

    if(!str) return '';
    // If the length of str is less than or equal to num
    // just return str--don't truncate it.
    if (str.length <= num) {
      return str
    }
    // Return str truncated with '...' concatenated to the end of str.
    return str.slice(0, num) + '...'
  }

export const fillerPirate = ()=>{
    return (
    <div className="recommendation filler">
        <a href=''>
            <img src={Pirate} />
        </a>
        <div className="info">
            <h3>Hearty Pirate</h3>
            <h4> Director of Pirates</h4>
        </div>
        <div className="action">
            <div className="button">
                <span className="btn medium ">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" className="svg-inline--fa fa-plus fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
                    </svg>
                    Add
                </span>
            </div>
        </div>
    </div>
    )
}

export const formatCandidateStatus = (text) => {
    text = text || "";
    var newText = text
      .toLowerCase()
      .replaceAll("_", " ")
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
    if (newText === "Introduction Made") {
      newText = "Actively Interviewing";
    } else if (newText === "Active Matches") {
        newText = "Matches";
    } else if (newText === "Active No Matches") {
        newText = "No Matches";
    }
    return newText;
};

export const formatRoleType = (text) => {
    text = text || "";
    var newText = text
      .toLowerCase()
      .replaceAll("_", " ")
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

      if (newText === "Qa") {
        newText = "QA";
      }
    return newText;
};

export const capitalizeLocation = (location) => {
    location = location || "";
    if (!location.includes(",")) {
        return location;
    }
    var city = location.split(",")[0].trim();
    const state = location.split(",")[1].trim();
    var newCity = city
      .toLowerCase()
      .replaceAll("_", " ")
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
    return newCity + ", " + state;
};

export const truncate = (text, length) => {
    if (text.length > length) {
        return text.substring(0, length) + "...";
    } else {
        return text;
    }
};
