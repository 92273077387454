import { useUserContext } from "./UserContext";

export const useIsRecruiter = () => {
    const user = useUserContext().user;
    return user && user.role_users && user.role_users.some((ru) => ru.role.name === "Employee" || ru.role.name === "Employer_Admin");
};

export const useIsEmployerAdmin = () => {
    const user = useUserContext().user;
    return user && user.role_users && user.role_users.some((ru) => ru.role.name === "Employer_Admin");
};

export const useIsAlly = () => {
    const user = useUserContext().user;
    return user && user.role_users && user.role_users.some((ru) => ru.role.name === "Ally");
};

export const useIsAdmin = () => {
    const user = useUserContext().user;
    return user && user.role_users && user.role_users.some((ru) => ru.role.name === "Admin");
};

export const useIsCommunityMember = () => {
    const context = useUserContext();
    const collectives = context.collectives;
    for(let i = 0; i < collectives.length; i++) {
        if(collectives[i].roles.includes("TALENT")) {
            return true;
        }
    }
};