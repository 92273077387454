import React, { useState, useEffect } from "react";
import "../CSS/Onboarding/CollectiveOnboarding.scss";
import logo from "../images/collective-white.png";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import Signup from "../components/Signup";
import { saveCollectiveOnboardingProfile } from "../LocalStorageUtil";


export default function CollectiveOnboarding(props) {
  let { inviteCode } = useParams();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [jobFunctions, setJobFunctions] = useState([]);

  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [name, setName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [jobFunctionId, setJobFunctionId] = useState("");
  const [zip, setZip] = useState("");
  const [headline, setHeadline] = useState("");
  const [isCommunityConnections, setIsCommunityConnections] = useState(true);
  const [areaOfExpertise, setAreaOfExpertise] = useState("");
  const [monthlyAllyMeetingCount, setMonthlyAllyMeetingCount] = useState(0);
  const [rewarding, setRewarding] = useState("");
  const [hobbies, setHobbies] = useState("");
  const [hometown, setHometown] = useState("");
  const [isAlly, setIsAlly] = useState(false);

  const [errors, setErrors] = useState([]);
  const [step, setStep] = useState(1);
  const [showLogin, setShowLogin] = useState(false);

  const stepCount = 9;
  const barWidth = 100 / stepCount;

  useEffect(() => {
    const header = document.querySelector("nav");
    header.style.display = "none";

    return () => {
      const header = document.querySelector("nav");
      header.style.display = "block";
    };
  }, []);

  useEffect(() => {
    getJobFunctions();
    getCollectiveInviteCode();
  }, []);

  const cleanLinkedInUrl = (url) => {
    let vanity = url.split('/in/')[1].replace(/\//g, '').trim()
    return `https://www.linkedin.com/in/${vanity}/`;
  };

  const save = async () => {
    const data = {
      linkedinUrl: cleanLinkedInUrl(linkedinUrl),
      imageUrl: imageUrl,
      jobFunctionId: jobFunctionId,
      zip: zip,
      headline: headline,
      isCommunityConnections: isCommunityConnections,
      areaOfExpertise: areaOfExpertise,
      monthlyAllyMeetingCount: monthlyAllyMeetingCount,
      rewarding: rewarding,
      hobbies: hobbies,
      hometown: hometown,
      inviteCode: inviteCode,
    };

    saveCollectiveOnboardingProfile(data);
  };

  const next = () => {
    let newStep = step + 1;
    if (newStep > stepCount || (newStep > 3 && !isCommunityConnections)) {
      setShowLogin(true);
      save();
    } else if (newStep === 3 && isAlly) {
      // automatically opt in to community connections if they are using an ally invite code
      setIsCommunityConnections(true);
      newStep = 4;
      setStep(newStep);
    } else {
      setStep(newStep);
    }

    if (newStep === 4 && isCommunityConnections) {
      setMonthlyAllyMeetingCount(3);
    }
  };

  const back = () => {
    let newStep = step - 1;

    setStep(newStep);
  };

  const uploadImage = async(files) => {
    const selectedImage = files[0];
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", selectedImage, selectedImage.name);

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/files/uploadFile?directory=avatar`,
      {
        method: "POST",
        enctype: "multipart/form-data",
        contentType: false,
        body: formData,
      }
    )
    .then((response) => {
      if (!response.ok) {
        throw new Error("Error uploading image");
      }
      return response.text();
    })
    .then((url) => {
      setImageUrl(url);
    });
  };

  const getJobFunctions = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/job-functions`,
      {
        method: "GET",
      }
    );
    const jobFunctions = await response.json();
    setJobFunctions(jobFunctions);
  };

  const getCollectiveInviteCode = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/collective-invite-codes?code=${inviteCode}`,
      {
        method: "GET",
      }
    );

    if (response.ok) {
      const collectiveInviteCode = await response.json();
      if (collectiveInviteCode && collectiveInviteCode.role.name === "Ally") {
        setIsAlly(true);
      }
    }
  };

  const getExternalProfile = async (linkedinUrl) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/external-profiles/linkedin?linkedinUrl=${cleanLinkedInUrl(linkedinUrl)}`,
      {
        method: "GET",
      }
    );
    if (response.ok) {
      const externalProfile = await response.json();
      if (externalProfile.id) {
        setName(externalProfile.name);
        setImageUrl(externalProfile.image);
        setHeadline(externalProfile.headline);
        setZip(externalProfile.zip);
        getProfileJobFunctions(externalProfile.id);
      }
    }
  };

  const getProfileJobFunctions = async (externalProfileId) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/external-profiles/${externalProfileId}/job-functions`,
      {
        method: "GET",
      }
    );
    const profileJobFunctions = await response.json();
    if (profileJobFunctions.length > 0) {
      jobFunctions.forEach((jobFunction) => {
        if (jobFunction.name === profileJobFunctions[0].name) {
          setJobFunctionId(jobFunction.id);
        }
      });
    }
  };

  return (
    <div className="onboarding">
      {errors.length > 0 &&
        errors.map((error) => {
          return <div className="error">{error}</div>;
        })}

      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>{`Hearty Collective`}</title>
        <meta name="description" content="Helping the best engineers find new jobs" />
        <meta property="og:title" content="Hearty Collective" />
        <meta
          property="og:description"
          content="Helping the best engineers find new job"
        />
        <meta
          property="og:image"
          content="https://www.hearty.xyz/images/collective.png"
        />
        <meta property="og:url" content={window.location} />

        <meta property="twitter:title" content="Hearty Collective" />
        <meta
          property="twitter:description"
          content="Helping the best engineers find new job"
        />
        <meta
          property="twitter:image"
          content="https://www.hearty.xyz/images/collective.png"
        />
        <meta name="twitter:card" content="summary_large_image"></meta>
      </Helmet> */}

      <div className="onboarding-header">
        <img src={logo} alt="logo" className="logo" />
      </div>
      <div className="bar">
        <div className="bar-item" style={{width: barWidth + '%', marginLeft: (barWidth * (step - 1)) + '%'}}></div>
      </div>
      {step > 1 && (
        <span
          className="back"
          onClick={() => {
            back();
            setErrors([]);
          }}
        >
          {" "}
          Back
        </span>
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          save();
        }}
      >
        {step === 1 && (
          <div className="formItem linkedin">
            <div className="left">
              <h4>Please enter your LinkedIn url</h4>
            </div>

            <div className="right">
              <input
                onChange={(e) => {
                  setLinkedinUrl(e.target.value);
                }}
                className="linkedinUrl"
                value={linkedinUrl}
                type="text"
                placeholder="ex. https://www.linkedin.com/in/johndoe/"
              ></input>
            </div>
            <button
              type="button"
              onClick={async () => {
                if (!linkedinUrl) {
                  setErrors(["Please enter your LinkedIn url"]);
                  return;
                }
                await getExternalProfile(linkedinUrl);
                next();
                setErrors([]);
              }}
            >
              Next
            </button>
          </div>
        )}

        {step === 2 && (
          <div className="formItem profile">
            <div className="left">
              <h4>Create your profile</h4>
            </div>
            <div className="profile right">
              <div className="imageUploadBox">
                <input type="file" onChange={e=> uploadImage(e.target.files)} /> 
                <div className='uploadShow'>
                  <p>Upload New Photo</p>
                </div>
                <img onError={(e)=>{e.target.onerror = null;}} src={imageUrl} />
              </div>

              <div className="info">
                <div className="field">
                  <label htmlFor="name">Name</label>
                  <input className="name" type="text" placeholder="Name" onChange={(e)=>{
                    setName(e.target.value);
                    }} value={name}>
                  </input>
                </div>
                <div className="field">
                  <label htmlFor="headline">Headline</label>
                  <input className="headline" type="text" onChange={(e)=>{
                    setHeadline(e.target.value);
                  }} value={headline} placeholder="Title @ Company"></input>
                </div>
                <div className="field">
                  <label htmlFor="zipCode">Zip Code</label>
                  <input type="number" className="zipCode" onChange={(e)=>{
                    setZip(e.target.value);
                  }} value={zip} placeholder="Zip Code"
                  ></input>
                </div>
                <div className="field">
                  <label htmlFor="jobFunction">Function</label>
                  <select
                    onChange={(e) => {
                      setJobFunctionId(e.target.value);
                    }}
                    value={jobFunctionId}
                    className="jobFunction"
                  >
                    <option value="" disabled>Select a function</option>
                    {jobFunctions.map((jobFunction) => {
                      return (
                        <option value={jobFunction.id}>
                          {jobFunction.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <button
              type="button"
              onClick={() => {
                if (!name) {
                  setErrors(["Please enter your name"]);
                  return;
                }
                if (!headline) {
                  setErrors(["Please enter a headline"]);
                  return;
                }
                if (!zip) {
                  setErrors([
                    "Please enter your zip code",
                  ]);
                  return;
                }
                if (zip.length > 5) {
                  setErrors(["Please enter a 5 digit zip code"]);
                  return;
                }
                if (!jobFunctionId) {
                  setErrors([
                    "Please choose a function",
                  ]);
                  return;
                }
                next();
                setErrors([]);
              }}
            >
              Next
            </button>
          </div>
        )}

        {step === 3 && (
          <div className="formItem communityConnections">
            <div className="left">
              <h4>May we include you in the Community Connections program?</h4>
              <p>Community Connections is a way to meet Allies and fellow community members for networking, advice, and opportunities. By opting in to the program, you can search the network and request time to connect.</p>
            </div>
            <div className="right">
              <div className="yesRadio input">
                <input
                  onChange={(e) => {
                    setIsCommunityConnections(true);
                  }}
                  value={true}
                  type="radio"
                  checked={isCommunityConnections === true}
                  name="communityConnections"
                  id="yesRadio"
                />
                <span><label for="yesRadio">Yes</label></span>
              </div>
              <div className="noRadio input">
                <input
                  onChange={(e) => {
                    setIsCommunityConnections(false);
                  }}
                  value={false}
                  type="radio"
                  checked={isCommunityConnections === false}
                  name="communityConnections"
                  id="noRadio"
                />
                <span><label for="noRadio">No&nbsp;</label></span>
              </div>
            </div>
            <button
              type="button"
              onClick={() => {
                if (isCommunityConnections === null) {
                  setErrors(["Please choose an option"]);
                  return;
                }
                next();
                setErrors([]);
              }}
            >
              Next
            </button>
          </div>
        )}

        {step === 4 && (
          <div className="formItem monthlyAllyMeetingCount">
            <div className="left">
              <h4>How many ally meetings per month will you commit to?</h4>
              <p>Most members start with 3 or 4. You can increase or decrease this at any time in your profile.</p>
            </div>

            <div className="right">
              <input
                onChange={(e) => {
                  setMonthlyAllyMeetingCount(e.target.value);
                }}
                className="monthlyAllyMeetingCount"
                value={monthlyAllyMeetingCount}
                type="number"
              ></input>
            </div>
            <button
              type="button"
              onClick={() => {
                if (!monthlyAllyMeetingCount) {
                  setErrors(["Please enter a number of ally meetings per month"]);
                  return;
                }
                next();
                setErrors([]);
              }}
            >
              Next
            </button>
          </div>
        )}

        {step === 5 && (
          <div className="formItem explanation">
            <div className="full">
              <p>The following questions will help fellow members discover you on our platform and learn some background to make meetings more productive and interesting.</p>
            </div>
            <button
              type="button"
              onClick={() => {
                next();
                setErrors([]);
              }}
            >
              Next
            </button>
          </div>
        )}

        {step === 6 && (
          <div className="formItem areaOfExpertise">
            <div className="left">
              <h3>What are your areas of expertise?</h3>
              <p>Regarding your work, share some of the topics that you are working on and interested in so that other members can discover you.</p>
            </div>
            <div className="right">
              <textarea
                onChange={(e) => {
                  setAreaOfExpertise(e.target.value);
                }}
                value={areaOfExpertise}
                type="text"
              ></textarea>
            </div>
            <button
              type="button"
              onClick={() => {
                if (!areaOfExpertise) {
                  setErrors(["Please enter your areas of expertise"]);
                  return;
                }
                next();
                setErrors([]);
              }}
            >
              Next
            </button>
          </div>
        )}

        {step === 7 && (
          <div className="formItem rewarding">
            <div className="left">
              <h3>What’s something about work that you find most rewarding?</h3>
            </div>
            <div className="right">
              <textarea
                onChange={(e) => {
                  setRewarding(e.target.value);
                }}
                value={rewarding}
                type="text"
              ></textarea>
            </div>
            <button
              type="button"
              onClick={() => {
                next();
              }}
            >
              Next
            </button>
          </div>
        )}

        {step === 8 && (
          <div className="formItem hobbies">
            <div className="left">
              <h3>What are your hobbies / what do you do for fun?</h3>
            </div>
            <div className="right">
              <textarea
                onChange={(e) => {
                  setHobbies(e.target.value);
                }}
                value={hobbies}
                type="text"
              ></textarea>
            </div>
            <button
              type="button"
              onClick={() => {
                next();
              }}
            >
              Next
            </button>
          </div>
        )}

        {step === 9 && (
          <div className="formItem hometown">
            <div className="left">
              <h3>What’s your hometown and why should everyone visit?</h3>
            </div>
            <div className="right">
              <textarea
                onChange={(e) => {
                  setHometown(e.target.value);
                }}
                value={hometown}
                type="text"
              ></textarea>
            </div>
            <button
              type="button"
              onClick={() => {
                next();
              }}
            >
              Next
            </button>
          </div>
        )}
      </form>

      {showLogin && (
        <>
        <div className="modal-container">
          <div className="modal">
              <Signup auth0={props.auth0} firstName={name.split(" ")[0]} lastName={name.split(" ")[1] || ""} linkedinUrl={cleanLinkedInUrl(linkedinUrl)}/>
          </div>
          <div className="skrim">
          </div>
        </div>
        </>
      )}
    </div>
  );
}
