import { useUserContext } from "./UserContext";
import { useIsRecruiter } from "./RoleHelper";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const HomeRedirect = () => {
    const { user, collectives, contextLoaded } = useUserContext();
    const history = useHistory();
    const isRecruiter = useIsRecruiter();
    const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect();
        } else if (!isLoading && isAuthenticated && contextLoaded) {
            if (isRecruiter) {
                history.push("/employer");
            } else if (collectives.length > 0 && !collectives.map(c => c.collectiveId).includes(1)) { // Belongs to a collective other than the original
                history.push("/community-connections");
            } else if (collectives.length > 0) { // only belongs to the original collective
                history.push("/collective");
            } else if (user.is_nft_user && !user.in_collective) { // NFT user only
                history.push("/trophies");
            } else {  // legacy user
                history.push("/profile");
            }
        }
    }, [isAuthenticated, loginWithRedirect, isLoading, isRecruiter, collectives, contextLoaded]);

    return (
        <div>Loading...</div>
    );
};

export default HomeRedirect;