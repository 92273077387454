import React from "react";
import "./App.scss";
import "./CSS/Profile.scss";
import { Redirect } from "react-router-dom";
import ProfileTop from "./components/Profile/ProfileTop";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Nft from "./Trophies/components/Nft";
import NftSingle from "./Trophies/components/NftSingle";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stats: [],
      showMoreCompany: false,
      trophies: [],
      showMoreNfts: false,
      top: [],
      actual: [],
      user: {
        external_profile: {
          Positions: [],
          Skills: [],
          profile_data: {},
        },
      },
      showMoreTop: true,
    };
  }

  

  async getTrophies() {
    let token = this.state.token;

    let response = await fetch(`${process.env.REACT_APP_URL_JAVA}/trophies`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    let data = await response.json();

    this.setState({
      trophies: data.content
    })
  };

  async logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('roles');
    this.props.auth0.logout({ returnTo: window.location.origin });
  }

  async getUserByExternalProfileId(id) {
    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/external-profiles/${id}/user`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json(); // parse JSON
    return data;
  }

  async componentDidMount() {
    const rolesJSON = localStorage.getItem("roles");
    const roles = JSON.parse(rolesJSON);
    const {
      match: { params },
    } = this.props;
    let blockRatings = true;
    let user = JSON.parse(localStorage.getItem("user"));

    user = await this.getUserByExternalProfileId(user.external_profile.id)

    user.header_image = user.external_profile.headerImage;
    
    await this.setState({
      user: user,
    });

    if ((roles && roles.includes("Recruiter")) || roles.includes("Admin")) {
      blockRatings = false;
    }

    const token =  await this.props.auth0.getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access"
    });

    this.setState({
      token
    })

    this.getTrophies();

    this.setState({
      loading: true,
      showWelcomeModal: false,
      blockRatings,
    });
  }

  convertToNumber(pills) {
    if(!pills) return 0;
    return pills.split("<>").length;
  }

  render() {
    if (this.state.redirect) return <Redirect to={this.state.redirect} />;


    return (
      <div
        className={`app profile singleProfile ${
          this.state.numberOfRated >= 50 ? "fullWidth" : "smallWidth"
        }`}
      >
        <div className="profile-main">
          {!this.state.user.is_nft_user && !this.state.user.in_collective && (
            <div className="under-construction">
              Under construction. The core Hearty experience is changing. Check back soon.
            </div>
          )}

          <ProfileTop
            user={this.state.user.external_profile}
            userId={this.state.user.id}
            publicProfile={false}
            Positions={this.state.Positions}
            member={this.state.user}
          />

          <div className="myRatings">
            {this.state.trophies.length > 0 && (
              <div className="yourNfts">
                <h3>Your NFTs ({this.state.trophies.length})
                <a onClick={()=>{
                  this.setState({
                    showMoreNfts: !this.state.showMoreNfts
                  })
                }}>{this.state.showMoreNfts ? 'Show Less' : 'Show More'}</a>
                </h3>
                {this.state.trophies.map((nft, index) => {
                  if(this.state.showMoreNfts == false && index > 3) return null;
                  if(nft.single) return <NftSingle singleDisplay={true} key={nft.trophyId} nft={nft} />;
                  return <Nft key={nft.trophyId} nft={nft} />;
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withAuthenticationRequired(Profile, {
  onRedirecting: () => <p>Loading...</p>,
});