import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import "../CSS/Collective/Introductions.scss";

export default function Introductions(props) {
    const { getAccessTokenSilently } = useAuth0();
    const [view, setView] = useState("pending");
    const [introductions, setIntroductions] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [counts, setCounts] = useState(null);
    
    useEffect(() => {
        getCounts();
        getIntroductions();
    }, []);
    
    const getIntroductions = async (status) => {
        let token = false;
        try {
            token = await getAccessTokenSilently({
            audience: `https://api-v2.behearty.co`,
            scope: "read:current_user offline_access",
        });
        } catch (error) {
        }

        let url = `${process.env.REACT_APP_URL_JAVA}/introductions?`;

        if (status && status !== 'all') {
            url += `status=${status}&`;
        }

        const response = await fetch(
            url,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
        const data = await response.json();
        setIntroductions(data.content);
    };

    const getCounts = async () => {
        let token = false;
        try {
            token = await getAccessTokenSilently({
            audience: `https://api-v2.behearty.co`,
            scope: "read:current_user offline_access",
        });
        } catch (error) {
        }

        let url = `${process.env.REACT_APP_URL_JAVA}/introductions/status`;

        const response = await fetch(
            url,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
        const data = await response.json();
        setCounts(data);
    };

    const updateIntroductionStatus = async (id, status) => {
        let token = false;
        try {
            token = await getAccessTokenSilently({
            audience: `https://api-v2.behearty.co`,
            scope: "read:current_user offline_access",
        });
        } catch (error) {
        }

        let url = `${process.env.REACT_APP_URL_JAVA}/introductions/${id}/status`;

        const response = await fetch(
            url,
            {
              method: "PATCH",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
                body: JSON.stringify({
                    status: status,
                }),
            }
          );
        const data = await response.json();
        getIntroductions(view);
    };

    const formatIntroductionStatus = (status) => {
        switch (status) {
            case "PENDING":
                return "Pending";
            case "YES":
                return "Introduction Made";
            case "No":
                return "Rejected";
            case "HOLD":
                return "Hold";
            default:
                return "Unknown";
        }
    };

    const handleSearch = async (query) => {
        let token = await getAccessTokenSilently({
          audience: `https://api-v2.behearty.co`,
          scope: "read:current_user offline_access",
        });
    
        const response = await fetch(
          `${process.env.REACT_APP_URL_JAVA}/introductions?&name=${query}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
    
        setView('all');
        const searched = await response.json();
        setIntroductions(searched.content);
      };
    
    const searchAPIDebounced = AwesomeDebouncePromise(handleSearch, 400);
    
    return (
        <div className="introductions">
            <div className="sidebar">
                <a
                    className={`${view === "all" ? "active" : ""}`}
                    onClick={async() => {
                        await getIntroductions();
                        setView("all");
                    }}
                >
                    All
                </a>
                <a
                    className={`${view === "pending" ? "active" : ""}`}
                    onClick={async() => {
                        await getIntroductions("pending");
                        setView("pending");
                    }}
                >
                    Pending Introduction {counts && (counts.pending > 0 ? `(${counts.pending})` : '')}
                </a>
                <a
                    className={`${view === "hold" ? "active" : ""}`}
                    onClick={async() => {
                        await getIntroductions("hold");
                        setView("hold");
                    }}
                >
                    On Hold {counts && (counts.hold > 0 ? `(${counts.hold})` : '')}
                </a>
            </div>
            <div className="introductionsContainer">
                <div className="filters">
                    <input
                        className="search"
                        onChange={(e) => {
                        setSearchText(e.target.value);
                        searchAPIDebounced(e.target.value);
                        }}
                        type="text"
                        value={searchText}
                        placeholder="Search"
                    />
                </div>
                {introductions.length > 0 && introductions.map((intro) => {
                    return (
                    <div className="introduction" key={intro.id}>
                        <Link to={`/user/${intro.introCustomUrl}`} target="_blank">
                            <div className="name-image">
                                <div className="avatar">
                                    <img src={intro.introImage} alt="avatar" />
                                </div>
                                <div className="name">
                                    <h3>{intro.introName}</h3>
                                    <p className="requestedBy">Requested by: {intro.requestName} @ {intro.companyName}</p>
                                </div>
                            </div>
                        </Link>
                        <div className="messages">
                            <Link to={`/conversations/admin?conversationId=${intro.conversationId}`} target="_blank"><FontAwesomeIcon icon={faEnvelope} /><span> {intro.numberOfMessages} message{intro.numberOfMessages > 1 ? "s" : ""}</span></Link>
                        </div>
                        
                        <div className="buttons">
                            {intro.status === "PENDING" || intro.status === "HOLD" ? (
                                <>
                                <button className="introduce"
                                    onClick={async()=> {
                                    await updateIntroductionStatus(intro.id, 'YES');
                                    }}>
                                    Make Introduction
                                </button>
                                <button className="decline"
                                    onClick={async()=> {
                                    await updateIntroductionStatus(intro.id, 'NO');
                                    }}>
                                    Reject
                                </button>
                                </>
                            ) : (
                                <div className="status"><p>{formatIntroductionStatus(intro.status)}</p></div>
                            )}
                        </div>
                    </div>
                );
                })}
            </div>
        </div>
    );
};