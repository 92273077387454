export const getAuthenticatedUserCollectives = () => {
    const collectivesJson = localStorage.getItem("collectives");

    if (!collectivesJson) {
        return [];
    }
    const collectives = JSON.parse(collectivesJson);
    return collectives;
};

export const setAuthenticatedUserCollectives = (collectives) => {
    localStorage.setItem("collectives", JSON.stringify(collectives));
};

export const saveCollectiveOnboardingProfile = (profile) => {
    localStorage.setItem("CollectiveOnboardingProfile", JSON.stringify(profile));
};

export const deleteCollectiveOnboardingProfile = () => {
    localStorage.removeItem("CollectiveOnboardingProfile");
};

export const getUser = () => {
    const userJson = localStorage.getItem("user");

    if (!userJson) {
        return null;
    }
    const user = JSON.parse(userJson);
    return user;
};

export const setRoles = (user) => {
    const roles = user.role_users.map((ru) => {
        return ru.role.name;
    });
    localStorage.setItem("roles", JSON.stringify(roles));
};