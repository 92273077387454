import React, { useState } from "react";

import check from "../images/checklist.png"
import '../CSS/HeartyName.scss';

export default function HeartyName(props) {
  const [showOrgDropdown, setShowOrgDropdown] = useState(false);

  const displayCompanies = (all)=>{

    if(all.length === 0) return '';
    return (
      <div onClick={(e)=>{
        setShowOrgDropdown(!showOrgDropdown);
      }} className="allOrgs">&middot; {all.length} {all.length > 1 ? 'organizations' : 'organization'}
      
      {showOrgDropdown &&
                <div className="orgDropdown">
                  <div className="modal">
                    {all.map((comp)=>{
                      if(comp.type==='company')  return <a href={`/company/${comp.url}`}><img src={comp.image} /> {comp.name}</a>
                      return <a href={`/organization/${comp.url}`}><img src={comp.image} /> {comp.name}</a>
                    
                    })}
                    </div>
                </div>
              }
      </div>
    )
  }

  if(props.isProven) {
      return (
      <span className="heartyName">
        {props.name} {props.nonMember && <span>(Non Member)</span>}
        <img className="heartyImage" src={check} />
        {props.lists &&
          displayCompanies(props.lists)
        }
        </span>
      )
  }


  return (
    <span className="heartyName">{props.name} {props.nonMember && <span>(Non Member)</span>} {props.lists &&
      displayCompanies(props.lists)
    }</span>
  );
}
