import React, { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import "../CSS/Collective/admin.scss";
import { Link } from "react-router-dom";

const CollectiveCandidateAdmin = (props) => {
  const {
    loginWithRedirect,
    user,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    logout,
  } = useAuth0();
  const userObject = JSON.parse(localStorage.getItem("user"));
  const [activeRoles, setActiveRoles] = useState([]);

  const [isAdmin, setIsAdmin] = useState(() => {
    const rolesJSON = localStorage.getItem("roles");
    const roles = JSON.parse(rolesJSON);
    return roles && roles.includes("Admin");
  });


  const getActiveRoles = async () => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      loginWithRedirect();
    }

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/company-collective-roles/active`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();

    setActiveRoles(data);
  };

  useEffect(() => {
    if (isAdmin) {
      getActiveRoles();
    }
  }, []);

  if (!userObject) {
    return <div>Loading...</div>;
  }

  return (
    <div className="collectiveAdmin">
      <div className="talentContainer">
        <div className="talent">
          <table className="active">
            <thead className="sticky">
              <tr>
                <th>Role</th>
                <th>Introductions Offered</th>
                <th>Initial Screen</th>
                <th>Tech Evaluation</th>
                <th>Final Interview</th>
                <th>Offer Not Made</th>
              </tr>
            </thead>
            <tbody>
              {activeRoles.map((role) => {
                return (
                  <tr key={role.companyCollectiveRoleId}>
                    <td className="name-image">
                      <span className="avatar">
                        <Link to={`/company/${role.companyCustomUrl}`} target="_blank">
                          <img src={role.companyImageUrl} alt="avatar" />
                        </Link>
                      </span>
                      <span className="name-date">
                        <Link to={`/company/${role.companyCustomUrl}`} target="_blank">
                          <h3 className="name">{role.roleName}</h3>
                        </Link>
                        <span className="last-contacted">
                          <div className="label">{role.companyName}</div>
                        </span>
                      </span>
                    </td>
                    <td><span className="tooltip">{role.introductionsOffered}<span className="tooltiptext">{role.introductionsOfferedCandidates?.replaceAll("|", "\n")}</span></span></td>
                    <td><span className="tooltip">{role.initialScreen}<span className="tooltiptext">{role.initialScreenCandidates?.replaceAll("|", "\n")}</span></span></td>
                    <td><span className="tooltip">{role.techEvaluation}<span className="tooltiptext">{role.techEvaluationCandidates?.replaceAll("|", "\n")}</span></span></td>
                    <td><span className="tooltip">{role.finalInterview}<span className="tooltiptext">{role.finalInterviewCandidates?.replaceAll("|", "\n")}</span></span></td>
                    <td><span className="tooltip">{role.offerNotMade}<span className="tooltiptext">{role.offerNotMadeCandidates?.replaceAll("|", "\n")}</span></span></td>
                  </tr>
                )
              })}
            </tbody>
            <tfoot className="sticky">
              <tr>
                <td>TOTAL</td>
                {activeRoles && activeRoles.length > 0 && (
                  <>
                  <td>{activeRoles.map((c) => c.introductionsOffered).reduce((sum, role) => sum + role)}</td>
                  <td>{activeRoles.map((c) => c.initialScreen).reduce((sum, role) => sum + role)}</td>
                  <td>{activeRoles.map((c) => c.techEvaluation).reduce((sum, role) => sum + role)}</td>
                  <td>{activeRoles.map((c) => c.finalInterview).reduce((sum, role) => sum + role)}</td>
                  <td>{activeRoles.map((c) => c.offerNotMade).reduce((sum, role) => sum + role)}</td>
                  </>
                )}
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CollectiveCandidateAdmin;
