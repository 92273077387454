import React from 'react';
import './App.scss';
import {
    Redirect,
  } from "react-router-dom";


class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordMatch: true,
      token: false,
      email: '',
      user: {
          External_Profile: {}
      },
      userPreferences: {}
    }
    this.listRef = React.createRef();
  }

  async componentDidMount() {
    await this.getToken();
    this.getUser();
    this.getEmail();
    this.getUserEmailPreferences();
  }

  async getToken(){
    const token =  await this.props.auth0.getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access"
    });

    this.setState({token})
  }

  getUserEmailPreferences() {
    const token =  this.state.token;
    let user = localStorage.getItem('user');
    user = JSON.parse(user);
    fetch(`${process.env.REACT_APP_URL_JAVA}/users/${user.id}/email-preferences`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => response.json())
    .then((userPreferences) => {
      this.setState({
        userPreferences
      })
    })
  }

  async emailChanged(e) {
    const userPreferences = this.state.userPreferences;
    userPreferences.unsubscribedAll = !e
    this.setState({
      userPreferences
    })
  }

  async activityChanged(e) {
    const userPreferences = this.state.userPreferences;
    userPreferences.notificationEmail = e
    this.setState({
      userPreferences
    })
  }

  async messagesChanged(e) {
    const userPreferences = this.state.userPreferences;
    userPreferences.messagingEmail = e
    this.setState({
      userPreferences
    })
  }

  async digestChanged(e) {
    const userPreferences = this.state.userPreferences;
    userPreferences.leaderboardEmail = e
    this.setState({
      userPreferences
    })
  }

  async openDmChanged(e) {
    const userPreferences = this.state.userPreferences;
    userPreferences.openDm = e
    this.setState({
      userPreferences
    })
  }

  getUser() {
    const token =  this.state.token;

    let user = localStorage.getItem('user');
    user = JSON.parse(user);
    fetch(`${process.env.REACT_APP_URL_JAVA}/users/me`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => response.json())
    .then((user) => {
      this.setState({
        user: user,
        email: user.email
      })
    })
  }

  getEmail() {
    const token =  this.state.token;

    let user = localStorage.getItem('user');
    user = JSON.parse(user);
    fetch(`${process.env.REACT_APP_URL_JAVA}/users/email`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => response.text())
    .then((email) => {
      this.setState({
        email
      })
    })
  }

  async updateUser() {
    const token =  this.state.token;

    let user = JSON.parse(localStorage.getItem('user'))
    const response = await fetch(`${process.env.REACT_APP_URL_JAVA}/users/${user.id}`, {
      method: 'PUT',
      body:JSON.stringify(this.state.user),
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    })
    const data = await response.json() // parse JSON
    this.setState({
      success:true
    })

    setTimeout(() => {
      this.setState({
        success:false
      })
    }, 5000);

    return data;
  }

  async updateUserEmailPreferences() {
    const token =  this.state.token;

    const response = await fetch(`${process.env.REACT_APP_URL_JAVA}/user-preferences/${this.state.user.id}`, {
      method: 'PUT',
      body:JSON.stringify(this.state.userPreferences),
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    })
    const data = await response.json() // parse JSON
    return data
  }

  async submitForm(e) {
    e.preventDefault();    
    const user = await this.updateUser();
    const userPreferences = await this.updateUserEmailPreferences();
    this.setState({
      user: user
    })
    // this.props.changeHeader();
  }


  changeSelect(e) {
    const user = this.state.user;
    user[e.target.name] = e.target.value;

    this.setState({
        user
    })
  }

  changeInput(e) {
    const user = this.state.user;
    user[e.target.name] = e.target.value;

    this.setState({
        user
    })
  }

  changeEmail(e) {
    this.setState({
        email: e.target.value
    })
  }

  async changeEmailForm(e) {
    e.preventDefault();
    const token =  this.state.token;

    const response = await fetch(`${process.env.REACT_APP_URL_JAVA}/users/${this.state.user.id}/email`, {
      method: 'PATCH',
      body:JSON.stringify({
        email: this.state.email
      }),
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    })
    const data = await response.json() // parse JSON
    this.setState({
      emailSuccess: true
    })

    setTimeout(() => {
      this.setState({
        emailSuccess: false
      })
    }, 5000);
    return data
  }

  changeTextarea(e) {
    const user = this.state.user;
    user.External_Profile[e.target.name] = e.target.value;

    this.setState({
        user
    })
  }
  
  render() {

    if (this.state.redirect) return <Redirect to={this.state.redirect} />
    return (
      <div className="settings">
        {this.state.success &&
          <div className="alert success">Your preferences have been saved!</div>
        }
        <div class="section">
          <h2>User Settings</h2>
          <form className="settingsForm" onSubmit={this.changeEmailForm.bind(this)}>
              <div className="row">
                {this.state.emailSuccess &&
                <div className="alert success">Email succesfully changed</div>
                }
                <div className="item">
                  <label>Email</label>
                  <input onChange={this.changeEmail.bind(this)} value={this.state.email} name="email" type="text" />
                </div>
              </div>

              <div className="row">
                <button className="btn large" type="submit">Save</button>
              </div>
          </form>
        </div>

        {/* <div class="section">
          <form className="settingsForm" onSubmit={this.submitForm.bind(this)}>
            <h2>Email Settings</h2>

            <div className="radio">
              <label>Direct Messages</label>
              <select onChange={(e)=> {
                this.messagesChanged(e.target.value)
              }} value={this.state.userPreferences.messagingEmail}>
                <option value={'INDIVIDUAL'}>Individually</option>
                <option value={'HOURLY'}>Hourly</option>
                <option value={'OFF'}>Off</option>
              </select>
            </div>

            <h2>Message Settings</h2>

            <div className="radio">
              <label>Who can message me</label>
              <select onChange={(e)=> {
                this.openDmChanged(e.target.value)
              }} value={this.state.userPreferences.openDm}>
                <option value={true}>Anyone</option>
                <option value={false}>People you follow</option>
              </select>
            </div>
            <div className="row">
                <button className="btn large" type="submit">Save</button>
            </div>
          </form>
        </div> */}
      </div>
    );
  }
}

export default Home;
