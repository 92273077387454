
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../CSS/Leaderboards/single.scss";
import Single from './single';

import { useLocation } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";


export default function LeaderboardSingle(props) {
  const [loggedInUser, setLoggedInUser] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const [leaderBoardData, setLeaderBoardData] = useState({
    user: {
      external_profile: {},
    },
  });
  let params = useParams();

  const search = useLocation().search;
  let leaderId = new URLSearchParams(search).get("leaderId");

  useEffect(() => {
    const jsonUser = localStorage.getItem("user");

    if (jsonUser) {
      const user = JSON.parse(jsonUser);
      setLoggedInUser(user);
    }
    async function fetchData() {
      window.scrollTo(0, 0);
      const leaderboard = await getLeaderboard();
    }
    fetchData();


  }, []);





  const getLeaderboard = async () => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access"
      });
    } catch (error) {
      console.log(error);
    }
    
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }
    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/leaderboard/custom-url?url=${params.id}`,
      {
        method: "GET",
        headers,
      }
    );
    const leaderboards = await response.json();
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && leaderboards.userId === user.id) {
      setCanEdit(true);
    }
    console.log(leaderboards);
    setLeaderBoardData(leaderboards);
    return leaderboards;
  };


  if(!leaderBoardData || !leaderBoardData.id) return (<p>Loading..</p>)

  if(leaderBoardData.type==="INDIVIDUAL") {
      return <Single getLeaderboard={getLeaderboard} leaderBoardData={leaderBoardData} />
  }

}
