import React from "react";
import "./App.scss";
import "./CSS/PublicProfile.scss";
import { Redirect } from "react-router-dom";
import ProfileTop from "./components/Profile/ProfileTop";

import { Helmet } from "react-helmet";
import Nft from "./Trophies/components/Nft";
import NftSingle from "./Trophies/components/NftSingle";
import moment from "moment";

class PublicProfile extends React.Component {
  constructor(props) {
    super(props);

    const rolesJSON = localStorage.getItem('roles');
    const roles = JSON.parse(rolesJSON);
    let admin = false;
    if(roles) {
      admin = roles.includes('Admin');
    }

    this.state = {
      externalProfileId: 669,
      showWelcomeModal: false,
      trophies: [],
      noUser: false,
      actual: [],
      externalProfile: {
        External_Profile: {
          Skills: [],
          Positions: [],
          profile_data: {},
        },
      },
      admin,
      email: '',
      cities: [],
      skills: [],
      workHistory: [],
      jobFunctions: [],
      showMoreSkills: false,
    };

    this.listRef = React.createRef();
    this.toggleWelcomeModal = this.toggleWelcomeModal.bind(this);
  }

  getAwards() {
    const token = this.state.token;
    let headers = {};

    if(token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophies?externalProfileId=${this.state.externalProfile.id}`,
      {
        method: "GET",
        headers,
      }
    )
      .then((response) => response.json())
      .then(async (trophies) => {
        if (trophies.errors) {
          return this.setState({
            trophies: false,
          });
        }
        this.setState({
          trophies: trophies.content
        });
      });
  }

  async getExternalProfileByUserName() {
    try {
      const token = this.state.token;
      const response = await fetch(
        `${process.env.REACT_APP_URL_JAVA}/external-profiles/url/${this.props.match.params.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json(); // parse JSON
      return data;
    } catch (error) {
      return false;
    }
  }

  async getUserByExternalProfileId(id) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_JAVA}/external-profiles/${id}/user`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json(); // parse JSON
      return data;
    } catch (error) {
      return false;
    }
  }

  async getSkills(id) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_JAVA}/external-profiles/${id}/skills`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json(); // parse JSON
      this.setState({
        skills: data,
      });
    } catch (error) {
      return false;
    }
  }

  async getWorkHistory(id) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_JAVA}/external-profiles/${id}/positions`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json(); // parse JSON
      this.setState({
        workHistory: data,
      });
    } catch (error) {
      return false;
    }
  }

  async getJobFunctions(id) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_JAVA}/external-profiles/${id}/job-functions`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json(); // parse JSON
      this.setState({
        jobFunctions: data,
      });
    } catch (error) {
      return false;
    }
  }

  async componentDidMount() {
    let blockRatings = true;
    const loggedinUser = JSON.parse(localStorage.getItem("user"));
    await this.setState({
      loggedinUser,
    });

    let token = false;

    try {
      token = await this.props.auth0.getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      console.log("WTF", error);
    }

    await this.setState({
      token,
    });

    // NEEDS ERROR HANDLING
    const externalProfile = await this.getExternalProfileByUserName();
    let user = false;
    if (externalProfile.id) {
      user = await this.getUserByExternalProfileId(externalProfile.id);
      this.getSkills(externalProfile.id);
      this.getWorkHistory(externalProfile.id);
      this.getJobFunctions(externalProfile.id);
    }

    await this.setState({
      externalProfile,
      user,
    });

    if (!user && externalProfile.status === "NOT_FOUND") {
      return this.setState({
        redirect: '/feed'
      })
    }

    if(this.state.admin) {
      this.getEmail();
    }

    this.getAwards();
    this.setState({
      loading: true,
      showWelcomeModal: false,
      blockRatings,
    });
  }


  getEmail() {
    if (!this.state.user.id) {
      return;
    }

    const token = this.state.token;
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }
    try {
      fetch(
        `${process.env.REACT_APP_URL_JAVA}/users/${this.state.user.id}/email`,
        {
          method: "GET",
          headers,
        }
      )
        .then((response) => {
          if (response.status === 200) {
            return response.text();
          }
        }).then((email) => {
          this.setState({
            email,
            });
        });
    } catch (error) {
      console.log(error);
    }
  }

  toggleWelcomeModal() {
    this.setState({
      showWelcomeModal: !this.state.showWelcomeModal,
    });
  }

  formatDate(date) {
    if (!date) {
      return "";
    } else {
      return new Date(date).toLocaleString("en-US", {
        month: "long",
        year: "numeric",
      });
    }
  }

  //get duration of work experience
  getDuration(start, end) {
    if (!end) {
      end = moment();
    } else {
      end = new moment(end);
    }
    start = new moment(start);
    const duration = end.diff(start, "months");
    const months = duration % 12;
    const years = (duration - months) / 12;
    let durationString = "";
    if (years > 0) {
      durationString += years + "yr";
      if (years > 1) {
        durationString += "s";
      }
    }
    if (months > 0) {
      if (durationString.length > 0) {
        durationString += " ";
      }
      durationString += months + "mo";
      if (months > 1) {
        durationString += "s";
      }
    }

    return durationString;
  }

  render() {
    if (this.state.redirect) return <Redirect to={this.state.redirect} />;

    let SEOHeadline = `${this.state.externalProfile.name} - Hearty Profile`;

    return (
      <div className="app profile publicProfile fullWidth">
        {this.state.externalProfile.name && (
          <Helmet>
            <meta charSet="utf-8" />
            <title>{SEOHeadline}</title>
            <meta
              name="description"
              content={this.state.externalProfile.headline}
            />
            <meta
              property="og:title"
              content={this.state.externalProfile.name}
            />
            <meta
              property="og:description"
              content={this.state.externalProfile.headline}
            />
            <meta
              property="og:image"
              content={this.state.externalProfile.image}
            />
            <meta property="og:url" content={window.location} />

            <meta
              property="twitter:title"
              content={this.state.externalProfile.name}
            />
            <meta
              property="twitter:description"
              content={this.state.externalProfile.headline}
            />
            <meta
              property="twitter:image"
              content={this.state.externalProfile.image}
            />
            <meta name="twitter:card" content="summary_large_image"></meta>
          </Helmet>
        )}
        <div className="fullWidth">
          {this.state.externalProfile.id && (
            <ProfileTop
              user={this.state.externalProfile}
              publicProfile={true}
              actualUser={this.state.user}
              Positions={this.state.Positions}
              updateCounts={this.updateCounts}
              email={this.state.email}
              jobFunctions={this.state.jobFunctions}
            />
          )}

          {this.state.skills?.length > 0 && (
            <div className="skills">
              <h3>Skills</h3>
              <span className="skillList">
                  {this.state.showMoreSkills && this.state.skills.map((skill) => (
                      <div key={skill} className="skill">{skill}</div>
                  ))}
                  {!this.state.showMoreSkills && this.state.skills.slice(0, 10).map((skill) => (
                      <div key={skill} className="skill">{skill}</div>
                  ))}
              </span>
              {this.state.skills.length > 10 && (
                <div
                  className="showMore"
                  onClick={() => this.setState({ showMoreSkills: !this.state.showMoreSkills })}
                  >
                    Show {this.state.showMoreSkills ? "Less" : "More"}
                </div>
              )}
            </div>
          )}

          {this.state.workHistory?.length > 0 && (
            <div className="workHistory">
              <h3>Work History</h3>
              <span className="jobList">
                  {this.state.workHistory.map((job) => (
                      <div key={job.id} className="job">
                        <div className="job-title">{job.title}</div>
                        <div className="company">{job.company}</div>
                        <div className="date">{this.formatDate(job.startDate)} - {this.formatDate(job.endDate) || "Present"}﹒{this.getDuration(job.startDate, job.endDate)}</div>
                      </div>
                  ))}
              </span>
            </div>
          )}

          {this.state.trophies?.length > 0 && (
            <div className="trophyList">
              <h3>NFTs</h3>
              {this.state.trophies.map((nft)=>{
                if(nft.status === "PENDING") return "";
                if(nft.single) return <NftSingle singleDisplay={true} key={nft.trophyId} nft={nft} />;
                return <Nft key={nft.trophyId} nft={nft} />;
              })}
            </div>
          )}

          {this.state.user?.is_community_connections && (
            <div className="communityConnections">
              <h3>Community Connections</h3>
              <div className="meetingCount group">
                <div className="question">How many ally meetings per month will you commit to?</div>
                <div className="answer">{this.state.user.monthly_ally_meeting_count}</div>
              </div>
              {this.state.user.area_of_expertise && (
                <div className="areasOfExpertise group">
                  <div className="question">What are your areas of expertise?</div>
                  <div className="answer">{this.state.user.area_of_expertise}</div>
                </div>
              )}
              {this.state.user.rewarding && (
                <div className="rewarding group">
                  <div className="question">What’s something about work that you find most rewarding?</div>
                  <div className="answer">{this.state.user.rewarding}</div>
                </div>
              )}
              {this.state.user.hobbies && (
                <div className="hobbies group">
                  <div className="question">What are your hobbies / what do you do for fun?</div>
                  <div className="answer">{this.state.user.hobbies}</div>
                </div>
              )}
              {this.state.user.hometown && (
                <div className="hometown group">
                  <div className="question">What’s your hometown and why should everyone visit?</div>
                  <div className="answer">{this.state.user.hometown}</div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default PublicProfile;
