import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import {
  faLocationDot,
  faPencilAlt,
  faUsers,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import HeartyName from "../../components/HeartyName";
import { truncateString, capitalizeLocation } from "../../helpers";
import { getAuthenticatedUserCollectives, getUser } from "../../LocalStorageUtil";
import Captain from "../../images/captain-emoji.png";

export default function ProfileTop(props) {
  const [headerImage, setHeaderImage] = useState(props.user.header_image);
  const { isAuthenticated, getAccessTokenSilently } =
    useAuth0();
  const [truncateLength, setTruncateLength] = useState(250);
  const [introStatus, setIntroStatus] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalMeeting, setModalMeeting] = useState(false);
  const [jobTitle, setJobTitle] = useState(null);
  const [jobDescriptionUrl, setJobDescriptionUrl] = useState(null);
  const [nextAvailableMeeting, setNextAvailableMeeting] = useState(null);
  const [meetingBooked, setMeetingBooked] = useState(false);
  const [showBookMeetingButton, setShowBookMeetingButton] = useState(false);
  const [showRequestIntroButton, setShowRequestIntroButton] = useState(false);
  const [showAllyBadge, setShowAllyBadge] = useState(false);
  const [showRecruiterBadge, setShowRecruiterBadge] = useState(false);
  const [showERGBadge, setShowERGBadge] = useState(false);

  useEffect(() => {
    if (props.user.header_image) setHeaderImage(props.user.header_image);
    if (props.user.headerImage) setHeaderImage(props.user.headerImage);
  }, [
    props.user.header_image,
    props.user.headerImage,
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      getProfileCollectives();
      getIntro();
      getMeeting();
      getNextAvailableMeeting();
    }
  }, []);

  const headerImageConditional = () => {
    if (headerImage) {
      return {
        backgroundImage: `url(${headerImage})`,
      };
    } else {
      return {
        backgroundColor: "#b70000",
      };
    }
  };

  const getProfileCollectives = async() => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-collectives/roles?externalProfileId=${props.user.id}`,
      {
        method: "GET",
        headers,
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject("No collectives found");
        }
      })
      .then((collectives) => {
        setShowBookMeetingButton(canBookMeeting(collectives));
        return collectives;
      })
      .then((collectives) => {
        setShowRequestIntroButton(canRequestIntro(collectives));
        return collectives;
      })
      .then((collectives) => {
        setShowAllyBadge(isAlly(collectives));
        setShowRecruiterBadge(isRecruiter(collectives));
        setShowERGBadge(isERG(collectives));
      }).catch((error) => {});
  };

  // On file select (from the pop up)
  const onFileChange = async (file) => {
    onFileUpload(file[0]);
  };

  // On file upload (click the upload button)
  const onFileUpload = async (selectedImage) => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", selectedImage, selectedImage.name);

    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/files/uploadFile?directory=header`,
      {
        method: "POST",
        enctype: "multipart/form-data",
        contentType: false,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    )
      .then((response) => {
        console.log(response);
        if (response.ok) {
          return response.text();
        }

        throw new Error("Error uploading image");
      })
      .then((data) => {
        setHeaderImage(data);
        saveHeaderImage(data);
      })
      .catch((error) => {});
  };

  const saveHeaderImage = async (header_image) => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const response = await fetch(`${process.env.REACT_APP_URL_JAVA}/users/me`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const userObject = await response.json();

    userObject.external_profile.headerImage = header_image;

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/external-profiles/${userObject.external_profile.id}`,
      {
        method: "PUT",
        body: JSON.stringify({
          ...userObject.external_profile,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        userObject.External_Profile = data;
        userObject.external_profile = data;

        localStorage.setItem("user", JSON.stringify(userObject));
      });
  };

  const rolesJSON = localStorage.getItem("roles");
  const roles = JSON.parse(rolesJSON);
  let admin = false;

  if (roles && roles.includes("Admin")) {
    admin = true;
  }

  const getName = () => {
    if(!props || !props.user || !props.user.name) return '';
    const name = props.user.name.split(' ')[0];

    return `${name}'s`
  };

  const isAlly = (profileCollectives) => {
    const loggedInUserCollectives = getAuthenticatedUserCollectives();

    for (let profileCollective of profileCollectives) {
      for (let loggedInUserCollective of loggedInUserCollectives) {
        // check if profile belongs to the same collective as the logged in user
        if (profileCollective.collectiveId === loggedInUserCollective.collectiveId) {
          // check if profile has role ALLY
          if (profileCollective.roles.includes("ALLY")) {
            return true;
          }
        }
      }
    }

    return false;
  };

  const isRecruiter = (profileCollectives) => {
    const loggedInUserCollectives = getAuthenticatedUserCollectives();

    for (let profileCollective of profileCollectives) {
      for (let loggedInUserCollective of loggedInUserCollectives) {
        // check if profile belongs to the same collective as the logged in user
        if (profileCollective.collectiveId === loggedInUserCollective.collectiveId) {
          // check if profile has role EMPLOYER_ADMIN or EMPLOYEE
          if (profileCollective.roles.includes("EMPLOYER_ADMIN") || profileCollective.roles.includes("EMPLOYEE")) {
            return true;
          }
        }
      }
    }

    return false;
  };

  const isERG = (profileCollectives) => {
    const loggedInUserCollectives = getAuthenticatedUserCollectives();

    for (let profileCollective of profileCollectives) {
      for (let loggedInUserCollective of loggedInUserCollectives) {
        // check if profile belongs to the same collective as the logged in user
        if (profileCollective.collectiveId === loggedInUserCollective.collectiveId) {
          // check if profile has role ERG
          if (profileCollective.roles.includes("ERG")) {
            return true;
          }
        }
      }
    }

    return false;
  };

  const canRequestIntro = (profileCollectives) => {
    //return false if viewing your own profile
    if (props.actualUser && props.actualUser.id === getUser().id) {
      return false;
    }

    const loggedInUserCollectives = getAuthenticatedUserCollectives();

    for (let profileCollective of profileCollectives) {
      for (let loggedInUserCollective of loggedInUserCollectives) {
        // check if profile belongs to the same collective as the logged in user
        if (profileCollective.collectiveId === loggedInUserCollective.collectiveId) {
          // check if profile has role TALENT and loggedInUser has role EMPLOYEE or EMPLOYER_ADMIN
          if (profileCollective.roles.includes("TALENT") && 
            (loggedInUserCollective.roles.includes("EMPLOYEE") || loggedInUserCollective.roles.includes("EMPLOYER_ADMIN") || admin)) {
            return true;
          }
        }
      }
    }

    return false;
  };

  const canBookMeeting = (profileCollectives) => {
    //return false if viewing your own profile
    if ((props.actualUser && props.actualUser.id === getUser().id) || !profileCollectives || profileCollectives.length === 0) {
      return false;
    }
    
    const loggedInUserCollectives = getAuthenticatedUserCollectives();

    for (let profileCollective of profileCollectives) {
      for (let loggedInUserCollective of loggedInUserCollectives) {
        // check if profile belongs to the same collective as the logged in user
        if (profileCollective.collectiveId === loggedInUserCollective.collectiveId) {
          // check if profile has joined community connections program and agreed to > 1 meeting per month
          if (props.actualUser && props.actualUser.is_community_connections && props.actualUser.monthly_ally_meeting_count > 1) {
            return true;
          }
        }
      }
    }

    return false;
  };

  const requestIntro = async() => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/introductions`,
      {
        method: "POST",
        headers,
        body: JSON.stringify(
          {
            externalProfileId: props.user.id,
            jobDescriptionUrl: jobDescriptionUrl,
            jobTitle: jobTitle,
          }
        ),
      }
    )
      .then((response) => response.json())
      .then(setIntroStatus("Intro Requested"))
      .then(hideModal());
  };

  const getIntro = async() => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/introductions/external-profile?externalProfileId=${props.user.id}`,
      {
        method: "GET",
        headers,
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((intro) => {
        let status = "";
        if (intro.status === "PENDING") {
          status = "Intro Requested";
        } else if (intro.status === "YES") {
          status = "Intro Made";
        } else if (intro.status === "No") {
          status = "Intro Declined";
        }
        setIntroStatus(status);
      })
      .catch((error) => {
      });
  };

  const bookMeeting = async() => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/ally-meeting`,
      {
        method: "POST",
        headers,
        body: JSON.stringify(
          {
            allyUserId: props.actualUser.id,
            meetingMonth: nextAvailableMeeting.getMonth() + 1,
            meetingYear: nextAvailableMeeting.getFullYear(),
          }),
      }
    )
      .then((response) => response.json())
      .then(setMeetingBooked(true));

    hideModal();
  };

  const getMeeting = async() => {
    if (!props.actualUser) return;

    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/ally-meeting/ally-user?allyUserId=${props.actualUser.id}`,
      {
        method: "GET",
        headers,
      }
    )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((intro) => {
      setMeetingBooked(true);
    })
    .catch((error) => {
    });
  };

  const getNextAvailableMeeting = async() => {
    if (!props.actualUser) return;
    
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/ally-meeting/next-available?allyUserId=${props.actualUser.id}`,
      {
        method: "GET",
        headers,
      }
    )
    .then((response) => {
      if (response.ok) {
        return response.text();
      }
    })
    .then((date) => {
      if (date) {
        setNextAvailableMeeting(new Date(date));
      }
    })
  };

  const hideModal = () => {
    setShowModal(false);
    setModalMeeting(false);
  };

  const formatMeetingDate = (date) => {
    if (!date) {
      return "";
    } else {
      return date.toLocaleString("en-US", {
        month: "long",
        year: "numeric",
      });
    }
  }

  return (
    <div className="profileTop">
      <div style={headerImageConditional()} className="headerImage">
        {props.publicProfile === false && (
          <div className="imageUpload">
            <input onChange={(e) => onFileChange(e.target.files)} type="file" />
            <button className="uploadHeaderPhoto">
              <FontAwesomeIcon icon={faPencilAlt} />
            </button>
          </div>
        )}
      </div>
      <div className="content">
        <div className="imageContainer">
          <img src={props.user.image ? props.user.image + "?w=400" : Captain} alt="profile"/>

          {admin && props.actualUser && props.actualUser.id && (
            <div className="memberSince">
              <span>Member Since</span>
              <p>{moment(props.actualUser.created_at).format("MMMM YYYY")}</p>
            </div>
          )}

          {admin && isAuthenticated &&
            props.publicProfile &&
            (!props.actualUser || !props.actualUser.id) && (
              <div className="memberSince nonMember">
                <span>Not a Member</span>
              </div>
            )}

          {admin && !props.publicProfile && (
            <div className="memberSince yours">
              <span>Member Since</span>
              <p>{moment(props.member.created_at).format("MMMM YYYY")}</p>
            </div>
          )}
        </div>
        {showAllyBadge && (
          <div className="allyBadge">
            ALLY
          </div>
        )}
        {showRecruiterBadge && (
          <div className="allyBadge">
            RECRUITER
          </div>
        )}
        {showERGBadge && (
          <div className="allyBadge">
            ERG
          </div>
        )}
        <div className="requestButton">
          {showRequestIntroButton && (
            <button disabled={introStatus ? true : false} className={`${introStatus ? "disabled" : ""} requestIntroButton`} onClick={() => setShowModal(true)}>{introStatus ? introStatus : 'Request Intro'}</button>
          )}
          {showBookMeetingButton && (
            <button disabled={meetingBooked} className={`${meetingBooked ? "disabled" : ""} bookMeetingButton`} onClick={() => {setShowModal(true); setModalMeeting(true);}}>{meetingBooked ? 'Meeting Scheduled' : 'Book Meeting'}</button>
          )}
        </div>
        <div className="userInfo">
          <h2>
            <HeartyName name={props.user.name} isProven={props.user.provenAt} />
            {props.jobFunctions && props.jobFunctions.length > 0 && (
              <span className="jobFunctions">
                {props.jobFunctions.map((jobFunction) => {
                  return (
                    <span className="jobFunction" key={jobFunction}>{jobFunction}</span>
                  )
                })}
              </span>
            )}
          </h2>

          <h4>{props.user.headline}</h4>

          {props.user.location && (
            <h4>
              <FontAwesomeIcon icon={faLocationDot} /> {capitalizeLocation(props.user.location)}
            </h4>
          )}

          {props.email && admin && (
            <h4><FontAwesomeIcon icon={faEnvelope} />{props.email}</h4>
          )}

          {props.actualUser && props.actualUser.in_collective && admin && (
            <h4><FontAwesomeIcon icon={faUsers} />Collective Member</h4>
          )}

        </div>
      </div>
      {props.user.about && (
        <div className="about">
          <h3>About</h3>
          <p>
            {props.user.about.length >= 250 &&
              truncateString(props.user.about, truncateLength)}
            {props.user.about.length < 250 && props.user.about}
            {props.user.about.length >= 250 && (
              <div className="truncate">
                {truncateLength === 250 && (
                  <span className="read"
                    onClick={(e) => {
                      setTruncateLength(1000000);
                    }}
                  >
                    Read More
                  </span>
                )}

                {truncateLength > 250 && (
                  <span className="read"
                    onClick={(e) => {
                      setTruncateLength(250);
                    }}
                  >
                    Read Less
                  </span>
                )}
              </div>
            )}
          </p>
        </div>
      )}
      {props.user.linkedinUrl && (
        <div className="linkedin">
          <a target="_blank" href={props.user.linkedinUrl} rel="noreferrer"> <FontAwesomeIcon icon={faLinkedin} /> {getName()} LinkedIn Profile</a>
        </div>
      )}
      {showModal && (
        <div className="modal-container">
          <div className="modal">
              <div className="header">
                <div className="bookLabel">{modalMeeting ? (<>Book a Meeting</>) : (<>Request Intro</>)}</div>
                <div className="modalProfile">
                  <div className="imageContainer">
                    <img src={props.user.image ? props.user.image : Captain} alt="user"/>
                  </div>
                  <div className="userInfo">
                    <div className="nameLine">
                      <div className="name">{props.user.name}</div>
                      {showAllyBadge && (
                        <div className="allyBadge">
                          ALLY
                        </div>
                      )}
                      {showRecruiterBadge && (
                        <div className="allyBadge">
                          RECRUITER
                        </div>
                      )}
                      {showERGBadge && (
                        <div className="allyBadge">
                          ERG
                        </div>
                      )}
                    </div>
                    <div className="headline">{props.user.headline}</div>
                    {props.user.location && (
                      <div className="location">
                        <FontAwesomeIcon icon={faLocationDot} /> {capitalizeLocation(props.user.location)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {modalMeeting ? (
              <div className="body">
                <div className="scheduleTimeLabel">Schedule Time</div>
                <p>Once you select a time and click "Schedule", and email introduction will be made so that the two of you can find a mutual time to meet.</p>
                <div className="nextAvailable">
                      Next Available: <span className="date">{formatMeetingDate(nextAvailableMeeting)}</span>
                </div>
                <div className="submit">
                  <button className="bookMeetingButton" onClick={() => bookMeeting()}>Schedule</button>
                </div>
              </div>
              ) : (
              <div className="body">
                <div className="scheduleTimeLabel">Role Description <label className="optional">(Optional)</label></div>
                <p>Including a role and job descripion will improve your response rate. If you have a specific role in mind, enter the details below.</p>
                <div className="jobTitle">
                  <input
                    type="text"
                    placeholder="Job Title"
                    onChange={(e) => {setJobTitle(e.target.value)}}
                  />
                </div>
                <div className="jobDescriptionUrl">
                  <input
                    type="text"
                    placeholder="Job Description URL"
                    onChange={(e) => {setJobDescriptionUrl(e.target.value)}}  
                  />
                </div>
                <div className="submit">
                  <button className="bookMeetingButton" onClick={() => requestIntro()}>Submit</button>
                </div>
              </div>
              )}
          </div>
          <div className="skrim"
            onClick={() => {hideModal()}}>
          </div>
        </div>
      )}
    </div>
  );
}
