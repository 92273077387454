import React from 'react';
import './../App.scss';
import {
  Redirect,
} from "react-router-dom";
import moment from 'moment';
import '../CSS/MessagingSidebar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Captain from "../images/captain-emoji.png";


class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      conversations: [],
      activeMessageId: 0,
      query: props.query,
      auth0: props.auth0,
      isAdmin: props.isAdmin
    };
    this.listRef = React.createRef();
    this.fetchMessages = this.fetchMessages.bind(this)
    this.isUnread = this.isUnread.bind(this)
    this.newConversation = this.newConversation.bind(this)
  }


  componentDidUpdate(prev) {
    if(prev.updateConversations !== this.props.updateConversations) {
      if(this.props.updateConversations === true) {
        this.fetchMessages(false)
      }
    }
  }

  componentDidMount() {
    this.fetchMessages();
  }

  async fetchMessages(setActive = true) {
    const token = await this.state.auth0.getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access"
    });

    let url = `${process.env.REACT_APP_URL_JAVA}/conversation/list?size=100`;
    if (this.state.isAdmin) {
      url = `${process.env.REACT_APP_URL_JAVA}/conversation/admin/?size=100`;
    }

    fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(async (data)=>{

      if(!data._embedded) {
        return this.setState({
          conversations: []
        })
      }
      this.setState({
        conversations: data._embedded.conversation
      })

      if(setActive) {
        if(this.state.query) {
          const found = data._embedded.conversation.filter((conversation)=>{
            if(conversation.conversationId == this.state.query) {
              return true
            }
            return false;
          })

          if(found[0]) {
            this.setState({
              activeMessageId: found[0].conversationId
            })
            return this.props.changeMessage(found[0])
          }
          return;
        }
        if(data._embedded.conversation.length > 0) {
          this.setState({
            activeMessageId: data._embedded.conversation[0].conversationId
          })
          this.props.changeMessage(data._embedded.conversation[0])
        }
      }
    })
  }

  sendItem(conversation) {
    this.setState({
      activeMessageId: conversation.conversationId
    })
    this.props.changeMessage(conversation)
  }

  renderImage(message) {
    const me = JSON.parse(localStorage.getItem('user'));
    const userList = message.userProfiles.split('<>');

    const userListObject = userList.map((user,idx)=>{
      const split = user.split('||')
      const id = split[0];
      if(Number(id) === Number(me.id)) return null;
      const name = split[1];
      const image = split[2];
      
      return {
        id,
        name,
        image
      }
    })
    .filter((item)=>{
      return (item !== null && item !== false);
    })
    .map((item)=>{
      return <img onError={(e)=>{e.target.onerror = null; e.target.src=Captain}} src={item.image} key={item.id}/>
    })
    .filter((item, idx)=>{
      if(idx > 1) return false;

      return true;
    })
    

    return <div className={`images ${userListObject.length > 1 && 'multiple'}`}>{userListObject}</div>
  }

  renderLastMessage(message) {

    function removeTags(str) {
      if ((str===null) || (str===''))
      return false;
      else
      str = str.toString();
      return str.replace( /(<([^>]+)>)/ig, '');
    }
    let newMessage = removeTags(message);

    if (newMessage.length > 100) return newMessage.substring(0, 100) + '...';

    return newMessage;
  }

  renderName(message) {

    const me = JSON.parse(localStorage.getItem('user'));

    const userList = message.userProfiles.split('<>');

    const userListObject = userList.map((user)=>{
      const split = user.split('||')
      const id = split[0];
      if(Number(id) === Number(me.id)) return null;
      const name = split[1];
      const image = split[2];
      
      return {
        id,
        name,
        image
      }
    })
    .filter((item)=>{
      return item !== null;
    })
    .reduce((curr, item)=>{
      let current = curr;

      current = current + item.name + ', '
      return current;
    }, '').slice(0,-2);

    if (userListObject.length > 30) {
      return userListObject.substring(0, 30) + '...';
   }

    return userListObject;
  }

  isUnread(message) {
    const me = JSON.parse(localStorage.getItem('user'));
    console.log(message, me);
    if(message.toUser.id === me.id && message.isRead === false) return <span className='unread'></span>

  }

  newConversation() {
    this.props.newConversation();
  }


  render() {
    if (this.state.redirect) return <Redirect to={this.state.redirect} />

    return (
      <div className="sidebar messagingSidebar">
          <h2>
            Messages
            {!this.state.isAdmin && (
              <span onClick={this.newConversation}>
                <FontAwesomeIcon icon={faEnvelope} />  
                New Message
              </span>
            )}
          </h2>
          

          <div className="messageList">
              <ul>
                {this.state.conversations.length === 0 &&
                  <li className='nullState'>You currently have no conversations. How about <span onClick={(e)=>{
                    this.newConversation();
                  }}
                  >reaching out to somebody?</span>
                  </li>
                }
                {this.state.conversations.map((conversation)=>{
                  return <li className={(this.state.activeMessageId === conversation.conversationId || this.state.activeMessageId === conversation.id) ? 'active' : ''} onClick={() => { this.sendItem(conversation) }} key={conversation.conversationId}>
                  <div className={`content ${conversation.unread ? 'unread' : 'read'}`}>
                        {this.renderImage(conversation)}
                      <div className="info">
                        <h4>{this.renderName(conversation)}</h4>
                        <p>{this.renderLastMessage(conversation.lastMessage)}</p>
                        <p className="time">{moment(conversation.lastMessageDate).fromNow()}</p>
                      </div>
                  </div>
                  </li>
                })}
              </ul>
          </div>
      </div>
    );
  }
}

export default Sidebar;
