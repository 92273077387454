import React, { useState, useEffect } from "react";
import "../CSS/Trophies/single.scss";
import Nft from "./components/Nft";
import NftSingle from "./components/NftSingle";

import { Helmet } from "react-helmet";
import Header from "../images/trophies-header.png";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";

const Single = (props) => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } =
    useAuth0();
  let { code } = useParams();
  const [customHeader, setCustomHeader] = useState(false);
  const [nft, setNft] = useState({});
  let { id } = useParams();
  const [me, setMe] = useState(JSON.parse(localStorage.getItem("user")));

  useEffect(() => {
    getTrophy();
  }, [isLoading]);

  const getTrophy = async () => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      console.log(error);
    }
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }

    let response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophies/${id}`,
      {
        method: "GET",
        headers,
      }
    );

    let data = await response.json();

    setNft(data);
  };

  if (!nft.animatedThumbnailUrl) {
    return <div>Loading...</div>;
  }

  return (
    <div className="trophies app">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{nft.trophyName}</title>
        <meta name="description" content={nft.trophyDescription} />
        <meta property="og:title" content={nft.trophyName} />
        <meta property="og:description" content={nft.trophyDescription} />
        <meta property="og:image" content={nft.animatedThumbnailUrl} />
        <meta property="og:url" content={window.location} />

        <meta
          property="twitter:title"
          content={`${nft.trophyName} - Hearty NFT`}
        />
        <meta property="twitter:description" content={nft.trophyDescription} />
        <meta property="twitter:image" content={nft.thumbnailUrl} />
        <meta name="twitter:card" content="summary_large_image"></meta>
      </Helmet>

      <section className="left dashboard">

        <div
          style={{
            backgroundImage: `url(${Header})`,
          }}
          className="listHeader"
        >
          <h2>Hearty NFTs</h2>

          <h4>NFTs issued for verified awards and accomplishments.</h4>
        </div>

        <div className="list">
          <div className="yourNfts">
            {nft?.url && <Nft nft={nft} />}
            {!nft.url && (
              <NftSingle collectionView={true} key={nft.trophyId} nft={nft} />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Single;
