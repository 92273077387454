import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams, useHistory } from "react-router-dom";
import "../../CSS/Trophies/editcollection.scss";


const CreateCollection = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState({});
  const [properties, setProperties] = useState([]);
  const [newProp, setNewProp] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [collection,setCollection] = useState({});
  const { id } = useParams()
  const history = useHistory();
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const rolesJSON = localStorage.getItem("roles");
    const roles = JSON.parse(rolesJSON);

    if (!roles || !roles.includes("Admin")) {
      return history.push({
        pathname: `/feed`,
      });
    }
    getCollection(id);
  }, []);

  const getCollection = async (id) => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const collectionRequest = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophy-collections/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      }
    );
    const collection = await collectionRequest.json();
    setName(collection.name);
    setDescription(collection.description);
    setCollection(collection);
  };

  const onFileChange = async (file) => {
    const selectedImage = file[0];

    uploadImage(selectedImage);
  };

  const saveCollection = async (e) => {
    if (e) e.preventDefault();
    setWaiting(true);

    if (!name || !description || !image) {
      return alert("Please fill out all fields");
    }

    const body = {...collection}
    body.name = name;
    body.description = description;

    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const collectionRequest = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophy-collections/${collection.id}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    const collectionReceived = await collectionRequest.json();

    if (collectionReceived.id) {
      setWaiting(false);
      setSuccess(true);

      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    }
  };


  const uploadImage = async (image) => {
    let selectedImage = image;

    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", selectedImage, selectedImage.name);

    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophy-collections/${id}/header`,
      {
        method: "POST",
        enctype: "multipart/form-data",
        contentType: false,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );

    const collectionReceived = await response.json();

    if (response.ok) {
      const newCollection = {...collection};
      newCollection.imageUrl = collectionReceived.imageUrl;
      setCollection(newCollection);
    }

    
  };

  return (
      <div className="trophies admin">
    <div className="container">
    {success && <div className="alert alert-success">Saved!</div>}
      <form
        onSubmit={(e) => {
            saveCollection(e);
        }}
      >
        <div className="form-group">
          <label htmlFor="collection_name">Collection Name</label>
          <input
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            type="text"
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="collection_name">Collection Description</label>
          <textarea
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="collection_name">Collection Header Image</label>
          <input
            onChange={(e) => onFileChange(e.target.files)}
            type="file"
            accept="image/*"
          />
        </div>

        <div>
        {collection.imageUrl && (
              <img
                style={{
                    maxWidth:'150px',
                    marginLeft:'20px'
                }}
                src={collection.imageUrl} />
          )}
        </div>

        <button disabled={waiting} type="submit">
          Save Collection
        </button>
      </form>
    </div>
    </div>
  );
};

export default CreateCollection;
