import { faPencilAlt,faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    useParams
  } from "react-router-dom";
import React, {useState, useEffect} from "react";
import '../CSS/Leaderboards/create.scss';
import { useAuth0 } from "@auth0/auth0-react";


export default function LeaderboardSingle(props) {

  const [name, setName] = useState('');
  const [leaderboard, setLeaderboard] = useState({});
  const [description, setDescription] = useState('');
  const [error, setError] = useState({
      error: false,
      message: ''
  });
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();


  let params = useParams();

  const [backgroundImageStyle, setBackgroundImageStyle] = useState({
      backgroundImage: `url()`,
      url: ''
  })

  const onFileChange = async (file) => {
    const selectedImage = file[0]
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", selectedImage, selectedImage.name);

    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access"
    });

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/files/uploadFile?directory=leaderboardHeader`,
      {
        method: "POST",
        enctype: "multipart/form-data",
        contentType: false,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    )
      .then((response) => {
        console.log(response);
        if (response.ok) {
          return response.text();
        }

        throw new Error("Error uploading image");
      })
      .then((data) => {
        setBackgroundImageStyle({
            backgroundImage: `url(${data})`,
            url: data
        })
      })
      .catch((error) => {});
  };


  const saveLeaderboard = async()=>{
    if(name === '' || description === '') {
        setError({
            error: true,
            message: "Please enter a name and a description."
        })

        return;
    }

    setError({
        error: false,
        message: ""
    })

    const user = JSON.parse(localStorage.getItem('user'))
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access"
    });
    const data = {
        id: leaderboard.id,
        userId: user.id,
        title: name,
        description,
        image: backgroundImageStyle.url,
        customUrl: leaderboard.customUrl,
        pinned: leaderboard.pinned,
    }

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/leaderboard`,
      {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error(response);
      })
      .then((data) => {
        props.history.push({
            pathname: `/leaderboards/${data.customUrl}`,
            state: { new: 'Succesfully edited leaderboard' }
        })
      })
      .catch((error) => {
          console.log(data);
      });
  }

  const getLeaderboard = async ()=>{
    let token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access"
    });
    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/leaderboard/custom-url?url=${params.id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const leaderboard = await response.json();
    setLeaderboard(leaderboard);
    setName(leaderboard.title);
    setDescription(leaderboard.description)
    setBackgroundImageStyle({
        backgroundImage: `url(${leaderboard.image})`,
        url: leaderboard.image
    })
    return leaderboard;
  }



  useEffect(() => {
    async function fetchData() {
        const leaderboard = await getLeaderboard();
      }
    fetchData();
  }, [
  ])



  return (
    <div className="LeaderboardCreate app">
        <section className="left">
            <section style={backgroundImageStyle} className="titleCard">
                {error.error &&
                    <p className="error alert">{error.message}</p>
                }
                <form onSubmit={(e)=>{
                    e.preventDefault();
                    saveLeaderboard();
                }}>
                <input maxLength={80} onChange={(e)=>{
                    setName(e.target.value)
                }} type="text" className='name' value={name} placeholder="Name your leaderboard"></input>
                <textarea maxLength={100} onChange={(e)=>{
                    setDescription(e.target.value)
                }} value={description} placeholder="Add a description" />

                <div className="imageUpload">
                    <input onChange={e=> onFileChange(e.target.files)} type="file" accept="image/*" />
                    <button className="uploadHeaderPhoto"><FontAwesomeIcon icon={faPencilAlt} /></button>
                </div>
                <div className="break"></div>
                <button type="submit" className="save btn large">Create</button>
                </form>
            </section>
        </section>
        <section className="right">
            <h3>You may know</h3>
            <div className="content">

            </div>
        </section>
    </div>
  );
}
