import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import DefaultImage from "../images/captain-emoji.png";
import "../CSS/MeetingSuggestion.scss";

export default function MeetingSuggestion(props) {

    return (
        <div key={props.id} className="meeting-suggestion-card">
            <div className="meeting-suggestion-card-top">
                <div className="meeting-suggestion-card-header">
                    <div className="header-image">
                        {props.headerImage && (
                            <img
                                src={props.headerImage + "?w=400"}
                                alt="header"
                            />
                        )}
                    </div>
                    <div className="profile-image">
                        <Link to={`/user/${props.customUrl}`} target="_blank">
                            <img
                                src={props.image + "?w=100"}
                                alt={props.name}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = DefaultImage;
                                }}
                            />
                        </Link>
                    </div>
                </div>
                <div className="meeting-suggestion-card-body">
                    <div className="name">
                        <Link to={`/user/${props.customUrl}`} target="_blank">{props.name}</Link>
                        {props.badges && props.badges.length > 0 && props.badges.map((badge) => {
                            return (
                                <span key={badge} className="allyBadge">{badge}</span>
                            )
                        })}
                    </div>
                    <div className="headline">{props.headline}</div>
                    {props.location && (
                        <div className="location"><FontAwesomeIcon icon={faLocationDot} /> {props.location}</div>
                    )}
                    <div className="expertise">
                        <label>Area of Expertise:</label>
                        <div>{props.areaOfExpertise}</div>
                    </div>
                </div>
            </div>
            <div className="meeting-suggestion-footer">
                <button
                    onClick={() => {
                        props.bookMeeting(
                            props.name,
                            props.image,
                            props.badges,
                            props.headline,
                            props.location,
                            props.date,
                            props.userId
                        );
                    }}
                >
                    Schedule
                </button>
            </div>
        </div>
    )
}