import React from "react";
import "../App.scss";
import "../CSS/Admin.scss";
import { Redirect, Link } from "react-router-dom";

import { withAuthenticationRequired } from "@auth0/auth0-react";

class Admin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activity: [],
      jobs: [],
      activityPages: 0,
      loadingActivity: true,
      view: "polls",
      searching: false,
      showAddCompany: false,
      options: [],
      error: false,
      token: false,
      provenMembers: [],
      ambassador: { id: null },
    };
    this.listRef = React.createRef();
  }

  async onFileChange(file) {
    this.onFileUpload(file[0]);
  }

  async onFileUpload(selectedImage) {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", selectedImage, selectedImage.name);

    let token = await this.props.auth0.getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/files/uploadFile?directory=company`,
      {
        method: "POST",
        enctype: "multipart/form-data",
        contentType: false,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.text();
        }

        throw new Error("Error uploading image");
      })
      .then((data) => {
        this.setState({
          companyImage: data,
          error: false,
        });
      })
      .catch((error) => {
        this.setState({
          error: "Error uploading image",
        });
      });
  }

  render() {
    if (this.state.redirect) return <Redirect to={this.state.redirect} />;

    return (
      <div className="leaderboards app admin">
        <section>
          <div className="adminSidebar">
            <ul>

              <li className={`${this.state.view === "trophies" ? "active" : ""}`}>
                <Link to="/admin/trophies">Trophies</Link>
              </li>
              <li>
                <Link to="/collective/user/new">New Candidate</Link>
              </li>
            </ul>
          </div>
        </section>
        <section className="right">

        </section>
      </div>
    );
  }
}

export default withAuthenticationRequired(Admin, {
  onRedirecting: () => <p>Loading...</p>,
});
