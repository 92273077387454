import React, { useState, useEffect } from "react";
import "../../CSS/Trophies/components/ClaimModal.scss";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
const ClaimModal = (props) => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  let { code } = useParams();
  const [showClaimModal, setShowClaimModal] = useState(false);
  const [nft, setNft] = useState({});
  const [userProperties, setUserProperties] = useState([]);
  const [userDocs, setUserDocs] = useState([]);
  const [comment, setComment] = useState(false);
  const [selector, setSelector] = useState('start');
  const [userTrophy, setUserTrophy] = useState({});
  const [whiteLabel, setWhiteLabel] = useState(false);
  const [whiteLabelName, setWhiteLabelName] = useState('');
  const [whiteLabelRedirectUrl, setWhiteLabelRedirectUrl] = useState('');
  const [etherWallet, setEtherWallet] = useState(false);
  const [claimed, setClaimed] = useState(false);
  const [match, setMatch] = useState(false);
  const [claiming, setClaiming] = useState(false);

  useEffect(() => {
    getNFT(code);
  }, []);

  const sendClaim = async () => {
    setClaiming(true);
    const token = await getAccessTokenSilently();
    const body = {
      walletAddress: etherWallet,
    };
    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophies/${nft.trophyId}/request-nft`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const owners = await response.json();
    setClaiming(false);
    window.location.href = whiteLabelRedirectUrl;
  };

  const patchUserTrophy = async () => {
    const token = await getAccessTokenSilently();

    const metadata = {};

    if (userProperties) {
      Object.keys(userProperties).map((key, index) => {
        return (metadata[userProperties[key].id] = userProperties[key].value);
      });
    }

    const body = {
      comment,
      metadata,
    };

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-trophies/${userTrophy.userTrophyId}/properties`,
      {
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "PATCH",
      }
    );

    if (userDocs.length > 0) {
      const docs = userDocs.map((document) => {
        return uploadSupportingDocument(userTrophy.userTrophyId, document, "ATTACHMENT");
      });
    }
  };

  const uploadSupportingDocument = async (id, file, type) => {
    let selectedImage = file;
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("type", type);
    formData.append("file", selectedImage, selectedImage.name);

    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-trophies/${id}/upload-attachment`,
      {
        method: "POST",
        enctype: "multipart/form-data",
        contentType: false,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );

    if (response.ok) {
      return true;
    } else {
      throw new Error("Error uploading image");
    }
  };

  const getNFT = async (code) => {
    let token = await getAccessTokenSilently();

    let response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophies/invite/${code}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    let data = await response.json();

    let name = data.trophyCollectionName.toLowerCase();
    
    if(name.includes("ribbit")) {
      setWhiteLabel(true);
      setWhiteLabelName('Ribbit');
      setWhiteLabelRedirectUrl('https://www.ribbit.ai');
    } else if (name.includes("century 21")) {
      setWhiteLabel(true);
      setWhiteLabelName('Century 21');
      setWhiteLabelRedirectUrl('/trophies');
    }
    setNft(data);
    getTrophyProperties(data)
  };

  const getTrophyProperties = async (nft) => {
    console.log(nft);
    const token = await getAccessTokenSilently();
    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophy-collections/${nft.trophyCollectionId}/filters?type=USER_TROPHY`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const properties = await response.json();
    const propertiesObject = {};

    if(properties.length > 0){
      properties.forEach((property) => {
        propertiesObject[property.name] = {
          id: property.id,
          value: "",
          options: property.options,
        };
      });
    }

    setUserProperties(propertiesObject);
  };

  const claim = async () => {
    let token = await getAccessTokenSilently();

    let response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophies/claim/${code}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    let data = await response.json();
    setUserTrophy(data);

    const name = nft.trophyCollectionName.toLowerCase();
    
    if(name.includes("ribbit")) {
      return setSelector("white-label");
    } else if (name.includes("century 21")) {
      return setSelector("white-label");
    }

    // if(!nft.single) {
    //   setSelector("patch");
    // }

    props.close();
  };

  const me = JSON.parse(localStorage.getItem("user"));

  return (
    <div className="claimModal">
      <div className="modal">
        <div className="content">
          {selector === "patch" && (
            <div className="UserInformation">
              <h3>Customize Your Trophy</h3>

              <FontAwesomeIcon
                onClick={() => {
                  props.close();
                }}
                icon={faTimes}
              />

              {userProperties && Object.keys(userProperties).length > 0 && (
                <div className="additionalBox">
                  <span>
                    Provide the following information so we can make your trophy
                    personal to you.
                  </span>
                  {Object.keys(userProperties).map((keyName, i) => {
                    if (
                      !userProperties[keyName].options ||
                      userProperties[keyName].options.length === 0
                    ) {
                      return (
                        <div
                          style={{
                            marginRight: "30px",
                          }}
                          key={i}
                        >
                          <span
                            style={{
                              marginRight: "10px",
                            }}
                            className="input-label"
                          >
                            {keyName}
                          </span>
                          <textarea
                            type="text"
                            onChange={(e) => {
                              const newUserProperties = {
                                ...userProperties,
                              };
                              newUserProperties[keyName].value = e.target.value;
                              setUserProperties(newUserProperties);
                            }}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div
                          style={{
                            marginRight: "30px",
                          }}
                          key={i}
                        >
                          <span
                            style={{
                              marginRight: "10px",
                            }}
                            className="input-label"
                          >
                            {keyName}
                          </span>
                          <select
                            onChange={(e) => {
                              const newUserProperties = {
                                ...userProperties,
                              };
                              newUserProperties[keyName].value = e.target.value;
                              setUserProperties(newUserProperties);
                            }}
                          >
                            {userProperties[keyName].options.map(
                              (option, i) => {
                                return (
                                  <option key={i} value={option}>
                                    {option}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        </div>
                      );
                    }
                  })}
                </div>
              )}

              <div className="additionalDocs">
                <label>Attachments</label>
                <span>
                  Include any files relevant to your trophy you want to share
                  with the community.
                </span>
                <input
                  onChange={(e) => {
                    const newUserDocs = [...userDocs, e.target.files[0]];
                    setUserDocs(newUserDocs);
                  }}
                  type="file"
                />

                {userDocs.map((file, index) => {
                  return (
                    <div className="supporting" key={index}>
                      <span>{file.name}</span>
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => {
                          const newUserDocs = [...userDocs];
                          newUserDocs.splice(index, 1);
                          setUserDocs(newUserDocs);
                        }}
                      />
                    </div>
                  );
                })}

              <div className="buttons">
                <button
                  onClick={() => {
                    patchUserTrophy();
                  }}
                  className="btn large"
                >
                  Customize
                </button>
              </div>

              </div>
            </div>
          )}

          {selector === 'start' && (
            <div className="right">
              <FontAwesomeIcon
                onClick={() => {
                  props.close();
                }}
                icon={faTimes}
              />
              <h3>Congratulations, {me?.external_profile?.name}</h3>

              {whiteLabel && (
              <p>
                You've been awarded a {whiteLabelName} NFT. Click "Claim" below to claim your {whiteLabelName} NFT.
              </p>
              )}

              {!whiteLabel && (
              <p>
                You've been awarded a Hearty NFT. Add to your profile to
                display to the Hearty community or transfer to your personal
                wallet, it's yours forever.
              </p>
              )}

              <img src={nft.animatedThumbnailUrl} alt="trophy" />

              <div className="buttons">
                <button
                  onClick={() => {
                    claim(code);
                  }}
                  className="btn large"
                >
                  Claim
                </button>
              </div>
            </div>
          )}

          {selector === "white-label" && (
            <div className="right shareRight ribbit">
               <FontAwesomeIcon
                onClick={() => {
                  props.close();
                }}
                icon={faTimes}
              />
              <h3>
                Hurray! Your {whiteLabelName} NFT trophy has been added to your profile. Time to
                share the love!
              </h3>
              <div className="left">
                <img src={nft.animatedThumbnailUrl} alt="trophy" />
              </div>
              <div className="right">

                <div className="share">
                <div className="formBox">
                          <p>
                            Enter the address of the wallet you want to transfer
                            the trophy to. If you don't have one, but are interested in getting one, here's a <a href="https://codehs.com/tutorial/jkeesh/how-to-set-up-an-ethereum-wallet-on-metamask">helpful article</a>. 
                          </p>
                          <input
                            onChange={(e) => {
                              setEtherWallet(e.target.value);
                              const regex = /^0x[a-fA-F0-9]{40}$/;
                              const match = e.target.value.match(regex);
                              const ens = /.*\.eth/;
                              const matchENS = e.target.value.match(ens);

                              if (match || matchENS) {
                                setMatch(true);
                              } else {
                                setMatch(false);
                              }
                            }}
                            type="text"
                            placeholder="Enter wallet address"
                          />
                          {!match && etherWallet.length > 0 && (
                            <p class="alert">
                              Invalid Ether Address. Please enter a valid
                              address.
                            </p>
                          )}
                          {!match ? (
                            <button disabled className="btn large">
                              Transfer
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                sendClaim();
                              }}
                              className="btn large"
                            >
                              Transfer
                            </button>
                          )}

                          {claiming && (
                               <p style={{
                                 marginTop:'20px'
                               }} class="alert success">
                              Transferring...
                           </p>
                          )}

                          {claimed && (
                            <p class="alert success">
                              Success! Please give it up to an hour for it to show in your wallet.
                            </p>
                          )}
                        </div>

                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClaimModal;
