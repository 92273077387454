import React, {useState, useEffect} from "react";
import '../CSS/Leaderboards/list.scss';
import LeaderboardCard from './components/LeaderboardCard';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import InfiniteScroll from 'react-infinite-scroll-component';
import Header from "../images/leaderboardHeader.png";
import socialBG from "../images/socialBG.png";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import useQueryParam from '../useQueryParam.ts';
import TagSelector from './components/TagSelector';


const Leaderboards = (props)=> {
  const [leaderboards, setleaderboards] = useState([]);
  const [page, setPage] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [totalPages, setTotalPages] = useState(0)
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [filter, setFilter] = useQueryParam('filter', null);
  const [sort, setSort] = useQueryParam('sort', 'RECOMMENDED');
  const [viewSortBox, setViewSortBox] = useState(false);
  const [viewFilterBox, setViewFilterBox] = useState(false);
  const [searchText, setSearchText] = useQueryParam('search', '');
  const [loading,setLoading] = useState(false);
  const [tagId, setTagId] = useQueryParam('tagId', false);
  const [filteringTag, setFilteringTag] = useState(false);


  useEffect(() => {
    getLists();

    if(tagId){
      getTag(tagId);
    }
    
  }, [
  ])

  const getTag = async(tagId)=>{
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access"
    });

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/tag/${tagId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )

    const tag = await response.json();

    setFilteringTag(tag);
  }

  const fetchMoreData =async ()=>{
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access"
    });

    // If not logged in take them to registration page.
    if(!token) return window.location.replace("/register/individual");

    if(hasMore === false) return;

    let filterText = '';
    if(filter) filterText = `&filter=${filter}`;

    let tagText = '';
    if(tagId) tagText = `&tagId=${tagId}`;

    let url = `${process.env.REACT_APP_URL_JAVA}/leaderboard/list?page=${page}&size=50&sort=${sort}${filterText}${tagText}&search=${searchText}`;

    if(filter === 'UNTAGGED') url = `${process.env.REACT_APP_URL_JAVA}/leaderboard/no-tag?page=${page}&size=50`

    fetch(
      url,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error(response);
      })
      .then((data) => {
        const newData = [...leaderboards, ...data._embedded.leaderboardList]
        setleaderboards(newData)
 
        // Is this the last call we need?
        if(data.page.totalPages == data.page.number) {
          return setHasMore(false);
        }

        setPage(page + 1)
      })
      .catch((error) => {
          console.log();
      });
  }

  const getLists = async (sorting, filtering, removeFilter = false, clearSearch = false, tagging = true)=>{
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access"
    });

    // Reset has more.
    setHasMore(true);
    setLoading(true);

    // Reset sorting
    let newSort = sort;
    if(sorting) newSort = sorting;


    // Reset Filter
    let newFilter = filter;
    if(filtering) newFilter = filtering;
    let filterText = '';
    if(newFilter) filterText = `&filter=${newFilter}`


    // Reset Tagging
    let newTagId = tagId;
    if(tagging !== true) newTagId = tagging;
    let tagText = '';
    if(newTagId) tagText = `&tagId=${newTagId}`;

    // If we are removing the filter. Async update doesnt work right.
    if(removeFilter) {
      newFilter = false;
      filterText = '';
    }

    // Reset Search
    let newSearch = searchText;
    if(clearSearch==true) {
      newSearch = '';
    } else if(clearSearch !== false) {
      newSearch = clearSearch
    }

    // If not logged in take them to registration page.
    if(!token) return window.location.replace("/register/individual");

    let url = `${process.env.REACT_APP_URL_JAVA}/leaderboard/list?page=0&size=50&sort=${newSort}${filterText}&search=${newSearch}${tagText}`

    // Admin feature to see untagged leaderboards
    if(newFilter === 'UNTAGGED') url = `${process.env.REACT_APP_URL_JAVA}/leaderboard/no-tag?page=0&size=50`
    
    await fetch(
      url,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error(response);
      })
      .then(async (data) => {


        const socialCapital = {
          type:'sponsored',
          title: "Social Capital Leaders",
          description: "Hearty members who have contributed the most to their peers and our community through in-app actions",
          backgroundImage: socialBG
        }

        let leaderBoardList = [];

        if(data && data._embedded) leaderBoardList = data._embedded.leaderboardList;

        // Admin feature to see untagged leaderboards
        if(newFilter === 'UNTAGGED') {
          leaderBoardList = data;
          setHasMore(false);
        }

        // If its a hearty filter we only show the sponsored one.
        if(newFilter === 'HEARTY') leaderBoardList = [];

        if(newSearch === '' && newTagId === false) {
          leaderBoardList.splice( 4, 0, socialCapital );
        }

        setLoading(false);

        // Is this the last call we need?
        if(leaderBoardList.length === 0) {
          return setHasMore(false);
        }
        
        setleaderboards(leaderBoardList)
        // Is this the only call?
        if(data.page.totalPages == 1) {
          setTotalPages(data.page.totalPages);
          return setHasMore(false);
        }
        setPage(1)
      })
      .catch((error) => {
          console.log();
          setLoading(false);
      });
  }
  



  const setSortFunction = async (sort)=>{
    await setViewSortBox(false);
    await setSort(sort);
    getLists(sort);
  }

  const receiveTag = async (tag)=>{
    await setFilteringTag(tag);
    await setSearchText('')
    await setTagId(String(tag.id))
    getLists(false,false,false,true,tag.id);
  }

  const receiveSearch = async (search)=>{
    await setSearchText(search)
    await setFilteringTag(false);
    await setTagId(false)
    setTimeout(() => {
      getLists(false,false,false,search,false);
    });
  }

  const setFilterFunction = async (filter)=>{

    await setViewFilterBox(false);
    
    await setFilter(filter);
    if(!filter) return getLists(false,false,true);

    getLists(false, filter, false);
  }

  const sortToText = () => {
    switch (sort) {
      case "RECOMMENDED":
        return "Recommended for you";
      case "TOP":
        return "Top";
      case "NEWEST":
        return "Newest";
      case "TRENDING":
        return "Trending";
      default:
        return 'Recommmended'
    }
  };

  const filterToText = () => {
    switch (filter) {
      case "FEATURED":
        return "Boards where you’re featured";
      case "FOLLOW":
        return "Followed boards";
      case "FRIENDS":
        return "Boards with people you know";
      case "UNTAGGED":
        return "Untagged Leaderboards";
    }
  };

  const tagToText = () => {
    if(filteringTag) return `Topic: ${filteringTag.name}`
  };

  const rolesJSON = localStorage.getItem('roles');
  const roles = JSON.parse(rolesJSON);
  let admin = false;

  if(roles && roles.includes('Admin')) {
    admin = true;
  }

  
  return (
    <div className="leaderboardsList app">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hearty Leaderboards</title>
        <meta
          name="description"
          content="Hearty list of leaderboards showcasing the best people for what they do."
        />
      </Helmet>
      <section className="left">
        <div class="actionText">

          <div className="tagText">
            <span>{tagToText()}</span>
            {tagToText() &&
            <FontAwesomeIcon onClick={async (e) => {
              setTagId(false);
              setFilteringTag(false)
              getLists(false,false,false,false,false)
            }} icon={faTimes} />
            }
          </div>
        </div>
        <div
          className="listHeader"
        >
          <div className="actions">
            <div className="sortBox">
              <button
                onClick={(e) => {
                  setViewSortBox(!viewSortBox);
                }}
              >
                Sort by <span>{sortToText()}</span>
              </button>

              {viewSortBox && (
                <div className="sortBoxDropdown">
                  <ul>
                    <li
                      onClick={async (e) => {
                        setSortFunction("RECOMMENDED");
                      }}
                    >
                      Recommended for you
                    </li>
                    <li
                      onClick={async (e) => {
                        setSortFunction("NEWEST");
                      }}
                    >
                      Newest
                    </li>
                    <li
                      onClick={async (e) => {
                        setSortFunction("TOP");
                      }}
                    >
                      Top
                    </li>
                    <li
                      onClick={async (e) => {
                        setSortFunction("TRENDING");
                      }}
                    >
                      Trending
                    </li>
                  </ul>
                </div>
              )}

              {viewSortBox && (
                <div
                  onClick={(e) => {
                    setViewSortBox(!viewSortBox);
                  }}
                  className="skrim"
                ></div>
              )}
            </div>

            <div className="filterBox">
              <button
                onClick={(e) => {
                  setViewFilterBox(!viewFilterBox);
                }}
              >
                Filter{" "}
                {filterToText() &&
                  <span className="filterText">
                    <span>{filterToText()}</span>
                    {filterToText() &&
                    <FontAwesomeIcon onClick={async (e) => {
                      await setViewFilterBox(false);
                      setFilterFunction(false);
                    }} icon={faTimes} />
                    }
                </span>
              }
              </button>

              {viewFilterBox && (
                <div className="sortBoxDropdown">
                  <ul>
                    <li
                      onClick={async (e) => {
                        setFilterFunction("FEATURED");
                      }}
                    >
                      Boards where you’re featured
                    </li>
                    <li
                      onClick={async (e) => {
                        setFilterFunction("FOLLOW");
                      }}
                    >
                      Followed boards
                    </li>
                    <li
                      onClick={async (e) => {
                        setFilterFunction("FRIENDS");
                      }}
                    >
                      Board with people you know
                    </li>
                    {}
                    {admin &&
                      <li
                        onClick={async (e) => {
                          setFilterFunction("UNTAGGED");
                        }}
                      >
                        Untagged
                      </li>
                    }
                  </ul>
                </div>
              )}

              {viewFilterBox && (
                <div
                  onClick={(e) => {
                    setViewFilterBox(!viewFilterBox);
                  }}
                  className="skrim"
                ></div>
              )}
            </div>

            <div className="tagBox">
              <TagSelector 
              searchText={searchText}
              receiveSearch={receiveSearch} 
              receiveTag={receiveTag}/>
            </div>


            <a href="/leaderboards/create">+ New Board</a>

          </div>
        </div>
        <div className="content">
          <InfiniteScroll
            dataLength={leaderboards.length}
            next={(e) => {
              return fetchMoreData();
            }}
            style={{ display: "flex", width: "100%", flexWrap: "wrap" }} //To put endMessage and loader to the top.
            inverse={false}
            hasMore={hasMore}
            loader={<h4>Loading Leaderboards...</h4>}
          >
            {leaderboards.map((leaderboard) => {
              return <LeaderboardCard leaderboard={leaderboard} />;
            })}
          </InfiniteScroll>
          {(!loading && leaderboards.length === 0) &&
            <h4 className="null">No leaderboards found. Try adjusting your filtering.</h4>
          }
        </div>
      </section>
    </div>
  );
}

export default withAuthenticationRequired(Leaderboards, {
  onRedirecting: () => <p>Loading...</p>,
});

