import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "./CSS/Employees.scss";
import ProfileCard from "./components/ProfileCard";
import { useUserContext } from "./UserContext";
import { useIsRecruiter } from "./RoleHelper";

export default function Employees(props) {
    const { getAccessTokenSilently } = useAuth0();
    const [recruiters, setRecruiters] = useState([]);
    const [allies, setAllies] = useState([]);
    const [recruiterCode, setRecruiterCode] = useState("");
    const [allyCode, setAllyCode] = useState("");
    const user = useUserContext().user;
    const isRecruiter = useIsRecruiter();

    useEffect(() => {
        if (!isRecruiter) {
            props.history.push("/");
        }
    }, [isRecruiter]);

    useEffect(() => {
        if (user && user.company_id) {
            getEmployees("EMPLOYEE,EMPLOYER_ADMIN").then((data) => {
                setRecruiters(data.content);
            });
        }
    }, [user]);

    useEffect(() => {
        if (user && user.company_id) {
            getEmployees("ALLY").then((data) => {
                setAllies(data.content);
            });
        }
    }, [user]);

    useEffect(() => {
        if (user.company_id) {
            getInviteCodes();
        }
    }, [user]);

    const getEmployees = async (rolesString) => {
        let token = await getAccessTokenSilently({
            audience: `https://api-v2.behearty.co`,
            scope: "read:current_user offline_access",
        });

        let headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };

        let url = `${process.env.REACT_APP_URL_JAVA}/external-profiles/search?`
        const roles = rolesString.split(",");
        for (let i = 0; i < roles.length; i++) {
            url += `role=${roles[i]}&`;
        }

        url += `companyId=${user.company_id}`;
            
        const response = await fetch(
            url,
            {
                headers,
                method: "GET",
            }
        );

        return await response.json();
    };

    const getInviteCodes = async () => {
        let token = await getAccessTokenSilently({
            audience: `https://api-v2.behearty.co`,
            scope: "read:current_user offline_access",
        });

        let headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };

        let url = `${process.env.REACT_APP_URL_JAVA}/collective-invite-codes/company?companyId=${user.company_id}`
        const response = await fetch(
            url,
            {
                headers,
                method: "GET",
            }
        );

        const codes = await response.json();
        
        if (codes.length > 0) {
            for (let i = 0; i < codes.length; i++) {
                if (codes[i].roleId === 7) {
                    setRecruiterCode(codes[i].code);
                } else if (codes[i].roleId === 8) {
                    setAllyCode(codes[i].code);
                }
            }
        }
    }

    return (
        <div className="employees-home">
            <div className="recruiters">
                <div className="recruiters-header">
                    <h3>Recruiters</h3>
                    <div className="invite-link">
                        <span>Recruiter Invite: app.hearty.xyz/register/{recruiterCode}</span>
                    </div>
                </div>
                <div className="recruiters-list">
                    {recruiters && recruiters.map((recruiter) => {
                        return (
                            <ProfileCard key={recruiter.id} profile={recruiter} compact={true}/>
                        );
                    })}
                </div>
            </div>
            <div className="allies">
                <div className="allies-header">
                    <h3>Allies</h3>
                    <div className="invite-link">
                        <span>Allies Invite: app.hearty.xyz/register/{allyCode}</span>
                    </div>
                </div>
                <div className="allies-list">
                    {allies && allies.map((ally) => {
                        return (
                            <ProfileCard key={ally.id} profile={ally} isAlly={true} compact={true}/>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}