import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import DefaultImage from "../images/captain-emoji.png";
import { capitalizeLocation } from "../helpers";
import { useAuth0 } from "@auth0/auth0-react";

export default function RateMeetingModal(props) {
    const { getAccessTokenSilently } = useAuth0();
    const [hasHadMeeting, setHasHadMeeting] = useState(props.hasHadMeeting || null);
    const [meetingFeedback, setMeetingFeedback] = useState("");
    const [meetingReason, setMeetingReason] = useState("");

      const submit = async() => {
        let token = await getAccessTokenSilently({
          audience: `https://api-v2.behearty.co`,
          scope: "read:current_user offline_access",
        });
        let headers = {
          "Content-Type": "application/json",
        };
    
        if (token) {
          headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          };
        }
        
        fetch(
          `${process.env.REACT_APP_URL_JAVA}/ally-meeting/follow-up`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(
              {
                allyMeetingId: props.id,
                answer: hasHadMeeting ? "YES" : "NO",
                feedback: meetingFeedback,
                reason: meetingReason,
              }),
          }
        )
          .then((response) => response.json())
          .then(props.feedbackSubmitted());
        };

    return (
        <div className="rate-meeting-modal">
            <div className="header">
            <div className="rateLabel">Community Connection</div>
            <div className="modalProfile">
                <div className="imageContainer">
                    <img
                        src={props.image + "?w=100"}
                        alt={props.name}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = DefaultImage;
                        }}
                    />
                </div>
                <div className="userInfo">
                <div className="nameLine">
                    <div className="name">{props.name}</div>
                    {props.badges && props.badges.length > 0 && props.badges.map((badge) => (
                        <div className="allyBadge">
                            {badge}
                        </div>
                    ))}
                </div>
                <div className="headline">{props.headline}</div>
                {props.location && (
                    <div className="location">
                    <FontAwesomeIcon icon={faLocationDot} /> {capitalizeLocation(props.location)}
                    </div>
                )}
                </div>
            </div>
            </div>
            <div className="body">
              {hasHadMeeting === null && (
                <>
                <div className="rateLabel">Have you had your meeting with {props.name}?</div>
                <div className="buttons">
                    <button className="yesButton" onClick={() => setHasHadMeeting(true)}>&#128077; Yes</button>
                    <button className="noButton" onClick={() => setHasHadMeeting(false)}>&#128078; No</button>
                </div>
                </>
              )}
              {hasHadMeeting === true && (
                <>
                <div className="rate">
                  <div className="rateLabel center">How did it go?</div>
                  <textarea className="rateInput" onChange={(e) => setMeetingFeedback(e.target.value)}></textarea>
                </div>
                <div className="submit">
                    <button className="submitRatingButton" onClick={() => submit()}>Submit</button>
                </div>
                </>
              )}
              {hasHadMeeting === false && (
                <>
                <div className="rate">
                  <label className="rateLabel">Why hasn't it happened?</label>
                  <select className="rateSelect" onChange={(e) => setMeetingReason(e.target.value)}>
                      <option disabled selected value> -- Select a Reason -- </option>
                      <option value="NO_RESPONSE">No response from the other party</option>
                      <option value="WORKING_TO_FIND_DATE">Working to find dates</option>
                      <option value="MEETING_SCHEDULED">Meeting is scheduled but hasn't occurred</option>
                      <option value="OTHER">Other</option>
                  </select>
                  <textarea className="rateInput" placeholder="Leave us some feedback (optional)" onChange={(e) => setMeetingFeedback(e.target.value)}></textarea>
                </div>
                <div className="submit">
                    <button className="submitRatingButton" onClick={() => submit()}>Submit</button>
                </div>
                </>
              )}
            </div>
        </div>
    );
};