import React, { useEffect, useState, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import CandidateMatch from "./CandidateMatch";

const CandidateMatches = (props) => {
    const { isLoading, getAccessTokenSilently } = useAuth0();
    const [showMatches, setShowMatches] = useState(false);
    const [matches, setMatches] = useState([]);

    const getMatches = async (companyCollectiveRoleId) => {
        let token = false;
        try {
            token = await getAccessTokenSilently({
                audience: `https://api-v2.behearty.co`,
                scope: "read:current_user offline_access",
            });
        } catch (error) {
            return console.log(error);
        }

        const response = await fetch(
            `${process.env.REACT_APP_URL_JAVA}/company-collective-roles/${companyCollectiveRoleId}/matches`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const candidates = await response.json();
        setMatches(candidates);
    }

    const dismiss = async(companyCollectiveUserId) => {
        const updatedMatches = [...matches];
        const index = updatedMatches.findIndex(m => m.companyCollectiveUserId === companyCollectiveUserId);
        updatedMatches.splice(index, 1);
        setMatches(updatedMatches);
    };

    const offerIntroduction = async(companyCollectiveUserId) => {
        const updatedMatches = [...matches];
        const index = updatedMatches.findIndex(m => m.companyCollectiveUserId === companyCollectiveUserId);
        updatedMatches[index].status = "INTRODUCTION_OFFERED";
        setMatches(updatedMatches);
    };

    return (
        <>
            <div>
                <button className="btn showHideMatches"
                    onClick={() => {
                        if (!showMatches) {
                            getMatches(props.companyCollectiveRoleId);
                        }
                        setShowMatches(!showMatches);
                    }}
                >
                    {showMatches ? "Hide" : "Show"} Candidate Matches
                </button>
            </div>
            {showMatches &&
                <div>
                    {matches.map((match) => {
                        return (
                            <CandidateMatch key={match.companyCollectiveUserId} match={match} roleTechnologies={props.roleTechnologies} dismiss={dismiss} offerIntroduction={offerIntroduction}/>
                        )
                    })}
                </div>
            }
        </>
    )
}

export default CandidateMatches;