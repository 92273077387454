import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "./CSS/EmployerHome.scss";
import { useIsRecruiter, useIsEmployerAdmin } from "./RoleHelper";
import ProfileCard from "./components/ProfileCard";
import { useUserContext } from "./UserContext";
import ProfileHomeSidebar from "./components/ProfileHomeSidebar";
import CompanySidebarWidget from "./components/CompanySidebarWidget";
import { getJobFunctions } from "./service/ExternalProfileService.js";


export default function EmployerHome(props) {
    const { getAccessTokenSilently } = useAuth0();
    const [candidates, setCandidates] = useState([]);
    const [filter, setFilter] = useState("all");
    const [onlyMine, setOnlyMine] = useState(false);
    const [jobFunctions, setJobFunctions] = useState([]);
    const [badges, setBadges] = useState([]);
    const [company, setCompany] = useState({});
    const user = useUserContext().user;
    const collectives = useUserContext().collectives;
    const isRecruiter = useIsRecruiter();
    const isEmployerAdmin = useIsEmployerAdmin();

    useEffect(() => {
        if (!isRecruiter) {
            props.history.push("/");
        }
    }, [isRecruiter]);

    useEffect(() => {
        if (user.company_id) {
            getCandidates(user.company_id);
        }
    }, [user, filter, onlyMine]);

    useEffect(() => {
        if (user && user.company_id) {
            getCompany(user.company_id);
        }
    }, [user]);

    useEffect(() => {
        if (user && user.external_profile) {
            getJobFunctions(user.external_profile.id).then((response) => {
                setJobFunctions(response);
            });
        }
    }, [user]);

    useEffect(() => {
        if (collectives) {
            let badges = [];
            for (let i = 0; i < collectives.length; i++) {
                if (collectives[i].roles.includes("ALLY")) {
                badges.push("ALLY");
                }
                if (collectives[i].roles.includes("EMPLOYEE" || "EMPLOYER_ADMIN")) {
                badges.push("RECRUITER");
                }
                if (collectives[i].roles.includes("ERG")) {
                badges.push("ERG");
                }
            }
            setBadges(badges);
        }
    }, [collectives]);

    const getCompany = async (companyId) => {
        let token = await getAccessTokenSilently({
            audience: `https://api-v2.behearty.co`,
            scope: "read:current_user offline_access",
        });

        let headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };

        let url = `${process.env.REACT_APP_URL_JAVA}/companies/${companyId}/profile`

        const response = await fetch(
            url,
            {
                headers,
                method: "GET",
            }
        );

        const data = await response.json();
        setCompany(data);
    };

    const getCandidates = async (companyId) => {
        let token = await getAccessTokenSilently({
            audience: `https://api-v2.behearty.co`,
            scope: "read:current_user offline_access",
        });

        let headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };

        let url = `${process.env.REACT_APP_URL_JAVA}/introductions/search?`

        if (filter !== "all") {
            url += `&status=${filter}`;
        }

        if (!onlyMine) {
            url += `&companyId=${companyId}`;
        }

        const response = await fetch(
            url,
            {
                headers,
                method: "GET",
            }
        );

        const results = await response.json();
        setCandidates(results);
    };

    const redirectToSearch = (searchTerm) => {
        props.history.push('/search', {searchTerm: searchTerm});
    };

    return (
        <div className="employer-home">
            <div className="employer-home-left">
                {user && user.external_profile && (
                    <ProfileHomeSidebar
                    headerImage={user.external_profile.headerImage}
                    customUrl={user.external_profile.customUrl}
                    image={user.external_profile.image}
                    name={user.name}
                    jobFunctions={jobFunctions}
                    badges={badges}
                    headline={user.external_profile.headline}
                    location={user.external_profile.location}
                    areaOfExpertise={user.area_of_expertise}
                    />
                )}
                {user && company && (
                    <CompanySidebarWidget
                        customUrl={company.customUrl}
                        image={company.image}
                        name={company.name}
                        location={company.location}
                        url={company.url}
                        overview={company.overview}
                        recruiterCount={company.recruiterCount}
                        allyCount={company.allyCount}
                        id={company.id}
                        showEmployeeCounts={isRecruiter && company.id === user.company_id}
                        showEditButton={isEmployerAdmin && company.id === user.company_id}
                    />
                )}
            </div>
            <div className="employer-home-right">
                <div className="employer-home-search">
                    <input
                        type="text"
                        placeholder="🔍 Search Keywords"
                        onKeyUp={
                            (e) => {
                                if (e.key === "Enter") {
                                    redirectToSearch(e.target.value);
                                }
                            }
                        }
                    />
                </div>
                <div className="filters">
                    <button
                        className={`all-candidates ${filter === "all" ? "active" : ""}`}
                        onClick={() => setFilter("all")}
                    >
                        All Candidates
                    </button>
                    <button
                        className={`pending-candidates ${filter === "pending" ? "active" : ""}`}
                        onClick={() => setFilter("pending")}
                    >
                        Pending Candidates
                    </button>
                    <input
                        id="only-mine"
                        className="only-mine"
                        type="checkbox"
                        checked={onlyMine}
                        onChange={() => setOnlyMine(!onlyMine)}
                    />
                    <label className="only-mine" htmlFor="only-mine">Show only my candidates</label>
                </div>
                <div className="candidates">
                    {candidates && candidates.length > 0 && candidates.map((candidate) => {
                        return (
                            <ProfileCard key={candidate.introductionId} profile={candidate.profile} status={candidate.status} />
                        );
                    })}
                </div>
            </div>
        </div>
    );
}