import { faUsers } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import "../CSS/Trophies/collection.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAuth0 } from "@auth0/auth0-react";
import Nft from "./components/Nft";
import NftSingle from "./components/NftSingle";
import { Helmet } from "react-helmet";
import Captain from "../images/captain-emoji.png";
import { useHistory } from "react-router-dom";
import ClaimModal from "./components/ClaimModal";

export default function Collection(props) {
  const [loggedInUser, setLoggedInUser] = useState(false);
  const [size, setSize] = useState(100);
  const [copyText, setCopyText] = useState("Copy link");
  const [page, setPage] = useState(0);
  const [trophyLength, setTrophyLength] = useState(0);
  const [owners, setOwners] = useState([]);
  const [loadingOwners, setLoadingOwners] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [noUser, setNoUser] = useState(false);
  const [collection, setCollection] = useState({});
  const [trophies, setTrophies] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  const [sort, setSort] = useState("recommended");
  const [view, setView] = useState("trophies");
  const [loading, setLoading] = useState(false);
  let { id } = useParams();

  let { code } = useParams();
  const [collectionId, setCollectionId] = useState(id);
  const [inviteCode, setInviteCode] = useState(code);
  const [ribbit, setRibbit] = useState(false);
  const [century21, setCentury21] = useState(false);

  const [expandOwners, setExpandOwners] = useState(false);
  const history = useHistory();

  const [isAdmin, setIsAdmin] = useState(() => {
    const rolesJSON = localStorage.getItem("roles");
    const roles = JSON.parse(rolesJSON);
    return roles && roles.includes("Admin");
  });

  const [isCollectionAdmin, setIsCollectionAdmin] = useState(false);

  useEffect(() => {
    if(code) {
      getNFT(code);
    }
  }, [isLoading]);

  const getNFT = async (code) => {
    let token = await getAccessTokenSilently();

    let response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophies/invite/${code}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    let data = await response.json();

    let name = data.trophyCollectionName.toLowerCase();
    setCollectionId(data.trophyCollectionId);
    
    if(name.includes("ribbit")) {
      setRibbit(true);
    } else if (name.includes("century 21")) {
      setCentury21(true);
    }
  };

  const download = async () => {
    const token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const download = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophy-collections/${collection.id}/export`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )

    

    const blob = await download.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${collection.name}.csv`;
    a.click();
  }

  const togglePublic = async (change) => {
    const token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const patchCollection = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophy-collections/${collection.id}/private?private=${change}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )

    const response = await patchCollection.json();

    const newCollection ={...collection};
    newCollection.private = change;

    setCollection(newCollection);
  }

  useEffect(() => {
    const jsonUser = localStorage.getItem("user");
    const rolesJSON = localStorage.getItem("roles");

    if (jsonUser) {
      const user = JSON.parse(jsonUser);
      setLoggedInUser(user);
    }
    async function fetchData() {
      window.scrollTo(0, 0);
      const user = JSON.parse(localStorage.getItem("user"));
      const collection = await getCollection(collectionId);
      await getTrophies(false, collection.id);
      await getOwners(collection.id);
    }
    if (collectionId) {
      fetchData();
    }
  }, [props.getOnboardingStep, collectionId]);

  const getOwners = async (collectionId) => {
    setLoadingOwners(true);

    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      setOwners([]);
      setLoadingOwners(false);
      return;
    }

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophy-collections/${collectionId}/awardees?page=0&size=1000`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then(async (data) => {
        if (!data.content || data.content.length === 0) {
          setOwners([]);
          setLoadingOwners(false);
          return;
        }
        setOwners([]);
        setOwners(data.content);
        setLoadingOwners(false);
      });
  };

  const fetchMoreTrophies = async () => {
    if (!collection.id) return false;
    if (hasMore === false) return;
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      console.log(error);
    }
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }

    let searchQuery = "";

    if (searchText) {
      searchQuery = `&query=${searchText}`;
    }

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophies?trophyCollectionId=${collection.id}&page=${page}&size=${size}${searchQuery}`,
      {
        method: "GET",
        headers,
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error(response);
      })
      .then((data) => {
        const newTrophies = [...trophies, ...data.content];

        setTrophies(newTrophies);
        setTrophyLength(data.totalElements);
        // Is this the last call we need?
        if (data.size >= data.totalElements) {
          return setHasMore(false);
        }

        setPage(page + 1);
      })
      .catch((error) => {
        console.log();
      });
  };

  const getCollection = async (collectionId) => {
    if (!collectionId) return false;
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      console.log(error);
    }
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }

    let url = `${process.env.REACT_APP_URL_JAVA}/trophy-collections/${collectionId}`;

    if (isNaN(collectionId)) {
      url = `${process.env.REACT_APP_URL_JAVA}/trophy-collections/url/${collectionId}`;
    }

    const response = await fetch(url, {
      method: "GET",
      headers,
    });

    const data = await response.json();

    setCollection(data);
    setIsCollectionAdmin(data.admin);

    return data;
  };

  const getTrophies = async (search = false, id = collectionId) => {
    if (!collection) return false;
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      console.log(error);
    }
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }

    let count = size;

    setLoading(true);

    let searchQuery = "";

    if (search) {
      searchQuery = `&query=${search}`;
    }

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophies?trophyCollectionId=${id}&page=${page}&size=${size}${searchQuery}`,
      {
        method: "GET",
        headers,
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error(response);
      })
      .then((data) => {
        setTrophyLength(data.totalElements);
        let items = data.content;
        setLoading(false);
        setTrophies(items);

        if (data.totalPages === 1) {
          return setHasMore(false);
        }
        setPage(1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="Collection app">
      {collection && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${collection.name} - Hearty Trophy Collection`}</title>
          <meta name="description" content={collection.name} />
          <meta property="og:title" content={collection.name} />
          <meta property="og:description" content={collection.description} />
          <meta property="og:image" content={collection.imageUrl} />
          <meta property="og:url" content={window.location} />

          <meta property="twitter:title" content={collection.name} />
          <meta
            property="twitter:description"
            content={collection.description}
          />
          <meta property="twitter:image" content={collection.imageUrl} />
          <meta name="twitter:card" content="summary_large_image"></meta>
        </Helmet>
      )}

      {inviteCode && (
        <ClaimModal
          close={() => {
            setInviteCode(false);

            // Set the LS
            const user = JSON.parse(localStorage.getItem("user"));
            user.is_nft_user = true;
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.removeItem("NFTCode");
            window.location.href = "/trophies";
          }}
          code={code}
        />
      )}

      <section className={`left ${!loggedInUser ? "full" : ""}`}>
        <section
          style={{
            backgroundImage: `url('${collection.imageUrl}')`,
          }}
          className="titleCard"
        >
          <div className="beta">
            <span>Beta</span>
          </div>
          {isAdmin && (
            <div className="admin">
              <a href={`/collection/edit/${collection.id}`}>Edit</a>
            </div>
          )}
          <h2>{collection.name}</h2>
          <p>{collection.description}</p>
        </section>
        <div className="content">
          <div className="actions">
            <div className="numbers">
              <div className="top">
                <div className="search">
                  <input
                    type="text"
                    placeholder="Search"
                    onChange={(e) => {
                      setSearchText(e.target.value);
                      if (e.target.value.length > 2) {
                        getTrophies(e.target.value, collection.id);
                      } else {
                        getTrophies(e.target.value, collection.id);
                      }
                    }}
                  />
                </div>
                <div className="trophiesCount">
                  <FontAwesomeIcon icon={faUsers} /> <b>{trophyLength}</b>{" "}
                  NFTs
                </div>
                <div className="ownersCount">
                  <FontAwesomeIcon icon={faUsers} />{" "}
                  <b>{collection.recipientCount}</b> Owners
                </div>
              </div>

              <div className="tabsContainer">
                <div className="tabs">
                  <span
                    className={`${view === "trophies" ? "active" : ""}`}
                    onClick={() => {
                      setView("trophies");
                    }}
                  >
                    NFTs
                  </span>
                </div>
                <div>
                  {isAdmin && (
                    <button
                      onClick={() => {
                        togglePublic(!collection.private);
                      }}
                      style={{
                        marginRight:'10px'
                      }}
                      className="btn large"
                    >
                      {collection.private ? "Make Public" : "Make Private"}
                    </button>
                  )}
                  {(isAdmin || isCollectionAdmin) && (
                    <button
                      onClick={() => {
                       download();
                      }}
                      style={{
                        marginRight:'10px'
                      }}
                      className="btn large"
                    >
                      Download
                    </button>
                  )}
                </div>
              </div>

              <div className="break"></div>
            </div>
          </div>
          <div className="left">
            <div className="content">
              {view === "trophies" && loading === false && (
                <InfiniteScroll
                  dataLength={trophies.length}
                  next={() => {
                    return fetchMoreTrophies();
                  }}
                  style={{ display: "flex", width: "100%", flexWrap: "wrap" }} //To put endMessage and loader to the top.
                  inverse={false}
                  hasMore={hasMore}
                >
                  {trophies.map((trophy) => {
                    if (trophy.single)
                      return (
                        <NftSingle
                          showClaimed={true}
                          collectionView={true}
                          key={trophy.trophyId}
                          nft={trophy}
                        />
                      );
                    return (
                      <Nft
                        showClaimed={true}
                        collectionView={true}
                        key={trophy.trophyId}
                        nft={trophy}
                      />
                    );
                  })}
                </InfiniteScroll>
              )}

              {view === "trophies" && trophies.length === 0 && (
                <p className="none">No trophies in this collection yet</p>
              )}
            </div>
          </div>
        </div>
      </section>
      {loggedInUser && (
        <section className="right">
          <h3>Owners</h3>
          <div className="content">
            {owners.map((owner, idx) => {
              return (
                <Link
                  key={owner.id}
                  className="owner"
                  to={`/user/${owner.customUrl}`}
                >
                  <img
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = Captain;
                    }}
                    src={owner.image}
                  />
                  <div className="name">
                    <h3>{owner.name}</h3>
                    <p>{owner.headline}</p>
                  </div>
                </Link>
              );
            })}

            {owners.length === 0 && loggedInUser && (
              <p className="blank">No owners yet!</p>
            )}
          </div>
        </section>
      )}

    </div>
  );
}
