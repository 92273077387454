import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ApproveTrophy from "./ApproveTrophy";

const CreateCollection = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [pending, setPending] = useState([]);

  useEffect(() => {
    getPending();
  }, []);


  const getPending = async () => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const pendingRequest = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-trophies/pending`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const pending = await pendingRequest.json();

    setPending(pending);
  };

  return <div className="container">
     <h3>Approve Trophies</h3>
     <div className="approvals">
     {pending.map(trophy => {
        return <ApproveTrophy key={trophy.id} callback={()=>{
          getPending();
        }} trophy={trophy} />
     })}
     </div>
  </div>
};

export default CreateCollection;
