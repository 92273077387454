import React, { useEffect, useState, useRef } from "react";
import "../CSS/Collective/Review.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import Logo from "../images/collective.png";
import Reviewer from "./Reviewer";

export default function Creator(props, ref) {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  let { guid } = useParams();

  const [users, setUsers] = useState([]);
  const [company, setCompany] = useState({});
  const [state, setState] = useState('INTERVIEW');

  useEffect(() => {
    const header = document.querySelector("nav");
    header.style.display = "none";

    getUsers('INTRODUCTION_OFFERED');
    getCompany();
  }, []);

  const getUsers = async (tab) => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      console.log(error);
    }
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }

    let response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/company-collective-users?guid=${guid}&tab=${tab}`,
      {
        method: "GET",
        headers,
      }
    );

    let data = await response.json();
    setUsers(data);
  };

  const getCompany = async () => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      console.log(error);
    }
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }

    let response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/company-collectives/guid/${guid}`,
      {
        method: "GET",
        headers,
      }
    );

    let data = await response.json();
    setCompany(data);
  };

  return (
    <div className="CollectiveReview">
      <div className="header">
        <div className="header-title">
          <img src={Logo} alt="logo" />
          <h2>Candidate Review</h2>
          <img src={company.image} alt="logo" />
        </div>
        <div className="header-bottom">
          <button onClick={()=>{
            getUsers("ALL");
            setState("ALL")
          }} className={state === "ALL" ? 'active' : ''}>All</button>
          <button onClick={()=>{
            getUsers('INTRODUCTION_OFFERED')
            setState("INTERVIEW")
          }} className={state === "INTERVIEW" ? 'active' : ''}>Introduction Offered: {company.introductionOfferedCount}</button>
          <button onClick={()=>{
            getUsers('ACTIVELY_INTERVIEWING')
            setState("ACTIVE")
          }} className={state === "ACTIVE" ? 'active' : ''}>Actively Interviewing: {company.activelyInterviewingCount}</button>
          <button onClick={()=>{
            getUsers('ARCHIVE')
            setState("ARCHIVE")
          }} className={state === "ARCHIVE" ? 'active' : ''}>Archive: {company.archiveCount}</button>
        </div>
      </div>

      {users.map((user)=>{
        return (
          <Reviewer finished={()=>{
            if (state === "ALL") {
              getUsers("ALL");
            } else if (state === "INTERVIEW") {
              getUsers('INTRODUCTION_OFFERED');
            } else if (state === "ACTIVE") {
              getUsers('ACTIVELY_INTERVIEWING');
            } else {
              getUsers('ARCHIVE');
            }

            getCompany();
          }} key={user.id} guid={guid} item={user} />
        )
      })}

      {users.length === 0 && <div className="no-data"><p>No Matches Yet! Keep checking back!</p></div>}
    </div>
  );
}
