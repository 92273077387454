import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import "../CSS/SkillsSelector.scss";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { useAuth0 } from "@auth0/auth0-react";

export default function SkillsSelector(props) {
  const [searchText, setSearchText] = useState(props.searchText || "");
  const [searchedSkills, setSearchedSkills] = useState([]);
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  const [showDropdown, setShowDropdown] = useState(false);
  const [skills, setSkills] = useState([]);
  const [allSkills, setAllSkills] = useState([]);
  const [removedSkills, setRemovedSkills] = useState([]);

  const searchAPI = async (search) => {
    let headers = {
      "Content-Type": "application/json",
    };

    if (isAuthenticated) {
      const token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });

      if (token) {
        headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
      }
    }

    return fetch(
      `${process.env.REACT_APP_URL_JAVA}/${props.url}?q=${search}&page=0&size=100`,
      {
        method: "GET",
        headers: headers
      }
    );
  };

  const getSkills = async () => {
    let headers = {
      "Content-Type": "application/json",
    };

    if (isAuthenticated) {
      const token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });

      if (token) {
        headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
      }
    }

    const data = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/${props.url}?page=0&size=300`,
      {
        method: "GET",
        headers: headers
      }
    );
    const body = await data.json();
    setAllSkills(body.content);
  };

  const searchAPIDebounced = AwesomeDebouncePromise(searchAPI, 200);

  const enteredText = async (search) => {
    const query = await searchAPIDebounced(search);
    const skills = await query.json();
    setSearchedSkills(skills.content);
    if (skills.content.length > 0) {
      setShowDropdown(true);
    }
  };

  const includesSkill = (skill) => {
    return skills.some((item) => item.id === skill.id);
  };

  const addSkill = (skill) => {
    const length = skills.length;
    const newSkills = skills.filter((item) => item.name !== skill.name);
    let pushedSkills = [];

    if (length > newSkills.length) {
      setSkills(newSkills);
      pushedSkills = newSkills;
      setRemovedSkills([...removedSkills, skill]);
      
      if (props.setRemovedSkills) {
        props.setRemovedSkills([...removedSkills, skill]);
      }
    } else {
      setSkills([...skills, skill]);
      pushedSkills = [...skills, skill];

      if (props.setRemovedSkills) {
        props.setRemovedSkills(removedSkills.filter((item) => item.name !== skill.name));
      }
      setRemovedSkills(removedSkills.filter((item) => item.name !== skill.name));
    }

    props.setData(pushedSkills);
  };

  const changeRequired = (skill) => {
    let newSkills = skills.map((item)=>{
      if(skill.id ===  item.id) {
        if(!item.required || item.required === false) {
          item.required = true;
        } else {
          item.required = false;
          item.score = null;
        }
      }
      return item;
    })

    setSkills(newSkills);
    props.setData(newSkills);
  };

  const changeScore = (skill, score) => {
    let newSkills = skills.map((item)=>{
      if(skill.id === item.id) {
        if(item.required === true) {
          item.score = score;
        }
      }
      return item;
    })

    setSkills(newSkills);
    props.setData(newSkills);
  };

  useEffect(() => {
    setSkills(props.data);
    if (props.showCloud) getSkills();
  }, [props.data]);

  return (
    <div className="SkillsSelector">
      <p className="label">{props.description}</p>
      <input
        placeholder={searchText}
        // autoFocus={true}
        spellCheck="false"
        onChange={(e) => {
          enteredText(e.target.value);
        }}
        type="text"
      />

      {showDropdown && (
        <div className="dropdown">
          {searchedSkills.map((skill) => {
            return (
              <div
                onClick={() => {
                  addSkill(skill);
                  setShowDropdown(false);
                }}
                className="skill"
                key={skill.id}
              >
                {skill.name}
              </div>
            );
          })}
        </div>
      )}

      {!props.showCloud && (
        <div className="skills">
          {skills.map((skill) => {
            return (
              <div
                className="skill"
                key={skill.id}
              >
                <p  onClick={() => {
                  addSkill(skill);
                }}>{skill.name} {props.showX && (<span className="x">X</span>)}</p>

                {props.showRequired && (
                  <>
                  <input onChange={()=>{
                    changeRequired(skill);
                  }} type="checkbox" className="checkbox"
                    checked={skill.required} />
                  {skill.required && (
                    <input className="score" onChange={(e)=>{
                      changeScore(skill, e.target.value);
                    }} type="number" min={1} max={5}
                      value={skill.score}/>
                  )}
                  </>
                )}
              </div>
            );
          })}
        </div>
      )}

      {props.showCloud && (
        <div className="cloud">
          {allSkills.map((skill) => {
            return (
              <div
                onClick={() => {
                  addSkill(skill);
                }}
                className={`skill ${includesSkill(skill)}`}
                key={skill.id}
              >
                <p>{skill.name}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
