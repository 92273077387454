import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import "./CSS/MemberList.scss";
import Captain from "./images/captain-emoji.png";
import { Link } from "react-router-dom";

export const MemberList = (props) => {
    const { loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const [members, setMembers] = useState([]);
    
    useEffect(() => {
        getMembers();
    }, []);

    const getMembers = async () => {
        let token = false;
        try {
            token = await getAccessTokenSilently({
            audience: `https://api-v2.behearty.co`,
            scope: "read:current_user offline_access",
            });
        } catch (error) {
            loginWithRedirect();
        }
    
        const response = await fetch(
            `${process.env.REACT_APP_URL_JAVA}/users/members?page=0&size=100000`,
            {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            }
        );
    
        const data = await response.json();
    
        setMembers(data.content);
    };
    
    return (
        <div className="member-list">
        {members.map((member) => (
            <div className="member">
                <div className="image">
                    <Link to={`/user/${member.customUrl}`} target="_blank">
                        <img src={member.image + '?w=100'}
                            onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = Captain;
                        }} alt={member.name}/>
                    </Link>
                </div>
                <div className="info">
                    <div className="name">
                        <Link to={`/user/${member.customUrl}`} target="_blank">{member.name}</Link>
                        {member.linkedinUrl && (
                            <span className="linkedinUrl">
                                <a target="_blank" href={member.linkedinUrl} rel="noreferrer"> <FontAwesomeIcon icon={faLinkedin} /></a>
                            </span>
                        )}
                    </div>
                    {member.headline && (
                        <div className="headline">{member.headline}</div>
                    )}
                    {(member.role || member.company) && (
                        <div className="roleCompany">{member.role} @ {member.company}</div>
                    )}
                    {member.about && (
                        <div className="about">{member.about}</div>
                    )}
                </div>
            </div>
        ))}
        </div>
    );
};