import React, { useState, useEffect, useRef } from "react";
import "../CSS/Companies/profile.scss";
import bg from "../images/companybg.png";
import { useAuth0 } from "@auth0/auth0-react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import Captain from "../images/captain-emoji.png";
import { useLocation } from "react-router-dom";
import SkillsSelector from "../components/SkillsSelector";
import SaveRole from "../components/companies/SaveRole";
import { formatRoleType } from "../helpers";

import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faGlobe,
  faLink,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { truncateString } from "../helpers";
import CandidateMatches from "../Collective/CandidateMatches";
import TipTap from "../Feed/components/TipTap";
import { Link } from "react-router-dom";
import { getAuthenticatedUserCollectives } from "../LocalStorageUtil";

export default function CompanyProfile(props, ref) {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [loggedIn, setLoggedIn] = useState(true);
  const [company, setCompany] = useState({
    overview: "",
  });
  const [truncateLength, setTruncateLength] = useState(250);
  const [admin, setAdmin] = useState(false);
  const [employerAdmin, setEmployerAdmin] = useState(false);
  const [collective, setCollective] = useState({});
  const [inCollective, setInCollective] = useState(false);
  const [me, setMe] = useState({});
  const [editingId, setEditingId] = useState(null);
  const search = useLocation().search;
  const [skills, setSkills] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const [addingRole, setAddingRole] = useState(false);
  const [savingRole, setSavingRole] = useState(false);
  const [roleToEdit, setRoleToEdit] = useState(null);

  const [newRoleName, setNewRoleName] = useState('');
  const [newRoleNotes, setNewRoleNotes] = useState('');
  const [newRoleMinSalary, setNewRoleMinSalary] = useState('');
  const [newRoleMaxSalary, setNewRoleMaxSalary] = useState('');
  const [newRoleMinExperience, setNewRoleMinExperience] = useState('');
  const [newRoleMaxExperience, setNewRoleMaxExperience] = useState('');
  const [newRoleJobPostUrl, setNewRoleJobPostUrl] = useState('');
  const [newRoleJobDescription, setNewRoleJobDescription] = useState('');
  const [newRoleType, setNewRoleType] = useState(null);
  const [newRoleSuppressAutomaticSuggestions, setNewRoleSuppressAutomaticSuggestions] = useState(true);

  const [roles, setRoles] = useState([]);
  const [newSkills, setNewSkills] = useState([]);
  const [companyCollectiveGuid, setCompanyCollectiveGuid] = useState('');
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    getMe();

    if (isAdmin()) {
      setAdmin(true);
    }

    getCompany();
    
    if (isEmployerAdmin()) {
      setEmployerAdmin(true);
    }
  }, []);

  const isAdmin = () => {
    const rolesJSON = localStorage.getItem("roles");
    const roles = JSON.parse(rolesJSON);
    if (roles && roles.includes("Admin")) {
      return true;
    } else {
      return false;
    }
  };

  const isEmployerAdmin = () => {
    const loggedInUserCollectives = getAuthenticatedUserCollectives();

    for (let loggedInUserCollective of loggedInUserCollectives) {
      // check if user has role EMPLOYER_ADMIN
      if (loggedInUserCollective.roles.includes("EMPLOYER_ADMIN") && me.company_id === company.id) {
        return true;
      }
    }
    return false;
  };

  const getTech = async (id) => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      return console.log(error);
    }

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/company-collective-technologies?companyCollectiveId=${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const tech = await response.json();
    const newTech =
      tech.length > 0 &&
      tech.map((tech) => {
        return {
          id: tech.technologyId,
          name: tech.technologyName,
          dbId: tech.id,
        };
      });

    setSkills(newTech);
  };
  
  const saveSkills = async (id) => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const responseArray = newSkills.map((skill) => {
      const body = {
        companyCollectiveRoleId: id,
        technologyId: skill.id,
        required: skill.required,
        score: skill.score,
      };
      return fetch(
        `${process.env.REACT_APP_URL_JAVA}/company-collective-role-technologies`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
    });

    return Promise.all(responseArray);
  };


  const getBenefits = async (id) => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      return console.log(error);
    }

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/company-collective-benefits?companyCollectiveId=${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const benefits = await response.json();
    const newBenefits =
      benefits.length > 0 &&
      benefits.map((benefit) => {
        return {
          id: benefit.benefitId,
          name: benefit.benefitName,
          dbId: benefit.id,
        };
      });
    setBenefits(newBenefits);
  };

  const getMe = async () => {
    if (isAuthenticated) {
      let token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });

      const response = await fetch(`${process.env.REACT_APP_URL_JAVA}/users/me`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      try {
        const me = await response.json();
        setMe(me);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });

  const getCollective = async (id) => {
    let endpoint = `${process.env.REACT_APP_URL_JAVA}/company-collectives?companyId=${id}`;

    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
      },
    });

    const collective = await response.json();

    if (isAuthenticated) {
      getTech(collective.content[0].companyCollectiveId);
      getBenefits(collective.content[0].companyCollectiveId);
    }

    setCollective(collective.content[0]);

    if (isAdmin()) {
      getRoles(collective.content[0].companyCollectiveId);
      getCompanyCollectiveGuid(collective.content[0].companyCollectiveId);
    }
  };

  const getEmployees = async (id) => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      return setLoggedIn(false);
    }

    let endpoint = `${process.env.REACT_APP_URL_JAVA}/external-profiles/search?companyId=${id}`;

    const collectives = getAuthenticatedUserCollectives();
    for (let i = 0; i < collectives.length; i++) {
      endpoint += `&collectiveId=${collectives[i].collectiveId}`;
    }

    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();
    setEmployees(data.content);
  };

  const getCompanyCollectiveGuid = async (id) => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      return setLoggedIn(false);
    }

    let endpoint = `${process.env.REACT_APP_URL_JAVA}/company-collectives/${id}/guid`;

    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const guid = await response.text();
    setCompanyCollectiveGuid(guid);
  };

  const getRoles = async (id)=>{
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      return setLoggedIn(false);
    }

    let endpoint = `${process.env.REACT_APP_URL_JAVA}/company-collective-roles?companyCollectiveId=${id}`;

    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const roles = await response.json();
    setRoles(roles);
  }

  const addRole = async () =>{
    const body = {
      name: newRoleName,
      notes: newRoleNotes,
      minSalary: newRoleMinSalary,
      maxSalary: newRoleMaxSalary,
      minExperience: newRoleMinExperience,
      maxExperience: newRoleMaxExperience,
      jobPostUrl: newRoleJobPostUrl,
      jobDescription: newRoleJobDescription,
      type: newRoleType,
      companyCollectiveId: collective.companyCollectiveId,
      suppressAutomaticSuggestions: newRoleSuppressAutomaticSuggestions,
    };

    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      return setLoggedIn(false);
    }

    let endpoint = `${process.env.REACT_APP_URL_JAVA}/company-collective-roles`;

    const response = await fetch(endpoint, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();

    const savedSkills = await saveSkills(data.id);

    getRoles(collective.companyCollectiveId);

    setNewRoleName('');
    setNewRoleNotes('');
    setNewRoleMinSalary('');
    setNewRoleMaxSalary('');
    setNewRoleMinExperience('');
    setNewRoleMaxExperience('');
    setNewRoleJobPostUrl('');
    setNewRoleJobDescription('');
    setNewRoleType(null);
    setNewRoleSuppressAutomaticSuggestions(true);

    setAddingRole(false);
    createMatches(data.id);
  }

  const deleteRole = async (id) => {
    if (window.confirm("Are you sure you want to deactivate this role?")) {
      let token = false;
      try {
        token = await getAccessTokenSilently({
          audience: `https://api-v2.behearty.co`,
          scope: "read:current_user offline_access",
        });
      } catch (error) {
        return setLoggedIn(false);
      }

      let endpoint = `${process.env.REACT_APP_URL_JAVA}/company-collective-roles/${id}`;

      const response = await fetch(endpoint, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      getRoles(collective.companyCollectiveId);
    }
  };

  const createMatches = async(id) => {
    const token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });

    let endpoint = `${process.env.REACT_APP_URL_JAVA}/company-collective-roles/${id}/matches`;

    fetch(endpoint, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });
};

  const getCompany = async () => {
    let endpoint = `${process.env.REACT_APP_URL_JAVA}/companies/`;
    let id = props.match.params.id;
    let headers = {};

    if (isNaN(id)) {
      endpoint+=`custom-url?url=${id}`;
    } else {
      endpoint+=`${id}`;

      try {
        let token = await getAccessTokenSilently({
          audience: `https://api-v2.behearty.co`,
          scope: "read:current_user offline_access",
        });
        headers = {
          Authorization: `Bearer ${token}`,
        }
      } catch (error) {
        setLoggedIn(false);
      }
    }

    const response = await fetch(endpoint, {
      method: "GET",
      headers: headers,
    });

    const company = await response.json();

    if (company.inCollective) {
      setInCollective(true);
      getCollective(company.id);
      getEmployees(company.id);
    }

    // We have no image so set one.
    if (!company.image) company.image = Captain;
    setCompany(company);
  };

  const handleDoneSaving = async () => {
    await getRoles(collective.companyCollectiveId);
    setSavingRole(false);
  };

  const isAlly = (employee) => {
    const loggedInUserCollectives = getAuthenticatedUserCollectives();

    for (let employeeCollective of employee.collectives) {
      for (let loggedInUserCollective of loggedInUserCollectives) {
        // check if profile belongs to the same collective as the logged in user
        if (employeeCollective.id === loggedInUserCollective.collectiveId) {
          // check if profile has role ALLY
          if (employeeCollective.roles.map((r) => r.name).includes("ALLY")) {
            return true;
          }
        }
      }
    }

    return false;
  };

  const isRecruiter = (employee) => {
    const loggedInUserCollectives = getAuthenticatedUserCollectives();

    for (let employeeCollective of employee.collectives) {
      for (let loggedInUserCollective of loggedInUserCollectives) {
        // check if profile belongs to the same collective as the logged in user
        if (employeeCollective.id === loggedInUserCollective.collectiveId) {
          // check if profile has role EMPLOYEE or EMPLOYER_ADMIN
          if (employeeCollective.roles.map(r => r.name).includes("EMPLOYEE") || employeeCollective.roles.map(r => r.name).includes("EMPLOYER_ADMIN")) {
            return true;
          }
        }
      }
    }

    return false;
  };

  const isERG = (employee) => {
    const loggedInUserCollectives = getAuthenticatedUserCollectives();

    for (let employeeCollective of employee.collectives) {
      for (let loggedInUserCollective of loggedInUserCollectives) {
        // check if profile belongs to the same collective as the logged in user
        if (employeeCollective.id === loggedInUserCollective.collectiveId) {
          // check if profile has role ERG
          if (employeeCollective.roles.map(r => r.name).includes("ERG")) {
            return true;
          }
        }
      }
    }

    return false;
  };

  return (
    <div className={`CompanyProfile home`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${company.name} - Company Profile`}</title>
        <meta name="description" content={`${company.overview}`} />
        <meta
          property="og:title"
          content={`${company.name} - Company Profile`}
        />
        <meta property="og:description" content={`${company.overview}`} />
        <meta property="og:url" content={window.location} />

        <meta
          property="twitter:title"
          content={`${company.name} - Company Profile`}
        />
        <meta property="twitter:description" content={company.overview} />
        <meta name="twitter:card" content="summary_large_image"></meta>
      </Helmet>

      <div className="companyContainer">
        <div className="left">
          <div className="companyTop">
            <header
              style={{
                backgroundImage: `url(${bg})`,
              }}
            ></header>
            <div className="info">

              {(admin || employerAdmin) && (
                <Link
                  to={`/company/${company.id}/edit`}
                  className="btn medium editButton"
                >
                  Edit
                </Link>
              )}

              <img
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = Captain;
                }}
                src={company.image}
              />
              <div className="descriptions">
                <h2>{company.name}</h2>
                {company.overview && (
                  <p>
                    {company.overview.length >= 250 &&
                      truncateString(company.overview, truncateLength)}
                    {company.overview.length < 250 && company.overview}
                    {company.overview.length >= 250 && (
                      <p className="truncate">
                        {truncateLength === 250 && (
                          <span
                            onClick={(e) => {
                              setTruncateLength(1000000);
                            }}
                          >
                            Read More
                          </span>
                        )}

                        {truncateLength > 250 && (
                          <span
                            onClick={(e) => {
                              setTruncateLength(250);
                            }}
                          >
                            Read Less
                          </span>
                        )}
                      </p>
                    )}
                  </p>
                )}
                {company.location && (
                  <p>
                    <FontAwesomeIcon icon={faMapMarkerAlt} /> {company.location}
                  </p>
                )}
                {company.size && (
                  <p>
                    <FontAwesomeIcon icon={faBuilding} /> {company.size}
                  </p>
                )}

                {company.url && (
                  <p>
                    <a
                      style={{
                        color: "black",
                        textDecoration: "none",
                      }}
                      href={company.url}
                    >
                      <FontAwesomeIcon icon={faGlobe} /> {company.url}
                    </a>
                  </p>
                )}

                <div className="memberInfo">
                  <p>
                    <a href={company.linkedInUrl}>
                      <FontAwesomeIcon icon={faLinkedin} />{" "}
                      {company.linkedinUrl}
                    </a>
                  </p>
                  {company.crunchbaseUrl && (
                    <p>
                      <a href={company.crunchbaseUrl}>
                        <FontAwesomeIcon icon={faLink} /> {company.crunchbaseUrl}
                      </a>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="companyBottom">
            {inCollective && (
              <div className="collectiveBG">
                <div className="companyInfo">
                  {/* <img src={CollectiveLogo} /> */}
                  {collective.collectiveDei && (
                    <div className="dei">
                      <h2>Diversity, Equity & Inclusion</h2>
                      <p>{collective.collectiveDei}</p>
                    </div>
                  )}
                  <div className="description">
                    <h2>The Hearty Take</h2>
                    <p>{collective.collectiveDescription}</p>
                  </div>
                {skills.length > 0 && (
                  <div className="skills">
                    <h2>Tech Stack</h2>
                    {skills.map((skill) => (
                      <div key={skill.id} className="skill">
                        <p>{skill.name}</p>
                      </div>
                    ))}
                  </div>
                )}

                {benefits.length > 0 && (
                  <div className="benefits">
                    <h2>Benefit Stack</h2>
                    {benefits.map((skill) => (
                      <div key={skill.id} className="skill">
                        <p>{skill.name}</p>
                      </div>
                    ))}
                  </div>
                )}

                {collective.collectiveMediaUrl && (
                  <div className="video">
                    <h2>A Deeper Look</h2>
                    <video style={{
                      width:'100%',
                      height:'300px'
                    }} type="video/mp4"  src={collective.collectiveMediaUrl} loop controls>
                    </video>
                  </div>
                )}

                {collective.collectiveNotes && (
                  <div className="notes">
                    <h2>Additional Tips and Notes</h2>
                    <p dangerouslySetInnerHTML={{
                      __html:collective.collectiveNotes
                    }} />
                  </div>
                )}
                </div>
              </div>
            )}

            {admin && inCollective && (
              <div className="adminContainer">
                <h3>Admin Info</h3>
                <div><label>Contact Name: </label>{collective.contactName}</div>
                <div><label>Contact Email: </label>{collective.contactEmail}</div>
                <div><label>Job Listings URL: </label><a href={collective.jobListingsUrl} target="_blank">{collective.jobListingsUrl}</a></div>
                <div><label>Candidate Review: </label><a href={`/collective-review/${companyCollectiveGuid}`} target='_blank'>{`/collective-review/${companyCollectiveGuid}`}</a></div>
                <div><label>Retainer: </label>{collective.retainer ? "Yes" : "No"}</div>
                <div className="internalNotes">
                  <h4>Internal Notes</h4>
                  <p dangerouslySetInnerHTML={{
                    __html:collective.collectiveInternalNotes
                  }} />
                </div>
              </div>
            )}

            {admin && (
                <div className="rolesContainer">
                  <div className="header">
                  <h3>Roles</h3>
                  <a onClick={()=>{
                    setAddingRole(true)
                  }} >Add Role</a>
                  </div>

                  <div className="roles">
                    {roles.map((role)=>{
 
                      return(
                        <div key={role.id} className="role">
                          <div className="left">
                            {!role.active && (
                              <h3 className="inactive">Inactive</h3>
                            )}
                            <h3><a className="jobPostUrl" href={role.jobPostUrl} target="_blank">{role.name}</a>
                              <CopyToClipboard text={`${window.location.protocol}//${window.location.host}/job/${role.id}`}>
                                <button className="copy" title="Copy public link">
                                  <FontAwesomeIcon icon={faCopy} /> 
                                </button>
                              </CopyToClipboard>
                            </h3>
                              <p>{role.notes}</p>
                              <div className="tech">
                                  {role.technologies.map((tech)=>{
                                    return(
                                      <div key={tech.id} className="tech">
                                        <p>{tech.required ? "*" : ""}{tech.technologyName}{tech.required ? ` (${tech.score})` : ""}</p>
                                      </div>
                                    )
                                  })}
                              </div>
                          </div>
                          <div className='right'>
                          <div className="typeContainer">
                            <h3>Type</h3>
                            {formatRoleType(role.type)}
                          </div>
                            <div className="salaryContainer">
                              <h3>Salary</h3>
                              {formatter.format(role.minSalary)} - {formatter.format(role.maxSalary)}
                            </div>
                            <div className="experienceContainer">
                              <h3>Experience</h3>
                              {role.minExperience} years{role.maxExperience ? ` - ${role.maxExperience} years` : ""}
                            </div>
                            <a onClick={()=>{
                              setSavingRole(true);
                              setRoleToEdit(role);
                            }} >Edit</a>
                            {role.active && (
                              <a className="delete" onClick={()=>{
                                deleteRole(role.id);
                              }} >Deactivate</a>
                            )}
                          </div>
                          <div className="jobDescription left">
                            <p dangerouslySetInnerHTML={{
                              __html:role.jobDescription
                            }} />
                          </div>
                          <div className="matches">
                            <CandidateMatches companyCollectiveRoleId={role.id} roleTechnologies={role.technologies} />
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
          </div>
          {employees && employees.length > 0 && (
            <div className="employees">
              <h2>Employees in the Collective</h2>
              <div className="employeeList">
                {employees.map((employee) => (
                  <Link key={employee.id} to={`/user/${employee.customUrl}`}>
                    <div key={employee.id} className="employee">
                      <div className="imageContainer">
                        <img src={employee.image ? employee.image + "?w=100" : Captain} />
                      </div>
                      <div className="userInfo">
                        <div className="nameLine">
                          <div className="name">{employee.name}</div>
                        </div>
                        <div className="headline">{employee.headline}</div>
                      </div>
                      {isAlly(employee) && (
                        <div className="allyBadge">
                          ALLY
                        </div>
                      )}
                      {isRecruiter(employee) && (
                        <div className="allyBadge">
                          RECRUITER
                        </div>
                      )}
                      {isERG(employee) && (
                        <div className="allyBadge">
                          ERG
                        </div>
                      )}
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {addingRole && (
              <div className="addingRole">
                <div className="modal">
                  <h3>Add Role</h3>

                  <form onSubmit={(e)=>{
                    e.preventDefault();
                    addRole();
                  }}>
                    <div className="form-group">
                      <label htmlFor="roleName">Role Name</label>
                      <input onChange={(e) => {
                      setNewRoleName(e.target.value);
                    }} type="text"></input>
                    </div>

                    <div className="form-group">
                      <label htmlFor="roleType">Type</label>
                      <select 
                        onChange={(e) => {
                          setNewRoleType(e.target.value);
                        }}>
                        <option selected disabled value="">Select Role Type</option>
                        <option value="FRONT_END">Front End</option>
                        <option value="BACK_END">Back End</option>
                        <option value="FULL_STACK">Full Stack</option>
                        <option value="DEVOPS">Devops</option>
                        <option value="MOBILE">Mobile</option>
                        <option value="DATA_SCIENCE">Data Science</option>
                        <option value="DATA_ENGINEERING">Data Engineering</option>
                        <option value="QA">QA</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <span className="checkbox">
                        <label htmlFor="suppressAutomaticSuggestions">Suppress Automatic Match Emails?</label>
                        <input
                          onChange={() => {
                            setNewRoleSuppressAutomaticSuggestions(!newRoleSuppressAutomaticSuggestions);
                          }}
                          type="checkbox"
                          id="suppressAutomaticSuggestions"
                          checked={newRoleSuppressAutomaticSuggestions}
                        />
                      </span>
                    </div>

                    <div className="form-group">
                      <label htmlFor="jobPostUrl">Job Post URL</label>
                      <input onChange={(e) => {
                      setNewRoleJobPostUrl(e.target.value);
                    }} type="text"></input>
                    </div>

                    <div className="form-group">
                      <div className="inputBox tiptap">
                        <label>Job Description</label>
                        <TipTap
                          data={newRoleJobDescription}
                          editing={false}
                          update={(data) => {
                            setNewRoleJobDescription(data);
                          }}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="roleName">Role Notes</label>
                      <textarea onChange={(e) => {
                      setNewRoleNotes(e.target.value);
                    }}></textarea>
                    </div>

                    <div className="form-group">
                      <label htmlFor="roleName">Salary Band</label>
                      <div className="band">
                        <input onChange={(e) => {
                        setNewRoleMinSalary(e.target.value);
                    }} placeholder="Min" type="text"></input>
                        <p>-</p>
                        <input onChange={(e) => {
                        setNewRoleMaxSalary(e.target.value);
                    }} placeholder="Max" type="text"></input>
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="roleName">Experience Level</label>
                      <div className="band">
                        <input onChange={(e) => {
                        setNewRoleMinExperience(e.target.value);
                    }} placeholder="Min" type="text"></input>
                        <p>-</p>
                        <input onChange={(e) => {
                        setNewRoleMaxExperience(e.target.value);
                    }} placeholder="Max" type="text"></input>
                      </div>
                    </div>

                    <SkillsSelector
                      setData={(data) => {
                        setNewSkills(data);
                      }}
                      data={newSkills}
                      url={"technologies"}
                      showRequired={true}
                      description={
                        "Please enter the skills required for the company"
                      }
                    />

                    <button className="btn large" type="submit">Add Role</button>

                  </form>
                </div>
                <div onClick={()=>{
                  setAddingRole(false);
                }} className="skrim"></div>
              </div> 
            )} 

      {savingRole && (
        <div className="addingRole">
          <SaveRole role={roleToEdit} onDoneSaving={handleDoneSaving}/>
          <div onClick={()=>{
            setSavingRole(false);
          }} className="skrim"></div>
        </div>
      )}

    </div>
  );
}
