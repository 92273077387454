import React, { useState, useEffect } from "react";
import "../CSS/Modal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default function FollowButton(props) {
  return (
    <div className="modalComponent">
      <div className="modal">
        <h2>{props.headline}</h2>
        <FontAwesomeIcon
          icon={faTimes}
          onClick={(e) => {
            props.close();
          }}
        />
        <div className="content">{props.content}</div>

        {(props.cancel || props.success) && 
            <div className="actions">
                {props.cancel &&
                    <button onClick={(e)=>{
                        props.cancel();
                    }} className="btn btn-medium">
                        {props.cancelText}
                    </button>
                }

                {props.confirm &&
                    <button onClick={(e)=>{
                        props.confirm();
                    }} className="btn btn-large">
                        {props.confirmButtonText}
                    </button>
                }
            </div>
        }
      </div>
      <div
        onClick={(e) => {
          props.close();
        }}
        className="skrim"
      ></div>
    </div>
  );
}
