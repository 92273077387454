import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "../CSS/Collective/home.scss";
import Logo from "../images/collective-white.png";
import Captain from "../images/captain-emoji.png";
import { useHistory, Link } from "react-router-dom";
import Match from "./Match";
import { getAuthenticatedUserCollectives } from "../LocalStorageUtil.js";
import { capitalizeLocation } from "../helpers";
import { useIsRecruiter } from "../RoleHelper";
import { useUserContext } from "../UserContext";

const CollectiveHome = (props) => {
  const { loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const userObject = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();
  const [collective, setCollective] = useState({});
  const [technologies, setTechnologies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [matches, setMatches] = useState([]);
  const collectiveIds = useState(() => {
    const collectives = getAuthenticatedUserCollectives();
    return collectives && collectives.length > 0 ? collectives.map(c => c.collectiveId) : [];
  });
  const isRecruiter = useIsRecruiter();
  const collectives = useUserContext().collectives;

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });

  const getCollectiveInfo = async () => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      loginWithRedirect();
    }

    const collectiveId = getAuthenticatedUserCollectives()[0].collectiveId;

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-collectives?collectiveId=${collectiveId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();

    setCollective(data);

    const techResponse = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-collective-technologies`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const tech = await techResponse.json();
    setTechnologies(tech);
  };

  const getMatches = async() => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      console.log(error);
    }

    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    let response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-collectives/matches`,
      {
        method: "GET",
        headers,
      }
    );

    let matches = await response.json();
    setMatches(matches.content);

    if (matches.content.filter(m => m.candidateAccepted == null).length > 0) {
      history.push({
        pathname: '/c/match-review',
        state: {
          matchIndex: 0,
          matches: matches.content,
          matchCount: matches.content.length
        }
      })
    }
  }

  const getCompanies = async () => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      loginWithRedirect();
    }

    let url = `${process.env.REACT_APP_URL_JAVA}/company-collectives?page=0&size=1000`;
    for (let i = 0; i < getAuthenticatedUserCollectives().length; i++) {
      url += `&collectiveId=${getAuthenticatedUserCollectives()[i].collectiveId}`;
    }

    const response = await fetch(
      url,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();

    setCompanies(data.content);
  };

  const onMatchClick = (index) => {
    history.push({
      pathname: '/c/match-review',
      state: {
        matchIndex: index,
        matches: matches,
        matchCount: matches.length
      }
    })
  };

  useEffect(() => {
    getCollectiveInfo();
    getMatches();
    getCompanies();
  }, [isRecruiter, collectives]);

  if (!userObject) {
    return <div>Loading...</div>;
  }

  return (
    <div className="homeContainer">
      {collective.status === 'PENDING' && (
        <div className="pending">
          Profile pending review. A member of the Hearty team will be touch shortly
        </div>
      )}
      <div className="collectiveHome">
        <div className="sidebar">
          <div className="top">
            <div className="logo">
              
                <img src={userObject.external_profile.headerImage ? userObject.external_profile.headerImage : Logo} alt="logo" />
            </div>
            <div className="profile-img-edit">
              <div className="profile-img">
                <Link to={`/user/${userObject.external_profile.customUrl}`}>
                  <img src={userObject.external_profile.image? userObject.external_profile.image : Captain} alt="profile" />
                </Link>
              </div>
            </div>
            <h2 className="name">
              <Link to={`/user/${userObject.external_profile.customUrl}`}>
                <span>{userObject.name}</span>
              </Link>
            </h2>
            {collective.collectiveId !== 1 && (
              <>
              <div className="headline">
                <span>{userObject.external_profile.headline}</span>
              </div>
              <div className="location">
                <span>{capitalizeLocation(collective.location)}</span>
              </div>
              {userObject.area_of_expertise && (
              <div className="expertise">
                <label>Area of Expertise:</label>
                <div>{userObject.area_of_expertise}</div>
              </div>
              )}
              </>
            )}
            {collective.collectiveId === 1 && (
              <>
              <div className="basic-info">
                {(collective.status === "ACTIVE" || collective.status === "INACTIVE") && (
                  <div>Status: {collective.status === "INACTIVE" ? "Inactive" : "Active"}</div>
                )}
                <div>Years of Experience: {collective.experience}</div>
                <div>Minimum Salary: {formatter.format(collective.salary)}</div>
                <div className="sponsorship">{!collective.sponsorshipRequired && (<>No </>)}Sponsorship Required</div>
              </div>
              <div className="technologies">
                <h4>Technologies</h4>
                {
                  technologies.map((t) => {
                    return (
                      <div className="technology">
                        <span className="name">{t.technologyName}</span>
                        <div className="bar" title={t.score}>
                          <div className="bar-item" style={{width: (t.score * 20) + '%'}}>&nbsp;</div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
              </>
            )}
              <div className="edit-profile">
                {(collectiveIds.length === 1 && collectiveIds[0] === 1) ? (
                  <Link to="/collective/user/edit">Edit Profile</Link>
                ) : (
                  <Link to="/profile/edit">Edit Profile</Link>
                )}
              </div>
          </div>
        </div>

        <div className="matches">
          <h4 className="match-title">Matches</h4>
          {matches.map((item, i) => {
            return (
                <Match
                  key={item.companyCollectiveUserId}
                  candidateView={true}
                  item={item}
                  onClick={onMatchClick}
                  matchIndex={i}
                />
            );
          })}
          {!matches ||
            (matches.length === 0 && <p>No matches yet. We'll let you know if we find any.</p>)}
      </div>

        <div className="right">
          <div className="partners">
            <div className="title">
              <h4>Hiring Partners</h4>
              <Link to="/collective/companies">View All</Link>
            </div>
          </div>
          <div className="box">
            {companies &&
              companies.map((company) => {
                return (
                  <div className="partner" key={company.customUrl}>
                    <Link to={`/company/${company.customUrl}`}>
                      <img
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = Captain;
                        }}
                        src={company.image}
                        alt="partner"
                      />
                      <h3>{company.name}</h3>
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectiveHome;
