import React, { useEffect, useState, useRef } from "react";
import "../CSS/Collective/Profile.scss";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCode,
  faFileAlt,
  faGraduationCap,
  faMapMarked,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import ReactMarkdown from 'react-markdown';
import moment from "moment";

export default function ProfilePublic(props, ref) {
  let { customUrl } = useParams();
  const [profile, setProfile] = useState({});
  const [tech, setTech] = useState([]);
  const [focus, setFocus] = useState([]);

  useEffect(() => {
    if (customUrl) {
      getUserProfile(customUrl);
    } else {
      setProfile(props.profile);
      populateFocus(props.profile);
      getTechnologies(props.profile.userCollectiveId);
    }
  }, []);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });

  const getUserProfile = async (customUrl) => {
    let headers = {
      "Content-Type": "application/json",
    };

    let response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-collectives/custom-url/${customUrl}`,
      {
        method: "GET",
        headers,
      }
    );

    let data = await response.json();
    setProfile(data);

    populateFocus(data);

    getTechnologies(data.userCollectiveId);
  };

  const populateFocus = async (data) => {
    if (data.focus && data.focus !== '') {
      const focus = data.focus.split(",").map((item) => {
          return item.replace("_", " ");
      });

      setFocus(focus);
    } else {
      setFocus([]);
    }
  }

  const getTechnologies = async (id) => {
    let headers = {
      "Content-Type": "application/json",
    };

    let response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-collective-technologies?userCollectiveId=${id}`,
      {
        method: "GET",
        headers,
      }
    );

    let tech = await response.json();
    setTech(tech);
  };

  const download = async (id) => {
    const download = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-collectives/resume/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // Extract filename from header
    const filename = download.headers.get('content-disposition')
      .split(';')
      .find(n => n.includes('filename='))
      .replace('filename=', '')
      .replaceAll('\"', '')
      .trim()
    ;

    const blob = await download.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
  };

  return (
    <div className="PublicProfile">
      <div className="top">
        <div className="left">
          <div className="avatar">
            <img src={profile?.imageUrl} alt="avatar" />
          </div>
          <div className="info">
            <h3>{profile.userUsername}</h3>
            <h4>
              {profile.experience} years of experience
              {profile.tenure && (
                <span> - {profile.tenure?.replace("_", "-")} years in role</span>
              )}
            </h4>
            {profile.location && (
              <h4>
                <FontAwesomeIcon icon={faMapMarked} /> {profile.location}
              </h4>
            )}
            {profile.education && (
            <h4>
              <FontAwesomeIcon icon={faGraduationCap} /> {profile.education.replace("_", " ")}
            </h4>
            )}
            {profile.industry && (
            <h4>
              <FontAwesomeIcon icon={faBuilding} /> {profile.industry}
            </h4>
            )}
          </div>
        </div>
        <div className="right">
          {props.isAdmin && (
            <h4>{profile.lastContactDate ? "Last Contacted: " + moment(profile.lastContactDate).format("M/d/YY") : ""}</h4>
          )}
        </div>
      </div>
      <div className="tech">
        <h2>Profile</h2>
        <div className="content">
          <div className="left">
            <h2>Technologies</h2>

            <div className="technologies">
              {tech.map((item) => {
                return (
                  <div className={`tech-item opacity-${item.score}`} title={item.score} key={item.technologyName}>
                    <p>{item.technologyName}</p>
                  </div>
                );
              })}
            </div>
            <h2>Links</h2>
            {profile.resumeKey && (
              <div className='resume-download'
                onClick={() => {
                  download(profile.customUrl);
                }}
              >
                <FontAwesomeIcon icon={faFileAlt} title="Resume" />
              </div>
            )}

            {profile.github && (
              <a href={profile.github} target='_blank'>
                <FontAwesomeIcon icon={faGithub} title="Github" />
              </a>
            )}

            {profile.linkedinUrl && (
              <a href={profile.linkedinUrl} target='_blank'>
                <FontAwesomeIcon icon={faLinkedin} title="LinkedIn" />
              </a>
            )}

            {profile.videoUrl && (
              <a href={profile.videoUrl} target='_blank'>
                <FontAwesomeIcon icon={faVideo} title="Video" />
              </a>
            )}

            {profile.codeAssessmentUrl && (
              <a href={profile.codeAssessmentUrl} target='_blank'>
                <FontAwesomeIcon icon={faCode} title="Code Assessment" />
              </a>
            )}
          </div>
          <div className="right">
            {focus.length > 0 && (
              <>
              <h2>Focus</h2>
              <div className="focus">
                {focus.map((item) => {
                  return <p key={item}>{item}</p>;
                })}
              </div>
              </>
            )}
            <h2>Salary Expectations</h2>
            <p>{formatter.format(profile?.salary)}</p>
            <h2>TLDR</h2>
            <ReactMarkdown>{profile?.tldr}</ReactMarkdown>
            {props.isAdmin && (
              <>
              <h2>Internal Notes</h2>
              <p dangerouslySetInnerHTML={{
                __html: profile?.internalNotes
              }} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
