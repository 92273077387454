import { Link } from "react-router-dom";
import ProfileCardHeader from "./ProfileCardHeader";
import "../CSS/ProfileCard.scss";

export default function ProfileCard(props) {
    const profile = props.profile;
    const status = props.status;
    const compact = props.compact || false;

    return (
        <div key={profile.id} className="profile-card">
            <ProfileCardHeader
                id={profile.id}
                name={profile.name}
                customUrl={profile.customUrl}
                image={profile.image}
                headline={profile.headline}
                location={profile.location}
                status={status}
                badges={props.badges}
            />
            {!compact && (
                <>
                {profile.jobFunctions.length > 0 && (
                    <div className="functions">
                        <span className="left">Function</span>
                        <span className="right">
                            {profile.jobFunctions.map((jobFunction) => (
                                <div key={jobFunction.id} className="function">{jobFunction.name}</div>
                            ))}
                        </span>
                    </div>
                )}
                {profile.workHistory.length > 0 && (
                    <div className="experiences">
                        <span className="left">Experience</span>
                        <span className="right">
                            {profile.workHistory.slice(0,2).map((experience) => (
                                <div key={experience.title + experience.company} className="experience">
                                    {experience.title} @ {experience.company} ﹒ <label className="years">{experience.startYear} - {experience.endYear || "Present"}</label>
                                </div>
                            ))}
                            {profile.workHistory.length > 2 && (
                                <div className="more">
                                    <Link to={`/user/${profile.customUrl}`} target="_blank">
                                        <label className="moreLabel">{profile.workHistory.length - 2} more</label>
                                    </Link>
                                </div>
                            )}
                        </span>
                    </div>
                )}
                {profile.skills.length > 0 && (
                    <div className="skills">
                        <span className="left">Skills</span>
                        <span className="right">
                            <div className="skillList">
                            {profile.skills.slice(0, 5).map((skill) => (
                                <div key={skill.name} className="skill">{skill.name}</div>
                            ))}
                            </div>
                            {profile.skills.length > 5 && (
                                <div className="more">
                                    <Link to={`/user/${profile.customUrl}`} target="_blank">
                                        <label className="moreLabel">{profile.skills.length - 5} more</label>
                                    </Link>
                                </div>
                            )}
                        </span>
                    </div>
                )}
                </>
            )}
        </div>
    )
}