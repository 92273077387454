import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "../CSS/FAQ.scss";

export default function Suggestion(props) {
  const [showAnswer, setShowAnswer] = useState(false);

  return (
    <div onClick={()=>{
        setShowAnswer(!showAnswer)
    }} className="faq">
        {showAnswer ? (
            <FontAwesomeIcon icon={faMinus} />
         ) : (
            <FontAwesomeIcon icon={faPlus} />
         )}
      <div className="question">
          <h3>{props.question}</h3>
      </div>
      {showAnswer && (
      <div className="answer">
        <p dangerouslySetInnerHTML={{__html: props.answer}}></p>
      </div>
      )}
    </div>
  );
}
