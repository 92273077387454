import React, { useState, useEffect } from "react";
import "../CSS/Salary.scss";
import logo from "../images/collective-white.png";
import { useAuth0 } from "@auth0/auth0-react";
import { Helmet } from "react-helmet";
import SkillsSelector from "../components/SkillsSelector";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { Link } from "react-router-dom";
import Signup from "../components/Signup";

export default function CandidateOnboarding(props) {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [isDeveloper, setIsDeveloper] = useState(props.isDeveloper || false);

  const [experience, setExperience] = useState(null);
  const [salary, setSalary] = useState(null);
  const [city, setCity] = useState(false);
  const [errors, setErrors] = useState([]);
  const [stepList, setStepList] = useState([]);
  const [step, setStep] = useState(1);
  const [stepIndex, setStepIndex] = useState(0);
  const [github, setGithub] = useState("");
  const [resumeUpload, setResumeUpload] = useState(false);
  const [userCollectiveId, setUserCollectiveId] = useState(0);
  const [skills, setSkills] = useState([]);
  const [sponsorship, setSponsorship] = useState(null);
  const [education, setEducation] = useState(null);
  const [specialties, setSpecialties] = useState([]);
  const [matches, setMatches] = useState([]);
  const [matchCount, setMatchCount] = useState(null);
  const [name, setName] = useState(null);
  const [showLogin, setShowLogin] = useState(false);

  const Steps = {
    Years_Experience: 1,
    Technologies: 2,
    Proficiency: 3,
    Zip: 4,
    Education: 5,
    Specialty: 6,
    Salary: 7,
    Sponsorship: 8,
    Resume: 9,
    Matches: 10,
  };

  const developerSteps = [
    Steps.Years_Experience,
    Steps.Technologies,
    Steps.Proficiency,
    Steps.Zip,
    Steps.Education,
    Steps.Specialty,
    Steps.Salary,
    Steps.Sponsorship,
  ];

  const otherSteps = [
    Steps.Years_Experience,
    Steps.Zip,
    Steps.Education,
    Steps.Salary,
    Steps.Sponsorship,
  ];

  useEffect(() => {
    if (isDeveloper) {
      setStepList(developerSteps);
    } else {
      setStepList(otherSteps);
    }
  }, [isDeveloper]);


  useEffect(() => {
    const header = document.querySelector("nav");
    header.style.display = "none";

    const query = new URLSearchParams(props.location.search);
    setName(query.get('name'));

    return () => {
      const header = document.querySelector("nav");
      header.style.display = "block";
    };
  }, []);

  useCalendlyEventListener({
    onEventScheduled: (e) => next(),
  });

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0
  });

  const formatExperience = (exp) => {
    return exp.replace(/[^0-9]+/g, "");
  };

  const formatSalary = (salary) => {
    return formatter.format(stripSalary(salary));
  };

  const stripSalary = (salary) => {
    return salary.replace(/[^0-9]+/g, "");
  };

  const setSpecialty = (e) => {
    const index = specialties.indexOf(e);
    if (index > -1) {
      const newSpecilities = specialties.filter((item) => {
        if (item === e) {
          return false;
        }

        return true;
      });
      setSpecialties(newSpecilities);
    } else {
      const newSpecilties = [...specialties, e];
      setSpecialties(newSpecilties);
    }
  };

  const uploadResume = async (file) => {
    const selectedFile = file[0];
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", selectedFile, selectedFile.name);

    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    fetch(`${process.env.REACT_APP_URL_JAVA}/user-collectives/resume`, {
      method: "POST",
      enctype: "multipart/form-data",
      contentType: false,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        }

        throw new Error("Error uploading resume");
      })
      .then((data) => {
        setResumeUpload(true);
      })
      .catch((error) => {});
  };

  const updateUserCollective = async (e) => {
    setErrors([]);

    if (github === "") {
      return;
    }

    const data = {
      github: github
    };

    let token = false;
    let headers = {};

    token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-collectives/${userCollectiveId}`,
      {
        headers,
        body: JSON.stringify(data),
        method: "PATCH",
      }
    );
    const sent = await response.json();
  };

  const save = async (e) => {
    const data = {
      collectiveId: 1,
      salary: stripSalary(salary),
      experience: experience,
      zip: city,
      technologies: skills,
      userTitle: "Developer",
      role:'TALENT',
      status: 'ACTIVE',
      education: education,
      focus: specialties.join(","),
      sponsorshipRequired: sponsorship
    };

    if (isAuthenticated) {
      let token = false;
      let headers = {};
      try {
        token = await getAccessTokenSilently({
          audience: `https://api-v2.behearty.co`,
          scope: "read:current_user offline_access",
        });

        headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
      } catch (error) {
        headers = {
          "Content-Type": "application/json",
        };
      }

      const response = await fetch(
        `${process.env.REACT_APP_URL_JAVA}/user-collectives`,
        {
          headers,
          body: JSON.stringify(data),
          method: "POST",
        }
      );
      const sent = await response.json();
      localStorage.removeItem('CandidateProfile')
      localStorage.removeItem('CandidateProfileSource')
      setUserCollectiveId(sent.userCollectiveId);
    } else {
      data.status = 'PENDING';
      data.matchResponses = [];
      localStorage.setItem('PublicCandidateProfile', JSON.stringify(data));
    }
  };

  const getMatches = async() => {

    if (sponsorship) {
      setMatches([]);
      setMatchCount(0);
      return;
    }

    let headers = {
      "Content-Type": "application/json",
    };

    let queryParams = "";

    if (isAuthenticated) {
      let token = false;
      try {
        token = await getAccessTokenSilently({
          audience: `https://api-v2.behearty.co`,
          scope: "read:current_user offline_access",
        });
      } catch (error) {
        console.log(error);
      }

      if (token) {
        headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
      }
    } else {
      queryParams = `?minSalary=${stripSalary(salary)}&yearsExperience=${experience}`

      skills.forEach((s) => {
        queryParams += `&technologyId=${s.id}`
      });

      specialties.forEach((s) => {
        queryParams += `&focus=${s}`
      });
    }

    let response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-collectives/matches${queryParams}`,
      {
        method: "GET",
        headers,
      }
    );

    let matches = await response.json();
    setMatches(matches.content);
    setMatchCount(matches.totalElements);
  }

  const next = () => {
    let newStepIndex = stepIndex + 1;

    if (newStepIndex > stepList.length - 1) {
      // take to account creation
      setShowLogin(true);
    }

    setStep(stepList[newStepIndex]);
    setStepIndex(newStepIndex);
  };

  const back = () => {
    let newStepIndex = stepIndex - 1;

    setStep(stepList[newStepIndex]);
    setStepIndex(newStepIndex);
  };

  return (
    <div className="salary">
      {errors.length > 0 &&
        errors.map((error) => {
          return <div className="error">{error}</div>;
        })}

      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Hearty Collective`}</title>
        <meta name="description" content="Helping the best talent find new jobs" />
        <meta property="og:title" content="Hearty Collective" />
        <meta
          property="og:description"
          content="Helping the best talent find new jobs"
        />
        <meta
          property="og:image"
          content="https://www.hearty.xyz/images/collective.png"
        />
        <meta property="og:url" content={window.location} />

        <meta property="twitter:title" content="Hearty Collective" />
        <meta
          property="twitter:description"
          content="Helping the best talent find new jobs"
        />
        <meta
          property="twitter:image"
          content="https://www.hearty.xyz/images/collective.png"
        />
        <meta name="twitter:card" content="summary_large_image"></meta>
      </Helmet>

      <div className="salary-header">
        <img src={logo} alt="logo" className="logo" />
      </div>
      <div className="bar">
        <div className={`bar-item step-${step}`}></div>
      </div>
      {step > 1 && (
        <span
          className="back"
          onClick={() => {
            back();
            setErrors([]);
          }}
        >
          {" "}
          Back
        </span>
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          save();
        }}
      >
        {step === 1 && (
          <div className="formItem">
            <div className="left">
              <h4>How many years of relevant professional experience do you have?</h4>
            </div>

            <div className="right">
            <input
                onChange={(e) => {
                  setExperience(formatExperience(e.target.value));
                }}
                type="number"
                value={experience}
              ></input>
            </div>
            <button
              type="button"
              onClick={() => {
                if (!experience) {
                  setErrors(["Please enter your years of experience"]);
                  return;
                }
                next();
                setErrors([]);
              }}
            >
              Next
            </button>
          </div>
        )}

        {step === 2 && (
          <div className="formItem languages">
            <div className="left">
              <h4>What technologies are you proficient in? </h4>
            </div>

            <div className="right">
              <SkillsSelector
                setData={(data) => {
                  setSkills(data);
                }}
                data={skills}
                showCloud={true}
                url={"technologies"}
                searchText={"Search Technologies"}
              />
            </div>
            <button
              type="button"
              onClick={() => {
                if (skills.length === 0 || !skills) {
                  setErrors(["Please choose at least one technology"]);
                  return;
                }
                next();
                setErrors([]);
              }}
            >
              Next
            </button>
          </div>
        )}

        {step === 3 && (
          <div className="formItem languages rating">
            <div className="left">
              <h4>How proficient are you in each skill? (1 minimal experience, 5 expert) </h4>
            </div>

            <div className="right">
              {skills.map((skill) => {
                return (
                  <div className="input" key={skill.id}>
                    <h3>{skill.name}</h3>
                    <div className="rating">
                      <a className={`${skill.score === 1 ? 'active': ''}`} onClick={() => {
                        const newSkills = skills.map((s) => {
                          if (s.id === skill.id) {
                            s.score = 1;
                          }
                          return s;
                        });
                        setSkills(newSkills);
                        return ;

                      }}>1</a>

                      <a className={`${skill.score === 2 ? 'active': ''}`} onClick={() => {
                        const newSkills = skills.map((s) => {
                          if (s.id === skill.id) {
                            s.score = 2;
                          }
                          return s;
                        });
                        setSkills(newSkills);
                        return ;

                      }}>2</a>

                      <a className={`${skill.score == 3 ? 'active': ''}`} onClick={() => {
                        const newSkills = skills.map((s) => {
                          if (s.id === skill.id) {
                            s.score = 3;
                          }
                          return s;
                        });
                        setSkills(newSkills);
                        return ;

                      }}>3</a>

                      <a className={`${skill.score === 4 ? 'active': ''}`} onClick={() => {
                        const newSkills = skills.map((s) => {
                          if (s.id === skill.id) {
                            s.score = 4;
                          }
                          return s;
                        });
                        setSkills(newSkills);
                        return ;

                      }}>4</a>

                      <a className={`${skill.score === 5 ? 'active': ''}`} onClick={() => {
                        const newSkills = skills.map((s) => {
                          if (s.id === skill.id) {
                            s.score = 5;
                          }
                          return s;
                        });
                        setSkills(newSkills);
                        return ;
                      }}>5</a>
                    </div>
                  </div>
                )}
              )}
            </div>
            <button
              type="button"
              onClick={() => {
                const filter = skills.filter((skill) => {
                  if(!skill.score || skill.score <= 0) {
                    return true;
                  }
                  return false;
                });

                if(filter.length > 0) {
                  setErrors(["Please rate all skills"]);
                  return;
                }

                setErrors([]);
                next();
              }}
            >
              Next
            </button>
          </div>
        )}

        {step === 4 && (
          <div className="formItem">
            <div className="full">
              <h3>Where do you live</h3>
              <p>Enter your 5 digit zip code.</p>

              <input
                onChange={(e) => {
                  setCity(e.target.value);
                }}
                type="number"
                value={city}
                className="zipCode"
              ></input>
            </div>
            <button
              type="button"
              onClick={() => {
                if (!city) {
                  setErrors([
                    "Please enter your zip code",
                  ]);
                  return;
                }
                if (city.length > 5) {
                  setErrors(["Please enter a 5 digit zip code"]);
                  return;
                }
                next();
                setErrors([]);
              }}
            >
              Next
            </button>
          </div>
        )}

        {step === 5 && (
          <div className="formItem">
            <div className="left">
              <h4>What is your highest education level?</h4>
            </div>

            <div className="right">
              <div className="input">
                <input
                  onChange={() => {
                    setEducation("HIGH_SCHOOL");
                  }}
                  value={"HIGH_SCHOOL"}
                  type="radio"
                  checked={education === "HIGH_SCHOOL"}
                  name="education"
                  id="high-school"
                />
                <span><label htmlFor="high-school">High School</label></span>
              </div>
              <div className="input">
                <input
                  onChange={() => {
                    setEducation("SOME_COLLEGE");
                  }}
                  value={"SOME_COLLEGE"}
                  type="radio"
                  checked={education === "SOME_COLLEGE"}
                  name="education"
                  id="some-college"
                />
                <span><label htmlFor="some-college">Some College</label></span>
              </div>
              <div className="input">
                <input
                  onChange={() => {
                    setEducation("BOOTCAMP");
                  }}
                  value={"BOOTCAMP"}
                  checked={education === "BOOTCAMP"}
                  type="radio"
                  name="education"
                  id="bootcamp"
                />
                <span><label htmlFor="bootcamp">Bootcamp</label></span>
              </div>
              <div className="input">
                <input
                  onChange={() => {
                    setEducation("BACHELORS");
                  }}
                  value={"BACHELORS"}
                  checked={education === "BACHELORS"}
                  type="radio"
                  name="education"
                  id="bachelors"
                />
                <span><label htmlFor="bachelors">Bachelors Degree</label></span>
              </div>

              <div className="input">
                <input
                  onChange={() => {
                    setEducation("MASTERS");
                  }}
                  value={"MASTERS"}
                  checked={education === "MASTERS"}
                  type="radio"
                  name="education"
                  id="masters"
                />
                <span><label htmlFor="masters">Masters Degree</label></span>
              </div>

              <div className="input">
                <input
                  onChange={() => {
                    setEducation("DOCTORATE");
                  }}
                  value={"DOCTORATE"}
                  checked={education === "DOCTORATE"}
                  type="radio"
                  name="education"
                  id="doctorate"
                />
                <span><label htmlFor="doctorate">Doctorate</label></span>
              </div>
            </div>
            <button
              type="button"
              onClick={() => {
                if (!education || education === "") {
                  setErrors(["Please choose your highest education level"]);
                  return;
                }
                next();
                setErrors([]);
              }}
            >
              Next
            </button>
          </div>
        )}

        {step === 6 && (
          <div className="formItem">
            <div className="left">
              <h4>
                What types of jobs are you interested in?
              </h4>
            </div>

            <div className="right">
              <div className="input">
                <input
                  onChange={() => {
                    setSpecialty("FRONT_END");
                  }}
                  value={"FRONT_END"}
                  checked={specialties.indexOf("FRONT_END") > -1}
                  type="checkbox"
                  id="front-end"
                />
                <span><label htmlFor="front-end">Front End</label></span>
              </div>

              <div className="input">
                <input
                  onChange={() => {
                    setSpecialty("BACK_END");
                  }}
                  value={"BACK_END"}
                  checked={specialties.indexOf("BACK_END") > -1}
                  type="checkbox"
                  id="back-end"
                />
                <span><label htmlFor="back-end">Back End</label></span>
              </div>

              <div className="input">
                <input
                  onChange={() => {
                    setSpecialty("FULL_STACK");
                  }}
                  value={"FULL_STACK"}
                  checked={specialties.indexOf("FULL_STACK") > -1}
                  type="checkbox"
                  id="full-stack"
                />
                <span><label htmlFor="full-stack">Full Stack</label></span>
              </div>

              <div className="input">
                <input
                  onChange={() => {
                    setSpecialty("MOBILE");
                  }}
                  value={"MOBILE"}
                  checked={specialties.indexOf("MOBILE") > -1}
                  type="checkbox"
                  id="mobile"
                />
                <span><label htmlFor="mobile">Mobile</label></span>
              </div>

              <div className="input">
                <input
                  onChange={() => {
                    setSpecialty("DEVOPS");
                  }}
                  value={"DEVOPS"}
                  checked={specialties.indexOf("DEVOPS") > -1}
                  type="checkbox"
                  id="devops"
                />
                <span><label htmlFor="devops">Devops</label></span>
              </div>

              <div className="input">
                <input
                  onChange={() => {
                    setSpecialty("DATA_SCIENCE");
                  }}
                  value={"DATA_SCIENCE"}
                  checked={specialties.indexOf("DATA_SCIENCE") > -1}
                  type="checkbox"
                  id="data-science"
                />
                <span><label htmlFor="data-science">Data Science</label></span>
              </div>

              <div className="input">
                <input
                  onChange={() => {
                    setSpecialty("DATA_ENGINEERING");
                  }}
                  value={"DATA_ENGINEERING"}
                  checked={specialties.indexOf("DATA_ENGINEERING") > -1}
                  type="checkbox"
                  id="data-engineering"
                />
                <span><label htmlFor="data-engineering">Data Engineering</label></span>
              </div>

              <div className="input">
                <input
                  onChange={() => {
                    setSpecialty("QA");
                  }}
                  value={"QA"}
                  checked={specialties.indexOf("QA") > -1}
                  type="checkbox"
                  id="qa"
                />
                <span><label htmlFor="qa">QA</label></span>
              </div>
            </div>
            <button
              type="button"
              onClick={() => {
                if (!specialties || specialties.length === 0) {
                  setErrors(["Please choose at least one focus"]);
                  return;
                }
                next();
                setErrors([]);
              }}
            >
              Next
            </button>
          </div>
        )}

        {step === 7 && (
          <div className="formItem ">
            <div className="full">
              <h3>What is your minimum salary expectation?</h3>
              <input
                onChange={(e) => {
                  setSalary(formatSalary(e.target.value));
                }}
                value={salary}
                type="text"
              ></input>
              <button
                type="button"
                onClick={() => {
                  if (!salary) {
                    setErrors(["Please enter your salary expectation"]);
                    return;
                  } else if (stripSalary(salary) < 10000 || stripSalary(salary) > 1000000) {
                    setErrors(["Please enter a valid salary"]);
                    return;
                  }
                  next();
                  setErrors([]);
                }}
              >
                Next
              </button>
            </div>
          </div>
        )}

        {step === 8 && (
          <div className="formItem ">
            <div className="full">
              <h3>Do you require sponsorship to work in the US?</h3>
              <div className="input">
                <input
                  onChange={(e) => {
                    setSponsorship(true);
                  }}
                  value={true}
                  type="radio"
                  checked={sponsorship === true}
                  name="sponsorship"
                  id="yesRadio"
                />
                <label for="yesRadio">Yes</label>
              </div>
              <div className="input">
                <input
                  onChange={(e) => {
                    setSponsorship(false);
                  }}
                  value={false}
                  type="radio"
                  checked={sponsorship === false}
                  name="sponsorship"
                  id="noRadio"
                />
                <label for="noRadio">No&nbsp;</label>
              </div>
              <button
                type="button"
                onClick={async() => {
                  await save();
                  next();
                  setErrors([]);
                }}
              >
                Next
              </button>
            </div>
          </div>
        )}

        {step === 9 && (
          <div>
            <div className="formItem">
              <div className="left">
                <h2>
                  Upload your resume
                </h2>
              </div>
              <div className="right">
                <input
                  onChange={(e) => uploadResume(e.target.files)}
                  accept="application/pdf"
                  type="file"
                />
              </div>
              {isDeveloper && (
                <div className="formItem">
                  <div className="left">
                    <h2>
                      Github Profile (if applicable)
                    </h2>
                  </div>
                  <div className="right">
                    <input
                      onChange={(e) => setGithub(e.target.value)}
                      type="text"
                    />
                  </div>
                </div>
              )}
            </div>
            <button
              type="button"
              onClick={() => {
                updateUserCollective();
                next();
                setErrors([]);
              }}
              className="btn large"
            >
              Next
            </button>
      </div>
      )}
      </form>
        
      {step === 10 && (
        <div className="summary">
          <h3>Thank you for completing a profile - we're excited to talk to you!</h3>
          <p>Please schedule some time to talk.</p>
          <a onClick={() => {
              next();
            }}>
            <p>I've already scheduled a time.</p>
          </a>
          <InlineWidget
            url="https://calendly.com/ross-lewellyn/hearty-collective-priority-call-ex?hide_gdpr_banner=1"
            styles={{
              height: '900px'
            }}
          />
        </div>
      )}

      {step === 10 && matchCount === 0 && (
        <div className="match-summary">
          <h3>We don’t have any active roles that match your skills, but our jobs are always changing. We’ll send you an email as soon as we get one we think you’ll like.</h3>
        </div>
      )}

      {step === 10 && matchCount > 0 && (
        <div className="match-summary">
          <h3>Good news! You're a great match for {matchCount} of our open roles</h3>
            <Link to={{ pathname: "/c/match-review", state: { matches: matches, matchCount: matchCount, name: name } }}>
              <button
                type="button"
                className="btn large"
              >
                See Matches
              </button>
            </Link>
        </div>
      )}

      {showLogin && (
        <>
        <div className="modal-container">
          <div className="modal">
              <Signup auth0={props.auth0}/>
          </div>
          <div className="skrim">
          </div>
        </div>
        </>
      )}
    </div>
  );
}
