import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import DefaultImage from "../images/captain-emoji.png";
import { capitalizeLocation } from "../helpers";
import { useAuth0 } from "@auth0/auth0-react";

export default function BookMeetingModal(props) {
    const { getAccessTokenSilently } = useAuth0();
    const [nextAvailableMeeting, setNextAvailableMeeting] = useState(null);

    useEffect(() => {
        getNextAvailableMeeting();
    }, []);

    const formatMeetingDate = (date) => {
        if (!date) {
            return "";
        } else {
            return date.toLocaleString("en-US", {
                month: "long",
                year: "numeric",
            });
        }
    };

    const getNextAvailableMeeting = async() => {
        let token = await getAccessTokenSilently({
          audience: `https://api-v2.behearty.co`,
          scope: "read:current_user offline_access",
        });
        let headers = {
          "Content-Type": "application/json",
        };
    
        if (token) {
          headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          };
        }
    
        fetch(
          `${process.env.REACT_APP_URL_JAVA}/ally-meeting/next-available?allyUserId=${props.userId}`,
          {
            method: "GET",
            headers,
          }
        )
        .then((response) => {
          if (response.ok) {
            return response.text();
          }
        })
        .then((date) => {
          if (date) {
            setNextAvailableMeeting(new Date(date));
          }
        })
      };

      const bookMeeting = async() => {
        let token = await getAccessTokenSilently({
          audience: `https://api-v2.behearty.co`,
          scope: "read:current_user offline_access",
        });
        let headers = {
          "Content-Type": "application/json",
        };
    
        if (token) {
          headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          };
        }
        
        fetch(
          `${process.env.REACT_APP_URL_JAVA}/ally-meeting`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(
              {
                allyUserId: props.userId,
                meetingMonth: nextAvailableMeeting.getMonth() + 1,
                meetingYear: nextAvailableMeeting.getFullYear(),
              }),
          }
        )
          .then((response) => response.json())
          .then(props.meetingBooked());
        };

    return (
        <div className="book-meeting-modal">
            <div className="header">
            <div className="bookLabel">Book a Meeting</div>
            <div className="modalProfile">
                <div className="imageContainer">
                    <img
                        src={props.image + "?w=100"}
                        alt={props.name}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = DefaultImage;
                        }}
                    />
                </div>
                <div className="userInfo">
                <div className="nameLine">
                    <div className="name">{props.name}</div>
                    {props.badges && props.badges.length > 0 && props.badges.map((badge) => (
                        <div className="allyBadge">
                            {badge}
                        </div>
                    ))}
                </div>
                <div className="headline">{props.headline}</div>
                {props.location && (
                    <div className="location">
                    <FontAwesomeIcon icon={faLocationDot} /> {capitalizeLocation(props.location)}
                    </div>
                )}
                </div>
            </div>
            </div>
            <div className="body">
                <div className="scheduleTimeLabel">Schedule Time</div>
                <p>Once you select a time and click "Schedule", and email introduction will be made so that the two of you can find a mutual time to meet.</p>
                <div className="nextAvailable">
                        Next Available: <span className="date">{formatMeetingDate(nextAvailableMeeting)}</span>
                </div>
                <div className="submit">
                    <button className="bookMeetingButton" onClick={() => bookMeeting()}>Schedule</button>
                </div>
            </div>
        </div>
    );
};