import {
  faAngleUp,
  faComment,
  faGlobe,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";

import { faComment as faCommentLight } from "@fortawesome/free-regular-svg-icons";
import HeartyName from "../../components/HeartyName";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import "../../CSS/Leaderboards/Leader.scss";
import hat from "../../images/hat.svg";
import { truncateString } from "../../helpers";

import HeartyAvatar from "../../components/HeartyAvatar";
import { useAuth0 } from "@auth0/auth0-react";

export default function Leader(props, ref) {
  const [showComments, setShowComments] = useState(false);
  const [showRecommendations, setShowRecommendations] = useState(false);

  const [leaderboard, setLeaderboard] = useState({});
  const [leader, setLeader] = useState({});
  const [comments, setComments] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [commentEntry, setCommentEntry] = useState("");
  const [userComment, setUserComment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(false);
  const [noUser, setNoUser] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [askForEmail, setAskForEmail] = useState(false);
  const [animatedClass, setAnimatedClass] = useState(false);
  const [showSocialCapitalRecommend, setShowSocialCapitalRecommend] =
    useState(false);
  const [showSocialCapitalComment, setShowSocialCapitalComment] =
    useState(false);
  const [socialCapitalRecommendScore, setSocialCapitalRecommendScore] =
    useState(1);
  const myRef = useRef(null);
  const { user, getAccessTokenSilently } = useAuth0();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showAdminRemoveModal, setShowAdminRemoveModal] = useState(false);
  const [showOrgDropdown, setShowOrgDropdown] = useState(false);
  const [showCommentDropdown, setShowCommentDropdown] = useState(false);
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    const jsonUser = localStorage.getItem("user");
    const rolesJSON = localStorage.getItem("roles");
    const roles = JSON.parse(rolesJSON);

    if (jsonUser) {
      const user = JSON.parse(jsonUser);
      setLoggedInUser(user);
    }
    if (roles && roles.includes("Admin")) {
      setAdmin(true);
    }

    if (props.leader.scroll) {
      setScroll(props.leader.scroll);
      myRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
      setTimeout(() => {
        setAnimatedClass("animate__animated animate__rubberBand");
      }, 1000);
    }

    setLeaderboard(props.leaderboard);
    setLeader(props.leader);
    setShowComments(false);
  }, [props.leaderboard, props.leader, props.sort]);

  const removeYourself = async () => {
    const token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/leaderboard-recommendation/remove-me?leaderboardId=${props.leaderboard.id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error(response);
      })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const removeAdmin = async () => {
    const token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/external-profiles?url=${leader.userCustomUrl}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const user = await response.json(); // parse JSON

    const deleteObject = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/leaderboard-external-profile?externalProfileId=${user.id}&leaderboardId=${props.leaderboard.id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    await deleteObject.text();

    window.location.reload();
  };

  const downvoteLeader = async (id) => {
    const token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });
    const user = JSON.parse(localStorage.getItem("user"));

    if (!user) {
      setNoUser(true);
      return;
    }

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/leaderboard-recommendation/${id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error(response);
      })
      .then(() => {
        let leaderNew = { ...leader };
        leaderNew.upvoteCount = leader.upvoteCount - 1;
        if (leaderNew.upvoteCount < 0) leaderNew.upvoteCount = 0;
        leaderNew.loggedInUserRecommendationId = 0;
        setLeader(leaderNew);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const upvoteLeader = async () => {
    const token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      setNoUser(true);
      return;
    }

    // You can't recommend yourself
    if (leader.recommendedExternalProfileId == user.External_Profile.id) {
      return false;
    }

    const data = {
      recommendedExternalProfileId: leader.recommendedExternalProfileId,
      leaderboardId: leaderboard.id,
      userId: user.id,
    };

    fetch(`${process.env.REACT_APP_URL_JAVA}/leaderboard-recommendation`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error(response);
      })
      .then((data) => {
        let leaderNew = { ...leader };
        leaderNew.upvoteCount = leader.upvoteCount + 1;
        leaderNew.loggedInUserRecommendationId = data.id;
        setLeader(leaderNew);
        toggleComments(true);
        setShowRecommendations(false);

        if (!data.receivingUserId) {
          setAskForEmail(true);
        } else {
          setShowSocialCapitalRecommend(true);
          setTimeout(() => {
            setShowSocialCapitalRecommend(false);
          }, 2200);
        }
        props.getOnboardingStep();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const toggleComments = async (value) => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (!user) {
      setNoUser(true);
      return;
    }
    if (value) {
      getComments();
      getRecommendations();
    }
    await setShowComments(value);

    setShowRecommendations(false);
    if (showRecommendations) {
      setShowComments(false);
    } else {
      setShowComments(value);
    }
  };

  const getComments = async () => {
    setUserComment({});
    setLoading(true);
    const token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });
    fetch(
      `${process.env.REACT_APP_URL_JAVA}/leaderboard-recommendation/comments?leaderboardId=${leaderboard.id}&externalProfileId=${leader.recommendedExternalProfileId}&page=0&size=20`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error(response);
      })
      .then((data) => {
        setLoading(false);
        const user = JSON.parse(localStorage.getItem("user"));
        if (!data._embedded) {
          setComments([]);
          return;
        }
        const comments = data._embedded.leaderboardRecommendationList.filter(
          (comment) => {
            console.log(comment.user.id, user.id, comment);
            if (
              comment.user.id == user.id &&
              (comment.comment.length > 0) & (comment.comment !== "")
            ) {
              setUserComment(comment);
            }
            return comment.comment && comment.comment !== "";
          }
        );
        setComments(comments);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRecommendations = async () => {
    setUserComment({});
    setLoading(true);
    const token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });
    fetch(
      `${process.env.REACT_APP_URL_JAVA}/external-profiles/${leader.recommendedExternalProfileId}/leaderboard-recommendations?leaderboardId=${leaderboard.id}&size=1000`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error(response);
      })
      .then((data) => {
        setLoading(false);
        const user = JSON.parse(localStorage.getItem("user"));
        if (!data._embedded || data._embedded.iExternalProfileList) {
          setRecommendations([]);
          return;
        }
        setRecommendations(data._embedded.externalProfileUserSearchResultList);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteComment = async (id) => {
    const token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });
    const body = {
      comment: "",
    };
    fetch(
      `${process.env.REACT_APP_URL_JAVA}/leaderboard-recommendation/${id}`,
      {
        method: "PATCH",
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error(response);
      })
      .then((data) => {
        setShowComments(false);
        let leaderNew = { ...leader };
        leaderNew.commentCount = leader.commentCount - 1;
        leaderNew.loggedInUserComment = false;
        setLeader(leaderNew);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const submitComment = async (id) => {
    const token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });
    const body = {
      comment: commentEntry,
    };

    if (!commentEntry || commentEntry === "") return false;

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/leaderboard-recommendation/${id}`,
      {
        method: "PATCH",
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error(response);
      })
      .then((data) => {
        let editing = false;
        const commentsNew = comments.map((comment) => {
          if (comment.id === data.id) {
            editing = true; // We are editing an existing comment
            return data;
          }

          return comment;
        });

        setShowSocialCapitalComment(true);

        setTimeout(() => {
          setShowSocialCapitalComment(false);
        }, 2200);

        // Its a new comment. Add to array and up count.
        if (!editing) {
          commentsNew.push(data);
          let leaderNew = { ...leader };
          leaderNew.commentCount = leader.commentCount + 1;
          setLeader(leaderNew);
        }

        setComments(commentsNew);
        setCommentEntry("");
        setUserComment(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCompanies = (leader) => {
    let companies = [];
    let communities = [];

    if (leader.companyList) {
      const companiesList = leader.companyList.split("<<>>");
      companies = companiesList.reduce((prev, curr) => {
        const split = curr.split("||");
        const company = {
          name: split[0],
          image: split[1],
          url: split[2],
          type: "company",
        };
        prev = [...prev, company];

        return prev;
      }, []);
    }

    if (leader.communityList) {
      const communityList = leader.communityList.split("<>");
      communities = communityList.reduce((prev, curr) => {
        const split = curr.split("||");
        const comm = {
          name: split[0],
          image: split[1],
          url: split[2],
          type: "organization",
        };
        prev = [...prev, comm];

        return prev;
      }, []);
    }

    const all = [...companies, ...communities];

    return all;
  };

  const showCommentForm = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (
      leader.loggedInUserRecommendationId > 0 && // They have recommended
      user && // They are a user
      user.External_Profile && // With an external profile
      leader.recommendedExternalProfileId !== user.External_Profile.id && // Its not the current user
      (!userComment.id || userComment.id === 0) && // They haven't commented yet
      !loading // Its not loading
    ) {
      return true;
    }

    return false;
  };

  const me = JSON.parse(localStorage.getItem("user"));

  const isCaptain = () => {
    if (props.sort === "proximity") return false;
    return leader.rank === 1 && leader.upvoteCount >= 2;
  };

  const code = (
    <div
      ref={myRef}
      key={props.key}
      style={!leader.isUser ? { opacity: ".5" } : {}}
      className={`Leader ${isCaptain() ? "captain" : ""} ${
        me &&
        me.external_profile &&
        leader.userCustomUrl === me.external_profile.customUrl
          ? "isMe"
          : ""
      } ${animatedClass ? animatedClass : ""} ${
        showComments || showRecommendations ? "active" : ""
      }`}
    >
      <div className="main">
        <a
          className={`${isCaptain() ? "captainImageBox" : ""}`}
          href={`/user/${leader.userCustomUrl}`}
        >
          {isCaptain() && <img className="hat" src={hat} />}
          <HeartyAvatar
            hearty={leader.loggedInUserHearty}
            image={leader.image}
          />
          {props.sort !== "proximity" && (
            <span className="rank">#{leader.rank}</span>
          )}
        </a>
        <div className="leaderInfo">
          {isCaptain() && <h2 className="captainBadge">Leaderboard Captain</h2>}
          <h3>
            <HeartyName nonMember={!leader.isUser} name={leader.name} isProven={leader.isProven} />
            {me &&
              me.external_profile &&
              leader.userCustomUrl === me.external_profile.customUrl && (
                <p
                  onClick={(e) => {
                    setShowRemoveModal(true);
                  }}
                  className="removeMyself"
                >
                  Remove Yourself
                </p>
              )}
            {admin && (
              <p
                onClick={(e) => {
                  setShowAdminRemoveModal(true);
                }}
                className="removeMyself"
              >
                Remove Them
              </p>
            )}
          </h3>
          <h4>
            {truncateString(leader.headline, 60)}{" "}
            {showOrgDropdown && (
              <div className="orgDropdown">
                <div className="modal">
                  {getCompanies(leader).map((comp) => {
                    if (comp.type === "company")
                      return (
                        <a href={`/company/${comp.url}`}>
                          <img src={comp.image} /> {comp.name}
                        </a>
                      );
                    return (
                      <a href={`/organization/${comp.url}`}>
                        <img src={comp.image} /> {comp.name}
                      </a>
                    );
                  })}
                </div>
              </div>
            )}
          </h4>
        </div>

        {/* Hack to make the box clickable with z-index */}
        <div
          onClick={(e) => {
            toggleComments(!showComments);
            setShowRecommendations(false);
          }}
          className="clickBox"
        ></div>

        <div
          className={`actions ${props.sort === "proximity" ? "proximity" : ""}`}
        >
          {props.sort === 'proximity' &&
              <div className="proximityScore">
                <FontAwesomeIcon icon={faGlobe} />
                <span>{leader.proximityScore}</span>
              </div>
            }
          <button
            className={`${showComments ? "opened" : ""} ${
              leader.loggedInUserComment ? "active comment" : "comment"
            }`}
            onClick={(e) => {
              toggleComments(!showComments);
            }}
          >
            <div className={`icon ${leader.commentCount == "0" ? "zero" : ""}`}>
              {leader.commentCount == "0" && (
                <FontAwesomeIcon icon={faCommentLight} />
              )}

              {leader.commentCount != "0" && (
                <FontAwesomeIcon icon={faComment} />
              )}
            </div>{" "}
            {leader.commentCount}
          </button>

          <button
            onClick={(e) => {
              if (leader.loggedInUserRecommendationId > 0) {
                downvoteLeader(leader.loggedInUserRecommendationId);
              } else {
                upvoteLeader();
              }
            }}
            className={
              leader.loggedInUserRecommendationId > 0
                ? "active upvote"
                : "upvote"
            }
          >
            <div className="icon">
              <FontAwesomeIcon icon={faHeart} />
            </div>{" "}
            {leader.upvoteCount}
            {showSocialCapitalRecommend && (
              <div className="socialCapital">
                <span>+{socialCapitalRecommendScore}</span>
              </div>
            )}
          </button>
        </div>
      </div>

      {(showComments || showRecommendations) && (
        <div className="tabs">
          <span
            onClick={(e) => {
              setShowRecommendations(false);
              setShowComments(true);
            }}
            className={`${showComments ? "active" : ""}`}
          >
            <FontAwesomeIcon icon={faComment} /> Comments ({comments.length})
          </span>
          <span
            onClick={(e) => {
              setShowRecommendations(true);
              setShowComments(false);
            }}
            className={`${showRecommendations ? "active" : ""}`}
          >
            <FontAwesomeIcon icon={faHeart} />
            Recommendations ({recommendations.length})
          </span>
        </div>
      )}
      {showComments && (
        <div className="comments">
          {!leader.loggedInUserRecommendationId && (
            <p className="firstRecommend">
              If you’d like to leave a comment, please give {leader.name} a
              recommendation.
            </p>
          )}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submitComment(leader.loggedInUserRecommendationId);
            }}
            className="comment"
          >
            <textarea
              onChange={(e) => {
                setCommentEntry(e.target.value);
              }}
              disabled={!showCommentForm()}
              value={commentEntry}
              placeholder="Leave a comment"
            />

            {showSocialCapitalComment && (
              <div className="socialCapital">
                <span>+5</span>
              </div>
            )}

            {userComment.id === 0 ? (
              <button disabled={!showCommentForm()} type="submit" type="submit">
                Update
              </button>
            ) : (
              <button disabled={!showCommentForm()} type="submit" type="submit">
                Post
              </button>
            )}
          </form>

          {comments.map((comment) => {
            const user = JSON.parse(localStorage.getItem("user"));

            return (
              <div key={comment.id} className="comment">
                <HeartyAvatar
                  hearty={false}
                  image={comment.user.external_profile.image}
                />
                <div class="commentInfo">
                  <h3>
                    <HeartyName
                      name={comment.user.external_profile.name}
                      isProven={comment.user.proven}
                    />{" "}
                    &middot;{" "}
                    <span>
                      {moment(comment.createdAt).format("MM/DD/YYYY")}
                    </span>
                  </h3>
                  <p>{comment.comment}</p>
                  {comment.user.id == user.id && (
                    <div className="commentActions">
                      <span
                        onClick={(e) => {
                          setUserComment({ id: 0 });
                          setCommentEntry(comment.comment);
                        }}
                        className="edit"
                      >
                        Edit
                      </span>

                      <span
                        onClick={(e) => {
                          deleteComment(comment.id);
                        }}
                        className="delete"
                      >
                        Delete
                      </span>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}

      {showRecommendations && (
        <div className="recommendations">
          {recommendations.map((recommendation) => {
            return (
              <div class="recommendation">
                <a href={`/user/${recommendation.customUrl}`}>
                  <HeartyAvatar
                    hearty={recommendation.loggedInUserHearty}
                    image={recommendation.image}
                  />
                </a>
                <h3>
                  <HeartyName
                    name={recommendation.name}
                    isProven={recommendation.isProven}
                    lists={getCompanies(recommendation)}
                  />
                </h3>
              </div>
            );
          })}
        </div>
      )}

      {showRemoveModal && (
        <div className="removeModal">
          <div className="content">
            <h3>Are You Sure?</h3>
            <p>
              This will remove you and your {recommendations.count}{" "}
              recommendations from this leaderboard. Are you sure you want to do
              that?
            </p>
            <div className="actions">
              <button
                onClick={(e) => {
                  removeYourself();
                }}
                className="btn medium"
              >
                Yes
              </button>
              <button
                onClick={(e) => {
                  setShowRemoveModal(false);
                }}
                className="btn large"
              >
                Cancel
              </button>
            </div>
          </div>
          <div
            onClick={(e) => {
              setShowRemoveModal(false);
            }}
            className="skrim"
          ></div>
        </div>
      )}

      {showAdminRemoveModal && (
        <div className="removeModal">
          <div className="content">
            <h3>Are You Sure?</h3>
            <p>
              This will remove them and their {recommendations.count}{" "}
              recommendations from this leaderboard. Are you sure you want to do
              that?
            </p>
            <div className="actions">
              <button
                onClick={(e) => {
                  removeAdmin();
                }}
                className="btn medium"
              >
                Yes
              </button>
              <button
                onClick={(e) => {
                  setShowAdminRemoveModal(false);
                }}
                className="btn large"
              >
                Cancel
              </button>
            </div>
          </div>
          <div
            onClick={(e) => {
              setShowAdminRemoveModal(false);
            }}
            className="skrim"
          ></div>
        </div>
      )}
    </div>
  );

  if (props.leader.injector && props.sort !== 'proximity') {
    return (
      <div className="injector">
        <h3 className="nominations">Nominated</h3>
        {code}
      </div>
    );
  }

  return code;
}
