import { useState, useEffect } from 'react';
import '../CSS/TruncatedText.scss';

export default function TruncatedText(props) {
    const [text, setText] = useState("");
    const [showAll, setShowAll] = useState(false);
    const [showToggle, setShowToggle] = useState(false);

    useEffect(() => {
        if (props.text) {
            setText(truncate(props.text, props.length));
            setShowToggle(props.text.length > props.length);
        }
    }, [props.text, showAll]);

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    const truncate = (text, length) => {
        if (text.length > length && !showAll) {
            return text.substring(0, length) + "...";
        } else {
            return text;
        }
    };

    return (
        <div className="truncated-text">
            <div className="text">
                <span>{text}</span>
            </div>
            {showToggle && (
                <div className="show-more">
                    <span
                        onClick={toggleShowAll}
                    >{`Show ${showAll ? 'less' : 'more'}`}</span>
                </div>
            )}
        </div>
    )
}