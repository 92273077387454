import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  faChevronDown,
  faChevronRight,
  faTimes,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserProperty from "./UserProperty";

const CreateCollection = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState({});
  const [properties, setProperties] = useState([]);
  const [newProp, setNewProp] = useState("");

  const [trophyProperties, setTrophyProperties] = useState([]);
  const [newTrophyProp, setNewTrophyProp] = useState("");

  const [waiting, setWaiting] = useState(false);

  useEffect(() => {}, []);

  const onFileChange = async (file) => {
    const selectedImage = file[0];

    setImage(selectedImage);
  };

  const createCollection = async (e) => {
    if (e) e.preventDefault();
    setWaiting(true);

    if (!name || !description || !image || !image) {
      return alert("Please fill out all fields");
    }

    const body = {
      name,
      description,
    };

    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const collectionRequest = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophy-collections`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    const collection = await collectionRequest.json();

    if (collection.id) {
      const image = await uploadImage(collection.id);
      const properties = await uploadProperties(collection.id);
      const trophyProperties = await uploadTrophyProperties(collection.id);
      setWaiting(false);
      props.callback("Succesfully created Collection");
    }
  };

  const uploadProperties = async (trophyCollectionId) => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const promises = properties.map((name) => {
      const body = {
        trophyCollectionId,
        name,
        type: "TROPHY",
      };

      return fetch(
        `${process.env.REACT_APP_URL_JAVA}/trophy-collection-properties`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
    });

    const responses = await Promise.all(promises);

    return responses;
  };

  const uploadTrophyProperties = async (trophyCollectionId) => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const promises = trophyProperties.map((trophy) => {
      const body = {
        trophyCollectionId,
        name: trophy.name,
        type: "USER_TROPHY",
        options: trophy.values
      };

      return fetch(
        `${process.env.REACT_APP_URL_JAVA}/trophy-collection-properties`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
    });

    const responses = await Promise.all(promises);

    return responses;
  };

  const uploadImage = async (id) => {
    let selectedImage = image;

    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", selectedImage, selectedImage.name);

    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophy-collections/${id}/header`,
      {
        method: "POST",
        enctype: "multipart/form-data",
        contentType: false,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );

    if (response.ok) {
      return response.text();
    }

    throw new Error("Error uploading image");
  };

  return (
    <div className="container">
      <form
        onSubmit={(e) => {
          createCollection(e);
        }}
      >
        <div className="form-group">
          <label htmlFor="collection_name">Collection Name</label>
          <input
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            type="text"
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="collection_name">Collection Description</label>
          <textarea
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="collection_name">Collection Header Image</label>
          <input
            onChange={(e) => onFileChange(e.target.files)}
            type="file"
            accept="image/*"
          />
        </div>

        <div className="form-group">
          <label htmlFor="collection_name">Collection Properties</label>
          <input
            value={newProp}
            onChange={(e) => {
              setNewProp(e.target.value);
            }}
            type="text"
          ></input>
          <a
            className="btn medium"
            onClick={() => {
              const newProperties = [...properties, newProp];
              setProperties(newProperties);
              setNewProp("");
            }}
          >
            Add Property
          </a>
          <div className="properties">
            <h3>Collection Properties</h3>
            {properties.length > 0 &&
              properties.map((property) => {
                return (
                  <div className="property">
                    <p>{property}</p>
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => {
                        const newProperties = properties.filter((p) => {
                          return p !== property;
                        });
                        setProperties(newProperties);
                      }}
                    />
                  </div>
                );
              })}

            {(!properties || properties.length === 0) && (
              <p>No Properties Added Yet.</p>
            )}
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="collection_name">Trophy Properties</label>
          <input
            value={newTrophyProp.name}
            onChange={(e) => {
              setNewTrophyProp({
                name: e.target.value,
                values: [],
              });
            }}
            type="text"
          ></input>
          <a
            className="btn medium"
            onClick={() => {
              const newProperties = [...trophyProperties, newTrophyProp];
              setTrophyProperties(newProperties);
              setNewTrophyProp("");
            }}
          >
            Add Property
          </a>
          <div className="properties">
            <h3>Trophy Properties</h3>
            {trophyProperties.length > 0 &&
              trophyProperties.map((property) => {
                return (
                  <UserProperty
                    remove={(property) => {
                      const newProperties = properties.filter((p) => {
                        return p !== property;
                      });
                      setTrophyProperties(newProperties);
                    }}
                    update={(property) => {
                      const newProperties = trophyProperties.map((p) => { 
                        if (p.name === property.name) {
                          return property;
                          }
                        return p;
                      });
                      setTrophyProperties(newProperties);
                    }}
                    property={property}
                  />
                );
              })}

            {(!trophyProperties || trophyProperties.length === 0) && (
              <p>No Trophy Properties Added Yet.</p>
            )}
          </div>
        </div>

        <button disabled={waiting} type="submit">
          Create Collection
        </button>
      </form>
    </div>
  );
};

export default CreateCollection;
