import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  faChevronDown,
  faChevronRight,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import "../../CSS/Trophies/editcollection.scss";
import { useParams, useHistory } from "react-router-dom";

const CreateCollection = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [collections, setCollections] = useState([]);
  const [properties, setProperties] = useState({});
  const [collection, setCollection] = useState(false);
  const [name, setName] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [trophy, setTrophy] = useState(false);
  const [description, setDescription] = useState("");
  const [success, setSuccess] = useState(false);
  const [image, setImage] = useState({});
  const { id } = useParams()
  useEffect(() => {
      getData();
  }, []);

  const getData = async () => {
      await getTrophy();
      await getCollections();
  };

  const onFileChange = async (file) => {
    const selectedImage = file[0];

    setImage(selectedImage);
  };

  const saveTrophy = async (e) => {
    if (e) e.preventDefault();
    setWaiting(true);



    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const trophyRequest = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophies/${trophy.trophyId}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(trophy),
      }
    );

    const trophyData = await trophyRequest.json();

    if (trophyData.trophyId) {
        try {
            const image = await uploadImage(trophyData.trophyId);
        } catch (error) {
            console.log('No new image')
        }
      setWaiting(false);
      setSuccess(true);

      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    }
  };

  const uploadImage = async (id) => {
    let selectedImage = image;

    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", selectedImage, selectedImage.name);

    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophies/${id}/media`,
      {
        method: "POST",
        enctype: "multipart/form-data",
        contentType: false,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );

    if (response.ok) {
      return response.text();
    }

    throw new Error("Error uploading image");
  };


  const getCollections = async () => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const collectionRequest = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophy-collections`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const collections = await collectionRequest.json();

    setCollections(collections.content);
  };

  const getTrophy = async () => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const trophyRequest = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophies/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const trophy = await trophyRequest.json();

    if(trophy.metadata === '{}') {
        trophy.metadata = null;
    } else {
        setProperties(JSON.parse(trophy.metadata));
        trophy.metadata = JSON.parse(trophy.metadata);
    }
    setTrophy(trophy);
    setName(trophy.name);
    setDescription(trophy.description);
  };

  return (
    <div className="trophies admin">
    <div className="container">
        {success && <div className="alert alert-success">Saved!</div>}
      <form
        onSubmit={(e) => {
          saveTrophy(e);
        }}
      >

        <div className="groupContainer">
        <div className="form-group">
          <label htmlFor="collection_name">Trophy Name</label>
          <input
            value={trophy.trophyName}
            onChange={(e) => {
                const newTrophy = {...trophy};
                newTrophy.trophyName = e.target.value;
                setTrophy(newTrophy);
            }}
            type="text"
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="collection_name">Trophy Description</label>
          <textarea
            value={trophy.trophyDescription}
            onChange={(e) => {
                const newTrophy = {...trophy};
                newTrophy.trophyDescription = e.target.value;
                setTrophy(newTrophy);
            }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="collection_name">Trophy Video</label>
          <input onChange={(e) => onFileChange(e.target.files)} type="file" />
        </div>

        <div className="form-group">
          <label htmlFor="collection_name">Trophy Properties</label>
          {Object.keys(properties).map((keyName, i) => (
            <div
              style={{
                marginRight: "30px",
              }}
              className="travelcompany-input"
              key={i}
            >
              <span
                style={{
                  marginRight: "10px",
                }}
                className="input-label"
              >
                {keyName}
              </span>
              <input
                type="text"
                value={properties[keyName]}
                onChange={(e) => {
                  const newProperties = { ...properties };
                  newProperties[keyName] = e.target.value;
                  setProperties(newProperties);
                  
                  const newTrophy = {...trophy};
                  newTrophy.metadata = newProperties;
                  setTrophy(newTrophy);
                }}
              />
            </div>
          ))}

          {(!properties || Object.keys(properties).length === 0) && (
            <p>No properties for this collection</p>
          )}
        </div>

        <button disabled={waiting} type="submit">Save</button>
        </div>
      </form>
    </div>
    </div>
  );
};

export default CreateCollection;
