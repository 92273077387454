export class CompanyService {
    constructor(getAccessTokenSilently) {
        this.getAccessTokenSilently = getAccessTokenSilently;
    }

    getCompanyProfilesByCollectiveIds = async(collectiveIds) => {
        let token = await this.getAccessTokenSilently({
            audience: `https://api-v2.behearty.co`,
            scope: "read:current_user offline_access",
        });

        let headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };

        try {
            let url = `${process.env.REACT_APP_URL_JAVA}/companies/profiles?`
            for (let i = 0; i < collectiveIds.length; i++) {
                url += `&collectiveId=${collectiveIds[i]}`;
            }

            const response = await fetch(
                url,
                {
                    headers,
                    method: "GET",
                }
            );
            return response.json(); // parse JSON
        } catch (error) {
            console.log(`Error getting companies`, error);
        }
    };
}

export default CompanyService;