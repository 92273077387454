import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "../CSS/Collective/Role.scss";
import CandidateMatches from "../Collective/CandidateMatches";
import { formatRoleType } from "../helpers";

export default function Role(props) {
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const [role, setRole] = useState(props.role);
    const [showCompany, setShowCompany] = useState(props.showCompany);
    const [active, setActive] = useState(props.role.active);

    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });

      const deleteRole = async (id) => {
        if (window.confirm("Are you sure you want to deactivate this role?")) {
          let token = await getAccessTokenSilently({
              audience: `https://api-v2.behearty.co`,
              scope: "read:current_user offline_access",
            });
    
          let endpoint = `${process.env.REACT_APP_URL_JAVA}/company-collective-roles/${id}`;
    
          const response = await fetch(endpoint, {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          setActive(false);
        }
      };

    return (
        <div className={`role ${active ? "" : "inactive"}`}>
          <div className="info">
            <div className="imageTop">
              <a className="companyLink" href={`/company/${role.companyCustomUrl}`} target="_blank">
                <img src={role.companyImage} alt="company" />
              </a>
              <h2>
                <a className="companyLink" href={`/company/${role.companyCustomUrl}`} target="_blank">{role.companyName}</a> - <a className="jobLink" href={role.jobPostUrl} target="_blank">{role.name}</a>
              </h2>
            </div>
            {!active && (
              <div className="inactive">
                Inactive
              </div>
            )}
            {active && (
              <a className="delete" onClick={()=>{
                deleteRole(role.id);
              }} >Deactivate</a>
            )}
          </div>
          <div>
            <h3>{formatRoleType(role.type)}</h3>
          </div>
          <div className="fit">
            <div className="technologies">
                <h3>Skills</h3>
              <div className="technologyMap">
                {role.technologies.map((item) => {
                  return (
                    <div key={item.id} className={`technology-item ${item.included ? "included" : ""}`}>
                      <p>{item.required ? "*" : ""}{item.technologyName}{item.required ? "(" + item.score + ")" : ""}</p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="experience">
                <h3>Experience</h3>
              <p>{role.minExperience} - {role.maxExperience} Years</p>
            </div>
            <div className="compensation">
              <h3>Compensation</h3>
              <p>${role.minSalary ? role.minSalary.toLocaleString("en-US") : '0'}-${role.maxSalary ? role.maxSalary.toLocaleString("en-US") : '0'}</p>
            </div>
          </div>
          <div className="jobDescription">
            <p dangerouslySetInnerHTML={{
                __html:role.jobDescription
              }} />
          </div>
          <div className="notes">
            <div className="roleNotes">
              <h3>Role Notes</h3>
              <p>{role.roleNotes}</p>
            </div>
            <div className="companyNotes">
              <h3>Internal Company Notes</h3>
              <p dangerouslySetInnerHTML={{
                  __html:role.companyInternalNotes
                }} />
            </div>
          </div>
          <div className="matches">
            <CandidateMatches companyCollectiveRoleId={role.id} roleTechnologies={role.technologies} />
          </div>
        </div>
      );
}
