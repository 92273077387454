import {
  faHeart,
  faLock,
  faUser,
  faStar,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Captain from "../../images/captain-emoji.png";

import React, { useState, useEffect } from "react";
import "../../CSS/Leaderboards/LeaderboardCard.scss";
import AdminTagSelector from "./AdminTagSelector";
import { useAuth0 } from "@auth0/auth0-react";
import { faStar as unFilled } from "@fortawesome/free-regular-svg-icons";

export default function LeaderboardCard(props) {
  const [userImage, setUserImage] = useState(Captain);
  const [admin, setAdmin] = useState(() => {
    const rolesJSON = localStorage.getItem("roles");
    const roles = JSON.parse(rolesJSON);
    return roles && roles.includes("Admin");
  });
  const [showAdminSection, setShowAdminSection] = useState(false);
  const [priority, setPriority] = useState(props.leaderboard.priority);
  const [error, setError] = useState(false);
  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      setUserImage(user.External_Profile.image);
    }
  }, [props.leaderboard]);

  const leaderboardCount = () => {
    const count = props.leaderboard.totalMemberCount;

    if (count > 0) return props.leaderboard.totalMemberCount;

    return 0;
  };


  const upvoteCount = () => {
    const count = props.leaderboard.totalUpvoteCount;
    if (count > 0) return props.leaderboard.totalUpvoteCount;

    return 0;
  };

  const makePriority = async (priority) => {
    const token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const user = JSON.parse(localStorage.getItem("user"));

    const data = {
      externalProfileId: user.External_Profile.id,
      leaderboardId: props.leaderboard.id,
      priority,
    };
 
    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/leaderboard-external-profile/priority`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const json = await response.json();

    if (json.errors) {
      setError(json.errors[0]);

      setTimeout(() => {
        setError(false);
      }, 5000);
      return;
    }

    setError(false);
    setPriority(priority);
  };

  return (
    <div className="LeaderboardCard app">
      {error !== false && (
        <div className="alert error">
          {error === "User has already set 3 priority leaderboards" && (
            <p>
              You have already set 3 priority leaderboards. Please remove one
              and try again.
            </p>
          )}
        </div>
      )}
      <div className="priority">
        {priority && (
          <FontAwesomeIcon
            onClick={(e) => {
              makePriority(false);
            }}
            icon={faStar}
          />
        )}

        {!priority && (
          <FontAwesomeIcon
            onClick={(e) => {
              makePriority(true);
            }}
            className="unfilled"
            icon={unFilled}
          />
        )}
      </div>
      {admin && (
        <div className="adminSection">
          <FontAwesomeIcon
            icon={faLock}
            onClick={(e) => {
              setShowAdminSection(true);
            }}
          />

          {showAdminSection && (
            <div className="tags">
              <AdminTagSelector id={props.leaderboard.id} />
              <div
                className="skrim"
                onClick={(e) => {
                  setShowAdminSection(false);
                }}
              ></div>
            </div>
          )}
        </div>
      )}
      <section className="content">
        <div
          style={{
            backgroundImage: `url('${props.leaderboard.image}')`,
          }}
          className="backgroundImage"
        >
          <Link
            to={`/leaderboards/${props.leaderboard.customUrl}`}
            className={`${props.leaderboard.featured ? "hasFeatured" : ""}`}
          >
            <h2>{props.leaderboard.title}</h2>
          </Link>
          {props.leaderboard.featured ? (
            <div className="featured">
              <img
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = Captain;
                }}
                src={userImage}
              />
              <span>You are featured</span>
            </div>
          ) : (
            <div className="featured"></div>
          )}
        </div>
        <div className="leaderboardFollowButton">
          <Link to={`/leaderboards/${props.leaderboard.customUrl}`} className='follow btn large'>
            <span><FontAwesomeIcon icon={faList} />  View Board</span>
          </Link>
        </div>
        <Link
          to={`/leaderboards/${props.leaderboard.customUrl}`}
          className="contextInfomationLink"
        >
          <section className="contextualInformation">
            <div>
              <FontAwesomeIcon icon={faUser} /> {leaderboardCount()} members
            </div>
            <div className="alignRight">
              <FontAwesomeIcon icon={faHeart} /> {upvoteCount()}
            </div>
          </section>
        </Link>
      </section>
    </div>
  );
}
