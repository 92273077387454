import React, {useState, useEffect} from "react";
import '../CSS/HeartyAvatar.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart
} from "@fortawesome/free-solid-svg-icons";
import Captain from "../images/captain-emoji.png";

export default function ProfileRatings(props) {  
  const [hearty, setHearty] = useState(props.hearty);

  useEffect(() => {
    setHearty(props.hearty);
  }, [props.hearty])

  let image = props.image;

  if(!image) image = Captain;

  return (
    <div className='heartyAvatar'>
        <img
        onError={(e) => {
            e.target.onerror = null;
            e.target.src = Captain;
        }}
        src={image}
        />
        {hearty &&
        <FontAwesomeIcon icon={faHeart} className="heart" />
        }
    </div>
  );
}
