import "../CSS/Collective/Match.scss";

export default function JobPost(props, ref) {
    return (
        <div className="match">
            <div className="top">
                <div className="info">
                    <div className="match-title">
                        <div className="company-image">
                            <img src={props.job.companyImage}></img>
                        </div>
                        <div>
                            <h3>{props.job.companyName}</h3>
                            <h2>{props.job.roleName}</h2>
                        </div>
                    </div>
                    <div className="company-description">
                        <h3>Why We Like This Company</h3>
                        <p dangerouslySetInnerHTML={{
                        __html:props.job.companyDescription
                        }} />
                    </div>
                </div>
                {props.job.companyVideoUrl && (
                    <div className="video">
                        <video type="video/mp4"  src={props.job.companyVideoUrl} loop controls />
                    </div>
                    )}
            </div>
            <div className="company-info">
                {props.job.roleTechnologies.length > 0 && (
                <div className="skills">
                    <h3>Tech Stack</h3>
                    {props.job.roleTechnologies.map((skill) => (
                    <div key={skill.id} className="skill">
                        <p>{skill.technologyName}</p>
                    </div>
                    ))}
                </div>
                )}

                {props.job.companyBenefits.length > 0 && (
                <div className="benefits">
                    <h3>Benefit Stack</h3>
                    {props.job.companyBenefits.map((b) => (
                    <div key={b} className="benefit">
                        <p>{b}</p>
                    </div>
                    ))}
                </div>
                )}
            </div>
            <div className="job-description">
                <h3>Job Description</h3>
                <p dangerouslySetInnerHTML={{
                        __html:props.job.jobDescription
                        }} />
            </div>
        </div>
    )
}