export const getJobFunctions = async(id) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_URL_JAVA}/external-profiles/${id}/job-functions`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        return response.json(); // parse JSON
    } catch (error) {
        console.log(`Error getting job functions for ${id}`, error);
        return [];
    }
};