import { Link } from "react-router-dom";
import "../CSS/CompanySidebarWidget.scss"
import TruncatedText from "../components/TruncatedText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import companyHeaderBackground from "../images/companybg.png";
import WaxSeal from "../images/captain-emoji.png";

export default function CompanySidebarWidget(props) {

    return (
        <div className="company-sidebar-widget">
            <div className="company-header-image" style={{ backgroundImage: `url(${companyHeaderBackground})` }} />
            <div className="company-container">
                <div className="company-image">
                    <Link to={`/company/${props.customUrl}`}>
                        <img src={props.image ? `${props.image}?w=100` : WaxSeal} alt="Company Logo" />
                    </Link>
                </div>
                <div className="company-name">
                    <Link to={`/company/${props.customUrl}`}>
                        {props.name}
                    </Link>
                </div>
                {props.location && (
                    <div className="company-location">
                        <FontAwesomeIcon icon={faLocationDot} /> {props.location}
                    </div>
                )}
                <div className="company-url">
                    <a href={props.url}>{props.url}</a>
                </div>
                <div className="company-description">
                    <TruncatedText text={props.overview} length={150} />
                </div>
                {props.showEmployeeCounts && (
                    <div className="employee-counts">
                        <Link to={"/employer/employees"}>
                            <span className="recruiter-count"><span className="count">{props.recruiterCount}</span>Recruiters</span>
                            <span className="ally-count"><span className="count">{props.allyCount}</span>Allies</span>
                        </Link>
                    </div>
                )}
                {props.showEditButton && (
                    <div className="button-container">
                        <Link to={`/company/${props.id}/edit`}><button>Edit Profile</button></Link>
                    </div>
                )}
            </div>
        </div>
    )
} 