import React, { useState, useEffect, useRef } from "react";
import "../CSS/Companies/edit.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";
import Captain from "../images/captain-emoji.png";
import SkillsSelector from "../components/SkillsSelector";
import TipTap from "../Feed/components/TipTap";
import { getAuthenticatedUserCollectives } from "../LocalStorageUtil";

export default function Edit(props, ref) {
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [options, setOptions] = useState([]);
  const [showAddCompany, setShowAddCompany] = useState(false);
  const [newCompanyName, setNewCompanyName] = useState("");
  const [newCompanyDescription, setNewCompanyDescription] = useState("");
  const [newCompanyLocation, setNewCompanyLocation] = useState("");
  const [newCompanyUrl, setNewCompanyUrl] = useState("");
  const [newCompanyLinkedinUrl, setNewCompanyLinkedinUrl] = useState("");
  const [newCrunchbase, setNewCrunchbase] = useState("");
  const [newSize, setNewSize] = useState("");
  const [collectiveId, setCollectiveId] = useState(1);
  const [companyImage, setCompanyImage] = useState("");
  const [error, setError] = useState(false);
  const [searching, setSearching] = useState(false);
  const [company, setCompany] = useState({});
  const [skills, setSkills] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const [collective, setCollective] = useState({});
  const [collectiveDescription, setCollectiveDescription] = useState("");
  const [collectiveDei, setCollectiveDei] = useState("");
  const [collectiveNotes, setCollectiveNotes] = useState("");
  const [collectiveInternalNotes, setCollectiveInternalNotes] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactName, setContactName] = useState("");
  const [jobListingsUrl, setJobListingsUrl] = useState("");
  const [retainer, setRetainer] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [uploadingVideo, setUploadingVideo] = useState(false);
  const [customUrl, setCustomUrl] = useState(props.match.params.id);
  const [collectives, setCollectives] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const rolesJSON = localStorage.getItem("roles");
    const roles = JSON.parse(rolesJSON);
    const userCollectives = getAuthenticatedUserCollectives();
    let isCollectiveEmployerAdmin = false;
    for (let i = 0; i < userCollectives.length; i++) {
      if (userCollectives[i].roles.includes("EMPLOYER_ADMIN")) {
        isCollectiveEmployerAdmin = true;
      }
    }

    if (!roles || (!roles.includes("Admin") && !roles.includes("EMPLOYER_ADMIN") && !isCollectiveEmployerAdmin)) {
      window.location.href = "/";
    }

    if (roles.includes("Admin")) {
      setIsAdmin(true);
    }

    if (!isLoading && !props.create) {
      getCompany();
    }

    if (props.create) {
      getCollectives();
    }
  }, [isLoading]);


  const onVideoChange = async (video) => {
    const selectedImage = video[0];

    setUploadingVideo(true);

    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", selectedImage, selectedImage.name);

    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/company-collectives/${collective.companyCollectiveId}/video`,
      {
        method: "POST",
        enctype: "multipart/form-data",
        contentType: false,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );

    if (response.ok) {
      setUploadingVideo(false);
      return response.text();
    }

    setUploadingVideo(false);
    setUploadError('Error uploading video'); 
  };
  
  const getCollective = async (id) => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {}

    let endpoint = `${process.env.REACT_APP_URL_JAVA}/company-collectives?companyId=${id}`;

    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const collective = await response.json();

    if (collective.content[0]) {
      setCollective(collective.content[0]);
      getTech(collective.content[0].companyCollectiveId);
      getBenefits(collective.content[0].companyCollectiveId);
      setCollectiveDescription(collective.content[0].collectiveDescription);
      setCollectiveDei(collective.content[0].collectiveDei);
      setCollectiveNotes(collective.content[0].collectiveNotes);
      setCollectiveInternalNotes(collective.content[0].collectiveInternalNotes);
      setContactName(collective.content[0].contactName);
      setContactEmail(collective.content[0].contactEmail);
      setJobListingsUrl(collective.content[0].jobListingsUrl);
      setRetainer(collective.content[0].retainer);
    }
  };

  // On file upload (click the upload button)
  const onFileUpload = async (selectedImage) => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", selectedImage, selectedImage.name);

    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/files/uploadFile?directory=company`,
      {
        method: "POST",
        enctype: "multipart/form-data",
        contentType: false,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.text();
        }

        throw new Error("Error uploading image");
      })
      .then((data) => {
        setError(false);
        setCompanyImage(data);
      })
      .catch((error) => {
        setError("Issue uploading image");
      });
  };

  const getTech = async (id) => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      return console.log(error);
    }

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/company-collective-technologies?companyCollectiveId=${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const tech = await response.json();
    const newTech = tech.map((tech) => {
      return {
        id: tech.technologyId,
        name: tech.technologyName,
        dbId: tech.id,
      };
    });

    setSkills(newTech);
  };

  const getBenefits = async (id) => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      return console.log(error);
    }

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/company-collective-benefits?companyCollectiveId=${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const benefits = await response.json();
    const newBenefits = benefits.map((benefit) => {
      return {
        id: benefit.benefitId,
        name: benefit.benefitName,
        dbId: benefit.id,
      };
    });
    setBenefits(newBenefits);
  };

  const getCollectives = async () => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      return console.log(error);
    }

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/collectives`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const collectives = await response.json();
    setCollectives(collectives);
  };

  const getCompany = async () => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      return console.log(error);
    }

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/companies/${customUrl}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const company = await response.json();
    try {
      await getCollective(company.id);
    } catch (error) {}
    
    // We have no image so set one.
    setNewCompanyName(company.name);
    setNewCompanyUrl(company.url);
    setNewCompanyLocation(company.location);
    setNewCompanyLinkedinUrl(company.linkedinUrl);
    setNewCompanyDescription(company.overview);
    setCompanyImage(company.image);
    setCompany(company);
    setNewSize(company.size);
    setNewCrunchbase(company.crunchbaseUrl);
  };

  const editCompany = async () => {
    setError(false);

    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    if (
      !newCompanyName ||
      newCompanyName === "" ||
      !newCompanyDescription ||
      newCompanyDescription === "" ||
      !newCompanyLinkedinUrl ||
      newCompanyLinkedinUrl === "" 
    ) {
      setError("Please enter a name, description, and LinkedIn url");
      return;
    }

    const data = { ...company };
    data.name = newCompanyName;
    data.overview = newCompanyDescription;
    data.location = newCompanyLocation;
    data.url = newCompanyUrl;
    data.linkedinUrl = newCompanyLinkedinUrl;
    data.image = companyImage;
    data.size = newSize;
    data.crunchbaseUrl = newCrunchbase;

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/companies/${company.id}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    if (collective.companyCollectiveId > 0) {
      const skillsQuery = await saveSkills(collective.companyCollectiveId);
      const benefitsQuery = await saveBenefits(collective.companyCollectiveId);
      await editCompanyCollective(collective.companyCollectiveId);
    }

    const responseData = await response.json();

    window.location.href = `/company/${responseData.customUrl}`;
  };

  const editCompanyCollective = async (companyCollectiveId) => {
    setError(false);

    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

   

    const data = { ...collective };
    data.collectiveDescription = collectiveDescription;
    data.collectiveDei = collectiveDei;
    data.collectiveNotes = collectiveNotes;
    data.collectiveInternalNotes = collectiveInternalNotes;
    data.contactName = contactName;
    data.contactEmail = contactEmail;
    data.jobListingsUrl = jobListingsUrl;
    data.retainer = retainer;

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/company-collectives/${companyCollectiveId}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    const responseData = await response.json();

    return responseData;
  };

  const saveSkills = async (companyCollectiveId) => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const responseArray = skills.map((skill) => {
      const body = {
        companyCollectiveId: companyCollectiveId,
        technologyId: skill.id,
      };
      return fetch(
        `${process.env.REACT_APP_URL_JAVA}/company-collective-technologies`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
    });

    Promise.all(responseArray).then(() => {
      return true;
    });
  };

  const saveBenefits = async (companyCollectiveId) => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const responseArray = benefits.map((benefit) => {
      const body = {
        companyCollectiveId: companyCollectiveId,
        benefitId: benefit.id,
      };
      return fetch(
        `${process.env.REACT_APP_URL_JAVA}/company-collective-benefits`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
    });

    Promise.all(responseArray).then(() => {
      return true;
    });
  };

  const saveCompany = () => {
    if (props.create) {
      return createCompany();
    } else {
      return editCompany();
    }
  }

  const createCompany = async () => {
    setError(false);

    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    if (
      !newCompanyName ||
      newCompanyName === "" ||
      !newCompanyDescription ||
      newCompanyDescription === "" ||
      !newCompanyLinkedinUrl ||
      newCompanyLinkedinUrl === "" 
    ) {
      setError("Please enter a name, description, and LinkedIn url");
      return;
    }

    const data = { ...company };
    data.name = newCompanyName;
    data.overview = newCompanyDescription;
    data.location = newCompanyLocation;
    data.url = newCompanyUrl;
    data.linkedinUrl = newCompanyLinkedinUrl;
    data.image = companyImage;
    data.size = newSize;
    data.crunchbaseUrl = newCrunchbase;
    data.inCollective = true;
    data.collectiveId = collectiveId;

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/companies`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    const createdCompany = await response.json();

    setCustomUrl(createdCompany.customUrl);

    // await getCompany();

    let endpoint = `${process.env.REACT_APP_URL_JAVA}/company-collectives?companyId=${createdCompany.id}`;

    const collectiveResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const collective = await collectiveResponse.json();
    setCollective(collective.content[0]);

    const skillsQuery = await saveSkills(collective.content[0].companyCollectiveId);
    const benefitsQuery = await saveBenefits(collective.content[0].companyCollectiveId);
    await editCompanyCollective(collective.content[0].companyCollectiveId);

    window.location.href = `/company/${createdCompany.customUrl}`;
  };

  return (
    <div className={`CompanyEdit home editProfile`}>
      <div className="companyContainer">
        <div className="showAddCompany">
          <div className="modal">
            {error && <div className="alert error">{error}</div>}
            <div className="left">
              <div className="imageUploadBox">
                <div className="svg">
                  <FontAwesomeIcon icon={faEdit} />
                </div>
                <input
                  type="file"
                  onChange={(e) => onFileUpload(e.target.files[0])}
                />
                <div className="uploadShow">
                  <p>Upload New Photo</p>
                </div>
                <img
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = Captain;
                  }}
                  src={companyImage}
                />
              </div>
            </div>
            <div className="right">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  saveCompany();
                }}
              >
                <h3>Edit Your Company</h3>
                <div className="inputBox">
                  <label>Company Name <span className="required">*</span></label>
                  <input
                    value={newCompanyName}
                    type="text"
                    onChange={(e) => {
                      setNewCompanyName(e.target.value);
                    }}
                  ></input>
                </div>

                <div className="inputBox">
                  <label>Description <span className="required">*</span></label>
                  <textarea
                    value={newCompanyDescription}
                    type="text"
                    onChange={(e) => {
                      setNewCompanyDescription(e.target.value);
                    }}
                  ></textarea>
                </div>

                <div className="inputBox">
                  <label>Company Url <span className="required">*</span></label>
                  <input
                    value={newCompanyUrl}
                    type="text"
                    onChange={(e) => {
                      setNewCompanyUrl(e.target.value);
                    }}
                  ></input>
                </div>

                <div className="inputBox">
                  <label>Location</label>
                  <input
                    value={newCompanyLocation}
                    type="text"
                    onChange={(e) => {
                      setNewCompanyLocation(e.target.value);
                    }}
                  ></input>
                </div>

                <div className="inputBox">
                  <label>Company LinkedIn Url <span className="required">*</span></label>
                  <input
                    value={newCompanyLinkedinUrl}
                    type="text"
                    onChange={(e) => {
                      setNewCompanyLinkedinUrl(e.target.value);
                    }}
                  ></input>
                </div>

                {isAdmin && (
                  <div className="inputBox">
                    <label>Crunchbase Url</label>
                    <input
                      value={newCrunchbase}
                      type="text"
                      onChange={(e) => {
                        setNewCrunchbase(e.target.value);
                      }}
                    ></input>
                  </div>
                )}

                <div className="inputBox">
                  <label>Size</label>
                  <input
                    value={newSize}
                    type="text"
                    onChange={(e) => {
                      setNewSize(e.target.value);
                    }}
                  ></input>
                </div>

                {isAdmin && (collective.companyCollectiveId > 0 || props.create) && (
                  <div className="collectiveData">
                    <SkillsSelector
                      setData={(data) => {
                        setSkills(data);
                      }}
                      data={skills}
                      url={"technologies"}
                      description={
                        "Please enter the skills required for the company"
                      }
                    />

                    <SkillsSelector
                      setData={(data) => {
                        setBenefits(data);
                      }}
                      data={benefits}
                      url={"benefits"}
                      description={
                        "Please enter the benefits this company has to offer"
                      }
                    />
                  </div>
                )}

                <div className="inputBox">
                  <label>'Collectives by Hearty' Take</label>
                  <textarea
                    value={collectiveDescription}
                    type="text"
                    onChange={(e) => {
                      setCollectiveDescription(e.target.value);
                    }}
                    maxLength="4000"
                  ></textarea>
                </div>

                <div className="inputBox">
                  <label>Diversity, Equity & Inclusion</label>
                  <textarea
                    value={collectiveDei}
                    type="text"
                    onChange={(e) => {
                      setCollectiveDei(e.target.value);
                    }}
                  ></textarea>
                </div>

                {isAdmin && !collective.collectiveNotes && (
                <div style={{
                  marginTop:'50px'
                }} className="inputBox">
                  <label>Additional Tips and Notes</label>
                  <TipTap
                    data={collectiveNotes}
                    editing={false}
                    update={(data) => {
                      setCollectiveNotes(data);
                    }}
                  />
                </div>
                )}

                {isAdmin && collective.collectiveNotes && (
                <div style={{
                  marginTop:'50px'
                }} className="inputBox">
                  <label>Additional Tips and Notes</label>
                  <TipTap
                    data={collectiveNotes}
                    editing={true}
                    update={(data) => {
                      setCollectiveNotes(data);
                    }}
                  />
                </div>
                )}

                {isAdmin && !collective.collectiveInternalNotes && (
                <div style={{
                  marginTop:'50px'
                }} className="inputBox">
                  <label>Internal Company Notes</label>
                  <TipTap
                    data={collectiveInternalNotes}
                    editing={false}
                    update={(data) => {
                      setCollectiveInternalNotes(data);
                    }}
                  />
                </div>
                )}

                {isAdmin && collective.collectiveInternalNotes && (
                <div style={{
                  marginTop:'50px'
                }} className="inputBox">
                  <label>Internal Company Notes</label>
                  <TipTap
                    data={collectiveInternalNotes}
                    editing={true}
                    update={(data) => {
                      setCollectiveInternalNotes(data);
                    }}
                  />
                </div>
                )}

                {!props.create && (
                  <div style={{
                    marginTop:'40px'
                  }} className="inputBox">
                    <label>Video</label>
                    <input style={{
                      width:'250px',
                      height:'40px',
                      border:'none',
                      opacity:1,
                      position:'relative',
                    }} onChange={(e) => onVideoChange(e.target.files)} type="file" />
                  </div>
                )}
                  
                {uploadingVideo && <p>Uploading...</p>}

                {uploadError && <div className="alert error">{uploadError}</div>}

                {isAdmin && (collective.companyCollectiveId > 0 || props.create) && (
                  <div className="collectiveContact">
                    <div className="inputBox">
                      <label>Contact Name</label>
                      <input
                        value={contactName}
                        type="text"
                        onChange={(e) => {
                          setContactName(e.target.value);
                        }}
                      ></input>
                    </div>
                    <div className="inputBox">
                      <label>Contact Email(s) (comma separated)</label>
                      <input
                        value={contactEmail}
                        type="text"
                        onChange={(e) => {
                          setContactEmail(e.target.value);
                        }}
                      ></input>
                    </div>
                    <div className="inputBox">
                      <label>Job Listings URL</label>
                      <input
                        value={jobListingsUrl}
                        type="text"
                        onChange={(e) => {
                          setJobListingsUrl(e.target.value);
                        }}
                      ></input>
                    </div>
                    <div className="inputBox">
                      <label>Retainer</label>
                      <div className="retainer">
                        <div className="option">
                          <input
                            onChange={(e) => {
                              setRetainer(false);
                            }}
                            value={false}
                            type="radio"
                            checked={retainer === false}
                            name="retainer"
                          />
                          <span>No</span>
                        </div>
                        <div className="option">
                          <input
                            onChange={(e) => {
                              setRetainer(true);
                            }}
                            value={true}
                            type="radio"
                            checked={retainer === true}
                            name="retainer"
                          />
                          <span>Yes</span>
                        </div>
                      </div>
                    </div>
                    {props.create && (
                      <div className="inputBox">
                        <label>Collective</label>
                        <div className="chooseCollective">
                          <select
                            className="collectiveSelect"
                            onChange={(e) => {
                              setCollectiveId(e.target.value);
                            }}
                            value={collectiveId}
                          >
                            {collectives.map((collective) => {
                              return (
                                <option key={collective.id} value={collective.id}>
                                  {collective.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div className="buttonContainer">
                  <button type="submit" className="btn large">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div
            onClick={(e) => {
              setSearching(false);
              setShowAddCompany(false);
              setOptions([]);
              setError(false);
            }}
            className="skrim"
          ></div>
        </div>
      </div>
    </div>
  );
}
