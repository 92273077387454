import React, { useState, useEffect } from "react";
import "../CSS/Trophies/home.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPlay,
  faPlus,
  faTimes,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import ClaimModal from "./components/ClaimModal";
import Nft from "./components/Nft";
import NftSingle from "./components/NftSingle";

import Logo from "../images/h.png";
import Captain from "../images/captain-emoji.png";

import { Helmet } from "react-helmet";
import Header from "../images/trophies-header.png";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useParams, Link } from "react-router-dom";
import { truncateString } from "../helpers";

const Trophies = (props) => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } =
    useAuth0();
  let { code } = useParams();
  const [inviteCode, setInviteCode] = useState(code);
  const [yourNfts, setYourNfts] = useState([]);
  const [otherNfts, setOtherNfts] = useState([]);
  const [me, setMe] = useState({
    external_profile: {},
  });
  const [customHeader, setCustomHeader] = useState(false);
  const [token, setToken] = useState(null);
  const [popover, setPopover] = useState(false);
  const [tags, setTags] = useState([]);
  const [collections, setCollections] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [showVideo, setShowVideo] = useState(false);
  const [ribbit, setRibbit] = useState(false);
  const [century21, setCentury21] = useState(false);

  useEffect(() => {
    getMe();
    getTrophies();
    getCollections();

    if(code) {
      getNFT(code);
    }
  }, [isLoading]);

  const getMe = async () => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const response = await fetch(`${process.env.REACT_APP_URL_JAVA}/users/me`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const me = await response.json();

    if (me.has_seen_welcome_modal === true) {
      const header = document.querySelector("nav");
      header.style.display = "block";
    } else {
      setCustomHeader(true);
    }

    setMe(me.external_profile);
  };

  const getTrophies = async () => {
    let token = await getAccessTokenSilently();
    setToken(token);

    let response = await fetch(`${process.env.REACT_APP_URL_JAVA}/trophies`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    let data = await response.json();

    setYourNfts(data.content);
  };

  const getNFT = async (code) => {
    let token = await getAccessTokenSilently();

    let response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophies/invite/${code}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    let data = await response.json();

    let name = data.trophyCollectionName.toLowerCase();
    
    if(name.includes("ribbit")) {
      setRibbit(true);
    } else if (name.includes("century 21")) {
      setCentury21(true);
    }
  };

  const getCollections = async (search = false) => {
    let token = await getAccessTokenSilently();
    setToken(token);

    let searchQuery = "?page=0&size=100";

    if (search) {
      searchQuery = `?query=${search}&page=0&size=100`;
    }

    let response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophy-collections${searchQuery}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    let data = await response.json();

    setCollections(data.content);
  };

  return (
    <div className={`trophies app ${ribbit ? 'ribbit' : ''} ${century21 ? 'century-21' : ''}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hearty NFTs</title>
        <meta
          name="description"
          content="NFTs issued for verified awards and accomplishments"
        />
      </Helmet>

      {customHeader && (
        <nav>
          <div className="container">
            <a href="/feed" className="logo">
              <img src={Logo} />
            </a>
            <div className="mainMenu">
              <div>
                <div
                  onClick={() => {
                    setPopover(!popover);
                  }}
                  className="userProfile"
                >
                  <div className="image">
                    <img
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = Captain;
                      }}
                      src={me?.image}
                    />
                  </div>
                  {popover && (
                    <div className="popover">
                      <ul>
                        <li>
                          <Link to="/settings">My Account</Link>
                        </li>
                        <li
                          onClick={() =>
                            logout({ returnTo: window.location.origin })
                          }
                        >
                          Logout
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>

              {popover && <div className="popover"></div>}
            </div>
          </div>
        </nav>
      )}

      {inviteCode && (
        <ClaimModal
          close={() => {
            setInviteCode(false);

            // Set the LS
            const user = JSON.parse(localStorage.getItem("user"));
            user.is_nft_user = true;
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.removeItem("NFTCode");
            window.location.href = "/trophies";
          }}
          code={code}
        />
      )}

      <section className="left dashboard">

        <div
          style={{
            backgroundImage: `url(${Header})`,
          }}
          className="listHeader"
        >

          <div className="beta">
            <span>Beta</span>
          </div>
          <h2>Hearty NFTs</h2>

          <h4>NFTs issued for verified awards and accomplishments.</h4>

          <button onClick={()=>{
            setShowVideo(!showVideo)
          }} className="videoButton">
            What are trophies? <FontAwesomeIcon icon={faPlay} />
          </button>
        </div>

        <div className="informational">
          <div className="search">
            <input
              type="text"
              placeholder="Search Collections"
              onChange={(e) => {
                setSearchText(e.target.value);

                if (e.target.value.length > 2) {
                  getCollections(e.target.value);
                }
              }}
            />
          </div>
          <a
            target="_blank"
            href="https://forms.gle/445LtGHxYwcFYzrh9"
            className="btn large"
          >
            <FontAwesomeIcon icon={faPlus} />
            Suggest NFT
          </a>
        </div>

        <div className="list">
          {searchText === "" && yourNfts.length > 0 && (
            <div className="yourNfts">
              <h3>Your NFTs</h3>
              {yourNfts.map((nft, index) => {
                if(nft.single) return <NftSingle singleDisplay={true} key={nft.trophyId} nft={nft} />;
                return <Nft key={nft.trophyId} nft={nft} />;
              })}
            </div>
          )}
          {collections.length > 0 && (
            <div className="collections">
              {searchText === "" && <h3>NFT Collections</h3>}
              {searchText.length > 0 && <h3>{searchText} - Collections</h3>}
              {collections.map((collection) => {
                return (
                  <Link
                    className="collection"
                    to={`/collection/${collection.customUrl}`}
                    key={collection.id}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${collection.imageUrl})`,
                      }}
                      className="header"
                    >
                      <h3>{collection.name}</h3>
                    </div>
                    <p>{truncateString(collection.description, 110)}</p>
                  </Link>
                );
              })}
            </div>
          )}

          {collections.length === 0 && searchText.length > 0 && (
            <div className="noResults">
              <h3>No results found for term: {searchText} </h3>
            </div>
          )}
        </div>
      </section>

    {showVideo && (
      <div className="video">
        <div className="modal">
          <FontAwesomeIcon icon={faTimes} onClick={()=>{
            setShowVideo(!showVideo)
          }}/>
        <iframe
          src="https://player.vimeo.com/video/659044660?h=1c60619834&color=fff&title=0&byline=0&portrait=0"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
        </div>
        <div onClick={()=>{
          setShowVideo(false)
        }} className="skrim"></div>
      </div>
    )}
    </div>
  );
};

export default withAuthenticationRequired(Trophies, {
  onRedirecting: () => <p>Loading...</p>,
});
