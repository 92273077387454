import React, {useState, useEffect} from "react";
import '../../CSS/TagSelector.scss';
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default function TagSelector(props) {
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([], 'selectedTags');
  const [currentSearch, setCurrentSearch] = useState('', 'currentSearch');
  const [input, setInput] = useState('', 'input');
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(()=>{
      if(props.searchText) setCurrentSearch(props.searchText)
  },[props.searchText])


  const searchAPI = async (search) => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access"
    });
    return fetch(
      `${process.env.REACT_APP_URL_JAVA}/tag/list?q=${search}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const searchAPIDebounced = AwesomeDebouncePromise(searchAPI, 200);

  const handleSearch = async (search)=>{
    setCurrentSearch(search);

    if(search !== '') {
        setShowDropdown(true)
    } else {
        setShowDropdown(false)
    }

    const query = await searchAPIDebounced(search);
    const tags = await query.json();
    if(tags && tags._embedded) {
        setTags( tags._embedded.tag);
    }

    if(!tags || !tags._embedded) {
        setTags([]);
    }
  }




  return (
    <div className="TagSelector">
        <form onSubmit={(e)=>{
            e.preventDefault();
            props.receiveSearch(currentSearch)
        }}>
       <input placeholder="Search..." value={currentSearch} onChange={async (e)=>{
           await handleSearch(e.target.value);
       }} type="text"></input>
       {showDropdown &&
        <div className="tagDropdown">
            <ul>
                {tags.map((tag)=>{
                    return <li onClick={(e)=>{
                        setCurrentSearch('')
                        props.receiveTag(tag);
                    }}>{tag.name} &#183; <span>{tag.leaderboardCount} leaderboards</span></li>
                })}

                {tags.length === 0 &&
                <p>No Matching Topics. View all results to search in descriptions and titles.</p>
                }
            </ul>
           
            <div onClick={(e)=>{
                props.receiveSearch(currentSearch)
            }} className="more">All Results</div>
            
        </div>
       }
       <input style={{
           display:'none'
       }} type="submit"></input>
       </form>

       {showDropdown &&
       <div onClick={(e)=>{
           setShowDropdown(false);
    }} className="skrim"></div>
       }
    </div>
  );
}
