import React, {useState, useEffect} from "react";
import '../../CSS/AdminTagSelector.scss';
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default function TagSelector(props) {
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([], 'selectedTags');
  const [currentSearch, setCurrentSearch] = useState('', 'currentSearch');
  const [input, setInput] = useState('', 'input');
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();


  useEffect(()=>{
    if(props.id) {
        getTags();
    }
  },[props.id])


  const searchAPI = async (search) => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access"
    });
    return fetch(
      `${process.env.REACT_APP_URL_JAVA}/tag/list?q=${search}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const searchAPIDebounced = AwesomeDebouncePromise(searchAPI, 200);

  const handleSearch = async (search)=>{
    setCurrentSearch(search);
    const query = await searchAPIDebounced(search);
    const tags = await query.json();
    if(tags && tags._embedded) {
        const filteredOutCurrents = tags._embedded.tag.filter((newTag)=>{

            if(selectedTags.length === 0) return true;

            const contains = selectedTags.filter((selected)=>{
                if(selected.id === newTag.id) return true;
                return false;
            })

            return contains.length <= 1;
        })
        setTags(filteredOutCurrents);
    }

    if(!tags || !tags._embedded) {
        setTags([]);
    }
  }


  const getTags = async ()=> {
    let token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access"
    });
    const request = await fetch(
        `${process.env.REACT_APP_URL_JAVA}/leaderboard-tag/list?leaderboardId=${props.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
    );

    const tags = await request.json();
    
    if(tags && tags._embedded) setSelectedTags(tags._embedded.leaderboardTagList);
  }


  const addNewTagToLeaderboard = async (tag)=> {
    let token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access"
    });
    const request = await fetch(
        `${process.env.REACT_APP_URL_JAVA}/leaderboard-tag`,
        {
          method: "POST",
          body: JSON.stringify({
              tagId: tag.id,
              leaderboardId: props.id
          }),
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
    );
    const tagCreated = await request.json();
    tagCreated.tag = tag;
    const selectedTagsNew = [...selectedTags];
    selectedTagsNew.push(tagCreated);

    setSelectedTags(selectedTagsNew);
    setCurrentSearch('')


    let newTags = [...tags];

    newTags = newTags.filter((currentTag)=>{
        if(currentTag.name === tag.name) return false;

        return true;
    })

    setTags(newTags);
  }

  const addNewTag = async (name)=>{
    let token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access"
    });
    const request = await fetch(
        `${process.env.REACT_APP_URL_JAVA}/tag`,
        {
          method: "POST",
          body: JSON.stringify({
              name
          }),
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
    );

    const tag = await request.json();
    addNewTagToLeaderboard(tag);
  }

  const removeTagFromLeaderboard = async(tag)=>{
    let token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access"
    });

    const request = await fetch(
        `${process.env.REACT_APP_URL_JAVA}/leaderboard-tag/${tag.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
    );

    const deletedTag = await request.json();

    let selectedTagsNew = [...selectedTags];
    selectedTagsNew = selectedTagsNew.filter((currentTag)=>{
        if(currentTag.id === tag.id) return false;
        return true;
    })
    setSelectedTags(selectedTagsNew);
  }

  useEffect(() => {
  }, [])


  return (
    <div className="AdminTagSelector">
        <h3>Tags</h3>
       <input placeholder="Enter tag..." value={currentSearch} onChange={async (e)=>{
           await handleSearch(e.target.value);
       }} type="text"></input>
       {currentSearch.length > 0 &&
        <div className="tagDropdown">
            <ul>
                {tags.map((tag)=>{
                    return <li onClick={(e)=>{
                        addNewTagToLeaderboard(tag);
                    }}>{tag.name} ({tag.leaderboardCount})</li>
                })}

                {tags.length === 0 &&
                <p>No tags found. You can create one below.</p>
                }
            </ul>
            {(currentSearch!=='') &&
                <div>
                    <div onClick={(e)=>{
                        addNewTag(currentSearch)
                    }} className="addTag">+ Add {currentSearch} tag</div>
                </div>
            }
        </div>
       }

       {currentSearch.length > 0 &&
       <div onClick={(e)=>{
        setCurrentSearch('')
    }} className="skrim"></div>
       }
       <div className="selectedTags">
           {selectedTags.map((tag)=>{
                return (
                <span 
                    onClick={(e)=>{
                        removeTagFromLeaderboard(tag);
                    }} 
                    className="tag"
                >
                    {tag.tag.name} <FontAwesomeIcon icon={faTimes} /> 
                </span>
                )
           })}
       </div>
    </div>
  );
}
