import SettingPopover from "./SettingPopover";
import Captain from "../images/captain-emoji.png";
import { useUserContext } from "../UserContext";
import { useEffect, useState } from "react";

const SettingPopoverUser = (props) => {
    const user = useUserContext().user;
    const [ image, setImage ] = useState(Captain);

    useEffect(() => {
        if (user && user.external_profile) {
            setImage(`${user.external_profile.image}?w=100`);
        }
    }, [user]);

    return (
        <div>
            <div onClick={props.onClick} className="userProfile">
              <div className="image">
                <img
                  src={image}
                  alt="user"
                />
              </div>
            </div>

            {props.popover && (
              <SettingPopover
                logout={props.logout}
                toggleModal={props.toggleModal}
                user={props.user}
              />
            )}
          </div>
    );
};

export default SettingPopoverUser;