import React, { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Role from "../components/Role";
import "../CSS/Collective/admin.scss";

const CollectiveRoleAdmin = (props) => {
  const {
    loginWithRedirect,
    user,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    logout,
  } = useAuth0();
  const userObject = JSON.parse(localStorage.getItem("user"));
  const [roles, setRoles] = useState([]);

  const [isAdmin, setIsAdmin] = useState(() => {
    const rolesJSON = localStorage.getItem("roles");
    const roles = JSON.parse(rolesJSON);
    return roles && roles.includes("Admin");
  });

  const getRoles = async () => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      loginWithRedirect();
    }

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/company-collective-roles`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();

    setRoles(data);
  };

  useEffect(() => {
    if (isAdmin) {
      getRoles();
    }
  }, []);

  if (!userObject) {
    return <div>Loading...</div>;
  }

  return (
    <div className="collectiveAdmin">
      <div className="rolesContainer">
        <div className="roles">
          {roles.map((role) => {
            return (
              <Role key={role.id} role={role} showCompany={true} />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CollectiveRoleAdmin;
