import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import SkillsSelector from "../SkillsSelector";
import TipTap from "../../Feed/components/TipTap";

function SaveRole(props) {
    const { user, isAuthenticated, tacos, getAccessTokenSilently } = useAuth0();
    const [role, setRole] = useState(props.role);
    const skillsMap =  props.role.technologies.map((s) => {
        return {
          id: s.technologyId,
          name: s.technologyName,
          required: s.required,
          score: s.score,
          dbId: s.id,
        }
    });

    const [skills, setSkills] = useState(skillsMap);
    const [removedSkills, setRemovedSkills] = useState([]);

    const handleChange = e => {
        const { name, value } = e.target;
        setRole(prevState => ({
            ...prevState,
            [name]: value
        }))
    };

    const doneSaving = async() => {
        createMatches();
        props.onDoneSaving();
    };

    const createMatches = async() => {
        const token = await getAccessTokenSilently({
            audience: `https://api-v2.behearty.co`,
            scope: "read:current_user offline_access",
          });
    
        let endpoint = `${process.env.REACT_APP_URL_JAVA}/company-collective-roles/${role.id}/matches`;
    
        fetch(endpoint, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
    };

    const save = async() => {
        const token = await getAccessTokenSilently({
            audience: `https://api-v2.behearty.co`,
            scope: "read:current_user offline_access",
          });
    
        let endpoint = `${process.env.REACT_APP_URL_JAVA}/company-collective-roles`;
        if (role.id) {
            endpoint += `/${role.id}`
        }

        let method = "POST";
        if (role.id) {
            method = "PATCH";
        }
    
        const response = await fetch(endpoint, {
            method: method,
            body: JSON.stringify(role),
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
    
        const data = await response.json();
    
        const savedSkills = await saveSkills(data.id);
        doneSaving();
    };

    const saveSkills = async (id) => {
        let token = await getAccessTokenSilently({
          audience: `https://api-v2.behearty.co`,
          scope: "read:current_user offline_access",
        });
    
        const responseArray = skills.map((skill) => {
          const body = {
            companyCollectiveRoleId: id,
            technologyId: skill.id,
            required: skill.required,
            score: skill.score
          };
          return fetch(
            `${process.env.REACT_APP_URL_JAVA}/company-collective-role-technologies`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(body),
            }
          );
        });
    
        Promise.all(responseArray).then(() => {
          return true;
        });

        const remove = removedSkills.map((skill) => {
            return fetch(
              `${process.env.REACT_APP_URL_JAVA}/company-collective-role-technologies/${skill.dbId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                method: "DELETE",
              }
            );
          });
      
        const removed = await Promise.all(remove);
      };
    
    return (
        <div className="modal">
            <h3>Save Role</h3>

            <form onSubmit={async (e)=>{
                e.preventDefault();
                await save();
            }}>
            <div className="form-group">
                <label htmlFor="roleName">Role Name</label>
                <input onChange={handleChange} type="text"
                    value={role.name}
                    name="name"
                ></input>
            </div>

            <div className="form-group">
                <label htmlFor="roleType">Type</label>
                    <select onChange={handleChange} value={role.type} name="type">
                    <option value="FRONT_END">Front End</option>
                    <option value="BACK_END">Back End</option>
                    <option value="FULL_STACK">Full Stack</option>
                    <option value="DEVOPS">Devops</option>
                    <option value="MOBILE">Mobile</option>
                    <option value="DATA_SCIENCE">Data Science</option>
                    <option value="DATA_ENGINEERING">Data Engineering</option>
                    <option value="QA">QA</option>
                </select>
            </div>

            <div className="form-group">
                <span className="checkbox">
                <label htmlFor="suppressAutomaticSuggestions">Suppress Automatic Match Emails?</label>
                <input
                    onChange={(e) => {
                        handleChange({
                            target: {
                                name: e.target.name,
                                value: e.target.checked,
                            },
                        });
                    }}
                    checked={role.suppressAutomaticSuggestions}
                    type="checkbox"
                    id="suppressAutomaticSuggestions"
                    name="suppressAutomaticSuggestions"
                />
                </span>
            </div>

            <div className="form-group">
                <label htmlFor="jobPostUrl">Job Post URL</label>
                <input onChange={handleChange} type="text"
                    value={role.jobPostUrl}
                    name="jobPostUrl"
                ></input>
            </div>

            <div className="form-group">
                <div className="inputBox">
                <label>Job Description</label>
                <TipTap
                    data={role.jobDescription}
                    editing={false}
                    update={(data) => {
                        setRole(prevState => ({
                            ...prevState,
                            jobDescription: data
                        }))
                      }}
                />
                </div>
            </div>

            <div className="form-group">
                <label htmlFor="roleNotes">Role Notes</label>
                <textarea onChange={handleChange}
                    value={role.notes}
                    name="notes"
                ></textarea>
            </div>

            <div className="form-group">
                <label htmlFor="roleName">Salary Band</label>
                <div className="band">
                    <input onChange={handleChange} placeholder="Min" type="number" name="minSalary"
                        value={role.minSalary}>
                    </input>
                    <p>-</p>
                    <input onChange={handleChange} placeholder="Max" type="number" name="maxSalary"
                        value={role.maxSalary}>
                    </input>
                </div>
            </div>

            <div className="form-group">
                <label htmlFor="roleName">Experience Level</label>
                <div className="band">
                    <input onChange={handleChange} placeholder="Min" type="number" name="minExperience"
                        value={role.minExperience}>
                    </input>
                    <p>-</p>
                    <input onChange={handleChange} placeholder="Max" type="number" name="maxExperience"
                        value={role.maxExperience}>
                    </input>
                </div>
            </div>

            <SkillsSelector
                setData={(data) => {
                    setSkills(data);
                  }}
                data={skills}
                url={"technologies"}
                showRequired={true}
                description={
                    "Please enter the skills required for the role"
                }
                setRemovedSkills={(removedSkills) => {
                    setRemovedSkills(removedSkills);
                }}
            />

            <button className="btn large" type="submit">{role.id ? "Update" : "Create"} Role</button>

            </form>
        </div>
    )
}

export default SaveRole;