import { useEffect } from "react";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Select from "react-select";
import SkillsSelector from "../components/SkillsSelector";
import "../CSS/Search/Search.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { getAuthenticatedUserCollectives } from "../LocalStorageUtil";
import ProfileCard from "../components/ProfileCard";

export default function Search(props) {
    const { getAccessTokenSilently } = useAuth0();

    const [collectives, setCollectives] = useState([]);
    const [jobFunctions, setJobFunctions] = useState([]);
    const [msas, setMsas] = useState([]);
    const [companies, setCompanies] = useState([]);

    const [keywords, setKeywords] = useState(props.location?.state?.searchTerm || "");
    const [selectedJobFunctions, setSelectedJobFunctions] = useState([]);
    const [skills, setSkills] = useState([]);
    const [msaId, setMsaId] = useState(null);
    const radius = 50;
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [minExperience, setMinExperience] = useState("");
    const [maxExperience, setMaxExperience] = useState("");
    const [isHbcu, setIsHbcu] = useState(false);
    const [selectedCollectives, setSelectedCollectives] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [searchResultsCount, setSearchResultsCount] = useState(0);
    const [showResultCount, setShowResultCount] = useState(false);

    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        getCollectives();
        getJobFunctions();
        getMsas();
        if (isIndividual()) {
            getCompanies();
        }
    }, []);

    useEffect(() => {
        if (collectives.length > 0) {
            search();
        }
    }, [collectives]);

    const getCollectives = async() => {
        let url = `${process.env.REACT_APP_URL_JAVA}/collectives`

        let token = await getAccessTokenSilently({
            audience: `https://api-v2.behearty.co`,
            scope: "read:current_user offline_access",
        });

        let headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };

        const response = await fetch(
            url,
            {
                headers,
                method: "GET",
            }
        );

        const results = await response.json();
        setCollectives(results);
    };

    const isIndividual = () => {
        const loggedInUserCollectives = getAuthenticatedUserCollectives();
        for (let loggedInUserCollective of loggedInUserCollectives) {
            // check if profile has role TALENT
            if (loggedInUserCollective.roles.includes("TALENT")) {
                return true;
            }
        }
    
        return false;
    };

    const isRecruiter = () => {
        const loggedInUserCollectives = getAuthenticatedUserCollectives();
        for (let loggedInUserCollective of loggedInUserCollectives) {
            if (loggedInUserCollective.roles.includes("EMPLOYEE") || loggedInUserCollective.roles.includes("EMPLOYER_ADMIN")) {
                return true;
            }
        }
    
        return false;
    };

    const getCompanies = async () => {
        const collectives = getAuthenticatedUserCollectives();
    
        let token = false;
        let headers = {};
        try {
          token = await getAccessTokenSilently({
            audience: `https://api-v2.behearty.co`,
            scope: "read:current_user offline_access",
          });
    
          headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          };
        } catch (error) {}
    
        let url = `${process.env.REACT_APP_URL_JAVA}/company-collectives?`
        if (collectives) {
          for (let i = 0; i < collectives.length; i++) {
            url += `collectiveId=${collectives[i].collectiveId}&`;
          }
        }
    
        const response = await fetch(
          url,
          {
            headers,
          }
        );
        const data = await response.json();
        setCompanies(data.content.sort((a, b) => a.name.localeCompare(b.name)));
    };

    const getJobFunctions = async() => {
        let url = `${process.env.REACT_APP_URL_JAVA}/job-functions`

        let token = await getAccessTokenSilently({
            audience: `https://api-v2.behearty.co`,
            scope: "read:current_user offline_access",
        });

        let headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };

        const response = await fetch(
            url,
            {
                headers,
                method: "GET",
            }
        );

        const results = await response.json();
        setJobFunctions(results);
    };

    const getMsas = async() => {
        let url = `${process.env.REACT_APP_URL_JAVA}/location/msas`

        let token = await getAccessTokenSilently({
            audience: `https://api-v2.behearty.co`,
            scope: "read:current_user offline_access",
        });

        let headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };

        const response = await fetch(
            url,
            {
                headers,
                method: "GET",
            }
        );

        const results = await response.json();
        setMsas(results.map(msa => ({ label: msa.name.replace(" Metropolitan Area", ""), value: msa.id })));
    };

    const setMsa = (e) => {
        if (e != null) {
            setMsaId(e.value);
        } else {
            setMsaId(null);
        }
    };

    const search = async() => {
        await fetchData(0);
    };

    const fetchData = async(pageNumber) => {
        let url = `${process.env.REACT_APP_URL_JAVA}/external-profiles/search?page=${pageNumber}&size=10&`

        if (keywords) {
            url += `q=${keywords}&`;
        }

        if (selectedJobFunctions.length > 0) {
            selectedJobFunctions.forEach((jf) => {
                url += `jobFunctionId=${jf.value}&`;
            });
        }

        if (skills.length > 0) {
            skills.forEach((skill) => {
                url += `skill=${skill.name}&`;
            });
        }

        if (msaId) {
            url += `msaId=${msaId}&`;
        }

        if (radius) {
            url += `radius=${radius}&`;
        }

        if (selectedCompanies.length > 0) {
            selectedCompanies.forEach((c) => {
                url += `companyId=${c.value}&`;
            });
        }

        if (minExperience) {
            url += `minExperience=${minExperience}&`;
        }

        if (maxExperience) {
            url += `maxExperience=${maxExperience}&`;
        }

        if (selectedCollectives.length > 0) {
            selectedCollectives.forEach((c) => {
                url += `collectiveId=${c.value}&`;
            });
        } else {
            collectives.forEach((c) => {
                url += `collectiveId=${c.id}&`;
            });
        }

        if (isHbcu) {
            url += `isHbcu=${isHbcu}&`;
        }

        let token = await getAccessTokenSilently({
            audience: `https://api-v2.behearty.co`,
            scope: "read:current_user offline_access",
        });

        let headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };

        const response = await fetch(
            encodeURI(url),
            {
                headers,
                method: "GET",
            }
        );

        const results = await response.json();
        setPage(pageNumber + 1);
        setHasMore(results.last === false);
        setSearchResultsCount(results.totalElements);
        
        if (pageNumber > 0) {
            const newSearchResults = [...searchResults, ...results.content];
            setSearchResults(newSearchResults);
        } else {
            setSearchResults(results.content);
        }
    };

    const addJobFunction = (jobFunctions) => {
        setSelectedJobFunctions(jobFunctions);
    };

    const removeJobFunction = (id) => {
        setSelectedJobFunctions(selectedJobFunctions.filter((jf) => jf.value !== id));
    };

    const addCompany = (companies) => {
        setSelectedCompanies(companies);
    };

    const removeCompany = (id) => {
        setSelectedCompanies(selectedCompanies.filter((c) => c.value !== id));
    };

    const removeSkill = (name) => {
        setSkills(skills.filter((skill) => skill !== name));
    };

    const addCollective = (collectives) => {
        setSelectedCollectives(collectives);
    };

    const removeCollective = (id) => {
        setSelectedCollectives(selectedCollectives.filter((c) => c.value !== id));
    };

    const getProfileBadges = (profile) => {
        const badges = [];
        const loggedInUserCollectives = getAuthenticatedUserCollectives();
        for (let loggedInUserCollective of loggedInUserCollectives) {
            for (let profileCollective of profile.collectives) {
                if (loggedInUserCollective.collectiveId === profileCollective.id) {
                    if (profileCollective.roles.map(r => r.name).includes("ALLY")) {
                        badges.push("ALLY");
                    }
                    if (profileCollective.roles.map(r => r.name).includes("EMPLOYEE") || profileCollective.roles.map(r => r.name).includes("EMPLOYER_ADMIN")) {
                        badges.push("RECRUITER");
                    }
                    if (profileCollective.roles.map(r => r.name).includes("ERG")) {
                        badges.push("ERG");
                    }
                }
            }
        }
        return badges;
    };

    const selectStyle = {
        container: (baseStyles, state) => ({
          ...baseStyles,
          height: '40px',
          fontSize: '14px',
        }),
        control: (baseStyles, state) => ({
            ...baseStyles,
            border: 'solid 3px #f2f2f2',
            borderRadius: '10px',
        }),
        valueContainer: (baseStyles, state) => ({
            ...baseStyles,
            paddingLeft: '20px',
            paddingTop: '0px',
            paddingBottom: '0px',
        }),
      };

    return (
        <div className="search">
            <div className="search-filters">
                {isRecruiter() ? (
                    <h2>Search Candidates</h2>
                ) : (
                    <>
                    <h2>Community Connections</h2>
                    <p>Search the collective and to connect with allies and fellow members of your community</p>
                    </>
                )}
                
                <form className="filter-container" autoComplete="off"
                    onSubmit={async (e) => {
                        e.preventDefault();
                        await search();
                        setShowResultCount(true);
                    }}>
                    <div className="filter">
                        <div className="label"><label htmlFor="keywords">Keywords</label></div>
                        <div className="input">
                            <input type="text" className="input" id="keywords" name="keywords" placeholder="Search keywords" value={keywords} onChange={(e) => setKeywords(e.target.value)} />
                        </div>
                    </div>
                    <div className="filter">
                        <div className="label"><label htmlFor="job-function">Function</label></div>
                        <Select
                            name="job-function"
                            options={jobFunctions.map((jobFunction) => ({ label: jobFunction.name, value: jobFunction.id }))}
                            onChange={(e) => {
                                addJobFunction(e);
                            }}
                            placeholder="Choose Function"
                            styles={selectStyle}
                            isMulti
                            controlShouldRenderValue={false}
                            value={selectedJobFunctions}
                            isSearchable={false}
                        />
                        <div className="selected">
                            {selectedJobFunctions.map((jf) => (
                                <div key={jf.value} className="selected-item">
                                    <span>{jf.label}</span>
                                    <button onClick={() => removeJobFunction(jf.value)}>X</button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="filter">
                        <SkillsSelector
                            setData={(data) => {
                                setSkills(data);
                            }}
                            data={skills}
                            url={"skills"}
                            description={"Skills"}
                            searchText={"Add Skills"}
                            setRemovedSkills={(removedSkills) => {
                                removedSkills.forEach((skill) => removeSkill(skill.name));
                            }}
                            showX={true}
                        />
                    </div>
                    <div className="filter">
                        <div className="label"><label htmlFor="msa">Location</label></div>
                        <Select 
                            id="msa"
                            name="msa"
                            placeholder="Choose Location"
                            options={msas}
                            isClearable={true}
                            clearValue={() => setMsa(null)}
                            onChange={(e) => setMsa(e)}
                            styles={selectStyle}
                        />
                    </div>
                    {isRecruiter() && (
                        <>
                        <div className="filter">
                            <div className="label"><label htmlFor="hbcu">HBCU</label>
                                <input type="checkbox" id="hbcu" name="hbcu" checked={isHbcu} onChange={(e) => setIsHbcu(e.target.checked)} />
                            </div>
                        </div>
                        <div className="filter">
                            <div className="label"><label htmlFor="min-experience">Years of Experience</label></div>
                            <div className="experience-input">
                                <input className="input" type="number" id="min-experience" name="min-experience" placeholder="Min" value={minExperience} onChange={(e) => setMinExperience(e.target.value)} />
                                <input className="input" type="number" id="max-experience" name="max-experience" placeholder="Max" value={maxExperience} onChange={(e) => setMaxExperience(e.target.value)} />
                            </div>
                        </div>
                        </>
                    )}
                    {collectives.length > 1 && (
                        <div className="filter">
                            <div className="label"><label htmlFor="collectives">Collectives</label></div>
                            <Select 
                                name="collectives"
                                options={collectives.map((collective) => ({ label: collective.name, value: collective.id }))}
                                onChange={(e) => {
                                    addCollective(e);
                                }}
                                placeholder="Choose Collective"
                                styles={selectStyle}
                                isMulti
                                controlShouldRenderValue={false}
                                value={selectedCollectives}
                                isSearchable={false}
                            />
                            <div className="selected">
                                {selectedCollectives.map((c) => (
                                    <div key={c.value} className="selected-item">
                                        <span>{c.label}</span>
                                        <button onClick={() => removeCollective(c.value)}>X</button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {isIndividual() && (
                    <div className="filter">
                        <div className="label"><label htmlFor="company">Company</label></div>
                        <Select
                            name="company"
                            options={companies.map((company) => ({ label: company.name, value: company.companyId }))}
                            onChange={(e) => {
                                addCompany(e);
                            }}
                            placeholder="Choose Company"
                            styles={selectStyle}
                            isMulti
                            controlShouldRenderValue={false}
                            value={selectedCompanies}
                            isSearchable={true}
                        />
                        <div className="selected">
                            {selectedCompanies.map((c) => (
                                <div key={c.value} className="selected-item">
                                    <span>{c.label}</span>
                                    <button onClick={() => removeCompany(c.value)}>X</button>
                                </div>
                            ))}
                        </div>
                    </div>
                    )}
                    <div className="button-container">
                        <button type="submit">Search</button>
                    </div>
                </form>
            </div>
            <div className="search-results">
                <div className="result-count">
                    {showResultCount ? (
                        <p>{searchResultsCount >= 10000 ? '>' + searchResultsCount.toLocaleString() : searchResultsCount.toLocaleString()} results</p>
                    ) : (
                        <p>&nbsp;</p>
                    )}
                </div>
                
                {searchResults && searchResults.length > 0 && (
                    <InfiniteScroll
                        dataLength={searchResults.length}
                        next={() => { return fetchData(page) }}
                        hasMore={hasMore}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                              <b>End of results</b>
                            </p>
                          }
                    >
                        {searchResults.map((searchResult) => (
                            <ProfileCard key={searchResult.id} profile={searchResult} badges={getProfileBadges(searchResult)} />
                        ))}
                    </InfiniteScroll>
                )}
            </div>
        </div>
    );
}