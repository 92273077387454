import React from 'react';
import './../../App.scss';
import moment from 'moment'
import HeartyName from "../../components/HeartyName";


class Bubble extends React.Component {
  constructor(props) {
    super(props);
    let orientation = "left";
    const user = JSON.parse(localStorage.getItem('user'));

    if(props.message.userId === user.id) orientation = 'right'
    this.state = {
      orientation
    };

    this.markAsRead = this.markAsRead.bind(this)
  }

  async markAsRead(message) {
    let token = await this.props.auth0.getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access"
    });

    const data = {
      isRead: true
    }

    fetch(`${process.env.REACT_APP_URL_JAVA}/messages/${message.id}`, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(async (data)=>{
      this.props.message.isRead = true;
      this.props.isNowRead(message)
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  convertLinks( input ) {

    let text = input;
    const linksFound = text.match( /(?:www|https?)[^\s]+/g );
    const aLink = [];
  
    if ( linksFound != null ) {
  
      for ( let i=0; i<linksFound.length; i++ ) {
        let replace = linksFound[i];
        if ( !( linksFound[i].match( /(http(s?)):\/\// ) ) ) { replace = 'http://' + linksFound[i] }
        let linkText = replace.split( '/' )[2];
        if ( linkText.substring( 0, 3 ) == 'www' ) { linkText = linkText.replace( 'www.', '' ) }
        if ( linkText.match( /youtu/ ) ) {
  
          let youtubeID = replace.split( '/' ).slice(-1)[0];
          aLink.push( '<div class="video-wrapper"><iframe src="https://www.youtube.com/embed/' + youtubeID + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>' )
        }
        else if(replace.match(/\.(jpeg|jpg|gif|png)$/)) {
          aLink.push( `<img src="${replace}" />` )
        }
        else if ( linkText.match( /vimeo/ ) ) {
          let vimeoID = replace.split( '/' ).slice(-1)[0];
          aLink.push( '<div class="video-wrapper"><iframe src="https://player.vimeo.com/video/' + vimeoID + '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>' )
        }
        else {
          aLink.push( '<a href="' + replace + '" target="_blank">' + replace + '</a>' );
        }
        text = text.split( linksFound[i] ).map(item => { return aLink[i].includes('iframe') ? item.trim() : item } ).join( aLink[i] );
      }
      const textFiltered = text.split("\n").join("<br/>");
      return textFiltered;
  
    }
    else {
      return input;
    }
  }

  render() {    
    let orientation = "left";
    if(this.props.message.me === true) orientation = 'right';

    // if(this.props.message.fromUser.id !== me.id) {
    //   this.markAsRead(this.props.message);
    // }

    
    return (
    <div className={`messageBubble ${orientation}`}>
        {orientation === 'left' &&
        <img src={this.props.externalProfile.image} />
        }
        <div className="content">
            <h4><HeartyName 
                    name={this.props.externalProfile.name}
                    isProven={false}
                    /> <span>{moment(this.props.message.created_at).fromNow()}</span></h4>
            <p dangerouslySetInnerHTML={{__html: this.convertLinks(this.props.message.message)}}></p>
        </div>
        {orientation === 'right' &&
        <img style={{
          marginLeft:'10px'
        }} src={this.props.externalProfile.image} />
        }
    </div>
    );
  }
}

export default Bubble;
