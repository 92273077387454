import "./CSS/EditProfile.scss";
import React, { useState, useEffect } from "react";
import Captain from "./images/captain-emoji.png";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { useHistory } from "react-router-dom";

export default function EditProfile(props) {
  const history = useHistory();
  const [id, setId] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [selectedJobFunctions, setSelectedJobFunctions] = useState([]);
  const [zip, setZip] = useState("");
  const [headline, setHeadline] = useState("");
  const [isCommunityConnections, setIsCommunityConnections] = useState(null);
  const [areaOfExpertise, setAreaOfExpertise] = useState("");
  const [monthlyAllyMeetingCount, setMonthlyAllyMeetingCount] = useState(0);
  const [rewarding, setRewarding] = useState("");
  const [hobbies, setHobbies] = useState("");
  const [hometown, setHometown] = useState("");

  const [profileImage, setProfileImage] = useState(null);
  const [headerImage, setHeaderImage] = useState(null);
  const [allJobFunctions, setAllJobFunctions] = useState([]);
  const [currentJobFunctionNames, setCurrentJobFunctionNames] = useState([]);
  const [error, setError] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getAllJobFunctions();
    getUser();
  }, []);

  useEffect(() => {
    let jfs = [];
    allJobFunctions.forEach((jobFunction) => {
      if (currentJobFunctionNames.includes(jobFunction.name)) {
        jfs = [...jfs, {value: jobFunction.id, label: jobFunction.name}];
      }
    });
    setSelectedJobFunctions(jfs);
  }, [currentJobFunctionNames, allJobFunctions]);

  const getUser = async () => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    await fetch(`${process.env.REACT_APP_URL_JAVA}/users/me`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      setId(data.id);
      setFirstName(data.first_name);
      setLastName(data.last_name);
      setZip(data.external_profile.zip);
      setHeadline(data.external_profile.headline);
      setIsCommunityConnections(data.is_community_connections);
      setAreaOfExpertise(data.area_of_expertise);
      setMonthlyAllyMeetingCount(data.monthly_ally_meeting_count);
      setRewarding(data.rewarding);
      setHobbies(data.hobbies);
      setHometown(data.hometown);
      setProfileImage(data.external_profile.image);
      setHeaderImage(data.external_profile.headerImage);
      return data;
    })
    .then((user) => {
      return getJobFunctions(user.external_profile.id);
    })
    .then((jobFunctions) => {
      if (jobFunctions.length > 0) {
        setCurrentJobFunctionNames(jobFunctions);
      }
    })
    .catch((error) => {
      console.log(error);
    });
  };

  const getAllJobFunctions = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/job-functions`,
      {
        method: "GET",
      }
    );
    const jobFunctions = await response.json();
    setAllJobFunctions(jobFunctions);
  };

  const getJobFunctions = async (externalProfileId) => {
    await getAllJobFunctions();

    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    return await fetch(
      `${process.env.REACT_APP_URL_JAVA}/external-profiles/${externalProfileId}/job-functions`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const save = async () => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    await fetch(
      `${process.env.REACT_APP_URL_JAVA}/users/${id}/profile`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName: firstName,
          lastName: lastName,
          jobFunctionIds: selectedJobFunctions.map((j) => j.value),
          headline: headline,
          zip: zip,
          isCommunityConnections: isCommunityConnections,
          areaOfExpertise: areaOfExpertise,
          monthlyAllyMeetingCount: monthlyAllyMeetingCount,
          rewarding: rewarding,
          hobbies: hobbies,
          hometown: hometown,
          imageUrl: profileImage,
        }),
      }
    )
    .then((response) => {
      window.location.href = "/";
    });
  };

  // On file select (from the pop up)
  const onHeaderFileChange = async (file) => {
    onHeaderFileUpload(file[0]);
  };

  // On file upload (click the upload button)
  const onHeaderFileUpload = async (selectedImage) => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", selectedImage, selectedImage.name);

    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/files/uploadFile?directory=header`,
      {
        method: "POST",
        enctype: "multipart/form-data",
        contentType: false,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    )
      .then((response) => {
        console.log(response);
        if (response.ok) {
          return response.text();
        }

        throw new Error("Error uploading image");
      })
      .then((data) => {
        setHeaderImage(data);
        saveHeaderImage(data);
      })
      .catch((error) => {});
  };

  const saveHeaderImage = async (header_image) => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const response = await fetch(`${process.env.REACT_APP_URL_JAVA}/users/me`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const userObject = await response.json();

    userObject.external_profile.headerImage = header_image;

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/external-profiles/${userObject.external_profile.id}`,
      {
        method: "PUT",
        body: JSON.stringify({
          ...userObject.external_profile,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        userObject.External_Profile = data;
        userObject.external_profile = data;

        localStorage.setItem("user", JSON.stringify(userObject));
      });
  };

  // On file select (from the pop up)
  const onFileChange = async (file) => {
    onFileUpload(file[0]);
  };

  // On file upload (click the upload button)
  const onFileUpload = async (selectedImage) => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", selectedImage, selectedImage.name);

    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    fetch(
      `${process.env.REACT_APP_URL_JAVA}/files/uploadFile?directory=avatar`,
      {
        method: "POST",
        enctype: "multipart/form-data",
        contentType: false,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    )
      .then((response) => {
        console.log(response);
        if (response.ok) {
          return response.text();
        }

        throw new Error("Error uploading image");
      })
      .then((data) => {
        setError(false);
        setProfileImage(data);
      })
      .catch((error) => {
        setError("Issue uploading image");
      });
  };

  const addJobFunction = (jobFunctions) => {
    setSelectedJobFunctions(jobFunctions);
  };

  const headerImageConditional = () => {
    if (headerImage) {
      return {
        backgroundImage: `url(${headerImage})`,
      };
    } else {
      return {
        backgroundColor: "#b70000",
      };
    }
  };

  return (
    <div className="editProfile">
      <div className="modal">
        {error && <p className="error alert">{error}</p>}
        <div className="headerImage" style={headerImageConditional()}>
          <div className="imageUploadBox" title="Upload new header image">
            <div className="svg">
              <FontAwesomeIcon icon={faEdit} />
            </div>
            <input type="file" onChange={(e) => onHeaderFileChange(e.target.files)} />
          </div>
        </div>
        <div className="left">
          <div className="imageUploadBox">
            <div className="svg">
              <FontAwesomeIcon icon={faEdit} />
            </div>
            <input type="file" onChange={(e) => onFileChange(e.target.files)} />
            <div className="uploadShow">
              <p>Upload New Photo</p>
            </div>
            <img
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = Captain;
              }}
              src={profileImage}
              alt="avatar"
            />
          </div>
        </div>
        <div className="right">
          <div className="userInfo">
            <h2>Edit Profile</h2>
            <div className="name">
              <div className="inputBox firstName">
                <label>First Name</label>
                <input
                  onChange={(e) => setFirstName(e.target.value)}
                  type="text"
                  value={firstName}
                />
              </div>
              <div className="inputBox lastName">
                <label>Last Name</label>
                <input
                  onChange={(e) => setLastName(e.target.value)}
                  type="text"
                  value={lastName}
                />
              </div>
            </div>
            <div className="inputBox headline">
              <label>Headline</label>
              <input
                type="text"
                placeholder="Headline"
                onChange={(e) => {
                  setHeadline(e.target.value);
                }}
                value={headline}
              />
            </div>
            <div className="inputBox zipcode">
              <label>Zipcode</label>
              <input
                type="text"
                pattern="[0-9]*"
                value={zip}
                onChange={(e) => {
                    setZip(e.target.value);
                }}
              />
            </div>
            <div className="inputBox jobFunction">
              <label htmlFor="jobFunction">Function</label>
              <Select
                  name="job-function"
                  options={allJobFunctions.map((jobFunction) => ({ label: jobFunction.name, value: jobFunction.id }))}
                  onChange={(e) => {
                      addJobFunction(e);
                  }}
                  placeholder="Choose Function"
                  // styles={selectStyle}
                  isMulti
                  value={selectedJobFunctions}
                  isSearchable={false}
              />
            </div>
            <div className="inputBox communityConnections">
              <label
                title="Community Connections is a way to meet Allies and fellow community members for networking, advice, and opportunities. By opting in to the program, you can search the network and request time to connect."
              >Included in community connections?</label>
              <div className="inputBox radio">
                <input
                  onChange={(e) => {
                    setIsCommunityConnections(false);
                  }}
                  value={false}
                  type="radio"
                  checked={isCommunityConnections === false}
                  id="communityConnectionsNo"
                />
                <span><label htmlFor="communityConnectionsNo">No</label></span>
              </div>
              <div className="inputBox radio">
                <input
                  onChange={(e) => {
                    setIsCommunityConnections(true);
                  }}
                  value={true}
                  type="radio"
                  checked={isCommunityConnections === true}
                  id="communityConnectionsYes"
                />
                <span><label htmlFor="communityConnectionsYes">Yes</label></span>
              </div>
            </div>
            {isCommunityConnections && (
              <>
              <div className="inputBox monthlyAllyMeetingCount">
                <label>How many ally meetings per month will you commit to?</label>
                <input
                  type="number"
                  value={monthlyAllyMeetingCount}
                  onChange={(e) => {
                      setMonthlyAllyMeetingCount(e.target.value);
                  }}
                />
              </div>
              <div className="inputBox areaOfExpertise">
                <label>Areas of expertise</label>
                <textarea onChange={(e) => {
                    setAreaOfExpertise(e.target.value);
                }} value={areaOfExpertise} />
              </div>
              <div className="inputBox rewarding">
                <label>What’s something about work that you find most rewarding?</label>
                <textarea onChange={(e) => {
                    setRewarding(e.target.value);
                }} value={rewarding} />
              </div>
              <div className="inputBox hobbies">
                <label>Hobbies</label>
                <textarea onChange={(e) => {
                    setHobbies(e.target.value);
                }} value={hobbies} />
              </div>
              <div className="inputBox hometown">
                <label>What’s your hometown and why should everyone visit?</label>
                <textarea onChange={(e) => {
                    setHometown(e.target.value);
                }} value={hometown} />
              </div>
              </>
            )}

            <button
              className="saveButton"
              onClick={(e) => {
                save();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}