import React from 'react';
import './App.scss';
import {
  Redirect,
} from "react-router-dom";
import Captain from "./images/captain-emoji.png";

import MessagingSidebar from './components/MessagingSidebar';
import Modal from './components/Modal';
import AddMemberModal from './components/AddMemberModal';
import AddConversation from './components/AddConversation';

import MessageBubble from './components/Messaging/Bubble';
import './CSS/Messaging.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faWindowRestore } from '@fortawesome/free-solid-svg-icons';
import queryString from 'query-string';


class Messaging extends React.Component {
  constructor(props) {
    super(props);
    const userJSON = localStorage.getItem('user');
    let user = JSON.parse(userJSON);

    if(!user) {
      user = {
        id: 0
      }
    }

    let query = false;

    if(props.location) {
      query = queryString.parse(props.location.search).conversationId;
    }

    this.state = {
      query,
      anonymous: true,
      myId: Number(user.id) || 0,
      messages: [],
      messageUsers: [],
      updateConversations: false,
      currentConversation: false,
      conversationUserObjects: [],
      viewMember: false,
      leaveConverstionConfirmation: false,
      addMember: false,
      auth0: {},
      isAdmin: props.isAdmin === true ? true : false,
    }
    this.listRef = React.createRef();
    this.handleChange = this.handleChange.bind(this)
    this.changeMessage = this.changeMessage.bind(this);
    this.newConversation = this.newConversation.bind(this)
  }

  async componentDidMount() {
    let token = false;
    try {
      token = await this.props.auth0.getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access"
      });
      this.setState({
        auth0: this.props.auth0
      })
    } catch (error) {
      window.location.url = '/login'
    }

    if(!token) window.location.url = '/login'

    // They aren't logged in.
    if(!token) {
      return this.setState({
        redirect: '/login'
      })
    }
  }

  handleChange(event) {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  async leaveConversation() {
    const token = await this.props.auth0.getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access"
    });

    await fetch(`${process.env.REACT_APP_URL_JAVA}/conversation-user?conversationId=${this.state.currentConversation.id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then(response => response.json())
    .then(async (data)=>{
      window.location.reload();
    })
  }

  async addMember(data) {
    const token = await this.props.auth0.getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access"
    });

    const promiseObjects = data.users.map((user)=>{
      const dataObject = {
        conversation_id: this.state.currentConversation.id,
        user_id: user.id
      }
      return fetch(`${process.env.REACT_APP_URL_JAVA}/conversation-user`, {
        method: 'POST',
        body: JSON.stringify(dataObject),
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
    })

    Promise.all(promiseObjects)
    .then((values)=>{
      this.setState({
        updateConversations: true,
        addMember: false
      })

      const newConversation = {...this.state.currentConversation, conversationId: this.state.currentConversation.id}

      this.changeMessage(newConversation);
      
      setTimeout(() => {
        this.setState({
          updateConversations: false
        })
      }, 1000);
    })
  }

  async setRead(id) {
    const token = await this.props.auth0.getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access"
    });
    fetch(`${process.env.REACT_APP_URL_JAVA}/conversation-message-tracking/read?conversationId=${id}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.text())
    .then((convo)=>{
      console.log(convo);
    })
  }

  async changeMessage(conversation) {
    const token = await this.props.auth0.getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access"
    });

    let id = conversation.conversationId;

    if(!conversation.conversationId) id = conversation.id

    await fetch(`${process.env.REACT_APP_URL_JAVA}/conversation/${id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(async (data)=>{
      const me = JSON.parse(localStorage.getItem('user'));
      this.setRead(data.id);
      const messages = data.conversation_message.map((message)=>{
        if(message.user && Number(message.user.id) === Number(me.id)) message.me = true;

        return message;
      }).reverse();

      const messageUsers = data.conversation_users.filter((message)=>{
        if(message.user && Number(message.user.id) === Number(me.id)) return false;

        return true;
      })

      const conversationUserObjects =  data.conversation_users.map((message)=>{
        return message.user;
      })

      this.setState({
        messageUsers,
        conversationUserObjects,
        messages,
        currentConversation: data
      })
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  async submit() {
    this.setState({
      disableSubmit: true
    })

    if(!this.state.inputText || this.state.inputText.trim() === '') return ;

    const userJSON = localStorage.getItem('user');
    const me = JSON.parse(userJSON);
    const userList = this.state.currentConversation.conversation_users;

    const toUserList = userList
    .filter((user)=>{
      const id = user.user.id;
      if(Number(id) === Number(me.id)) return false;
      return true;
    })
    .map((user)=>{
      return user.user.id
    });

    let fromUserId = me.id;
    // reply as the creator of the conversation (admin) if isAdmin is true
    if (this.state.isAdmin) {
      fromUserId = this.state.currentConversation.user_id;
    }

    const data = {
      message: this.state.inputText,
      userId: fromUserId,
      toUserList,
      conversationId: this.state.currentConversation.id
    }

    const token = await this.props.auth0.getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access"
    });

    fetch(`${process.env.REACT_APP_URL_JAVA}/conversation`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
    .then((data)=>{
      return data.json();
    })
    .then((data)=>{
      if (!this.state.isAdmin) {
        this.setState({
          disableSubmit: false
        })
      }
      this.changeMessage(this.state.currentConversation);
      this.setState({
        inputText: '',
        messageSent: true
      })
      setTimeout(() => {
        this.setState({
          messageSent: false
        })
      }, 5000);
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  handleChange(e) {
    if (!this.state.isAdmin) {
      this.setState({
        disableSubmit: false
      })
      this.setState({
        [e.target.name]: e.target.value
      })
    }
  }

  getMemberContent() {
    return ( 
    <div className="members">
      {this.state.currentConversation.conversation_users.map((user)=>{
        return (
          <div className="user">
            <img onError={(e)=>{e.target.onerror = null; e.target.src=Captain}} src={user.user.external_profile.image} />
            <div>
            <h3>{user.user.external_profile.name}</h3>
            <h3>{user.user.external_profile.headline}</h3>
            </div>
          </div>
        )
      })}
    </div>
    )
  }

  getMessageExternalProfile(message) {
    return message.user ? message.user.external_profile : message.external_profile;
  }

  renderImage(message) {
    const me = JSON.parse(localStorage.getItem('user'));
    const userList = message.conversation_users;

    const userListObject = userList.map((u, idx)=>{
      const user = u.user ? u.user : u.external_profile;
      const ep = this.getMessageExternalProfile(message);
      if(user.user && Number(user.user.id) === Number(me.id)) return null;
      const name = ep.name;
      const image = ep.image;
      const customUrl = ep.customUrl;
      
      return {
        id: ep.id,
        name,
        image,
        customUrl
      }
    })
    .filter((item)=>{
      return (item !== null && item !== false);
    })
    .map((item)=>{
      return <a href={`/user/${item.customUrl}`} key={item.customUrl}><img onError={(e)=>{e.target.onerror = null; e.target.src=Captain}} src={item.image} /></a>
    })
    .filter((item, idx)=>{
      if(idx > 1) return false;

      return true;
    })

    return <div className={`images ${userListObject.length > 1 && 'multiple'}`}>{userListObject}</div>
  }

  newConversation() {
    this.setState({
      newConversation: true
    })
  }

  async addConversation(data) {
    const me = JSON.parse(localStorage.getItem('user'));

    const toUserList = data.users.map((user)=>{
      return user.id;
    })

    if(!data.message || data.message.trim() === '') return ;

      

    const dataObject = {
      message: data.message,
      userId: me.id,
      toUserList,
    }

    const token = await this.props.auth0.getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access"
    });

    fetch(`${process.env.REACT_APP_URL_JAVA}/conversation`, {
      method: 'POST',
      body: JSON.stringify(dataObject),
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
    .then((data)=>{
      return data.json();
    })
    .then((conversation)=>{
      this.setState({
        updateConversations: true,
        newConversation: false
      })

      const conversationNew = {...conversation, conversationId:conversation.id}

      this.changeMessage(conversationNew);
      
      setTimeout(() => {
        this.setState({
          updateConversations: false
        })
      }, 1000);
    });
  }

  renderName(message) {

    const me = JSON.parse(localStorage.getItem('user'));
    const userList = message.conversation_users;

    const userListObject = userList.map((u)=>{
      const user = u.user ? u.user : u.external_profile;
      const ep = u.user ? u.user.external_profile : u.external_profile;
      if(user.user && Number(user.user.id) === Number(me.id)) return null;
      const name = ep.name;
      const image = ep.image;
      
      return {
        id: ep.id,
        name,
        image,
      }
    })
    .filter((item)=>{
      return item !== null;
    })
    .reduce((curr, item)=>{
      let current = curr;

      current = current + item.name + ', '
      return current;
    }, '').slice(0,-2);

    return userListObject;
  }

  renderHeadline(message) {

    const me = JSON.parse(localStorage.getItem('user'));
    const userList = message.conversation_users;

    const userListObject = userList.map((u)=>{
      const user = u.user ? u.user : u.external_profile;
      const ep = u.user ? u.user.external_profile : u.external_profile;
      if(user.user && Number(user.user.id) === Number(me.id)) return null;
      const headline = ep.headline
      
      return {
        headline
      }
    })
    .filter((item)=>{
      return item !== null;
    })
    .reduce((curr, item)=>{
      let current = curr;

      current = current + item.headline
      return current;
    }, '')

    return userListObject;
  }


  render() {

    if (this.state.redirect) return <Redirect to={this.state.redirect} />

    if(!this.state.currentConversation) {
      return (
        <div className="app messaging">
          <MessagingSidebar
          changeMessage={this.changeMessage}
          auth0={this.props.auth0}
          newConversation={this.newConversation}
          query={this.state.query}
          isAdmin={this.state.isAdmin}
          />
          <div className="messagingContent nullState">
           <p className="blank">Please choose a conversation in the sidebar to your left!</p>
          </div>

          {this.state.viewMembers &&
          <Modal close={(e)=>{
            this.setState({
              viewMembers: false
            })
          }} headline="Current Members" content={this.getMemberContent()} />
        }

        {this.state.leaveConverstionConfirmation &&
        <Modal  
          close={(e)=>{
            this.setState({
              leaveConverstionConfirmation: false
            })
          }} 
          headline="Leave Group Message" 
          content={<p>Are you sure you want to leave the group message? You will lose access to any updates or new messages.</p>} 
          confirm={(e)=>{
            this.leaveConversation();
          }}
          cancel={(e)=>{
            this.setState({
              leaveConverstionConfirmation: false
            })
          }}
          cancelText="Cancel"
          confirmButtonText="Yes, Leave"
        />
        }

        {this.state.addMember &&
          <AddMemberModal
          close={(e)=>{
            this.setState({
              addMember: false
            })
          }}
          confirm={(data)=>{
            this.addMember(data);
          }}
          cancel={(e)=>{
            this.setState({
              addMember: false
            })
          }}
          conversationUserObjects= {this.state.conversationUserObjects}
          headline="Add Members To Conversation"
          cancelText="Cancel"
          confirmButtonText="Add"
          />
        }

        {this.state.newConversation &&
          <AddConversation
          close={(e)=>{
            this.setState({
              newConversation: false
            })
          }}
          confirm={(data)=>{
            this.addConversation(data);
          }}
          cancel={(e)=>{
            this.setState({
              newConversation: false
            })
          }}
          headline="New Conversation"
          cancelText="Cancel"
          confirmButtonText="Send"
          />
        }

        </div>
      )
    }

    return (
      <div className="app messaging">
        <MessagingSidebar 
        changeMessage={this.changeMessage}
        newConversation={this.newConversation}
        updateConversations={this.state.updateConversations}
        />

        <div className="messagingContent">
          {this.state.messageSent &&
            <div className="alert success">Your message was sucesfully sent.</div>
          }
          <div className="messagingUserInfo">
            <div className="background"></div>

            {this.state.messageUsers.length > 1 &&
              <div className="multipleMessage">
                <div className='messageUsersImages'>
                  {this.renderImage(this.state.currentConversation)}
                </div>
                <div className="messageUsersNames">
                  <p>{this.renderName(this.state.currentConversation)}</p>
                </div>
                <br />
                <span onClick={(e)=>{
                  this.setState({
                    viewMembers: true
                  })
                }} className="view">View Members</span>
              </div>
            }

            {this.state.messageUsers.length === 1 &&
              <div className="singleMessage">
                <div className='messageUsersImages'>
                  {this.renderImage(this.state.currentConversation)}
                </div>
                <div className="messageUsersNames">
                  <p>{this.renderName(this.state.currentConversation)}</p>
                  <p>{this.renderHeadline(this.state.currentConversation)}</p>
                </div>
              </div>
            }

            <div className="actions">
              {!this.state.isAdmin && this.state.messageUsers.length > 1 &&
                <span onClick={(e)=>{
                  this.setState({
                    leaveConverstionConfirmation: true
                  })
                }}>Leave</span>
              }

              {!this.state.isAdmin &&
                <span className="addMember" onClick={(e)=>{ 
                  this.setState({
                    addMember: true
                  })
                }}>Add Member</span>
              }
            </div>

          </div>

          <div className="messagingConversation">
            <div className="messages">
              {this.state.messages.map((message)=>{
                return <MessageBubble message={message} key={message.id} externalProfile={this.getMessageExternalProfile(message)} />
              })}
            </div>
            <div className="sendbar">
              <form onSubmit={(e)=>{
                e.preventDefault();
                this.submit.bind(this);
              }}>
                <div className="message">
                  <FontAwesomeIcon icon={faComment} />
                  <input 
                  name="inputText" 
                  onChange={this.handleChange.bind(this)} 
                  type="text" 
                  value={this.state.inputText} 
                  placeholder="Enter A Message"
                  autoComplete='off'
                  disabled={this.state.isAdmin}
                  />
                </div>

                <div className="send">
                  <button disabled={this.state.disableSubmit} onClick={this.submit.bind(this)} className="btn btn-primary" type="submit">SEND</button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {this.state.viewMembers &&
          <Modal close={(e)=>{
            this.setState({
              viewMembers: false
            })
          }} headline="Current Members" content={this.getMemberContent()} />
        }

        {this.state.leaveConverstionConfirmation &&
        <Modal  
          close={(e)=>{
            this.setState({
              leaveConverstionConfirmation: false
            })
          }} 
          headline="Leave Group Message" 
          content={<p>Are you sure you want to leave the group message? You will lose access to any updates or new messages.</p>} 
          confirm={(e)=>{
            this.leaveConversation();
          }}
          cancel={(e)=>{
            this.setState({
              leaveConverstionConfirmation: false
            })
          }}
          cancelText="Cancel"
          confirmButtonText="Yes, Leave"
        />
        }

        {this.state.addMember &&
          <AddMemberModal
          close={(e)=>{
            this.setState({
              addMember: false
            })
          }}
          confirm={(data)=>{
            this.addMember(data);
          }}
          cancel={(e)=>{
            this.setState({
              addMember: false
            })
          }}
          conversationUserObjects= {this.state.conversationUserObjects}
          headline="Add Members To Conversation"
          cancelText="Cancel"
          confirmButtonText="Add"
          />
        }

        {this.state.newConversation &&
          <AddConversation
          close={(e)=>{
            this.setState({
              newConversation: false
            })
          }}
          confirm={(data)=>{
            this.addConversation(data);
          }}
          cancel={(e)=>{
            this.setState({
              newConversation: false
            })
          }}
          headline="New Conversation"
          cancelText="Cancel"
          confirmButtonText="Send"
          />
        }
      </div>
    );
  }
}

export default Messaging;
