import React, { createContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import CompanyService from "../CompanyService";

// create context
const CompanyServiceContext = createContext();

const CompanyServiceProvider = ({ children }) => {
  return (
    // the Provider gives access to the context to its children
    <CompanyServiceContext.Provider value={new CompanyService(useAuth0().getAccessTokenSilently)}>
      {children}
    </CompanyServiceContext.Provider>
  );
};

export { CompanyServiceContext, CompanyServiceProvider };

export const useCompanyService = () => React.useContext(CompanyServiceContext);