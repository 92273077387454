import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import DefaultImage from "../images/captain-emoji.png";
import "../CSS/ProfileCardHeader.scss";
import { capitalizeLocation } from "../helpers";

export default function ProfileCardHeader(props) {
    const getStatusText = (status) => {
        let statusText = status;
        if (status === "YES") {
            statusText = "Introduction Made";
        } else if (status === "NO") {
            statusText = "Declined";
        } else if (status === "PENDING") {
            statusText = "Pending";
        }
        return statusText;
    };

    return (
        <div key={props.id} className="profile-card-header">
            <span className="image">
                <Link to={`/user/${props.customUrl}`} target="_blank">
                    <img
                        src={props.image + "?w=100"}
                        alt={props.name}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = DefaultImage;
                        }}
                    />
                </Link>
            </span>
            <span className="detail">
                <div className="name">
                    <div>
                        <Link to={`/user/${props.customUrl}`} target="_blank">{props.name}</Link>
                        {props.badges && props.badges.length > 0 && props.badges.map((badge) => {
                            return (
                                <span key={badge} className="allyBadge">{badge}</span>
                            )
                        })}
                    </div>
                    {props.status && (
                        <div><span className="status">{getStatusText(props.status)}</span></div>
                    )}
                    {props.viewMeetingDetails && (
                        <div
                            className="view-meeting-details"
                            onClick={() => {
                                props.submitFeedback(
                                    props.id,
                                    props.name,
                                    props.image,
                                    props.isRecruiter,
                                    props.isAlly,
                                    props.headline,
                                    props.location,
                                    props.userId
                                )
                            }}
                        >
                            <span>View Details</span>
                        </div>
                    )}
                </div>
                <div className="headline">{props.headline}</div>
                {props.location && (
                    <div className="location"><FontAwesomeIcon icon={faLocationDot} /> {capitalizeLocation(props.location)}</div>
                )}
            </span>
        </div>
    )
}