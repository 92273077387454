import React, { useState, useEffect } from "react";
import logo from "../images/collective.png";
import { Helmet } from "react-helmet";
import "../CSS/Collective/MatchReview.scss";
import JobPost from "./JobPost";
import { useParams } from "react-router-dom";

export default function PublicJobPost(props) {
    const { id } = useParams();
    const [job, setJob] = useState(null);

    useEffect(() => {
        getJob(id);

        const header = document.querySelector("nav");
        header.style.display = "none";
    
        return () => {
          const header = document.querySelector("nav");
          header.style.display = "block";
        };
    }, []);

    const getJob = async(id) => {
        let headers = {
            "Content-Type": "application/json",
        };

        const response = await fetch(
            `${process.env.REACT_APP_URL_JAVA}/company-collective-roles/${id}`,
            {
                method: "GET",
                headers,
            }
        );

        const data = await response.json();
        setJob(data);
    };

    return (
        <div className="match-review">
          <Helmet>
            <meta charSet="utf-8" />
            <title>{`Hearty Collective`}</title>
            <meta name="description" content="Helping the best engineers find new jobs" />
            <meta property="og:title" content="Hearty Collective" />
            <meta
              property="og:description"
              content="Helping the best engineers find new job"
            />
            <meta
              property="og:image"
              content="https://www.hearty.xyz/images/collective.png"
            />
            <meta property="og:url" content={window.location} />
    
            <meta property="twitter:title" content="Hearty Collective" />
            <meta
              property="twitter:description"
              content="Helping the best engineers find new job"
            />
            <meta
              property="twitter:image"
              content="https://www.hearty.xyz/images/collective.png"
            />
            <meta name="twitter:card" content="summary_large_image"></meta>
          </Helmet>
          <div className="match-review-header">
            <img src={logo} alt="logo" className="logo" />
          </div>
          {job && (
            <JobPost job={job} />
          )}
        </div>
      );
}