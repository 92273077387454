import React, { useEffect, useState, useRef } from "react";
import "../CSS/Collective/Profile.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import Match from "./Match";
import moment from "moment";
import { Link } from "react-router-dom";
import ProfilePublic from "./ProfilePublic";

export default function Creator(props, ref) {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  let { id } = useParams();
  const [profile, setProfile] = useState({});
  const [matches, setMatches] = useState([]);
  const [audit, setAudit] = useState([]);
  const [isAdmin, setIsAdmin] = useState(() => {
    const rolesJSON = localStorage.getItem("roles");
    const roles = JSON.parse(rolesJSON);
    return roles && roles.includes("Admin");
  });

  useEffect(() => {
    getUserProfile(id);
    getRoles(id);
    getAudit(id);
  }, []);

  const getUserProfile = async (id) => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      console.log(error);
    }
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }

    let response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-collectives/${id}`,
      {
        method: "GET",
        headers,
      }
    );

    let data = await response.json();
    setProfile(data);
  };

  const getAudit = async (id) => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      console.log(error);
    }
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }

    let response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-collectives/${id}/audit`,
      {
        method: "GET",
        headers,
      }
    );

    const data = await response.json();

    setAudit(data);
  };

  const getRoles = async (id) => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      console.log(error);
    }
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }

    let response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-collectives/${id}/matches`,
      {
        method: "GET",
        headers,
      }
    );

    let matches = await response.json();
    setMatches(matches);
  };

  const updateUserCollectiveStatus = async (status) => {
    const token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const body = {
      status: status,
    };

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-collectives/${id}/status`,
      {
        method: "PATCH",
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();
    setProfile(data);
  };

  const formatStatus = (text) => {
    text = text || "";
    var newText = text
      .toLowerCase()
      .replaceAll("_", " ")
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
    if (newText === "Interview Accepted") {
      newText = "Actively Interviewing";
    }
    return newText;
  };

  const copyPublicUrl = async () => {
    await navigator.clipboard.writeText(`${window.location.protocol}//${window.location.host}/candidate/${profile.customUrl}`);
  }

  return (
    <div className="CollectiveProfile">
      <div
        style={{
          position: "relative",
        }}
        className="banner"
      >
        <div>
          <select
            className="status"
            onChange={(e) => {
              updateUserCollectiveStatus(e.target.value);
            }}
            value={profile.substatus}
          >
            <option key="ACTIVE" value="ACTIVE">
              Active
            </option>
            <option
              key="INTRODUCTION_OFFERED"
              value="INTRODUCTION_OFFERED"
              disabled={true}
            >
              Introduction Offered
            </option>
            <option
              key="INTRODUCTION_MADE"
              value="INTRODUCTION_MADE"
              disabled={true}
            >
              Actively Interviewing
            </option>
            <option key="INACTIVE" value="INACTIVE">
              Inactive
            </option>
            <option key="INITIAL_APPROVAL" value="INITIAL_APPROVAL">
              Initial Approval
            </option>
            <option key="PENDING_INTRODUCTION" value="PENDING_INTRODUCTION">
              Pending Introduction
            </option>
            <option key="REJECTED" value="REJECTED">
              Rejected
            </option>
            <option key="PENDING" value="PENDING">
              Pending
            </option>
          </select>

          {isAdmin && (
            <div
            className="buttons"
            style={{
              position: "absolute",
              top: "80px",
              right: "40px",
            }}>
            <a className="btn large copy" onClick={async() => await copyPublicUrl()}>
              Copy public link
            </a>
            <Link
              className="btn large"
              to={`/collective/user/${profile.userCollectiveId}/edit`}
            >
              Edit
            </Link>
            </div>
          )}
        </div>
      </div>
      {profile.userCollectiveId && (
        <ProfilePublic profile={profile} isAdmin={isAdmin} />
      )}
      <div className="matchmaking">
        <h2>Matches</h2>
        {matches.map((item) => {
          return (
            <Match
              introduced={() => {
                getRoles(id);
              }}
              profile={profile}
              item={item}
              dismiss={()=>{getRoles(id)}}
              candidateView={false}
              key={item.companyCollectiveUserId}
            />
          );
        })}
        {!matches ||
          (matches.length === 0 && <p>No matches yet! Keep checking back!</p>)}
      </div>
      <div className="audit">
        <h2>Activity</h2>
        {audit.map((item) => {
          var message = item.action;
          if (item.action === "JOINED") {
            message = "Joined collective";
          } else if (item.action === "INTRODUCTION_OFFERED") {
            message = "Introduction offered to " + item.companyName;
          } else if (item.action === "INTRODUCTION_MADE") {
            message = "Introduction made to " + item.companyName;
          } else if (item.action === "INTRODUCTION_DECLINED") {
            message = "Introduction declined by " + item.companyName;
          } else if (item.action === "INTRODUCTION_ACCEPTED") {
            message = "Introduction accepted by " + item.companyName;
          } else if (item.action === "OFFER_NOT_MADE") {
            message = "Offer not made by " + item.companyName;
          } else if (item.action === "OFFER_DECLINED") {
            message = "Declined offer from " + item.companyName;
          } else if (item.action === "OFFER_ACCEPTED") {
            message = "Accepted offer from " + item.companyName;
          }
          return (
            <p key={item.id}>
              {moment(item.date).format("MM/DD/YYYY")} - {message}
            </p>
          );
        })}
      </div>
    </div>
  );
}
