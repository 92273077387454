import React, { useState, useEffect } from "react";
import "../CSS/MessagingUserSearch.scss";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Captain from "../images/captain-emoji.png";
import { useAuth0 } from "@auth0/auth0-react";
import HeartyName from "../components/HeartyName";

export default function AddMemberModal(props) {
  const [users, setUsers] = useState([]);
  const [foundUsers, setFoundUsers] = useState([]);
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const searchAPI = async (search) => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access"
    });
    return fetch(
      `${process.env.REACT_APP_URL_JAVA}/external-profiles/dm-user-search?q=${search}&page=0&size=50`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
  const searchAPIDebounced = AwesomeDebouncePromise(searchAPI, 200);

  const onEnter = async (search, key) => {
    const query = await searchAPIDebounced(search);
    const usersQuery = await query.json();

    if(!usersQuery._embedded.iExternalProfileList) {
        const usersFiltered = usersQuery._embedded.externalProfileUserSearchResultList.filter((user)=>{
            
            // Dont show people we have added
            const items = users.filter((userItem)=>{
                if(userItem.id === user.id) return true;

                return false
            })

            if(items.length > 0) return false;

            let conversationUserObjects = [];
            // Get rid of people already in the conversation to start with
            if(props.conversationUserObjects) {
                conversationUserObjects = props.conversationUserObjects.filter((userItem)=>{
                    if(userItem.id === user.id) return true;

                    return false;
                })
            }     

            if(conversationUserObjects.length > 0) return false;

            return true;
        })
        setFoundUsers(usersFiltered);
    } else {
        setFoundUsers([])
    }
  };

  return (
    <div className="MessagingUserSearch">
      <div className="inputSelectorBox">
      {(users && users.length) > 0 && (
        <div className="usersSelected">
          {users.map((user) => {
            return (
              <div onClick={(e)=>{
                  const currentUsers = [...users];
                  const index = currentUsers.indexOf(user)
                  currentUsers.splice(index, 1);
                  setUsers(currentUsers)
                  props.setUsers(currentUsers)
              }} className="foundUser">
                <p><HeartyName 
                    name={user.name}
                    isProven={user.isProven}
                    /></p>
              </div>
            );
          })}
        </div>
      )}
      <input
        placeholder="Search"
        id="search"
        autoFocus={true}
        spellCheck="false"
        onKeyUp={(e) => {
          onEnter(e.target.value, e.key, e.target);
        }}
        type="text"
      />
      </div>

      {foundUsers.length > 0 && (
        <div className="FoundUserPopup">
        <div className="foundUsers">
        <FontAwesomeIcon icon={faTimes} onClick={(e)=>{
            setFoundUsers([])
        }}/>
          {foundUsers.map((user) => {

            return (
              <div
                onClick={(e) => {
                  let currentUsers = [...users];
                  currentUsers.push(user);
                  setUsers(currentUsers);
                  props.setUsers(currentUsers)
                  setFoundUsers([])
                  const element = document.getElementById('search')
                  element.value = '';
                  element.focus();
                }}
                className="user"
              >
                <img onError={(e)=>{e.target.onerror = null; e.target.src=Captain}} src={user.image} />
                <div className="info">
                  <p><HeartyName 
                    name={user.name}
                    isProven={user.isProven}
                    /></p>
                  {(user.headline !== null && user.headline !== 'null') && <p>{user.headline}</p>}
                </div>
              </div>
            );
          })}
        </div>
        <div onClick={(e)=>{
            setFoundUsers([])
        }} className="skrim"></div>
        </div>

      )}
    </div>
  );
}
