import React from "react";
import NFTLanding from "./NFTLanding";

import CollectiveImage from "./images/collective.png";

import Settings from "./Settings";
import Logo from "./images/h.png";
import Profile from "./Profile";
import EditProfile from "./EditProfile";
import PublicProfile from "./PublicProfile";
import Admin from "./Admin/Admin";
import Terms from "./Terms";
import Privacy from "./Privacy";
import LeaderboardsList from "./Leaderboards/list";

import CollectiveHome from "./Collective/CollectiveHome";
import CollectiveProfile from "./Collective/Profile";
import CollectiveProfileEdit from "./Collective/edit";
import CollectiveReview from "./Collective/Review";
import ProfilePublic from "./Collective/ProfilePublic"

import CandidateLanding from "./Collective/CandidateLanding";
import CollectiveCandidateAdmin from "./Collective/CandidateAdmin";
import CollectiveRoleAdmin from "./Collective/RoleAdmin";
import ActiveRoleAdmin from "./Collective/ActiveRoleAdmin";

import CollectiveCompanies from "./Collective/Companies";

import CandidateOnboarding from "./Onboarding/CandidateOnboarding";

import Collection from "./Trophies/collection";
import Trophies from "./Trophies/home";
import Trophy from "./Trophies/single";
import TrophyAdmin from "./Trophies/admin";
import EditCollection from "./Trophies/components/EditCollection";
import EditTrophy from "./Trophies/components/EditTrophy";
import LeaderboardParent from "./Leaderboards/parent";
import LeaderboardEdit from "./Leaderboards/edit";
import LeaderboardCreate from "./Leaderboards/create";
import CompanyProfile from "./Companies/profile";
import CompanyEdit from "./Companies/edit";
import Auth from "./Auth";
import MatchReview from "./Collective/MatchReview";
import Captain from "./images/captain-emoji.png";
import { Helmet } from "react-helmet";
import SettingPopoverUser from "./components/SettingPopoverUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { withAuth0 } from "@auth0/auth0-react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  NavLink,
  Link,
} from "react-router-dom";

import "./App.scss";
import PublicJobPost from "./Collective/PublicJobPost";
import Search from "./Search/Search";
import Introductions from "./Collective/Introductions";
import Messaging from "./Messaging";
import CollectiveOnboarding from "./Onboarding/CollectiveOnboarding";
import { MemberList } from "./MemberList";
import EmployerHome from "./EmployerHome";
import Employees from "./Employees";
import CommunityConnections from "./CommunityConnections";
import HomeRedirect from "./HomeRedirect";
import Supporters from "./Supporters";
import { deleteCollectiveOnboardingProfile, getAuthenticatedUserCollectives, setAuthenticatedUserCollectives } from "./LocalStorageUtil";
import { UserContextProvider } from "./UserContext";
import { CompanyServiceProvider } from "./service/context/CompanyServiceContext";

class App extends React.Component {
  constructor(props) {
    const isLoggedIn = async () => {
      const email = await this.getEmail();

      if (!this.props.auth0.isAuthenticated) {
        return false;
      }

      this.props = props;

      this.state.email = email;

      const userObject = localStorage.getItem("user");
      let user = false;

      if (userObject) {
        user = JSON.parse(userObject);
        user.user_id = user.id;
      }

      if (user && user.id) {
        this.props.LogRocket.identify(user.id, {
          name: user.first_name + " " + user.last_name,
        });
      }

      return true;
    };
    super(props);

    let viewerType = "Individual";

    const areTheyLoggedIn = isLoggedIn();
    const isAdmin = this.isAdmin();
    const isShowSearch = this.isShowSearch();

    this.state = {
      showMobileMenu: false,
      viewerType,
      password: "",
      loggedIn: areTheyLoggedIn,
      token: false,
      linkedinDone: false,
      followDone: false,
      socialCapital: 0,
      invites: 0,
      recommendDone: false,
      redirect: null,
      showAbording: false,
      notifications: [],
      showInviteModal: false,
      newQuest: false,
      me: {
        external_profile: {},
      },
      isAdmin: isAdmin,
      isShowSearch: isShowSearch,
    };
    this.listRef = React.createRef();
    this.changeHeader = this.changeHeader.bind(this);
    this.toggleHover = this.toggleHover.bind(this);
    this.logout = this.logout.bind(this);
    this.checkScroll = this.checkScroll.bind(this);
    this.getOnboardingStep = this.getOnboardingStep.bind(this);
  }

  toggleHover() {
    this.setState({
      popover: !this.state.popover,
    });
  }

  async getEmail() {
    let token = false;
    try {
      token = await this.props.auth0.getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      console.log(error);
    }
    const user = localStorage.getItem("user");
    let userObject = JSON.parse(user);

    if (!token || !userObject) return false;

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/users/email`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const email = await response.text();

    return email;
  }

  isAdmin() {
    const rolesJSON = localStorage.getItem("roles");
    if (rolesJSON) {
      const roles = JSON.parse(rolesJSON);
      return roles && roles.includes("Admin");
    } else {
      return false;
    }
  }

  isShowSearch() {
    if (this.isAdmin()) {
      return true;
    }

    const collectives = getAuthenticatedUserCollectives();
    for (let i = 0; i < collectives.length; i++) {
      if (collectives[i].collectiveId > 1) {  // they are in a collective that is not the default one
        return true;
      }
    }
    return false;
  }

  goToProfile() {
    const user = localStorage.getItem("user");
    let userObject = JSON.parse(user);

    if (!userObject.External_Profile) return true;
    this.setState({
      redirect: "/settings",
    });
  }

  async logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("roles");
    return this.props.auth0.logout({ returnTo: window.location.origin });
  }

  async changeHeader() {
    if (!this.props.auth0.isAuthenticated) return false;
    let token = await this.props.auth0.getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });
    this.setState({
      loggedIn: token,
      showMobileMenu: false,
    });
  }

  // Hides and shows the mobile menu.
  toggleShowMenu() {
    this.setState({
      showMobileMenu: !this.state.showMobileMenu,
    });
  }

  async register() {
    const body = {
      email: this.props.auth0.user.email,
      firstName: this.props.auth0.user.given_name,
      lastName: this.props.auth0.user.family_name,
      auth0Id: this.props.auth0.user.sub,
    };

    const userData = await fetch(`${process.env.REACT_APP_URL_JAVA}/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })

    return await userData.json();
  }

  async testOnboarding() {
    let token = await this.props.auth0.getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const response = await fetch(`${process.env.REACT_APP_URL_JAVA}/users/me`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    let userOBJ = await response.json();

    this.setState({
      me: userOBJ,
    });

    const NFTCODE = localStorage.getItem("NFTCode");

    // User is claiming an NFT
    if (userOBJ && NFTCODE && NFTCODE.length > 0 && NFTCODE !== "null") {
      this.setState({
        redirect: `/claim-nft/${NFTCODE}`,
      });

      return true;
    }

    // Candidate who filled out a profile anonymously
    const publicCandidateProfile = localStorage.getItem('PublicCandidateProfile');

    if (publicCandidateProfile) {
      if (userOBJ.errors && userOBJ.errors.length > 0) {
        userOBJ = await this.register();
      }

      let headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };

      await fetch(
        `${process.env.REACT_APP_URL_JAVA}/user-collectives`,
        {
          headers,
          body: publicCandidateProfile,
          method: "POST",
        }
      );

      localStorage.removeItem('PublicCandidateProfile');
      
      userOBJ.in_collective = true;
      this.setState({
        me: userOBJ,
      });

      this.setState({
        redirect: `/collective`,
      });
    }

    const CandidateProfile = localStorage.getItem("CandidateProfile");

    // Candidate who registered but hasn't completed onboarding
    if (
      userOBJ &&
      CandidateProfile &&
      CandidateProfile.length > 0 &&
      CandidateProfile !== "null" &&
      userOBJ.in_collective === false
    ) {
      this.setState({
        redirect: `/collective-candidate`,
      });

      return true;
    }

    // User who just went through collective onboarding
    const collectiveOnboardingProfile = localStorage.getItem("CollectiveOnboardingProfile");
    if (collectiveOnboardingProfile) {
      let headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      await fetch(
        `${process.env.REACT_APP_URL_JAVA}/users/onboarding`,
        {
          headers,
          body: collectiveOnboardingProfile,
          method: "POST",
        }
      );

      await this.fetchCollectives();
      this.setState({
        isShowSearch: this.isShowSearch(),
      });
      deleteCollectiveOnboardingProfile();

      let user = JSON.parse(localStorage.getItem('user'));
      let profile = JSON.parse(collectiveOnboardingProfile);
      user.external_profile.image = profile.imageUrl;
      user.external_profile.headline = profile.headline;
      user.area_of_expertise = profile.areaOfExpertise;
      user.in_collective = true;
      let me = this.state.me;
      me.in_collective = true;
      this.setState({
        me,
      });
      localStorage.setItem("user", JSON.stringify(user));
      window.location.href = "/"; // hack to force a refresh and reload of UserContext

      return true;
    }


    // Not in collective but has an NFT
    if (
      userOBJ.in_collective === false &&
      userOBJ.is_nft_user === true
    ) {
      this.setState({
        redirect: "/trophies",
      });

      return true;
    }

    // Not in collective and not an NFT user
    if (
      userOBJ.in_collective === false &&
      userOBJ.is_nft_user === false
    ) {
      this.setState({
        redirect: "/profile",
      });

      return true;
    }

    return false;
  }

  async getOnboardingStep() {
    if (localStorage.getItem("token")) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("roles");
      return (window.location.url = "/login");
    }
    try {
      let token = await this.props.auth0.getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });

      await this.testOnboarding();
      this.setState({ token });

      const user = JSON.parse(localStorage.getItem("user"));

      if (!token || !user) return false;

      this.setState({
        loggedIn: true,
      });
    } catch (error) {
      console.log(error);
    }
  }

  checkScroll() {
    window.onscroll = (ev) => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 40) {
        if (this.state.shadowNav === false) {
          this.setState({
            shadowNav: true,
          });
        }
      } else {
        this.setState({
          shadowNav: false,
        });
      }
    };
  }

  async fetchCollectives() {
    let token = false;

    try {
      token = await this.props.auth0.getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
        ignoreCache: true
      });
    } catch (error) {
      return;
    }

    const response = await fetch(`${process.env.REACT_APP_URL_JAVA}/user-collectives/roles`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "application/json",
      }
    })

    const collectives = await response.json();

    setAuthenticatedUserCollectives(collectives);
  }

  componentDidMount() {
    this.getOnboardingStep();
    this.checkScroll();
  }

  render() {
    const redirect = () => {
      if (this.state.redirect) {
        return <Redirect to={this.state.redirect} />;
      }
    };

    const loginOrLogout = () => {
      if (userObject) {
        const user = localStorage.getItem("user");
        let userObject = JSON.parse(user);

        if (userObject) {
          this.props.LogRocket.identify(userObject.id, {
            name: userObject.first_name + " " + userObject.last_name,
            email: this.state.email,
          });
        }

        if (
          !userObject ||
          typeof userObject !== "object" ||
          !userObject.External_Profile
        ) {
          userObject = {};
          userObject.External_Profile = {};
          userObject.External_Profile.image = Captain;
          userObject.External_Profile.name = "";
        }

        return (
          <SettingPopoverUser
            onClick={this.toggleHover.bind(this)}
            popover={this.state.popover}
            logout={this.logout}
            toggleModal={this.toggleHover}
            user={this.state.me}
          />
        );
      }
      return (
        <ul>
          <li>
            <a
              className="nonLogInLink"
              onClick={() => {
                this.props.auth0.loginWithRedirect();
              }}
            >
              Login / Register
            </a>
          </li>
        </ul>
      );
    };

    const loginOrLogoutMobile = () => {
      if (userObject || this.props.auth0.isAuthenticated) {
        const user = localStorage.getItem("user");
        let userObject = JSON.parse(user);

        if (
          !userObject ||
          typeof userObject !== "object" ||
          !userObject.External_Profile
        ) {
          userObject = {};
          userObject.External_Profile = {};
          userObject.External_Profile.image = Captain;
          userObject.External_Profile.name = "";
        }

        return (
          <li onClick={this.logout.bind(this)}>
            <a href="#">Logout</a>
          </li>
        );
      }

      return (
        <a
          onClick={(e) => {
            this.props.auth0.loginWithRedirect();
          }}
        >
          Login
        </a>
      );
    };

    const showHeader = () => {
      const user = localStorage.getItem("user");
      let userObject = JSON.parse(user);
      let link = false;
      if (userObject) link = `/network`;

      let isAdmin = this.isAdmin();
      let isShowSearch = this.isShowSearch();

      if (this.props.auth0.isAuthenticated) {
        this.props.auth0.getAccessTokenSilently({
          audience: `https://api-v2.behearty.co`,
          scope: "read:current_user offline_access",
        });
      }

      return (
        <nav className={this.state.shadowNav ? "shadowNav" : ""}>
          <div className="container">
            <Link to="/home" className="logo">
              <img src={Logo} alt="logo"/>
            </Link>

            <div className="mobileMenu">
              <span onClick={this.toggleShowMenu.bind(this)}>
                <FontAwesomeIcon icon={faBars} />
              </span>
              <Link to="/home" className="mobileLogo">
                <img src={Logo} alt="logo"/>
              </Link>
              <ul>
                {userObject && (
                  <div
                    onClick={(e) => {
                      window.location.href = "/profile";
                    }}
                    className="image"
                  >
                    <img
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = Captain;
                      }}
                      src={userObject.External_Profile.image}
                      alt="user"
                    />
                  </div>
                )}
              </ul>
              {this.state.showMobileMenu && (
                <div className="mobileMenuOut">
                  <ul>
                    <span onClick={this.toggleShowMenu.bind(this)}>
                      <FontAwesomeIcon icon={faBars} />
                    </span>
                    {userObject &&
                    userObject.External_Profile &&
                    userObject.id &&
                    (this.state.me.has_seen_welcome_modal || userObject.has_seen_welcome_modal) &&
                    link ? (
                      <li>
                        <NavLink
                          onClick={() => {
                            this.setState({
                              showMobileMenu: false,
                            });
                            return;
                          }}
                          activeClassName="active"
                          to={"/home"}
                        >
                          Home
                        </NavLink>
                      </li>
                    ) : (
                      ""
                    )}
                    {userObject &&
                    userObject.External_Profile &&
                    userObject.id &&
                    this.state.isAdmin &&
                    (this.state.me.has_seen_welcome_modal || userObject.has_seen_welcome_modal) &&
                    link ? (
                      <li>
                        <NavLink
                          onClick={() => {
                            this.setState({
                              showMobileMenu: false,
                            });
                            return;
                          }}
                          activeClassName="active"
                          to={"/leaderboards"}
                        >
                          Leaderboards
                        </NavLink>
                      </li>
                    ) : (
                      ""
                    )}
                    {userObject &&
                    userObject.External_Profile &&
                    userObject.id &&
                    (this.state.me.has_seen_welcome_modal || userObject.has_seen_welcome_modal) &&
                    (this.state.isAdmin || userObject.is_nft_user) ? (
                      <li>
                        <NavLink
                          onClick={() => {
                            this.setState({
                              showMobileMenu: false,
                            });
                            return;
                          }}
                          activeClassName="active"
                          to="/trophies"
                        >
                          NFTs
                        </NavLink>
                      </li>
                    ) : (
                      ""
                    )}
                    {/* {userObject &&
                    userObject.External_Profile &&
                    (this.state.me.has_seen_welcome_modal || userObject.has_seen_welcome_modal) &&
                    userObject.id ? (
                      <li>
                        <NavLink
                          onClick={() => {
                            this.setState({
                              showMobileMenu: false,
                            });
                            return;
                          }}
                          activeClassName="active"
                          to="/profile"
                        >
                          My Profile
                        </NavLink>
                      </li>
                    ) : (
                      ""
                    )} */}

                    <li>
                      <a
                        target="_blank"
                        href="https://heartycollective.notion.site/heartycollective/5657cfe48f1241cb94f157b0eeec22e0" rel="noreferrer"
                      >
                        Help
                      </a>
                    </li>
                    { (this.state.me.has_seen_welcome_modal || userObject.has_seen_welcome_modal) && (
                      <li>
                      <NavLink
                        onClick={() => {
                          this.setState({
                            showMobileMenu: false,
                          });
                          return;
                        }}
                        activeClassName="active"
                        to="/settings"
                      >
                        Edit Settings
                      </NavLink>
                    </li>
                    )}
                    {loginOrLogoutMobile()}
                  </ul>
                </div>
              )}
            </div>

            <ul className="mainMenu">

              {userObject &&
              userObject.External_Profile &&
              userObject.id &&
              (this.state.me.has_seen_welcome_modal || userObject.has_seen_welcome_modal) &&
              (this.state.isAdmin || userObject.is_nft_user) ? (
                <li>
                  <NavLink activeClassName="active" to="/trophies">
                    NFTs
                  </NavLink>
                </li>
              ) : (
                ""
              )}

              {userObject &&
              userObject.External_Profile &&
              userObject.id &&
              isAdmin ? (
                <li>
                  <NavLink activeClassName="active" to="/leaderboards">
                    Leaderboards
                  </NavLink>
                </li>
              ) : (
                ""
              )}

              {userObject &&
              userObject.External_Profile &&
              userObject.id &&
              (this.state.me.in_collective || userObject.in_collective) ? (
                <li>
                  <NavLink activeClassName="active" to="/home" exact>
                    <img src={CollectiveImage} alt="collective"/>
                  </NavLink>
                </li>
              ) : (
                ""
              )}

              {isAdmin && userObject &&
              userObject.External_Profile &&
              userObject.id ? (
                <li>
                  <NavLink activeClassName="active" to="/collective/admin/candidates">
                    Candidates
                  </NavLink>
                </li>
              ) : (
                ""
              )}

              {isAdmin && userObject &&
              userObject.External_Profile &&
              userObject.id ? (
                <li>
                  <NavLink activeClassName="active" to="/collective/admin/active-roles">
                    Active Roles
                  </NavLink>
                </li>
              ) : (
                ""
              )}

              {isAdmin && userObject &&
              userObject.External_Profile &&
              userObject.id ? (
                <li>
                  <NavLink activeClassName="active" to="/collective/admin/roles">
                    Roles
                  </NavLink>
                </li>
              ) : (
                ""
              )}

              {isAdmin && userObject &&
              userObject.External_Profile &&
              userObject.id ? (
                <li>
                  <NavLink activeClassName="active" to="/collective/companies">
                    Companies
                  </NavLink>
                </li>
              ) : (
                ""
              )}

              {userObject &&
                userObject.External_Profile &&
                userObject.id &&
                (this.state.me.in_collective || userObject.in_collective) ? (
                  <li>
                    <NavLink activeClassName="active" to="/supporters">
                      Supporters
                    </NavLink>
                  </li>
                ) : (
                  ""
              )}

              {isShowSearch && userObject &&
              userObject.External_Profile &&
              userObject.id ? (
                <li>
                  <NavLink activeClassName="active" to="/search">
                    Search
                  </NavLink>
                </li>
              ) : (
                ""
              )}

              {isAdmin && userObject &&
              userObject.External_Profile &&
              userObject.id ? (
                <li>
                  <NavLink activeClassName="active" to="/introductions">
                    Introductions
                  </NavLink>
                </li>
              ) : (
                ""
              )}

              {isAdmin && userObject &&
              userObject.External_Profile &&
              userObject.id ? (
                <li>
                  <NavLink activeClassName="active" to="/members">
                    Members
                  </NavLink>
                </li>
              ) : (
                ""
              )}

              {userObject && <div className="break"></div>}

              {loginOrLogout()}
            </ul>
          </div>
        </nav>
      );
    };
    const userObject = localStorage.getItem("user");
    let user = false;

    if (userObject) {
      user = JSON.parse(userObject);
      user.user_id = user.id;
      user.email = this.state.email;
    }
    return (
      <div className="wholeApp">
        <UserContextProvider>
          <CompanyServiceProvider>
            <Helmet>
              <title>Hearty: Your Recommendation Network</title>
              <meta name="description" content="Your recommendation network" />
              <meta property="og:title" content="Hearty" />
              <meta
                property="og:description"
                content="Your recommendation network."
              />
              <meta
                property="og:image"
                content="https://www.behearty.co/images/grouping.png"
              />
              <meta property="og:url" content={window.location} />
            </Helmet>
            <Router>
              {redirect()}
              {showHeader()}

              <div className="app">
                <Switch>
                  <Route
                    path="/leaderboards/create"
                    render={(props) => <LeaderboardCreate {...props} />}
                  ></Route>

                  <Route path="/auth">
                    <Auth
                      getOnboardingStep={this.getOnboardingStep}
                      LogRocket={this.props.LogRocket}
                      changeHeader={this.changeHeader}
                    />
                  </Route>

                  <Route
                    path="/collective-candidate"
                    render={(props) => (
                      <CandidateOnboarding auth0={this.props.auth0} {...props} />
                    )}
                  ></Route>

                  <Route
                    path="/collective-review/:guid"
                    render={(props) => (
                      <CollectiveReview auth0={this.props.auth0} {...props} />
                    )}
                  ></Route>

                  <Route
                    path="/collective/admin/candidates"
                    render={(props) => <CollectiveCandidateAdmin {...props} />}
                  ></Route>  

                  <Route
                    path="/collective/admin/roles"
                    render={(props) => <CollectiveRoleAdmin {...props} />}
                  ></Route> 

                  <Route
                    path="/collective/admin/active-roles"
                    render={(props) => <ActiveRoleAdmin {...props} />}
                  ></Route>

                  <Route
                    path="/collective/onboarding"
                    render={(props) => (
                      <CandidateOnboarding auth0={this.props.auth0} {...props} />
                    )}
                  ></Route>

                  <Route
                    path="/collective/companies"
                    render={(props) => (
                      <CollectiveCompanies auth0={this.props.auth0} {...props} />
                    )}
                  ></Route>

                  <Route
                    path="/collective/user/:id/edit"
                    render={(props) => (
                      <CollectiveProfileEdit auth0={this.props.auth0} {...props} />
                    )}
                  ></Route>

                  <Route
                    path="/collective/user/new"
                    render={(props) => (
                      <CollectiveProfileEdit auth0={this.props.auth0} isNew={true} {...props} />
                    )}
                  ></Route>

                  <Route
                    path="/collective/user/edit"
                    render={(props) => (
                      <CollectiveProfileEdit auth0={this.props.auth0} {...props} />
                    )}
                  ></Route>

                  <Route
                    path="/collective/user/:id"
                    render={(props) => (
                      <CollectiveProfile auth0={this.props.auth0} {...props} />
                    )}
                  ></Route>

                  <Route
                    path="/candidate/:customUrl"
                    render={(props) => (
                      <ProfilePublic {...props} />
                    )}
                  ></Route>

                  <Route
                    path="/job/:id"
                    render={(props) => (
                      <PublicJobPost {...props} />
                    )}
                  ></Route>

                  <Route
                    path="/collective/user/:id/edit"
                    render={(props) => (
                      <CollectiveProfile auth0={this.props.auth0} {...props} />
                    )}
                  ></Route>

                  <Route
                    path="/collective"
                    render={(props) => (
                      <CollectiveHome auth0={this.props.auth0} {...props} />
                    )}
                  ></Route>

                  <Route
                    path="/onboarding"
                    render={(props) => (
                      <CandidateLanding {...props} auth0={this.props.auth0} />
                    )}
                  ></Route>

                  <Route
                    path="/leaderboards/edit/:id"
                    render={(props) => <LeaderboardEdit {...props} />}
                  ></Route>

                  <Route
                    path="/leaderboards/:id"
                    render={(props) => (
                      <LeaderboardParent
                        {...props}
                      />
                    )}
                  ></Route>

                  <Route
                    path="/leaderboards"
                    render={(props) => <LeaderboardsList {...props} />}
                  ></Route>

                  <Route path="/terms" component={Terms}></Route>

                  <Route path="/privacy" component={Privacy}></Route>

                  <Route
                    path="/admin/trophies"
                    render={(props) => <TrophyAdmin {...props} />}
                  ></Route>

                  <Route
                    path="/company/:id/edit"
                    render={(props) => <CompanyEdit {...props} />}
                  ></Route>

                  <Route path="/admin">
                    <Admin auth0={this.props.auth0} />
                  </Route>

                  <Route
                    path="/login"
                    render={(props) => this.props.auth0.loginWithRedirect()}
                  ></Route>

                  <Route
                    path="/user/:id"
                    render={(props) => (
                      <PublicProfile
                        {...props}
                        auth0={this.props.auth0}
                      />
                    )}
                  ></Route>

                  <Route
                    path="/company/new"
                    render={(props) => <CompanyEdit {...props} create={true} />}
                  ></Route>

                  <Route
                    path="/company/:id"
                    render={(props) => (
                      <CompanyProfile
                        {...props}
                        auth0={this.props.auth0}
                      />
                    )}
                  ></Route>

                  <Route
                    path="/claim-nft/:code"
                    render={(props) => (
                      <Collection {...props} auth0={this.props.auth0} />
                    )}
                  ></Route>

                  <Route
                    path="/profile/edit"
                    render={(props) => (
                      <EditProfile {...props} auth0={this.props.auth0} />
                    )}
                  ></Route>

                  <Route
                    path="/profile"
                    render={(props) => (
                      <Profile
                        {...props}
                        auth0={this.props.auth0}
                      />
                    )}
                  ></Route>

                  <Route
                    path="/collection/edit/:id"
                    render={(props) => (
                      <EditCollection {...props} auth0={this.props.auth0} />
                    )}
                  ></Route>

                  <Route
                    path="/trophies/edit/:id"
                    render={(props) => (
                      <EditTrophy {...props} auth0={this.props.auth0} />
                    )}
                  ></Route>

                  <Route
                    path="/collection/:id"
                    render={(props) => (
                      <Collection {...props} auth0={this.props.auth0} />
                    )}
                  ></Route>

                  <Route
                    path="/trophies/:id"
                    render={(props) => (
                      <Trophy {...props} auth0={this.props.auth0} />
                    )}
                  ></Route>

                  <Route
                    path="/trophies"
                    render={(props) => (
                      <Trophies {...props} auth0={this.props.auth0} />
                    )}
                  ></Route>

                  <Route
                    path="/c/candidate"
                    render={(props) => (
                      <CandidateLanding {...props} auth0={this.props.auth0} />
                    )}
                  ></Route>

                  <Route
                    path="/c/job-match"
                    render={(props) => (
                      <CandidateOnboarding {...props} auth0={this.props.auth0} isDeveloper={true} />
                    )}
                  ></Route>

                  <Route
                    path="/c/match-review"
                    render={(props) => (
                      <MatchReview {...props} auth0={this.props.auth0} />
                    )}
                  ></Route>

                  <Route
                    path="/c"
                    render={(props) => (
                      <CandidateOnboarding {...props} auth0={this.props.auth0} />
                    )}
                  ></Route>

                  <Route
                    path="/nft/:code"
                    render={(props) => (
                      <NFTLanding {...props} auth0={this.props.auth0} />
                    )}
                  ></Route>

                  <Route
                    path="/search"
                    render={(props) => (
                      <Search {...props} auth0={this.props.auth0} />
                    )}
                  ></Route>

                  <Route
                    path="/introductions"
                    render={(props) => (
                      <Introductions {...props} auth0={this.props.auth0} />
                    )}
                  ></Route>

                  <Route
                    path="/conversations/admin"
                    render={(props) => (
                      <Messaging {...props} auth0={this.props.auth0} isAdmin={true} />
                    )}
                  ></Route>

                  <Route
                    path="/conversations"
                    render={(props) => (
                      <Messaging {...props} auth0={this.props.auth0} />
                    )}
                  ></Route>

                  <Route
                    path="/register/:inviteCode"
                    render={(props) => (
                      <CollectiveOnboarding {...props} auth0={this.props.auth0} />
                    )}
                  ></Route>

                  <Route
                    path="/employer/employees"
                    render={(props) => (
                      <Employees {...props} auth0={this.props.auth0} />
                    )}
                  ></Route>

                  <Route
                    path="/employer"
                    render={(props) => (
                      <EmployerHome {...props} auth0={this.props.auth0} />
                    )}
                  ></Route>

                  <Route
                    path="/community-connections"
                    render={(props) => (
                      <CommunityConnections {...props} auth0={this.props.auth0} />
                    )}
                  ></Route>

                  <Route path="/settings">
                    <Settings
                      auth0={this.props.auth0}
                    />
                  </Route>

                  <Route path="/members">
                    <MemberList
                      auth0={this.props.auth0}
                    />
                  </Route>

                  <Route path="/supporters">
                    <Supporters />
                  </Route>

                  <Route path="/home">
                    <HomeRedirect />
                  </Route>

                  <Route path="/">
                    <HomeRedirect />
                  </Route>

                  <Route
                    path="/logout"
                    render={() => {
                      localStorage.clear();
                      return this.props.auth0.logout({
                        returnTo: window.location.origin,
                      });
                    }}
                  />
                </Switch>
              </div>
            </Router>
          </CompanyServiceProvider>
        </UserContextProvider>
      </div>
    );
  }
}

export default withAuth0(App);
