import React, { useState, useEffect } from "react";
import "../../CSS/Trophies/components/Nft.scss";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { cleanMessage } from "../../helpers";

import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faCopy,
  faExchangeAlt,
  faTimes,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Nft = (props) => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  let { code } = useParams();
  const [showClaimModal, setShowClaimModalState] = useState(false);
  const [nft, setNft] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [properties, setProperties] = useState(JSON.parse(props.nft.metadata));
  const [memberDetails, setMemberDetails] = useState([]);
  const [files, setFiles] = useState([]);
  const [showOwner, setShowOwner] = useState(false);
  const [owners, setOwners] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [imageDataUrl, setImageDataUrl] = useState(null);
  const [showClaim, setShowClaim] = useState(false);
  const [etherWallet, setEtherWallet] = useState(false);
  const [match, setMatch] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [claimed, setClaimed] = useState(false);
  const [copied, setCopied] = useState(false);
  const [selector, setSelector] = useState("details");
  const [requested, setRequested] = useState(false);
  const [request, setRequest] = useState(false);
  const [comment, setComment] = useState(false);
  const [userTrophy, setUserTrophy] = useState(false);
  const [error, setError] = useState(false);
  const [awaitingClaim, setAwaitingClaim] = useState(false);
  const [inviteCode, setInviteCode] = useState(false);
  const [userProperties, setUserProperties] = useState([]);
  const [me, setMe] = useState({
    external_profile: {},
  });
  const [isAdmin, setIsAdmin] = useState(() => {
    const rolesJSON = localStorage.getItem("roles");
    const roles = JSON.parse(rolesJSON);
    return roles && roles.includes("Admin");
  });

  const [supportingDocuments, setSupportingDocuments] = useState([]);
  const [userDocs, setUserDocs] = useState([]);
  const [hasTrophy, setHasTrophy] = useState(props.nft.requestingUserHasTrophy);
  const [showAddOrganization, setShowAddOrganization] = useState(false);
  const [searchOrganizations, setSearchOrganizations] = useState([]);
  const [whiteLabel, setWhiteLabel] = useState(false);

  useEffect(() => {
    const rolesJSON = localStorage.getItem("roles");
    const roles = JSON.parse(rolesJSON);
    const me = localStorage.getItem("user");
    setMe(JSON.parse(me));

    if (roles && roles.includes("Admin")) {
      getCode();
    }

    if (props.nft.trophyCollectionName.includes('Century 21')) {
      setWhiteLabel(true);
    }

    return () => {
      setSelector("details");
      setRequested(false);
    };
  }, [props.nft]);

  const getCode = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophies/${props.nft.trophyId}/code`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const code = await response.text();

    setInviteCode(code);
  };

  const uploadSupportingDocument = async (id, file, type) => {
    let selectedImage = file;
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("type", type);
    formData.append("file", selectedImage, selectedImage.name);

    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-trophies/${id}/upload-attachment`,
      {
        method: "POST",
        enctype: "multipart/form-data",
        contentType: false,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );

    if (response.ok) {
      const newSupporting = [...supportingDocuments, response.text()];
      setSupportingDocuments(newSupporting);
      return true;
    } else {
      throw new Error("Error uploading image");
    }
  };

  const getOwners = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_JAVA}/trophies/${props.nft.trophyId}/awardees?page=0&size=1000`,
        {
          headers: {},
        }
      );
      const owners = await response.json();
      setOwners(owners.content);
    } catch (error) {}
  };

  const addOrganization = async (org) => {
    try {
      let companyId = null;
      let communityId = null;

      if (org.type === "COMMUNITY") {
        communityId = org.id;
      } else {
        companyId = org.id;
      }

      const data = {
        companyId,
        communityId,
        trophyId: props.nft.trophyId,
        addToMembers: true,
      };
      let token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
      const response = await fetch(
        `${process.env.REACT_APP_URL_JAVA}/organization-trophies`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(data),
        }
      );

      let responseData = await response.json();

      setSearchOrganizations([]);
      setShowAddOrganization(false);
      getOrganizations();
    } catch (error) {}
  };

  const getOrganizations = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_JAVA}/trophies/${props.nft.trophyId}/organizations?page=0&size=1000`,
        {
          headers: {},
        }
      );
      const organizations = await response.json();
      setOrganizations(organizations);
    } catch (error) {}
  };

  const getMetadata = async () => {
    if (props.nft.userTrophyId) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL_JAVA}/user-trophies/${props.nft.userTrophyId}/metadata`,
          {
            headers: {},
          }
        );
        const metadata = await response.json();
        setMemberDetails(metadata);
      } catch (error) {}
    }
  };

  const getAttachments = async () => {
    if (props.nft.userTrophyId) {
      try {
        let token = await getAccessTokenSilently({
          audience: `https://api-v2.behearty.co`,
          scope: "read:current_user offline_access",
        });
        const response = await fetch(
          `${process.env.REACT_APP_URL_JAVA}/user-trophies/${props.nft.userTrophyId}/files`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        let files = await response.json();

        files = files.filter((item) => {
          return item.type !== "REQUEST_PROOF";
        });

        setFiles(files);
      } catch (error) {}
    }
  };

  const getTrophyProperties = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophy-collections/${props.nft.trophyCollectionId}/filters?type=USER_TROPHY`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const properties = await response.json();
    const propertiesObject = {};

    properties.forEach((property) => {
      propertiesObject[property.name] = {
        id: property.id,
        value: "",
        options: property.options,
      };
    });

    setUserProperties(propertiesObject);
  };

  const requestNft = async () => {
    const token = await getAccessTokenSilently();

    setError(false);

    if (!request || request === "") {
      setError("Please enter a reason on why you are requesting this Trophy");
      return;
    }

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophies/${props.nft.trophyId}/request-trophy`,
      {
        body: request,
        redirect: "follow",
        headers: {
          Authorization: `Bearer Bearer ${token}`,
          "Content-Type": "text/plain",
        },
        method: "POST",
      }
    );

    const userTrophy = await response.json();
    setUserTrophy(userTrophy);

    if (supportingDocuments.length > 0) {
      const docs = supportingDocuments.map((document) => {
        return uploadSupportingDocument(
          userTrophy.id,
          document,
          "REQUEST_PROOF"
        );
      });
    }
    setSelector("userProperties");
    return true;
  };

  const patchUserTrophy = async () => {
    const token = await getAccessTokenSilently();

    const metadata = {};

    if (userProperties) {
      Object.keys(userProperties).map((key, index) => {
        return (metadata[userProperties[key].id] = userProperties[key].value);
      });
    }

    const body = {
      comment,
      metadata,
    };

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-trophies/${userTrophy.id}/properties`,
      {
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "PATCH",
      }
    );

    if (userDocs.length > 0) {
      const docs = userDocs.map((document) => {
        return uploadSupportingDocument(userTrophy.id, document, "ATTACHMENT");
      });
    }

    setRequested(true);
    setHasTrophy(1);
    setSelector("request");
  };

  const sendClaim = async () => {
    const token = await getAccessTokenSilently();
    setAwaitingClaim(true);
    const body = {
      walletAddress: etherWallet,
    };
    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophies/${props.nft.trophyId}/request-nft`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const owners = await response.json();
    setAwaitingClaim(false);
    setClaimed(true);
  };

  return (
    <div key={props.nft.id} className="nft">
      <div
        onClick={() => {
          setShowPopup(!showPopup);
          getOwners();
          getOrganizations();
          getMetadata();
          getAttachments();
        }}
      >
        {props.nft.status === "PENDING" && (
          <div className="pending">PENDING</div>
        )}
        {hasTrophy && props.showClaimed && (
          <div className="pending">CLAIMED</div>
        )}
        <div className="imageBox"></div>
        <img
          className="displayImage"
          src={props.nft.animatedThumbnailUrl}
          alt={props.nft.name}
        />
        {props.collectionView !== true ? (
          <h3>{props.nft.trophyCollectionName}</h3>
        ) : (
          <h3>{props.nft.trophyName}</h3>
        )}
        <div className="info">
          {props.collectionView !== true ? (
            <h3>{props.nft.trophyName}</h3>
          ) : (
            <h3></h3>
          )}
          {props.collectionView !== true ? (
            <span>
              <FontAwesomeIcon icon={faUser} /> {props.nft.recipientCount}
            </span>
          ) : (
            <span
              style={{
                marginTop: "-25px",
              }}
            >
              <FontAwesomeIcon icon={faUser} /> {props.nft.recipientCount}
            </span>
          )}
        </div>
      </div>
      {showPopup && (
        <div className="popupModal">
          <div className="modal">
            <h3>
              NFT Details
              {!hasTrophy > 0 && (
                <div className="buttons">
                  <FontAwesomeIcon
                    onClick={() => {
                      setShowPopup(false);
                    }}
                    icon={faTimes}
                  />
                </div>
              )}
              {isAdmin && (
                <a
                  href={`/trophies/edit/${props.nft.trophyId}`}
                  className="edit"
                >
                  Edit
                </a>
              )}
              {isAdmin && (
                <a
                  href={`/trophies/edit/${props.nft.trophyId}`}
                  className="edit"
                >
                  Edit
                </a>
              )}
              {hasTrophy > 0 && props.nft.status !== "PENDING" && (
                <div className="buttons">
                  <div className="claim">
                    <h3
                      onClick={() => {
                        setShowClaim(!showClaim);
                      }}
                    >
                      <FontAwesomeIcon icon={faExchangeAlt} />
                    </h3>
                    <div className="hover">
                      <p>Transfer to Wallet</p>
                    </div>
                    {showClaim &&
                      !awaitingClaim &&
                      !claimed &&
                      !props.nft.nftRequested && (
                        <div className="formBox">
                          <p>
                            Enter the address of the wallet you want to transfer
                            the trophy to. If you don't have one, but are interested in getting one, here's a <a href="https://codehs.com/tutorial/jkeesh/how-to-set-up-an-ethereum-wallet-on-metamask">helpful article</a>. 
                          </p>
                          <input
                            onChange={(e) => {
                              setEtherWallet(e.target.value);
                              const regex = /^0x[a-fA-F0-9]{40}$/;
                              const match = e.target.value.match(regex);
                              const ens = /.*\.eth/;
                              const matchENS = e.target.value.match(ens);

                              if (match || matchENS) {
                                setMatch(true);
                              } else {
                                setMatch(false);
                              }
                            }}
                            type="text"
                            placeholder="Enter wallet address"
                          />
                          {!match && etherWallet.length > 0 && (
                            <p class="alert">
                              Invalid Ether Address. Please enter a valid
                              address.
                            </p>
                          )}
                          {!match ? (
                            <button disabled className="btn large">
                              Claim
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                sendClaim();
                              }}
                              className="btn large"
                            >
                              Claim
                            </button>
                          )}

                          {claimed && (
                            <p class="alert success">
                              Success! Please give the Hearty team up to 5
                              business days to transfer your NFT.
                            </p>
                          )}
                        </div>
                      )}

                    {showClaim &&
                      awaitingClaim &&
                      !claimed &&
                      !props.nft.nftRequested && (
                        <div className="formBox">
                          <p>Please Wait...</p>
                        </div>
                      )}

                    {showClaim && claimed && !props.nft.nftRequested && (
                      <div className="formBox">
                        <p>
                          Congratulations your trophy has been sent to your
                          wallet.
                        </p>
                      </div>
                    )}

                    {showClaim &&
                      props.nft.nftRequested &&
                      props.nft.userTrophyTokenIdentifier > 0 && (
                        <div className="formBox">
                          <p>
                            Congratulations your trophy has been sent to your
                            wallet.
                          </p>
                        </div>
                      )}
                  </div>

                  <FontAwesomeIcon
                    onClick={() => {
                      setShowPopup(false);
                    }}
                    icon={faTimes}
                  />
                </div>
              )}
            </h3>
            <div className="content">
              <div className="left">
                {props.nft.url.includes("mp4") && (
                  <video autoPlay muted loop controls>
                    <source src={props.nft.url} />
                  </video>
                )}
                {!props.nft.url.includes("mp4") && (
                  <img
                    className="displayImage"
                    src={props.nft.animatedThumbnailUrl}
                    alt={props.nft.name}
                  />
                )}

                {/* {props.nft.requestingUserHasTrophy > 0 && (
                  <div className="download">
                    <button className="btn medium">Download</button>
                    <div className="dropdown">
                      <a target="_blank" download href={props.nft.animatedThumbnailUrl}>Download Gif</a>
                      <a target="_blank" download href={props.nft.url}>Download Video</a>
                      </div>
                  </div>
                  
                )} */}
              </div>
              <div className="right">
                {selector !== "request" && (
                  <div className="selector">
                    <h3
                      className={selector === "details" ? "active" : ""}
                      onClick={() => {
                        setSelector("details");
                      }}
                    >
                      NFT details
                    </h3>
                    <h3
                      className={selector === "owner" ? "active" : ""}
                      onClick={() => {
                        setSelector("owner");
                      }}
                    >
                      Owners ({owners.length || 0})
                    </h3>
                  </div>
                )}

                {selector === "details" && (
                  <div className="details">
                    <h4 className="nameBox">
                      <b>Name</b> {props.nft.trophyName}
                    </h4>
                    <h4>
                      <b>Collection</b>
                      <a href={`/collection/${props.nft.trophyCollectionId}`}>
                        {props.nft.trophyCollectionName}
                      </a>
                    </h4>
                    <h4 className="nameBox">
                      <b>About</b>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: cleanMessage(props.nft.trophyDescription),
                        }}
                      />
                    </h4>
                    <div className="info">
                      <h4>
                        <b>Properties</b>
                      </h4>
                      {Object.keys(properties).map((key, index) => {
                        return (
                          <div className="meta" key={index}>
                            <h5>{key}</h5>
                            <p>{properties[key]}</p>
                          </div>
                        );
                      })}

                      {memberDetails && memberDetails.length > 0 && (
                        <div className="memberDetails info">
                          <h4>
                            <b>Member Details</b>
                          </h4>
                          {memberDetails.map((detail, index) => {
                            return (
                              <div className="meta" key={index}>
                                <h5>{detail.name}</h5>
                                <p>{detail.value}</p>
                              </div>
                            );
                          })}
                        </div>
                      )}

                      {files && files.length > 0 && (
                        <div className="memberDetails info">
                          <h4>
                            <b>Attachments</b>
                          </h4>
                          {files.map((detail, index) => {
                            if (detail.type === "REQUEST_PROOF") return "";
                            return (
                              <div className="attachment" key={index}>
                                <a href={`${detail.url}`}>{detail.filename}</a>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    {props.nft.comment && props.nft.comment !== "" && (
                      <div className="comment">
                        <h4>
                          <b>Comment</b>
                        </h4>
                        <p>{props.nft.comment}</p>
                      </div>
                    )}
                  </div>
                )}

                {selector === "owner" && (
                  <div className="owners">
                    {(hasTrophy > 0 || isAdmin) && (
                      <div className="owners claimBox">
                        <h4
                          onClick={() => {
                            setShowInvite(!showInvite);
                          }}
                        >
                          <b>Invite others to claim trophy</b>
                          <span>
                            <FontAwesomeIcon icon={faChevronDown} />
                          </span>
                        </h4>
                        {showInvite && (
                          <div className="ownersContainer inviteBox">
                            <p>
                              Share the trophy's claim link with anyone else you
                              believe is eligible for this award, such as other
                              winners, teammates, or co-workers that
                              contributed.
                            </p>
                            <CopyToClipboard
                              text={`https://${window.location.hostname}/nft/${
                                props.nft.inviteCode || inviteCode
                              }`}
                              onCopy={() => {
                                setCopied(true);
                              }}
                            >
                              {!copied ? (
                                <span>
                                  <FontAwesomeIcon icon={faCopy} /> Copy Claim
                                  URL
                                </span>
                              ) : (
                                <span>
                                  <FontAwesomeIcon icon={faCopy} /> Copied!
                                </span>
                              )}
                            </CopyToClipboard>
                          </div>
                        )}
                      </div>
                    )}
                    <div className="owners">
                      <h4
                        onClick={() => {}}
                      >
                        <b>Owners</b>
                      </h4>
                      <div className="ownersContainer">
                        {owners.map((owner, index) => {
                          return (
                            <div className="person" key={index}>
                              <a href={`/user/${owner.customUrl}`}>
                                <img src={owner.image} alt={owner.name} />
                                {owner.name}
                              </a>
                            </div>
                          );
                        })}

                        {!owners ||
                          (owners.length === 0 && (
                            <p
                              style={{
                                padding: 20,
                              }}
                            >
                              No Awardees Yet!
                            </p>
                          ))}
                      </div>
                    </div>
                  </div>
                )}

                {selector === "request" && requested === false && (
                  <div className="request">
                    <h4>Claim this Trophy</h4>
                    {error && (
                      <div
                        style={{
                          marginBottom: "10px",
                        }}
                        className="error alert"
                      >
                        <p>{error}</p>
                      </div>
                    )}
                    <p>
                      Trophies are awarded and verified accomplishments and
                      achievements. Please share details to establish your
                      eligibility so that our team may review your claim and, if
                      approved, award your trophy. Note: You or your
                      organization are responsible for obtaining any required
                      permissions or licenses.
                    </p>

                    <label>Why are you eligible for this trophy?</label>
                    <textarea onChange={(e) => setRequest(e.target.value)} />

                    <label>Upload Supporting Documents (optional)</label>
                    <input
                      onChange={(e) => {
                        const newSupporting = [
                          ...supportingDocuments,
                          e.target.files[0],
                        ];
                        setSupportingDocuments(newSupporting);
                      }}
                      type="file"
                    />

                    {supportingDocuments.map((file, index) => {
                      return (
                        <div className="supporting" key={index}>
                          <span>{file.name}</span>
                          <FontAwesomeIcon
                            icon={faTimes}
                            onClick={() => {
                              const newSupporting = [...supportingDocuments];
                              newSupporting.splice(index, 1);
                              setSupportingDocuments(newSupporting);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}

                {selector === "userProperties" && requested === false && (
                  <div className="UserInformation">
                    <h3>Customize Your Trophy</h3>

                    {userProperties && Object.keys(userProperties).length > 0 && (
                      <div className="additionalBox">
                        <span>
                          Provide the following information so we can make your
                          trophy personal to you.
                        </span>
                        {Object.keys(userProperties).map((keyName, i) => {
                          if (
                            !userProperties[keyName].options ||
                            userProperties[keyName].options.length === 0
                          ) {
                            return (
                              <div
                                style={{
                                  marginRight: "30px",
                                }}
                                key={i}
                              >
                                <span
                                  style={{
                                    marginRight: "10px",
                                  }}
                                  className="input-label"
                                >
                                  {keyName}
                                </span>
                                <textarea
                                  type="text"
                                  onChange={(e) => {
                                    const newUserProperties = {
                                      ...userProperties,
                                    };
                                    newUserProperties[keyName].value =
                                      e.target.value;
                                    setUserProperties(newUserProperties);
                                  }}
                                />
                              </div>
                            );
                          } else {
                            return (
                              <div
                                style={{
                                  marginRight: "30px",
                                }}
                                key={i}
                              >
                                <span
                                  style={{
                                    marginRight: "10px",
                                  }}
                                  className="input-label"
                                >
                                  {keyName}
                                </span>
                                <select
                                  onChange={(e) => {
                                    const newUserProperties = {
                                      ...userProperties,
                                    };
                                    newUserProperties[keyName].value =
                                      e.target.value;
                                    setUserProperties(newUserProperties);
                                  }}
                                >
                                  {userProperties[keyName].options.map(
                                    (option, i) => {
                                      return (
                                        <option key={i} value={option}>
                                          {option}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              </div>
                            );
                          }
                        })}
                      </div>
                    )}

                    <div className="commentBox">
                      <h5>Comments</h5>
                      <span>
                        Provide additional context you want to share with the
                        community
                      </span>
                      <textarea onChange={(e) => setComment(e.target.value)} />
                    </div>

                    <div className="additionalDocs">
                      <label>Attachments</label>
                      <span>
                        Include any files relevant to your trophy you want to
                        share with the community.
                      </span>
                      <input
                        onChange={(e) => {
                          const newUserDocs = [...userDocs, e.target.files[0]];
                          setUserDocs(newUserDocs);
                        }}
                        type="file"
                      />

                      {userDocs.map((file, index) => {
                        return (
                          <div className="supporting" key={index}>
                            <span>{file.name}</span>
                            <FontAwesomeIcon
                              icon={faTimes}
                              onClick={() => {
                                const newUserDocs = [...userDocs];
                                newUserDocs.splice(index, 1);
                                setUserDocs(newUserDocs);
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                {selector === "request" && requested === true && (
                  <div className="request">
                    <h4>Request Sent!</h4>
                    <p>
                      Please give team Hearty up to 5 business days to review
                      your request.
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="bottom">
              {/* Show while on normal nft view*/}
              {selector !== "request" && (
                <button
                  onClick={() => {
                    setShowPopup(false);
                  }}
                  className="btn medium"
                >
                  Close
                </button>
              )}

              {/* Show while seeing normal nft view*/}
              {selector !== "request" &&
                selector !== "userProperties" &&
                !hasTrophy > 0 &&
                me && (
                  <button
                    onClick={() => {
                      setSelector("request");
                      getTrophyProperties(props.nft.trophyId);
                    }}
                    className="btn large"
                  >
                    Claim
                  </button>
                )}


                {/* Show while seeing normal nft view*/}
              {/* {selector !== "request" &&
                selector !== "userProperties" &&
                !hasTrophy > 0 &&
                !me &&
                !whiteLabel && (
                  <button
                    onClick={() => {
                      return window.location.replace("/secret/tunnel")

                    }}
                    className="btn large"
                  >
                    Claim
                  </button>
                )} */}

              {/* For white label trophies */}
              {selector !== "request" &&
                selector !== "userProperties" &&
                !hasTrophy > 0 &&
                !me &&
                whiteLabel && (
                  <button
                    onClick={() => {
                      return window.location.replace(`/nft/${props.nft.inviteCode}`);
                    }}
                    className="btn large"
                  >
                    Claim
                  </button>
                )}

              {/* Show while attempting to request*/}
              {selector === "request" && (
                <button
                  onClick={() => {
                    setSelector("details");
                  }}
                  className="btn medium"
                >
                  Back
                </button>
              )}

              {/*Show while getting credential info*/}
              {selector === "request" && requested === false && (
                <button
                  className="btn medium"
                  onClick={async () => {
                    await requestNft();
                  }}
                >
                  Save
                </button>
              )}

              {/* Show while getting additional info*/}
              {selector === "userProperties" && requested === false && (
                <button
                  onClick={() => {
                    patchUserTrophy();
                  }}
                  className="btn large"
                >
                  Claim
                </button>
              )}
            </div>
          </div>
          <div
            onClick={() => {
              setShowPopup(false);
              setSelector("details");
              setRequested(false);
            }}
            className="skrim"
          ></div>
        </div>
      )}
    </div>
  );
};

export default Nft;
