import React, { createContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

// create context
const UserContext = createContext();

const UserContextProvider = ({ children }) => {
    // the value that will be given to the context
    const [user, setUser] = useState(null);
    const [collectives, setCollectives] = useState(null);
    const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
    const [contextLoaded, setContextLoaded] = useState(false);

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            loadUser().catch((error) => {
                console.log('User not logged in: ' + error);
            });
        }
    }, [isLoading, isAuthenticated, getAccessTokenSilently]);

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            loadCollectives().catch((error) => {
                console.log('User not logged in: ' + error);
            });
        }
    }, [isLoading, isAuthenticated, getAccessTokenSilently]);

    useEffect(() => {
        if (!isLoading && isAuthenticated && user && collectives && !contextLoaded) {
            setContextLoaded(true);
        }
    }, [isLoading, isAuthenticated, user, collectives, contextLoaded]);

    const loadUser = async () => {
        let token = await getAccessTokenSilently({
            audience: `https://api-v2.behearty.co`,
            scope: "read:current_user offline_access",
        });

        let headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };

        let url = `${process.env.REACT_APP_URL_JAVA}/users/me`

        const response = await fetch(
            url,
            {
                headers,
                method: "GET",
            }
        );

        const data = await response.json();
        setUser(data);
    };

    const loadCollectives = async() => {
        let token = await getAccessTokenSilently({
            audience: `https://api-v2.behearty.co`,
            scope: "read:current_user offline_access",
          });
    
        const response = await fetch(`${process.env.REACT_APP_URL_JAVA}/user-collectives/roles`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            "Content-Type": "application/json",
          }
        })
    
        const data = await response.json();
        setCollectives(data);
    };

    const refreshUserContext = async () => {
        return Promise.all([
            loadUser(),
            loadCollectives()
        ]);
    };

  return (
    // the Provider gives access to the context to its children
    <UserContext.Provider value={{user, collectives, refreshUserContext, contextLoaded}}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };

export const useUserContext = () => React.useContext(UserContext);