import React, { useState, useEffect } from "react";
import "../CSS/Trophies/admin.scss";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import CreateCollection from "./components/CreateCollection";
import CreateTrophy from "./components/CreateTrophy";
import ApproveTrophies from "./components/ApproveTrophies";

const TrophyAdmin = (props) => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } =
    useAuth0();
  const history = useHistory();
  const [view, setView] = useState("create_collection");
  const [message, setMessage] = useState(false);

  useEffect(() => {
    const rolesJSON = localStorage.getItem("roles");
    const roles = JSON.parse(rolesJSON);

    if (!roles || !roles.includes("Admin")) {
      return history.push({
        pathname: `/feed`,
      });
    }
  }, [isLoading]);

  const callback = (data) => {
      setMessage(data);
      setTimeout(() => {
          setMessage(null);
      }, 5000);
  }


  const getCurrentView = () => {
    switch (view) {
      case "create_collection":
        return <CreateCollection callback={callback}/>;
      case "create_trophy":
        return <CreateTrophy callback={callback} />;
      case "approve_trophies":
        return <ApproveTrophies callback={callback} />;
      default:
        return <p>Choose an option</p>;
    }
  }

  return (
    <div className="trophies admin">
      <div className="navigation">
        <ul>
          <li onClick={()=>{
            setView("create_collection")
          }} className={`${view === "create_collection" ? "active" : ""}`}>
            Create Collection
          </li>
          <li onClick={()=>{
            setView("create_trophy")
          }} className={`${view === "create_trophy" ? "active" : ""}`}>
            Create Trophy
          </li>
          <li onClick={()=>{
            setView("approve_trophies")
          }} className={`${view === "approve_trophies" ? "active" : ""}`}>
            Approve Trophies
          </li>
        </ul>
      </div>
      {message && <div style={{
          width:'100%'
      }} className="alert">{message}</div>}
      {getCurrentView()}
    </div>
  );
};

export default withAuthenticationRequired(TrophyAdmin, {
  onRedirecting: () => <p>Loading...</p>,
});
