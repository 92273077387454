import React, { useEffect, useState, useRef } from "react";
import "../CSS/Collective/Match.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as Taco } from "@fortawesome/free-regular-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { formatCandidateStatus } from "../helpers";

export default function Match(props, ref) {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();

  const [skills, setSkills] = useState([]);
  const [showOfferIntroduction, setShowOfferIntroduction] = useState(false);
  const [comments, setComments] = useState('');
  const [candidateView, setCandidateView] = useState(true);

  useEffect(() => {
    setMatches();
    if (props.candidateView === false) {
      setCandidateView(false);
    }
  }, [props.item]);

  const formatStatus = (match) => {
    var newText = match.status
        .toLowerCase()
        .replaceAll("_", " ")
        .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

    if (newText === "Introduction Made") {
      newText = "Actively Interviewing";
    }

    if (candidateView) {
      if ((newText === "Suggested" || newText === "Introduction Offered") && match.candidateAccepted == true) {
          newText = "Pending";
      } else if ((newText === "Suggested" || newText === "Introduction Offered") && match.candidateAccepted == false) {
          newText = "Not Interested"
      }
    } else {
      if (newText === "Introduction Offered" && match.candidateAccepted == null && match.companyAccepted == true) {
        newText = "Pending candidate response";
      } else if (newText === "Introduction Offered" && match.companyAccepted == false) {
          newText = "Company declined";
      } else if (newText === 'Suggested' && match.candidateAccepted == true) {
        newText += " - Interested";
      } else if (newText === 'Suggested' && match.candidateAccepted == false) {
        newText += " - Not Interested";
      }
    }

    return newText;
  };

  const offerIntroduction = async (companyCollectiveUserId) => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      console.log(error);
    }
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }

    const body = {
        id: companyCollectiveUserId,
        companyCollectiveId: props.item.companyCollectiveId,
        userCollectiveId: props.profile.userCollectiveId,
        companyCollectiveRoleId: props.item.companyCollectiveRoleId,
        status: "INTRODUCTION_OFFERED"
    }

    let response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/company-collective-users/${companyCollectiveUserId}/status`,
      {
        method: "PATCH",
        body: JSON.stringify(body),
        headers,
      }
    );

    if (response.status === 200) {
        setShowOfferIntroduction(false);
        props.introduced();
    }
  };

  const setMatches = () => {
    const roles = props.item.roleTechnologies.map((role) => {
      const filter = props.item.userTechnologies.filter((user) => {
        return user.technologyId === role.technologyId;
      });
      if (filter.length > 0) {
        role.included = true;
      }

      return role;
    });

    setSkills(roles);
  };

  const dismiss = async (companyCollectiveUserId) => {
    if (window.confirm("Are you sure you want to dismiss this match?")) {
      let token = false;
      try {
        token = await getAccessTokenSilently({
          audience: `https://api-v2.behearty.co`,
          scope: "read:current_user offline_access",
        });
      } catch (error) {
        console.log(error);
      }
      let headers = {
        "Content-Type": "application/json",
      };

      if (token) {
        headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
      }

      let response = await fetch(
        `${process.env.REACT_APP_URL_JAVA}/company-collective-users/${companyCollectiveUserId}`,
        {
          method: "DELETE",
          headers,
        }
      );

      props.dismiss();
    }
  };

  const updateSubstatus = async (substatus) => {
    props.item.substatus = substatus;

    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      console.log(error);
    }
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }

    const newUser = { ...props.item.companyCollectiveUserId };

    newUser.substatus = substatus;

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/company-collective-users/${props.item.companyCollectiveUserId}/substatus`,
      {
        method: "PATCH",
        body: JSON.stringify(newUser),
        headers: headers,
      }
    );

    const blob = await response.json();
    props.introduced();
  };

  const handleClick = async() => {
    if (props.onClick) {
      props.onClick(props.matchIndex);
    }
};

  return (
    <div className={`collectiveMatch ${props.item.roleActive ? "" : "inactive"}`}  onClick={()=>{handleClick();}}>
      <div className="info">
        <div className="imageTop">
          <a className="companyLink" href={`/company/${props.item.companyCustomUrl}`} target="_blank">
            <img src={props.item.companyImage} alt="company" />
          </a>
          <h2>
            <a className="companyLink" href={`/company/${props.item.companyCustomUrl}`} target="_blank">{props.item.companyName}</a> - {props.item.roleName}
          </h2>
        </div>
        <div className="candidate-status">
          {props.item.status !== "INTRODUCTION_MADE" && (
            <h3>{formatStatus(props.item)}</h3>
          )}
          {props.item.status === "INTRODUCTION_MADE" && candidateView && (
            <h3>{props.item.substatus.replaceAll("_", " ")}</h3>
          )}
          {props.item.status === "INTRODUCTION_MADE" && !candidateView && (
            <span className="substatus">
              <select className="substatus"
                onChange={(e) => {
                  updateSubstatus(e.target.value);
                }}
                value={props.item.substatus}
              >
                <option key="INITIAL_SCREEN" value="INITIAL_SCREEN">Initial Screen</option>
                <option key="TECH_EVALUATION" value="TECH_EVALUATION">Tech Evaluation</option>
                <option key="FINAL_INTERVIEW" value="FINAL_INTERVIEW">Final Interview</option>
              </select>
            </span>
          )}

        </div>
        {!candidateView && props.item.roleActive && props.profile.status === 'ACTIVE' && ["SUGGESTED"].includes(props.item.status) && props.item.companyAccepted != false && (
          <div>
            <button
              onClick={() => {
                setShowOfferIntroduction(true);
              }}
              className="button introduce"
            >
              Offer Introducion
            </button>
          </div>
        )}
        {!candidateView && props.item.status === "SUGGESTED" && (
          <div>
            <button className="dismiss" onClick={() => {dismiss(props.item.companyCollectiveUserId)}}>X</button>
          </div>
        )}
      </div>
      <div className="fit">
        <div className="skills">
          <h3>
            Skills Fit
            <span className="stars">
              {props.item.skillMatch >= 1 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
              {props.item.skillMatch >= 2 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
              {props.item.skillMatch >= 3 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
            </span>
          </h3>

          <div className="skillMap">
            {skills.map((item) => {
              return (
                <div
                  className={`skill-item ${item.included ? "included" : ""}`}
                  key={item.technologyId}
                >
                  <p>{item.required ? "*" : ""}{item.technologyName}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="skills">
          <h3>
            Experience Fit
            <span className="stars">
              {props.item.experienceMatch >= 1 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
              {props.item.experienceMatch >= 2 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
              {props.item.experienceMatch >= 3 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
            </span>
          </h3>

          {!candidateView && (
            <p>{props.item.roleMinExperience} - {props.item.roleMaxExperience} Years</p>
          )}
        </div>
        <div className="skills">
          <h3>
            Compensation Fit
            <span className="stars">
              {props.item.salaryMatch >= 1 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
              {props.item.salaryMatch >= 2 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
              {props.item.salaryMatch >= 3 ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                <FontAwesomeIcon icon={Taco} />
              )}
            </span>
          </h3>
          {!candidateView && (
            <p>${props.item.roleMinSalary.toLocaleString("en-US")}-${props.item.roleMaxSalary.toLocaleString("en-US")}</p>
          )}
        </div>
      </div>
      {!candidateView && (
        <div className="notes">
          <div className="roleNotes">
            <h3>Role Notes</h3>
            <p>{props.item.roleNotes}</p>
          </div>
          <div className="companyNotes">
            <h3>Internal Company Notes</h3>
            <p dangerouslySetInnerHTML={{
                __html:props.item.companyInternalNotes
              }} />
          </div>
        </div>
      )}

      {showOfferIntroduction && (
        <div className="introduceModal">
          <div className="modal">
            <h3>Offer Introduction</h3>

            <div className="info">
              <div className="profile">
                <img src={props.profile.imageUrl} alt="profile" />
                <div className="info-text">
                  <h4>{props.profile.userUsername}</h4>
                  <p>{props.profile.userTitle}</p>
                </div>
              </div>
              <div className="role-info">
                <p>Role Selected:</p>
                <h3>{props.item.companyName} - {props.item.roleName}</h3>
              </div>
              <div className="textarea">
              <button onClick={()=>{
                  offerIntroduction(props.item.companyCollectiveUserId);
              }} type="btn btn-large">Submit</button>
              </div>
            </div>
          </div>
          <div onClick={()=>{
            setShowOfferIntroduction(false)
          }} className="skrim"></div>
        </div>
      )}
    </div>
  );
}
