import React, { useState, useEffect, useRef } from "react";
import "../CSS/Collective/edit.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";
import Captain from "../images/captain-emoji.png";
import SkillsSelector from "../components/SkillsSelector";
import TipTap from "../Feed/components/TipTap";
import { useParams } from "react-router-dom";

export default function Edit(props, ref) {
  const notesTemplate = `
    <p><strong>Role</strong></p>
    <p><strong>Review</strong></p>
    <p><strong>Why looking?</strong></p>
    <ul><li><p></p></li></ul>
    <p><strong>Understand roles, skillset</strong></p>
    <ul><li><p></p></li></ul>
    <p><strong>What project are you most proud of?</strong></p>
    <ul><li><p></p></li></ul>
    <p><strong>Startup experience?</strong></p>
    <ul><li><p></p></li></ul>
    <p><strong>Hobby</strong></p>
    <ul><li><p></p></li></ul>
    <p><strong>Current and desired salary</strong></p>
    <ul><li><p></p></li></ul>
    <p><strong>Communication preference?</strong></p>
    <ul><li><p></p></li></ul>
    <p><strong>Must-have and nice-to-haves in next role (e.g. remote/location, specific tech, startup/BigCo, product vs. project/agency, specific benefits)</strong></p>
    <ul><li><p></p></li></ul>
    <p><strong>Applied/talking with any other companies yet?</strong></p>
    <ul><li><p></p></li></ul>
    <p><strong>Timing expectations</strong></p>
    <ul><li><p></p></li></ul>
  `;

  const [isLoading, setIsLoading] = useState(false);
  const { user, isAuthenticated, tacos, getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(false);
  const [skills, setSkills] = useState([]);
  const [removedSkills, setRemovedSkills] = useState([]);
  const [tech, setTech] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const [userObject, setUserObject] = useState({});
  const { id } = useParams();
  const [userCollectiveId, setUserCollectiveId] = useState(id);
  const [salary, setSalary] = useState("");
  const [gitHub, setGitHub] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [codeAssessmentUrl, setCodeAssessmentUrl] = useState("");
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [resumeUpload, setResumeUpload] = useState("");
  const [experience, setExperience] = useState("");
  const [sponsorship, setSponsorship] = useState(false);
  const [zip, setZip] = useState(null);
  const [education, setEducation] = useState(null);
  const [specialties, setSpecialties] = useState([]);
  const [tldr, setTldr] = useState("");
  const [status, setStatus] = useState(null);
  const [userUsername, setUserUsername] = useState("");
  const [email, setEmail] = useState("");
  const [internalNotes, setInternalNotes] = useState("");
  const [admin, setAdmin] = useState(() => {
    const rolesJSON = localStorage.getItem("roles");
    const roles = JSON.parse(rolesJSON);
    return roles && roles.includes("Admin");
  });
  const [isNew, setIsNew] = useState(props.isNew || false);

  useEffect(() => {
    if (!isLoading && !isNew) {
      getCollective(userCollectiveId);
      getTechnologies(userCollectiveId);
    }

    if (isNew) {
      setInternalNotes(notesTemplate);
    }
  }, [isLoading, isNew]);


  const onFileChange = async (file) => {
    const selectedImage = file[0];
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", selectedImage, selectedImage.name);

    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    fetch(`${process.env.REACT_APP_URL_JAVA}/user-collectives/resume?externalProfileId=${userObject.externalProfileId}`, {
      method: "POST",
      enctype: "multipart/form-data",
      contentType: false,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        }

        throw new Error("Error uploading resume");
      })
      .then((data) => {
        setResumeUpload(true);
      })
      .catch((error) => {});
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0
  });

  const formatSalary = (s) => {
    return formatter.format(stripSalary(s + ''));
  }

  const stripSalary = (s) => {
    return s.replace(/[^0-9]+/g, "");
  }

  const saveSkills = async (userCollectiveId) => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const dataCap = skills.map((skill) => {
      const data = {
        technologyId: skill.technologyId || skill.id,
        userCollectiveId: userCollectiveId,
        score: skill.score,
      };
      return fetch(
        `${process.env.REACT_APP_URL_JAVA}/user-collective-technologies`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
          method: "POST",
        }
      );
    });

    const all = await Promise.all(dataCap);

    const remove = removedSkills.map((skill) => {
      return fetch(
        `${process.env.REACT_APP_URL_JAVA}/user-collective-technologies/${skill.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          method: "DELETE",
        }
      );
    });

    const removed = await Promise.all(remove);

    return all;
  };

  const setSpecialty = (e) => {
    const index = specialties.indexOf(e);
    if (index > -1) {
      const newSpecilities = specialties.filter((item) => {
        if (item === e) {
          return false;
        }

        return true;
      });
      setSpecialties(newSpecilities);
    } else {
      const newSpecilties = [...specialties, e];
      setSpecialties(newSpecilties);
    }
  };

  const saveCollectiveUser = async (userCollectiveId) => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const newUser = { ...userObject };
    newUser.videoUrl = videoUrl;
    newUser.github = gitHub;
    newUser.linkedinUrl = linkedinUrl;
    newUser.codeAssessmentUrl = codeAssessmentUrl;
    newUser.salary = stripSalary(salary);
    newUser.experience = experience;
    newUser.sponsorshipRequired = sponsorship;
    newUser.zip = zip;
    newUser.education = education;
    newUser.focus = specialties.filter((s) => s !== "").join(",");
    newUser.tldr = tldr;
    newUser.userUsername = userUsername;
    newUser.email = email;
    newUser.internalNotes = internalNotes;

    let method = "PATCH";
    let url = `${process.env.REACT_APP_URL_JAVA}/user-collectives/${userCollectiveId}`;

    if (isNew) {
      method = "POST";
      url = `${process.env.REACT_APP_URL_JAVA}/user-collectives`;
      newUser.collectiveId = 1;
      newUser.status = "ACTIVE";
      newUser.imageUrl = imageUrl;
      newUser.technologies = skills.map((skill) => {
        return {
          id: skill.technologyId || skill.id,
          score: skill.score,
          name: skill.name,
        };
      });
    }

    let userCollectiveResponse = await fetch(
      url,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newUser),
        method: method,
      }
    );

    if (admin) {
      let userCollective = await userCollectiveResponse.json();
      window.location.href = `/collective/user/${userCollective.userCollectiveId}`;
    } else {
      window.location.href = `/home`;
    }
  };

  const getTechnologies = async (userCollectiveId) => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      console.log(error);
    }
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }

    let endpoint = `${process.env.REACT_APP_URL_JAVA}/user-collective-technologies`;

    if (typeof id !== 'undefined') {
      endpoint += `?userCollectiveId=${userCollectiveId}`
    }

    let response = await fetch(
      endpoint,
      {
        method: "GET",
        headers,
      }
    );

    let tech = await response.json();

    const newTech = tech.map((item) => {
      item.name = item.technologyName;
      return item;
    });

    setSkills(newTech);
  };

  const getCollective = async (id) => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {}

    let endpoint = `${process.env.REACT_APP_URL_JAVA}/user-collectives`;

    if (typeof id !== 'undefined') {
      endpoint += `/${id}`
    }

    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const user = await response.json();
    setSalary(formatSalary(user.salary));
    setGitHub(user.github);
    setVideoUrl(user.videoUrl);
    setCodeAssessmentUrl(user.codeAssessmentUrl);
    setLinkedinUrl(user.linkedinUrl);
    setImageUrl(user.imageUrl);
    setExperience(user.experience);
    setUserObject(user);
    setSponsorship(user.sponsorshipRequired);
    setUserCollectiveId(user.userCollectiveId);
    setZip(user.zip);
    setEducation(user.education);
    if (user.focus) {
      setSpecialties(user.focus.split(","));
    }
    setTldr(user.tldr);
    setStatus(user.status);
    setUserUsername(user.userUsername);
    setEmail(user.email);
    setInternalNotes(user.internalNotes || internalNotes);
  };

  //   // On file upload (click the upload button)
    const onFileUpload = async (selectedImage) => {
      // Create an object of formData
      const formData = new FormData();

      // Update the formData object
      formData.append("file", selectedImage, selectedImage.name);

      let token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });

      fetch(
        `${process.env.REACT_APP_URL_JAVA}/files/uploadFile?directory=avatar`,
        {
          method: "POST",
          enctype: "multipart/form-data",
          contentType: false,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.text();
          }

          throw new Error("Error uploading image");
        })
        .then((data) => {
          setError(false);

          setImageUrl(data);
          return data;
        })
        .then((data) => {
          updateUserImage(userCollectiveId, data);
          if (!admin) {
            let user = JSON.parse(localStorage.getItem('user'));
            user.external_profile.image = data;
            localStorage.setItem("user", JSON.stringify(user));
          }
        })
        .catch((error) => {
          setError("Issue uploading image");
        });
    };

  const updateUserImage = async (id, imageUrl) => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      return console.log(error);
    }

    const body = {
      imageUrl: imageUrl
    };

    const response = fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-collectives/${id}/image`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
      }
    );

    return response;
  };

  const getTech = async (id) => {
    let token = false;
    try {
      token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
    } catch (error) {
      return console.log(error);
    }

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/company-collective-technologies?companyCollectiveId=${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const tech = await response.json();
    const newTech = tech.map((tech) => {
      return {
        id: tech.technologyId,
        name: tech.technologyName,
        dbId: tech.id,
      };
    });

    setSkills(newTech);
  };

  const updateUserCollectiveStatus = async (newStatus) => {
    const token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const body = {
      status: newStatus,
    };

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-collectives/${userCollectiveId}/status`,
      {
        method: "PATCH",
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    
    const data = await response.json();
  };

  return (
    <div className="ProfileEdit">
      <div className="profileContainer">
        <div className="modal">
          {error && <div className="alert error">{error}</div>}
          <div className="left">
            <div className="imageUploadBox">
              <div className="svg">
                <FontAwesomeIcon icon={faEdit} />
              </div>
              <input
                type="file"
                onChange={(e) => onFileUpload(e.target.files[0])}
              />
              <div className="uploadShow">
                <p>Upload New Photo</p>
              </div>
              <img
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = Captain;
                }}
                src={imageUrl}
              />
            </div>
          </div>
          <div className="right">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              {(!isNew) ? <h3>Edit</h3> : <h3>New</h3>}
              {admin && (
                <div className="inputBox">
                  <label>Name</label>
                  <input
                    onChange={(e) => {
                      setUserUsername(e.target.value);
                    }}
                    value={userUsername}
                    type="text"
                    readOnly={!isNew}
                  ></input>
                </div>
              )}

              {admin && (
                <div className="inputBox">
                  <label>Email</label>
                  <input
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    value={email}
                    type="text"
                    readOnly={!isNew}
                  ></input>
                </div>
              )}

              {(status === "ACTIVE" || status === "INACTIVE" || admin) && (
                <div className="inputBox">
                  <label>Status</label>
                  <select
                    className="status"
                    onChange={(e) => {
                      setStatus(e.target.value);
                      updateUserCollectiveStatus(e.target.value);
                    }}
                    value={status}
                  >
                    <option key="ACTIVE" value="ACTIVE">
                      Active
                    </option>
                    <option key="INACTIVE" value="INACTIVE">
                      Inactive
                    </option>
                  </select>
                </div>
              )}

              <div className="inputBox">
                <label>Minimum Desired Salary</label>
                <input
                  onChange={(e) => {
                    setSalary(formatSalary(e.target.value));
                  }}
                  value={salary}
                  type="text"
                ></input>
              </div>

              <div className="inputBox">
                <label>Years of Experience</label>
                <input
                  onChange={(e) => {
                    setExperience(e.target.value);
                  }}
                  type="number"
                  value={experience}
                >
                </input>
              </div>

              <div className="inputBox">
                <label>Zip Code</label>
                <input
                  onChange={(e) => {
                    setZip(e.target.value);
                  }}
                  type="number"
                  value={zip}
                >
                </input>
              </div>

              <div className="inputBox">
                <label>Highest level of education</label>
                <select
                  onChange={(e) => {
                    setEducation(e.target.value);
                  }}
                  value={education}
                >
                  <option disabled="true">Please choose</option>
                  <option value={"HIGH_SCHOOL"} selected={education === "HIGH_SCHOOL"}>High School</option>
                  <option value={"SOME_COLLEGE"} selected={education === "SOME_COLLEGE"}>Some College</option>
                  <option value={"BOOTCAMP"} selected={education === "BOOTCAMP"}>Bootcamp</option>
                  <option value={"BACHELORS"} selected={education === "BACHELORS"}>Bachelors</option>
                  <option value={"MASTERS"} selected={education === "MASTERS"}>Masters</option>
                  <option value={"DOCTORATE"} selected={education === "DOCTORATE"}>Doctorate</option>
                </select>
              </div>

              <div className="inputBox roles">
                <label>Types of roles interested in</label>
                <div className="input">
                  <input
                    onChange={() => {
                      setSpecialty("FRONT_END");
                    }}
                    value={"FRONT_END"}
                    checked={specialties.indexOf("FRONT_END") > -1}
                    type="checkbox"
                    id="front-end"
                  />
                  <span><label htmlFor="front-end">Front End</label></span>
                </div>

                <div className="input">
                  <input
                    onChange={() => {
                      setSpecialty("BACK_END");
                    }}
                    value={"BACK_END"}
                    checked={specialties.indexOf("BACK_END") > -1}
                    type="checkbox"
                    id="back-end"
                  />
                  <span><label htmlFor="back-end">Back End</label></span>
                </div>

                <div className="input">
                  <input
                    onChange={() => {
                      setSpecialty("FULL_STACK");
                    }}
                    value={"FULL_STACK"}
                    checked={specialties.indexOf("FULL_STACK") > -1}
                    type="checkbox"
                    id="full-stack"
                  />
                  <span><label htmlFor="full-stack">Full Stack</label></span>
                </div>

                <div className="input">
                  <input
                    onChange={() => {
                      setSpecialty("MOBILE");
                    }}
                    value={"MOBILE"}
                    checked={specialties.indexOf("MOBILE") > -1}
                    type="checkbox"
                    id="mobile"
                  />
                  <span><label htmlFor="mobile">Mobile</label></span>
                </div>

                <div className="input">
                  <input
                    onChange={() => {
                      setSpecialty("DEVOPS");
                    }}
                    value={"DEVOPS"}
                    checked={specialties.indexOf("DEVOPS") > -1}
                    type="checkbox"
                    id="devops"
                  />
                  <span><label htmlFor="devops">Devops</label></span>
                </div>

                <div className="input">
                  <input
                    onChange={() => {
                      setSpecialty("DATA_SCIENCE");
                    }}
                    value={"DATA_SCIENCE"}
                    checked={specialties.indexOf("DATA_SCIENCE") > -1}
                    type="checkbox"
                    id="data-science"
                  />
                  <span><label htmlFor="data-science">Data Science</label></span>
                </div>

                <div className="input">
                  <input
                    onChange={() => {
                      setSpecialty("DATA_ENGINEERING");
                    }}
                    value={"DATA_ENGINEERING"}
                    checked={specialties.indexOf("DATA_ENGINEERING") > -1}
                    type="checkbox"
                    id="data-engineering"
                  />
                  <span><label htmlFor="data-engineering">Data Engineering</label></span>
                </div>

                <div className="input">
                  <input
                    onChange={() => {
                      setSpecialty("QA");
                    }}
                    value={"QA"}
                    checked={specialties.indexOf("QA") > -1}
                    type="checkbox"
                    id="qa"
                  />
                  <span><label htmlFor="qa">QA</label></span>
                </div>
              </div>

              <div className="inputBox">
                <label>Github</label>
                <input
                  onChange={(e) => {
                    setGitHub(e.target.value);
                  }}
                  type="text"
                  value={gitHub}
                />
              </div>

              <div className="inputBox sponsorship">
                <label>Sponsorship Required?</label>
                <div>
                  <input
                    onChange={(e) => {
                      setSponsorship(false);
                    }}
                    value={false}
                    type="radio"
                    checked={sponsorship === false}
                    name="sponsorship"
                  />
                  <span>No</span>
                </div>
                <div>
                  <input
                    onChange={(e) => {
                      setSponsorship(true);
                    }}
                    value={true}
                    type="radio"
                    checked={sponsorship === true}
                    name="sponsorship"
                  />
                  <span>Yes</span>
                </div>
              </div>

              <div className="inputBox">
                <label>LinkedIn Url</label>
                <input
                  onChange={(e) => {
                    setLinkedinUrl(e.target.value);
                  }}
                  type="text"
                  value={linkedinUrl}
                />
              </div>

              {admin && (
                <>
                <div className="inputBox">
                  <label>VideoAsk Url</label>
                  <input
                    onChange={(e) => {
                      setVideoUrl(e.target.value);
                    }}
                    type="text"
                    value={videoUrl}
                  />
                </div>

                <div className="inputBox">
                  <label>CodeAssesment Url</label>
                  <input
                    onChange={(e) => {
                      setCodeAssessmentUrl(e.target.value);
                    }}
                    type="text"
                    value={codeAssessmentUrl}
                  />
                </div>

                <div className="inputBox">
                  <label>TLDR</label>
                  <textarea
                    onChange={(e) => {
                      setTldr(e.target.value);
                    }}
                    type="text"
                    value={tldr}
                  />
                </div>

                <div className="inputBox">
                  <label>Internal Notes</label>
                  <TipTap
                    data={internalNotes}
                    editing={true}
                    update={(data) => {
                      setInternalNotes(data);
                    }}
                  />
                </div>
              </>
              )}
              
              <div className="collectiveData">
                <SkillsSelector
                  setData={(data) => {
                    setSkills(data);
                  }}
                  data={skills}
                  url={"technologies"}
                  description={"Skills"}
                  searchText={"Search Technologies"}
                  setRemovedSkills={(removedSkills) => {
                    setRemovedSkills(removedSkills);
                  }}
                />
                <div className="ratings">
                  <div className="label">Skill Proficiency</div>
                  {skills.map((skill) => {
                    return (
                      <div className="input" key={skill.id}>
                        <label>{skill.name}</label>
                        <div className="rating">
                          {[...Array(5)].map((x, i) =>
                              <a className={`${skill.score === (i+1) ? 'active': ''}`} onClick={() => {
                                const newSkills = skills.map((s) => {
                                  if (s.id === skill.id) {
                                    s.score = (i+1);
                                  }
                                  return s;
                                });
                                setSkills(newSkills);
                                return ;
    
                              }}>{(i+1)}</a>
                          )}
                        </div>
                      </div>
                    )}
                  )}
                </div>
              </div>

              <div className="inputBox">
              <label>
              Resume *
            </label>
            <input
              style={{
                marginBottom: "20px",
                position: "relative",
                marginBottom: '20px',
                position: 'relative',
                top: 'initial',
                left: 'initial',
                width: '200px',
                opacity: 1
              }}
              onChange={(e) => onFileChange(e.target.files)}
              type="file"
              disabled={isNew}
            />
              </div>

              <div className="buttonContainer">
                <button
                  onClick={ async() => {
                    await saveSkills(userCollectiveId);
                    saveCollectiveUser(userCollectiveId);
                  }}
                  type="submit"
                  className="btn large"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
