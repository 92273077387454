import React, { useState, useEffect } from "react";
import logo from "../images/collective.png";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as Taco, faThumbsUp, faThumbsDown } from "@fortawesome/free-regular-svg-icons";
import { faStar} from "@fortawesome/free-solid-svg-icons";
import "../CSS/Collective/MatchReview.scss";
import Signup from "../components/Signup";
import JobPost from "./JobPost";
import { Link } from "react-router-dom";

export default function MatchReview(props) {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

    const [matches, setMatches] = useState(props.location.state.matches);
    const [matchCount, setMatchCount] = useState(props.location.state.matchCount);
    const [index, setIndex] = useState(props.location.state.matchIndex || 0);
    const [editing, setEditing] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const history = useHistory();

    const barWidth = 100 / matchCount;


    useEffect(() => {
        const header = document.querySelector("nav");
        header.style.display = "none";
    
        return () => {
          const header = document.querySelector("nav");
          header.style.display = "block";
        };
      }, []);

    const next = () => {
        if (!isAuthenticated && (index >= matchCount - 1)) {
            // show login if not signed up AND they've gone through all the matches
            setShowLogin(true);
            return;
        } else if (index === matchCount - 1) {
            return history.push(`/collective`);
        } else {
            setIndex(index + 1);
        }

        window.scrollTo(0, 0);
    }

    const save = async(interest) => {
        matches[index].candidateAccepted = interest;
        
        if (isAuthenticated) {
            let token = false;
            try {
                token = await getAccessTokenSilently({
                    audience: `https://api-v2.behearty.co`,
                    scope: "read:current_user offline_access",
                });
            } catch (error) {
                console.log(error);
            }

            let headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            };

            const body = {
                id: matches[index].companyCollectiveUserId,
                companyCollectiveId: -1,
                userCollectiveId: -1,
                companyCollectiveRoleId: -1,
                candidateAccepted: interest,
            }

            await fetch(
                `${process.env.REACT_APP_URL_JAVA}/company-collective-users/${matches[index].companyCollectiveUserId}/candidate-interest`,
                {
                    method: "PATCH",
                    body: JSON.stringify(body),
                    headers,
                }
            );
        } else {
            // save to local storage
            let answer = {
                candidateAccepted: interest,
                companyCollectiveRoleId: matches[index].companyCollectiveRoleId
            }
            
            let profile = JSON.parse(localStorage.getItem("PublicCandidateProfile"));
            profile.matchResponses.push(answer);
            localStorage.setItem("PublicCandidateProfile", JSON.stringify(profile));

            if (props.location.state.name) {
                // indicate interest to api
                const body = {
                    id: -1,
                    companyCollectiveId: -1,
                    userCollectiveId: -1,
                    companyCollectiveRoleId: matches[index].companyCollectiveRoleId,
                    candidateAccepted: interest,
                }

                let headers = {
                    "Content-Type": "application/json",
                };

                let response = fetch(
                    `${process.env.REACT_APP_URL_JAVA}/company-collective-users/interest?name=${props.location.state.name}`,
                    {
                        method: "POST",
                        body: JSON.stringify(body),
                        headers,
                    }
                );
            }
        }

        setEditing(false);
    };

    const formatStatus = (match) => {
        var newText = match.status
            .toLowerCase()
            .replaceAll("_", " ")
            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
        if ((newText === "Suggested" || newText === "Introduction Offered") && match.candidateAccepted == true) {
            newText = "Pending";
        } else if ((newText === "Suggested" || newText === "Introduction Offered") && match.candidateAccepted == false) {
            newText = "Not Interested"
        }
        return newText;
    };

    return (
        <div className="match-review">
          <Helmet>
            <meta charSet="utf-8" />
            <title>{`Hearty Collective`}</title>
            <meta name="description" content="Helping the best engineers find new jobs" />
            <meta property="og:title" content="Hearty Collective" />
            <meta
              property="og:description"
              content="Helping the best engineers find new job"
            />
            <meta
              property="og:image"
              content="https://www.hearty.xyz/images/collective.png"
            />
            <meta property="og:url" content={window.location} />
    
            <meta property="twitter:title" content="Hearty Collective" />
            <meta
              property="twitter:description"
              content="Helping the best engineers find new job"
            />
            <meta
              property="twitter:image"
              content="https://www.hearty.xyz/images/collective.png"
            />
            <meta name="twitter:card" content="summary_large_image"></meta>
          </Helmet>
    
          <div className="match-review-header">
            <div className="counter">{index + 1} of {matchCount}</div><img src={logo} alt="logo" className="logo" />
            {matches.filter(m => m.candidateAccepted == null).length === 0 && (
                <div className="exit"><Link to="/collective">Exit</Link></div>
            )}
          </div>
          <div className="bar">
            <div className="bar-item" style={{width: barWidth + '%', marginLeft: (barWidth * index) + '%'}}></div>
          </div>
          {(
            <>
            <JobPost job={matches[index]} />
            <div className="footer">
                {(matches[index].candidateAccepted == null || editing) && (
                    <div className="interested">
                        <h1>Are you interested in this role?</h1>
                        <div className="scores">
                            <div className="skills-score">
                                <label className="skills-label">Skills</label>
                                <span className="skill-stars">
                                    {matches[index].skillMatch >= 1 ? (
                                        <FontAwesomeIcon icon={faStar} />
                                    ) : (
                                        <FontAwesomeIcon icon={Taco} />
                                    )}
                                    {matches[index].skillMatch >= 2 ? (
                                        <FontAwesomeIcon icon={faStar} />
                                    ) : (
                                        <FontAwesomeIcon icon={Taco} />
                                    )}
                                    {matches[index].skillMatch >= 3 ? (
                                        <FontAwesomeIcon icon={faStar} />
                                    ) : (
                                        <FontAwesomeIcon icon={Taco} />
                                    )}
                                </span>
                            </div>
                            <div className="experience-score">
                                <label className="experience-label">Experience</label>
                                <span className="experience-stars">
                                    {matches[index].experienceMatch >= 1 ? (
                                        <FontAwesomeIcon icon={faStar} />
                                    ) : (
                                        <FontAwesomeIcon icon={Taco} />
                                    )}
                                    {matches[index].experienceMatch >= 2 ? (
                                        <FontAwesomeIcon icon={faStar} />
                                    ) : (
                                        <FontAwesomeIcon icon={Taco} />
                                    )}
                                    {matches[index].experienceMatch >= 3 ? (
                                        <FontAwesomeIcon icon={faStar} />
                                    ) : (
                                        <FontAwesomeIcon icon={Taco} />
                                    )}
                                </span>
                            </div>
                            <div className="salary-score">
                                <label className="salary-label">Salary</label>
                                <span className="salary-stars">
                                    {matches[index].salaryMatch >= 1 ? (
                                        <FontAwesomeIcon icon={faStar} />
                                    ) : (
                                        <FontAwesomeIcon icon={Taco} />
                                    )}
                                    {matches[index].salaryMatch >= 2 ? (
                                        <FontAwesomeIcon icon={faStar} />
                                    ) : (
                                        <FontAwesomeIcon icon={Taco} />
                                    )}
                                    {matches[index].salaryMatch >= 3 ? (
                                        <FontAwesomeIcon icon={faStar} />
                                    ) : (
                                        <FontAwesomeIcon icon={Taco} />
                                    )}
                                </span>
                            </div>
                        </div>
                        <div className="buttons">
                            <button className="no" onClick={ async(e) => {
                                await save(false);
                                next();
                            }}>
                                <FontAwesomeIcon icon={faThumbsDown} />
                                No
                            </button>
                            <button className="yes" onClick={ async(e) => {
                                await save(true);
                                next();
                            }}>
                                Yes
                                <FontAwesomeIcon icon={faThumbsUp } />
                            </button>
                        </div>
                    </div>
                )}

                {matches[index].candidateAccepted != null && !editing && (
                    <div className="edit">
                        <h1>Role Status</h1>
                        <h2>{formatStatus(matches[index])}</h2>
                        <div className="buttons">
                            {(matches[index].candidateAccepted !== null && (matches[index].status === "SUGGESTED" || matches[index].status === "INTRODUCTION_OFFERED")) && (
                                <button className="edit-button" onClick={() => {setEditing(true)}}>Edit Response</button>
                            )}
                            <button className="next" onClick={(e) => next()}>Next</button>
                        </div>
                    </div>
                )}
            </div>
            </>
          )}
          {showLogin && (
            <>
            <div className="modal-container">
                <div className="modal">
                    <Signup auth0={props.auth0} />
                </div>
                <div className="skrim">
                </div>
            </div>
            </>
          )}
        </div>
      );
}