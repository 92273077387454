import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  faChevronDown,
  faChevronRight,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

const ApproveTrophy = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [files, setFiles] = useState([]);
  const [denying, setDenying] = useState(false);
  const [denyingMessage, setDenyingMessage] = useState("");

  useEffect(() => {
    getFiles();
  }, [props.trophy]);

  const ApproveTrophy = async (trophyId) => {
    const body = {
      status: "APPROVED",
    };

    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const saveTrophy = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-trophies/${props.trophy.userTrophyId}/status`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    const trophy = await saveTrophy.json();
    props.callback();
  };

  const DenyTrophy = async (trophyId) => {
    const body = {
        status: "DENIED",
        message: denyingMessage,
      };
  
      let token = await getAccessTokenSilently({
        audience: `https://api-v2.behearty.co`,
        scope: "read:current_user offline_access",
      });
  
      const saveTrophy = await fetch(
        `${process.env.REACT_APP_URL_JAVA}/user-trophies/${props.trophy.userTrophyId}/status`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
  
      const trophy = await saveTrophy.json();
      setDenying(false);
      setDenyingMessage('');
      props.callback();
  };

  const downloadFile = async(urlToSend, filename)=> {

    const extension =  filename.split('/').pop().split('?')[0].split('.').pop();

    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });
    
    var req = new XMLHttpRequest();
    req.open("GET", urlToSend, true);
    req.setRequestHeader('Authorization', 'Bearer ' + token);
    req.responseType = "blob";
    req.onload = function (event) {
      const fileName = `${filename}.${extension}`;
      const blob = req.response;

      if (window.navigator.msSaveBlob) { // IE
          window.navigator.msSaveOrOpenBlob(blob, fileName);
      } 
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);                
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(link.href);
    };

    req.send();
}

  const getFiles = async () => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const filesRequest = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/user-trophies/${props.trophy.userTrophyId}/files`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    let files = await filesRequest.json();

    files = files.filter((file) => {
      return file.type === "REQUEST_PROOF";
    });

    setFiles(files);
  };

  return (
    <div className="approval" key={props.trophy.trophyId}>
      <h3>{props.trophy.trophyCollectionName}</h3>
      <h5>Trophy: {props.trophy.trophyName}</h5>
      {props.trophy.statusMessage && <p>{props.trophy.statusMessage}</p>}
      <div className="user">
        <a href={`/user/${props.trophy.userCustomUrl}`}>
          <img
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "50%",
            }}
            src={props.trophy.userImage}
            alt="user"
          />
          <p>{props.trophy.userName}</p>
        </a>
      </div>
      <div style={{
        marginTop:20
      }}>
        <h3>Request Reason</h3>
        <p>{props.trophy.requestReason}</p>
      </div>
      {files.length > 0 && (
        <div style={{
          marginTop:20
        }} className="files">
          <h3>Files</h3>
          {files.map((file) => {
            return (
              <div className="file">
                <a download onClick={async ()=>{

              downloadFile(`${process.env.REACT_APP_URL_JAVA}/user-trophies/${props.trophy.userTrophyId}/files/${file.id}`, file.filename)

                }}>{file.filename}</a>
              </div>
            );
          })}
        </div>
      )}
      {!denying && (
        <div className="actions">
          <button onClick={() => ApproveTrophy(props.trophy.id)}>
            Approve
          </button>
          <button onClick={() => setDenying(true)}>Deny</button>
        </div>
      )}

      {denying && (
        <div className="deny-form">
          <h3>Deny Reason</h3>
          <textarea onChange={(e) => setDenyingMessage(e.target.value)} />
          <button onClick={() => DenyTrophy(props.trophy.id)}>Deny</button>
        </div>
      )}
    </div>
  );
};

export default ApproveTrophy;
