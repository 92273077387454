import React from 'react';
import './App.scss';
import {
  Redirect,
} from "react-router-dom";



class Projects extends React.Component {
  constructor(props) {
    super(props);
    window.scrollTo(500, 0);
    this.state = {
    }
    this.listRef = React.createRef();
  }

  componentDidMount() {
  }


  goToNew() {
    this.setState({
      redirect: `/admin/projects/new`
    })
  }

  render() {

    if (this.state.redirect) return <Redirect to={this.state.redirect} />

    return (
      <div className="app terms">
        <div className="projects privacy">
<p class="p2"><span class="s1">Last Revised: September 9, 2021</span></p>
<p class="p2"><span class="s1">Referin (collectively with its subsidiaries, &ldquo;Company,&rdquo; &ldquo;the Company,&rdquo; &ldquo;we,&rdquo; &ldquo;us,&rdquo; and &ldquo;our&rdquo;) is a software services provider.<span class="Apple-converted-space">&nbsp; </span>The Company respects your privacy and is committed to protecting your privacy through our compliance with this Privacy Policy (the &ldquo;Policy&rdquo;).<span class="Apple-converted-space">&nbsp; </span>This Policy should be read in conjunction with our Terms of Use, into which this Policy is incorporated by reference. </span></p>
<p class="p2"><span class="s1">This Policy describes:</span></p>
<ul class="ul1">
<li class="li2"><span class="s1">The types of information we collect from you or that you may provide when you visit our website available at: https://behearty.co, as well as any websites, blogs or related mobile applications directly owned by Company (collectively, our &ldquo;Platform&rdquo;), and any related data, accounts, profiles, forms and all content provided therein for the purposes of providing candidate recruitment services to our clients (collectively, the &ldquo;Services&rdquo;), which are employers seeking qualified candidates for client employment opportunities (&ldquo;Clients&rdquo;).<span class="Apple-converted-space">&nbsp; </span><br /> </span></li>
<li class="li2"><span class="s1">Our practices for collecting, using, maintaining, protecting, and disclosing that information.<br /> </span></li>
</ul>
<p class="p2"><span class="s1">Please read this Policy carefully to understand our practices regarding your information and how we will treat it.<span class="Apple-converted-space">&nbsp; </span>If you do not agree with our policies and practices, then please do not use our Platform.<span class="Apple-converted-space">&nbsp; </span>By using our Platform, you agree to the terms of this Policy.<span class="Apple-converted-space">&nbsp; </span>This Policy may change from time to time (see below, &ldquo;Changes to this Policy&rdquo;).<span class="Apple-converted-space">&nbsp; </span>Your continued use of our Platform after we make changes is deemed acceptance of those changes, so please check the Policy periodically for updates. </span></p>
<p><strong>Users</strong><br />  
  The Company provides the Services to assist its Clients in identifying potential candidates for the Client’s recruitment to employment (“Recruitment Candidates”). If you use the Platform to create an account, you are using the Platform and Services as a User.  As part of the Services, the Platform provides you, as a User, the ability to anonymously rate your personal and professional contacts as potential Recruitment Candidates for positions of employment offered by our Clients. Your ratings are aggregated with those of other Users and effectively serve as a referral to our Clients for their internal recruiting purposes.  By creating an account and enabling the Company to access your social media accounts, you give us permission to store your Personal Information and the personal information of your contacts on the Platform and use in the Services. </p>

<p class="p2"><span class="s1"><strong>What We Collect and How We Collect It</strong></span></p>
<p class="p2"><span class="s1">To ensure that we provide you with the best possible experience, we will store, use, and share information about you in accordance with this Policy.&nbsp;</span></p>
<p class="p2"><span class="s1"><em>Information You Provide to Us</em></span></p>
<p class="p2"><span class="s1">Personal Information is any information that can be used to individually identify you or one of your contacts from a larger group, such as data including, but not limited to:</span></p>
<ul class="ul1">
<li class="li2"><span class="s1">first and last name;</span></li>
<li class="li2"><span class="s1">email address;</span></li>
<li class="li2"><span class="s1">telephone number;</span></li>
<li class="li2"><span class="s1">place of employment;</span></li>
<li class="li2"><span class="s1">social media account information;</span></li>
<li class="li2"><span class="s1">professional experience (i.e. title and organization); and</span></li>
<li class="li3"><span class="s1">comments or messages provided in free text boxes.</span></li>
</ul>
<p class="p2"><span class="s1">You may provide us Personal Information when you:&nbsp;</span></p>
<ul class="ul1">
<li class="li2"><span class="s1">request information;</span></li>
<li class="li2"><span class="s1">subscribe to our emails;</span></li>
<li class="li2"><span class="s1">register yourself with the Platform;&nbsp;</span></li>
<li class="li2"><span class="s1">link or otherwise provide us access to your social media accounts (i.e. LinkedIn); and to your contacts or connections; and</span></li>
<li class="li2"><span class="s1">rate your contacts or connections as potential referrals to your employer.&nbsp;</span></li>
</ul>
<p class="p5"><span class="s1">A third party, such as the provider of the social media accounts you link to your Company account, may also provide us your Personal Information and Recruitment Candidates&rsquo; Personal Information when you synchronize or use the Company technologies to share third-party information with the Platform.<br /> </span></p>
<p class="p2"><span class="s3">Important Notice About Consumer Information</span><span class="s1">. The Company is a seller of software services.<span class="Apple-converted-space">&nbsp; </span>The Company is not a &ldquo;consumer reporting agency&rdquo; or &ldquo;reseller&rdquo; as those terms are defined under the Fair Credit Reporting Act (&ldquo;FCRA&rdquo;). The Company does not process, assemble, or merge any Personal Information to create or modify any consumer report for use by its Clients. Furthermore, the Company plays no role in any Client&rsquo;s eligibility determination about any Recruitment Candidate. <span class="Apple-converted-space">&nbsp; &nbsp;</span></span></p>
<p class="p2"><span class="s1"><strong>Automated Information Collection</strong></span></p>
<p class="p2"><span class="s1">In addition to the information that you provide to us, we may also collect information about you during your visit to our Platform.<span class="Apple-converted-space">&nbsp; </span>We collect this information using automated tools that are detailed below.<span class="Apple-converted-space">&nbsp; </span>These tools may collect information about your behavior and your computer system, such as your internet address (IP Address), the pages you have viewed, and the actions you have taken while using the Platform.<span class="Apple-converted-space">&nbsp; </span>Some of the tools we may use to automatically collect information about you may include:</span></p>
<ol class="ol1">
<li>Chrome extension.  As part of your use of the Platform, you may be instructed to install a companion Chrome extension. This extension uses your Linkedin session token to scrape the search results page of your first-degree connections. For each connection, we will collect:
<ul>
<li>Name</li>
<li>Profile photo</li>
<li>Headline text</li>
</ul>
Once all search results have been scraped, we will destroy your session token; no permanent session information will be retained. 
</li>
<br />
<li class="li2"><span class="s3">Google Contact Import.</span> As part of your use of the platform, you may be instructed to import your contacts from one or more of your Google accounts. For each contact imported, we will collect:<ul>
  <li>Name</li>
  <li>Email Addresses</li>
  <li>Phone numbers</li>
  </ul>
  </li>
<br />
<li class="li2"><span class="s3">Cookies</span><span class="s1">.<span class="Apple-converted-space">&nbsp; </span>A &ldquo;cookie&rdquo; is a small data file transmitted from a website to your device&rsquo;s hard drive.<span class="Apple-converted-space">&nbsp; </span>Cookies are usually defined in one of two ways, and we may use both of them:<span class="Apple-converted-space">&nbsp; </span><br /> <br /> (1) session cookies, which do not stay on your device after you close your browser, and <br /> <br /> (2) persistent cookies, which remain on your device until you delete them or they expire.<span class="Apple-converted-space">&nbsp; </span><br /> <br /> We may use the following categories of cookies on our Platform.</span><span class="s4"><sup><br /> </sup></span></li>
<ol class="ol1">
<ol class="ol2">
<li class="li2"><span class="s1"><strong>Strictly Necessary Cookies.<span class="Apple-converted-space">&nbsp; </span></strong>These cookies are essential in order to enable you to move around the Platform and use its features. Without these cookies, services you have requested, such as maintaining a record of your purchased items (e.g. a shopping cart), cannot be provided.<br /> </span></li>
<li class="li2"><span class="s1"><strong>Performance Cookies.<span class="Apple-converted-space">&nbsp; </span></strong>These cookies collect anonymous information on how people use our Platform to help us understand how you arrive at our site, browse or use our Platform and highlight areas where we can improve, such as navigation.<span class="Apple-converted-space">&nbsp; </span>The data stored by these cookies never shows personal details from which your individual identity can be established.<br /> </span></li>
<li class="li2"><span class="s1"><strong>Functionality Cookies.</strong><span class="Apple-converted-space">&nbsp; </span>These cookies remember choices you make such as the country from which you visit our Platform, your preferred language, and your search parameters.<span class="Apple-converted-space">&nbsp; </span>This information can then be used to provide you with an experience more appropriate to your selections and to make your visits to our Platform more tailored to your preferences.<span class="Apple-converted-space">&nbsp; </span>The information in these cookies may be anonymized.<span class="Apple-converted-space">&nbsp; </span>These cookies cannot track your browsing activity on other websites.<br /> </span></li>
<li class="li2"><span class="s1"><strong>Targeting Cookies or Advertising Cookies. </strong>These cookies collect information about your browsing habits in order to make advertising more relevant to you and your interests.<span class="Apple-converted-space">&nbsp; </span>They are also used to limit the number of times you see an advertisement as well as help measure the effectiveness of an advertising campaign.<span class="Apple-converted-space">&nbsp; </span>The cookies are usually placed by third-party advertising networks.<span class="Apple-converted-space">&nbsp; </span>These cookies remember the websites you visit and that information is shared with other parties such Facebook and Google. <br /> </span></li>
</ol>
</ol>
</ol>
<p class="p6"><span class="s1">Of course, if you do not wish to have cookies on your devices, you may turn them off at any time by modifying your internet browser&rsquo;s settings.<span class="Apple-converted-space">&nbsp; </span>However, by disabling cookies on your device, you may be prohibited from full use of the Platform&rsquo;s features or lose access to some functionality.</span></p>
<ol class="ol1">
<li class="li2"><span class="s3">Google Analytics</span><span class="s1">.<span class="Apple-converted-space">&nbsp; </span>The Platform sends aggregated, non-Personal Information to Google Analytics for the purpose of providing us with the ability to conduct technical and statistical analysis on the Platform&rsquo;s performance.<span class="Apple-converted-space">&nbsp; </span>For more information on how Google Analytics supports the Platform and uses information sent from the Platform, please review Google&rsquo;s privacy policy available at <a href="https://policies.google.com/technologies/partner-sites"><span class="s5">https://policies.google.com/technologies/partner-sites</span></a></span><span class="s3">.</span></li>
<li class="li2"><span class="s3">Web Beacons</span><span class="s1">.<span class="Apple-converted-space">&nbsp; </span>A Web Beacon is an electronic image. Web Beacons can track certain things from your computer and can report activity back to a web server allowing us to understand some of your behavior.<span class="Apple-converted-space">&nbsp; </span>If you choose to receive emails from us, we may use Web Beacons to track your reaction to our emails.<span class="Apple-converted-space">&nbsp; </span>We may also use them to track if you click on the links and at what time and date you do so.<span class="Apple-converted-space">&nbsp; </span>Some of our third-party marketing partners may use Web Beacons to track your interaction with online advertising banners on our Platform.<span class="Apple-converted-space">&nbsp; </span>This information is only collected in aggregate form and will not be linked to your Personal Information.<span class="Apple-converted-space">&nbsp; </span>Please note that any image file on a webpage can act as a Web Beacon.<br /> </span></li>
<li class="li2"><span class="s3">Embedded Web Links</span><span class="s1">.<span class="Apple-converted-space">&nbsp; </span>Links provided in our emails and, in some cases, on third-party websites may include tracking technology embedded in the link. The tracking is accomplished through a redirection system. The redirection system allows us to understand how the link is being used by email recipients.<span class="Apple-converted-space">&nbsp; </span>Some of these links will enable us to identify that you have personally clicked on the link and this may be attached to the Personal Information that we hold about you. This data is used to improve our service to you and to help us understand the performance of our marketing campaigns.<br /> </span></li>
<li class="li2"><span class="s3">Third-party Websites and Services</span><span class="s1">.<span class="Apple-converted-space">&nbsp; </span>We work with a number of service providers of marketing communications technology.<span class="Apple-converted-space">&nbsp; </span>These service providers may use various data collection methods to improve the performance of the marketing campaigns we are contracting them to provide.<span class="Apple-converted-space">&nbsp; </span>The information collected can be gathered on our Platform and on the websites where our marketing communications are appearing.<span class="Apple-converted-space">&nbsp; </span>For example, we may collect data where our banner advertisements are displayed on third-party websites. </span></li>
</ol>
<p class="p8"><span class="s1"><strong>Your Choices and Selecting Your Privacy Preferences</strong></span></p>
<p class="p8"><span class="s1">We want to provide you with relevant information that you have requested.</span></p>
<p class="p8"><span class="s1">If we provide subscription-based services, such as email newsletters, we will allow you to make choices about what information you provide at the point of information collection or at any time after you have received a communication from us while you are subscribed.<span class="Apple-converted-space">&nbsp; </span>Transactional or service-oriented messages are usually excluded from such preferences, as such messages are required to respond to your requests or to provide goods and services, and are not intended for the purposes of marketing.</span></p>
<p class="p8"><span class="s1">We will not intentionally send you email newsletters and marketing emails unless you consent to receive such marketing information.<span class="Apple-converted-space">&nbsp; </span>After you request to receive these emails, you may opt out of them at any time by selecting the &ldquo;unsubscribe&rdquo; link at the bottom of each email.<span class="Apple-converted-space">&nbsp; </span>Please note that by opting out or unsubscribing you may affect other services you have requested we provide to you, in which email communication is a requirement of the service provided. </span></p>
<p class="p8"><span class="s1">Any such communications you receive from us will be administered in accordance with your preferences and this Policy.&nbsp;</span></p>
<p class="p8"><span class="s1"><strong>Accuracy and Access to Your Personal Information</strong></span></p>
<p class="p8"><span class="s1">We strive to maintain and process your information accurately.<span class="Apple-converted-space">&nbsp; </span>We have processes in place to maintain all of our information in accordance with relevant data governance frameworks and legal requirements.<span class="Apple-converted-space">&nbsp; </span>We employ technologies designed to help us maintain information accuracy on input and processing. </span></p>
<p class="p9"><span class="s1">Where we can provide you access to your Personal Information in our possession, we will always ask you for a username and password to help protect your privacy and security.<span class="Apple-converted-space">&nbsp; </span>We recommend that you keep your password safe, that you change it periodically, and that you do not disclose it to any other person or allow any other person to use it.&nbsp;</span></p>
<p class="p2"><span class="s1">To view and change the Personal Information that you have provided to us, you can log in to your account and follow the instructions on that webpage, or contact us directly for assistance.</span></p>
<p class="p2"><span class="s1"><strong>Information of Minors</strong></span></p>
<p class="p2"><span class="s1">We do not intentionally seek to gather information from individuals under the age of sixteen (16).<span class="Apple-converted-space">&nbsp; </span>We do not target the Platform to minors, and would not expect them to be engaging with our Platform or services.<span class="Apple-converted-space">&nbsp; </span>We encourage parents and guardians to provide adequate protection measures to prevent minors from providing information unwillingly on the internet.<span class="Apple-converted-space">&nbsp; </span>If we are aware of any Personal Information that we have collected about minors, we will take steps to securely remove it from our systems. </span></p>
<p class="p2"><span class="s1"><strong>How We Use Your Information</strong></span></p>
<p class="p2"><span class="s1">The information we gather and that you provide is collected to provide the Services, in addition to various other purposes, including, but not limited to: </span></p>
<ul class="ul1">
<li class="li10"><span class="s1">Client identification of a Recruitment Candidate for a Client job opportunity;</span></li>
<li class="li10"><span class="s1">Sharing aggregated ratings about Recruitment Candidates with a Client;</span></li>
<li class="li10"><span class="s1">Responding to general information requests regarding the Services;</span></li>
<li class="li10"><span class="s1">Assisting you with items such as personalized experiences, facilitation of product usage, and enforcement of Terms of Use;<span class="Apple-converted-space">&nbsp; &nbsp;</span></span></li>
<li class="li10"><span class="s1">Preventing malicious activity and providing you with a secure experience;&nbsp;</span></li>
<li class="li10"><span class="s1">Providing service and support for services you request; and</span></li>
<li class="li10"><span class="s1">Preventing unwanted messages or content.</span></li>
</ul>
<p class="p2"><span class="s1"><br /> <strong>How We Share Your Information</strong></span></p>
<p class="p2"><span class="s1">We will not share Personal Information with any third parties except as described in this Policy, including the following:</span></p>
<ul class="ul1">
<li class="li2"><span class="s3"><em>Clients</em></span><span class="s1">.<span class="Apple-converted-space">&nbsp; </span>We may share with a Client the basic employee information relevant to your individual Company account.  We will not share or link your Personal Information to your personal or professional contact lists, or your individual Recruitment Candidate ratings.  A Client’s actions are solely based on a Client’s own policies and procedures, so please read those carefully and direct questions to your employer. <br /> </span></li>
<li class="li2"><span class="s3"><em>Vendors</em></span><span class="s1">.<span class="Apple-converted-space">&nbsp; </span>We may share your Personal Information with third-party vendors, contractors, and other service providers working on behalf of the Company and require access to your Personal Information to carry out their support of our Services.</span></li>
<li class="li2"><span class="s3"><em>Compliance with Laws</em></span><span class="s1">.<span class="Apple-converted-space">&nbsp; </span>We may disclose your Personal Information to a third party if (1) we believe that disclosure is reasonably necessary to comply with any applicable law, regulation, subpoena, or court order, (2) to respond to duly authorized information requests from law enforcement or other governmental authorities, (3) to enforce our agreements, policies, or Terms and Conditions of Use, (4) to investigate and help prevent security threats, fraud, or other malicious activity, or (5) to respond to an emergency which we believe in good faith requires us to disclose such information to assist in preventing the death or serious bodily injury of any person or Company employee.</span></li>
</ul>
<p class="p2"><span class="s1">There are circumstances where the Company may decide to buy, sell, or reorganize its business in selected countries.<span class="Apple-converted-space">&nbsp; </span>Under these circumstances, it may be necessary to share or receive Personal Information with prospective or actual partners or affiliates.<span class="Apple-converted-space">&nbsp; </span>In such circumstances, the Company will ensure your information is used in accordance with this Policy. </span></p>
<p class="p2"><span class="s1"><strong>Safeguarding the Information We Collect</strong></span></p>
<p class="p2"><span class="s1">We use reasonable technical, administrative, and physical safeguards in order to protect your Personal Information against accidental loss and from unauthorized access, use, alteration, and disclosure.<span class="Apple-converted-space">&nbsp; </span>However, we can never promise 100% security.<span class="Apple-converted-space">&nbsp; </span>You have a responsibility, as well, to safeguard your information through the proper use and security of any online credentials used to access your Personal Information, such as a username and password.<span class="Apple-converted-space">&nbsp; </span>If you believe your credentials have been compromised, please change your password.<span class="Apple-converted-space">&nbsp; </span>Please also notify us of any unauthorized use.<span class="Apple-converted-space">&nbsp; &nbsp; &nbsp; </span><strong><br /> <br /> Third-party Websites <br /> <br /> </strong>This Policy </span><span class="s3">does not</span><span class="s1"> apply to websites or other domains that are maintained or operated by third parties or our affiliates.<span class="Apple-converted-space">&nbsp; </span>Our Platform may link to third-party websites and services, including Client websites and services, but these links are not endorsements of these sites, and this Policy does not extend to them.<span class="Apple-converted-space">&nbsp; </span>Because this Policy is not enforced on these third-party websites, we encourage you to read any posted privacy policy of the third-party website before using the service or site and providing any information.&nbsp;</span></p>
<p class="p11"><span class="s1"><strong>Your California Rights<br /> </strong></span></p>
<p class="p11"><span class="s1">Pursuant to California Civil Code Section &sect; 1798.83, we will not disclose or share your Personal Information with third parties for the purposes of third-party marketing to you without your prior consent. </span></p>
<p class="p11"><span class="s1">Other than as disclosed in this Policy, the Platform does not track users over time and across third-party websites to provide targeted advertising.<span class="Apple-converted-space">&nbsp; </span>Therefore, the Platform does not operate any differently when it receives Do Not Track (&ldquo;DNT&rdquo;) signals from your internet web browser. </span></p>
<p class="p11"><span class="s1">If you are a California consumer, as defined by the California Consumer Privacy Act of 2018 (&ldquo;Act&rdquo;), please be advised that Referin, LLC, does not currently meet the regulatory thresholds of a &ldquo;business&rdquo; as that term is defined under the Act and therefore is not required to comply with the Act.<span class="Apple-converted-space">&nbsp; </span>Regardless, all Personal Information will be processed in accordance with this Policy. </span></p>
<p class="p2"><span class="s1"><strong>For Individuals Outside the United States</strong></span></p>
<p class="p2"><span class="s1">Our Platform is designed for use by individuals in the United States only.<span class="Apple-converted-space">&nbsp; </span>We do not warrant or represent this Policy or the Platform&rsquo;s use of your Personal Information comply with the laws of any other jurisdiction.<span class="Apple-converted-space">&nbsp; </span>Furthermore, to provide our Services, we may store, process, and transmit information in the United States and other locations around the world, including countries that may not have the same privacy and security laws as yours.<span class="Apple-converted-space">&nbsp; </span>Regardless of the country in which such information is stored, we will process your Personal Information in accordance with this Policy.&nbsp;</span></p>
<p class="p2"><span class="s1"><strong>Changes to this Policy</strong></span></p>
<p class="p2"><span class="s1">If we make any changes to this Policy, a revised Policy will be posted on this webpage and the date of the change will be reported in the &ldquo;Last Revised&rdquo; block above.<span class="Apple-converted-space">&nbsp; </span>You can get to this page from any of our webpages by clicking on the &ldquo;Privacy Policy&rdquo; link (usually at the bottom of the screen).</span></p>
<p class="p2"><span class="s1"><strong>How to Contact Us</strong></span></p>
<p class="p2"><span class="s1">We value your opinions and welcome your feedback.<span class="Apple-converted-space">&nbsp; </span>To contact us about this Policy or your Personal Information, please contact us at: </span></p>
<p class="p2"><span class="s1">Referin LLC</span></p>
<p class="p2"><span class="s1">4455 Carver Woods Dr Suite 140</span></p>
<p class="p2"><span class="s1">Blue Ash, OH 45242</span></p>
<p class="p13"><span class="s3"><a href="mailto:info@behearty.co">info@behearty.co</a></span></p>
              
        </div>
      </div>
    );
  }
}

export default Projects;