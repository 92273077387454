import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  faChevronDown,
  faChevronRight,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

const CreateTrophy = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [collections, setCollections] = useState([]);
  const [properties, setProperties] = useState({});
  const [collection, setCollection] = useState(false);
  const [name, setName] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [description, setDescription] = useState("");
  const [image, setImage] = useState({});
  useEffect(() => {
    getCollections();
  }, []);

  const onFileChange = async (file) => {
    const selectedImage = file[0];

    setImage(selectedImage);
  };

  const createTrophy = async (e) => {
    if (e) e.preventDefault();
    setWaiting(true);

    if(!collection || !name || !description || !image) {
      return alert("Please fill out all fields");
    }

    const body = {
      trophyCollectionId: collection,
      trophyName:name,
      trophyDescription: description,
      metadata: properties,
    };

    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const trophyRequest = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophies`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    const trophy = await trophyRequest.json();

    if (trophy.trophyId) {
      const image = await uploadImage(trophy.trophyId);
      props.callback("Succesfully created trophy");
      setWaiting(false);
    }
  };

  const uploadImage = async (id) => {
    let selectedImage = image;

    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", selectedImage, selectedImage.name);

    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const response = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophies/${id}/media`,
      {
        method: "POST",
        enctype: "multipart/form-data",
        contentType: false,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );

    if (response.ok) {
      return response.text();
    }

    throw new Error("Error uploading image");
  };

  const getProperties = async (id) => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const PropertyRequest = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophy-collections/${id}/filters`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const properties = await PropertyRequest.json();

    const propertiesObject = {};

    properties.forEach((property) => {
      propertiesObject[property.name] = "";
    });

    setProperties(propertiesObject);
  };

  const getCollections = async () => {
    let token = await getAccessTokenSilently({
      audience: `https://api-v2.behearty.co`,
      scope: "read:current_user offline_access",
    });

    const collectionRequest = await fetch(
      `${process.env.REACT_APP_URL_JAVA}/trophy-collections?page=0&size=100`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const collections = await collectionRequest.json();

    setCollections(collections.content);
  };

  return (
    <div className="container">
      <form
        onSubmit={(e) => {
          createTrophy(e);
        }}
      >
        <div className="form-group">
          <label htmlFor="collection_name">Collection</label>
          <select
            onChange={(e) => {
              console.log(e.target.value);
              setCollection(e.target.value);
              getProperties(e.target.value);
            }}
          >
            <option selected disabled={true} value="Please choose a collection">Please choose a collection</option>
            {collections.map((collection) => (
              <option key={collection.id} value={collection.id}>
                {collection.name}
              </option>
            ))}
          </select>
        </div>

        {collection && (
        <div className="groupContainer">
        <div className="form-group">
          <label htmlFor="collection_name">Trophy Name</label>
          <input
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            type="text"
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="collection_name">Trophy Description</label>
          <textarea
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="collection_name">Trophy Video</label>
          <input onChange={(e) => onFileChange(e.target.files)} type="file" />
        </div>

        <div className="form-group">
          <label htmlFor="collection_name">Trophy Properties</label>
          {Object.keys(properties).map((keyName, i) => (
            <div
              style={{
                marginRight: "30px",
              }}
              className="travelcompany-input"
              key={i}
            >
              <span
                style={{
                  marginRight: "10px",
                }}
                className="input-label"
              >
                {keyName}
              </span>
              <input
                type="text"
                onChange={(e) => {
                  const newProperties = { ...properties };
                  newProperties[keyName] = e.target.value;
                  setProperties(newProperties);
                }}
              />
            </div>
          ))}

          {(!properties || Object.keys(properties).length === 0) && (
            <p>No properties for this collection</p>
          )}
        </div>

        {waiting && ( 
          <p className="alert alert-success">Uploading...</p>
        )}

        <button disabled={waiting} type="submit">Create</button>
        </div>
        )}
      </form>
    </div>
  );
};

export default CreateTrophy;
